import React, { Suspense } from "react";
import { useThree, Canvas, extend } from "@react-three/fiber";
// import { Sparks } from './Sparks';
// import { SparkStorm } from './SparkStorm';
import { SpaceDust } from "./SpaceDust";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Sparks } from "./Sparks";
import { MeshLine } from "./Meshline/MeshLine";
import { MeshLineMaterial } from "./Meshline/Materials";

// import { Planet } from './Planet';
extend({ MeshLine, MeshLineMaterial, OrbitControls });

export const Controls = (props) => {
  const { gl, camera } = useThree();
  return <orbitControls args={[camera, gl.domElement]} {...props} />;
};
const colors = {
  malevolentIllusion: ["#c06995", "#de77c7", "#df86df", "#d998ee", "#ceadf4", "#c6bff9"],
  sunnyRainbow: ["#fbe555", "#fb9224", "#f45905", "#be8abf", "#ffeed0", "#feff89"],
};
export function ParticleVisualizer({ height, width }: { height: string; width: string }) {
  return (
    <div style={{ width: width, height: height }}>
      <Canvas
        id={"particleVisualizer"}
        pixelRatio={window.devicePixelRatio}
        camera={{ fov: 100, position: [0, 0, 30] }}
        onCreated={({ gl, size, camera }) => {
          if (size.width < 600) {
            camera.position.z = 45;
          }
          gl.setClearColor(new THREE.Color("#020207"));
        }}>
        <Suspense fallback={null}>
          <>
            <Controls />
            <pointLight distance={100} intensity={4} color="white" />
            <group>
              {/* <Planet /> */}

              <mesh>
                <sphereBufferGeometry attach="geometry" args={[12, 32, 32]} />
                {/* <meshMatcapMaterial matcap={matcap} /> */}
              </mesh>
              <SpaceDust count={10000} />
              <Sparks count={20} colors={colors.malevolentIllusion} />
              {/*
          <SparkStorm count={500} colors={colors.sunnyRainbow} /> */}
            </group>
          </>
        </Suspense>
      </Canvas>
    </div>
  );
}
