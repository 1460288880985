import { Room } from "colyseus.js";
import React, { useEffect, useState } from "react";
import { TomsState } from "../../../state/TomsState";
import { useColyseusRoom2 } from "../../hooks/useColyseusRoom";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { Sound } from "./Sound";
import "./xylophone.scss";

export function Xylophone() {

  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  const playerName = useGlobalStore2((store) => store.playerName);
  const playerImage = useGlobalStore2((store) => store.playerImageBase64);

  let defaultName = "TOM";

  let toMerge = { name: playerName || defaultName, forceNewRoom: false };
  console.log("JOINING COLLABAI ROOM WITH CONTEXT", teamsContext);
  let joinObj = {
    ...teamsContext,
    ...toMerge,
    page: { frameContext: "none" },
    ...{ name: playerName, imageBase64: playerImage },
  };

  const { client, room } = useColyseusRoom2<TomsState>(
    "tom",
    joinObj
  );

  const [volume, setVolume] = useState(100);
  let context = new (window.AudioContext || window.webkitAudioContext)();

  let playSound2 = (e) => {
    console.log(e.target.dataset);
    let sound = new Sound(context);
    let value = e.target.dataset.frequency;
    sound.play(value, volume);
    console.log(volume);
    sound.stop();
  };

  useEffect(() => {
    document.addEventListener("mousemove", cursor);
    var stick = document.querySelector<HTMLElement>(".stick");
    var glow1 = document.querySelector<HTMLElement>(".stick .glow-1");
    var glow2 = document.querySelector<HTMLElement>(".stick .glow-2");
    var notes = document.querySelectorAll<HTMLElement>(".note");
    notes.forEach((note) => {
      note.addEventListener("mouseenter", () => {
        room.send("note", note.dataset.frequency);
        playSound(note, volume / 100);
        showGlow();
        setTimeout(hideGlow, 300);
      });
      note.addEventListener("mouseleave", hideGlow);
    });

    function playSound(note, v) {
      let sound = new Sound(context);
      let value = note.dataset.frequency;
      sound.play(value, v);
      console.log(v);
      sound.stop();
    }

    function playRemoteSound(freq) {
      let sound = new Sound(context);
      sound.play(freq);
      sound.stop();
    }

    function showGlow() {
      glow1.style.animationPlayState = "running";
      glow2.style.animationPlayState = "running";
      glow1.classList.remove("hidden");
      glow2.classList.remove("hidden");
    }

    function hideGlow() {
      glow1.style.animationPlayState = "paused";
      glow2.style.animationPlayState = "paused";
      glow1.classList.add("hidden");
      glow2.classList.add("hidden");
    }

    function cursor(e) {
      stick.style.top = e.clientY - 12 + "px";
      stick.style.left = e.clientX + 12 + "px";
    }

    let listener = room.onMessage("notePlayed", (message) => {
      console.log(message);
      playRemoteSound(message);
      const uiNote = document.querySelector(`[data-frequency="${message}"]`);
      console.log(uiNote);
      uiNote.classList.add("selected");
      setTimeout(() => {
        uiNote.classList.remove("selected");
      }, 300);
    });

    return () => {
      listener();
      document.removeEventListener("mousemove", cursor);
    };
  }, [volume]);
  return (
    <div className={"xylophone"}>
      <input
        type="range"
        min="0"
        max="100"
        value={volume}
        className="range range-xs"
        onChange={(ev) => {
          setVolume(ev.target.value);
        }}
      />
      <div className="stick">
        <div className="glow-1 hidden"></div>
        <div className="glow-2 hidden"></div>
      </div>
      <div className="xylophone-container">
        <div className="top-row">
          <div className="note-group group-of-two group-1">
            <div className="note note-1" data-frequency="207.65">
              <div>G#</div>
            </div>
            <div className="note note-2" data-frequency="233.08">
              <div>A#</div>
            </div>
          </div>
          <div className="note-group group-of-two group-2">
            <div className="note note-3" data-frequency="277.18">
              <div>C#</div>
            </div>
            <div className="note note-4" data-frequency="311.13">
              <div>D#</div>
            </div>
          </div>
          <div className="note-group group-of-three group-3">
            <div className="note note-5" data-frequency="369.99">
              <div>F#</div>
            </div>
            <div className="note note-6" data-frequency="415.30">
              <div>G#</div>
            </div>
            <div className="note note-7" data-frequency="466.16">
              <div>A#</div>
            </div>
          </div>
          <div className="note-group group-of-two group-4">
            <div className="note note-8" data-frequency="554.37">
              <div>C#</div>
            </div>
            <div className="note note-9" data-frequency="622.25">
              <div>D#</div>
            </div>
          </div>
          <div className="note-group group-of-three group-5">
            <div className="note note-10" data-frequency="739.99">
              <div>F#</div>
            </div>
            <div className="note note-11" data-frequency="830.61">
              <div>G#</div>
            </div>
            <div className="note note-12" data-frequency="932.33">
              <div>A#</div>
            </div>
          </div>
        </div>
        <div className="bottom-row">
          <div className="note note-13" data-frequency="196.00">
            <div>G</div>
          </div>
          <div className="note note-14" data-frequency="220.00">
            <div>A</div>
          </div>
          <div className="note note-15" data-frequency="246.94">
            <div>B</div>
          </div>
          <div className="note note-16" data-frequency="261.63">
            <div>C</div>
          </div>
          <div className="note note-17" data-frequency="293.66">
            <div>D</div>
          </div>
          <div className="note note-18" data-frequency="329.63">
            <div>E</div>
          </div>
          <div className="note note-19" data-frequency="349.23">
            <div>F</div>
          </div>
          <div className="note note-20" data-frequency="392.00">
            <div>G</div>
          </div>
          <div className="note note-21" data-frequency="440.00">
            <div>A</div>
          </div>
          <div className="note note-22" data-frequency="493.88">
            <div>B</div>
          </div>
          <div className="note note-23" data-frequency="523.25">
            <div>C</div>
          </div>
          <div className="note note-24" data-frequency="587.33">
            <div>D</div>
          </div>
          <div className="note note-25" data-frequency="659.25">
            <div>E</div>
          </div>
          <div className="note note-26" data-frequency="698.46">
            <div>F</div>
          </div>
          <div className="note note-27" data-frequency="783.99">
            <div>G</div>
          </div>
          <div className="note note-28" data-frequency="880.00">
            <div>A</div>
          </div>
          <div className="note note-29" data-frequency="987.77">
            <div>B</div>
          </div>
          <div className="note note-30" data-frequency="1046.50">
            <div>C</div>
          </div>
        </div>
      </div>
    </div>
  );
}
