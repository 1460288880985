import React, { useEffect, useMemo, useState } from "react";
import {
  CallClient,
  CallAgent,
  VideoStreamRenderer,
  VideoStreamRendererView,
  LocalVideoStream,
  LocalAudioStream,
  CaptionsInfo,
  Features,
  CallClientFeatureFactory,
  Call,
  TeamsCall,
  CallClientFeature,
} from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { ChatClient } from "@azure/communication-chat";
import { getOnBehalfOfToken, getTeamsAuthToken } from "../../Helpers";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { createAudioTrackToSend } from "./ACS";

export function useACS(
  connectionString: string,
  endpointUrl: string,
  joinUrl: string,
  threadId?: string,
  displayName?: string
) {
  const [call, setCall] = useState<Call | TeamsCall>();
  const [callIsConnected, setCallIsConnected] = useState<boolean>(false);
  const [communicationUserId, setCommunicationUserId] = useState<string>("");
  const [communicationUserToken, setCommunicationUserToken] = useState<string>("");

  const teamsCtx = useGlobalStore2((state) => state.teamsContext);

  async function setup() {
    // if (localStorage.getItem("savedToken")) {
    //   console.log("using savedToken");
    //   const savedToken = localStorage.getItem("savedToken");

    //   const callClient = new CallClient();
    //   const tokenCredential = new AzureCommunicationTokenCredential(savedToken);

    //   let agent = await callClient.createCallAgent(tokenCredential, { displayName: "Meeting Soundboard" });

    //   let call = agent.join(
    //     {
    //       meetingLink: joinUrl,
    //     },
    //     {}
    //   );

    //   setCall(call);
    //   console.log("USED SAVEDTOKEN TO LOGIN");
    //   return;
    // } else {
    const identityClient = new CommunicationIdentityClient(connectionString);

    let identityResponse = await identityClient.createUserAndToken(["voip", "chat"]);
    console.log("ID", identityResponse);

    setCommunicationUserId(identityResponse.user.communicationUserId);
    setCommunicationUserToken(identityResponse.token);

    // let r2 = await identityClient.getToken(identityResponse.user, ["voip", "chat"]);
    // let teamsAuthToken = await getTeamsAuthToken();
    // let oboToken = await getOnBehalfOfToken(teamsAuthToken);
    // let clientId = process.env.REACT_APP_CLIENT_ID;
    // console.log("clientId", clientId);
    // console.log(oboToken);
    // console.log("TEAMS", teamsAuthToken);
    // let r3 = await identityClient.getTokenForTeamsUser({
    //   teamsUserAadToken: oboToken.access_token,
    //   clientId: clientId,
    //   userObjectId: teamsCtx?.user.id,
    // });
    // console.log("R3", r3);
    // console.log("TEAMS ACCES TOKEN THING", token2, expiresOn2);
    const { token, expiresOn, user } = identityResponse;

    const callClient = new CallClient();
    const tokenCredential = new AzureCommunicationTokenCredential(token);
    let agent = await callClient.createCallAgent(tokenCredential, { displayName: displayName || "Meeting Soundboard" });
    callClient.feature(Features.DebugInfo);

    let call: Call;
    try {
      call = agent.join(
        {
          meetingLink: joinUrl,
        },
        {}
      );

      // call = agent.startCall([{ phoneNumber: "+16169158054" }], { alternateCallerId: {phoneNumber: 'YOUR AZURE REGISTERED PHONE NUMBER HERE: +12223334444'});

      let raiseHandFeature = call.feature(Features.RaiseHand);
      raiseHandFeature.on("raiseHandChanged", (data) => {
        console.log("raiseHandChanged", data);

        if (data.isRaised) {
          createAudioTrackToSend(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Ask%20You%20a%20Bunch%20of%20Questions.mp3?sv=2019-12-12&st=2022-09-22T13%3A57%3A11Z&se=2103-03-23T13%3A57%3A00Z&sr=b&sp=r&sig=5povtvuXxADS4R4UJc106%2B%2FUswS7Z4lPbqjFJdZBtQ4%3D",
            1.0
          ).then((track) => {
            try {
              const localAudioStream = new LocalAudioStream(track);
              call?.startAudio(localAudioStream);
            } catch (e) {
              console.log("this error is super weird");
            }
          });
        } else {
          createAudioTrackToSend(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D",
            1.0
          ).then((track) => {
            try {
              const localAudioStream = new LocalAudioStream(track);
              call?.startAudio(localAudioStream);
            } catch (e) {
              console.log("this error is super weird");
            }
          });
        }
      });

      let mediaStatsFeature = call.feature(Features.MediaStats);
      mediaStatsFeature.startCollector();

      let teamsCaptionsFeature = call.feature(Features.TeamsCaptions);
      console.log("CAPTIONS FEAUTURE", teamsCaptionsFeature);
      teamsCaptionsFeature.on("isCaptionsActiveChanged", () => {
        console.log("isCaptionsActiveChanged");
      });

      teamsCaptionsFeature.on("captionsReceived", (data: CaptionsInfo) => {
        console.log("CAPTIONS DATA RECEIVED", data);
      });
      // let feat = call.feature(Features.TeamsCaptions);
      // console.log("TEAMS CAPTIONS ACTIVE", feat.isCaptionsActive);

      // feat.on("isCaptionsActiveChanged", () => {
      //   console.log("isCaptionsActiveChanged", feat.isCaptionsActive);
      // });

      // feat.on("captionsReceived", (data) => {
      //   console.log("CAPTIONS RECEIVED", data);
      // });

      let liveStreamFeature = call.feature(Features.LiveStream);
      console.log("liveStreamFeature", liveStreamFeature);

      let callTranscriptionApi = call.feature(Features.Transcription);
      console.log("TRANSCRIPTION", callTranscriptionApi);
      const isTranscriptionActive = callTranscriptionApi.isTranscriptionActive;

      const isTranscriptionActiveChangedHandler = () => {
        console.log(callTranscriptionApi.isTranscriptionActive);
      };
      callTranscriptionApi.on("isTranscriptionActiveChanged", isTranscriptionActiveChangedHandler);

      setCall(call);
    } catch (err) {
      console.log("ERROR CONNECTING TO CALL", err); //TODO
    }
  }
  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    call?.on("stateChanged", () => {
      console.log("CALL STATE CHANGES", call.state);
      if (call.state === "Connected") {
        console.log("We are connected 1");
        setCallIsConnected(true);
      }

      if (call.state === "InLobby") {
        console.log("We are in the lobby!");
        setCallIsConnected(true);
      }
    });

    call?.muteIncomingAudio().then(() => {
      console.log("Muted incoming audio");
    });

    // call?.on("localAudioStreamsUpdated", (ev) => {
    //   createAudioTrackToSend(
    //     "https://sparkprovisioningstor.blob.core.windows.net/soundboard/stop.mp3?sv=2019-12-12&st=2022-08-17T16%3A05%3A19Z&se=2094-08-18T16%3A05%3A00Z&sr=b&sp=r&sig=yCiVgvUw7eJF9F1JCnMPxnrekLAyDAY%2BE%2FFl%2BfsMbpE%3D",
    //     1.0
    //   ).then((track) => {
    //     try {
    //       call?.localAudioStreams[0].switchSource(track);
    //     } catch (e) {
    //       console.log("this error is super weird");
    //     }
    //   });
    // });

    // call?.mute();
  }, [call]);

  return {
    call: call,
    callIsConnected: callIsConnected,
    communicationUserId: communicationUserId,
    communicationUserToken: communicationUserToken,
  };
}
