import React, { useEffect, useMemo, useState } from "react";

export function MeetingRPGDynamicWrapper({ room }) {
  const [LoadedControl, setLoadedControl] = useState<any>();

  const loadData = async () => {
    const data = await import("./App");
    setLoadedControl(() => data.MeetingRPG);
  };

  useEffect(() => {
    if (!LoadedControl) loadData();
  }, []);
  if (LoadedControl) {
    return <LoadedControl room={room} />;
  } else {
    return null;
  }
}
