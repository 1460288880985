import { meeting } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";

export function Timesheet() {
  const [meetingScheduledDuration, setMeetingScheduledDuration] = useState("");
  const [projectName, setProjectName] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMeetingScheduledDuration("1 hour");
    setProjectName("JW - Modern Workplace Project");

    meeting.getMeetingDetails((err, meetingDetails) => {
      if (err) {
        setMessage(`Error: ${JSON.stringify(err)}`);
      }
      setMessage(JSON.stringify(meetingDetails));
      if (!meetingDetails) return;

      let meetingStart = meetingDetails.details.scheduledStartTime;
      let meetingEnd = meetingDetails.details.scheduledEndTime;

      let d1 = new Date(meetingStart);
      let d2 = new Date(meetingEnd);

      let duration = d2.getTime() - d1.getTime();
      setMeetingScheduledDuration("1 hour");
    });
  }, []);
  return (
    <div>
      <div className={"p-2"}>
        <div className={"text-3xl text-primary"}>Timesheet Settings</div>
        <p className={"p-4"}>
          Time in this meeting should be billed to this project: <span className={"text-secondary"}>{projectName}</span>
        </p>
        <p className={"px-4"}>
          Activity: <span className={"text-secondary"}>{"Company Meetings"}</span>
        </p>
        <div className={"px-4"}>
          Total time to book: <span className={"text-secondary text-xl"}> {meetingScheduledDuration} </span>
        </div>
        <p className={"p-2"}>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Automatically get added to OpenAir project.</span>
              <input type="checkbox" checked={true} className="checkbox checkbox-primary" />
            </label>
          </div>
        </p>

        <p className={"p-2"}>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Automatically book this meeting's time to OpenAir project.</span>
              <input type="checkbox" checked={true} className="checkbox checkbox-primary" />
            </label>
          </div>
        </p>

        <p className={"p-2"}>
          <div className="form-control">
            <label className="label cursor-pointer">
              <span className="label-text">Just book the whole day to {projectName}.</span>
              <input type="checkbox" checked={true} className="checkbox checkbox-primary" />
            </label>
          </div>
        </p>
      </div>
    </div>
  );
}
