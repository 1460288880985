import create from "zustand";
import { Client, Room } from "colyseus.js";
import { app } from "@microsoft/teams-js";

import { GetState, SetState } from "zustand";

type InTeamsStatus = "unknown" | "inTeams" | "notInTeams";

export type UserSlice = {
  playerRooms: Room[];
  addPlayerRoom: (room: Room) => void;
  soundOn: boolean;
  toggleSound: (toggle: boolean) => void;
  isInTeamsStatus: string;
  setInTeamsStatus: (status: string) => void;
  teamsContext: app.Context | null;
  setTeamsContext: (context: app.Context) => void;

  playerImageBase64: string | null;
  setPlayerImageBase64: (imageBase64: string) => void;
};

type TomsGlobalState = UserSlice;

const createUserSlice = (set: SetState<TomsGlobalState>, get: GetState<TomsGlobalState>) => ({
  playerRooms: [],
  addPlayerRoom: (by) => set((state) => ({ playerRooms: state.playerRooms.concat(by) })),
  soundOn: false,
  toggleSound: () => set((state) => ({ soundOn: !state.soundOn })),
  isInTeamsStatus: "unknown",
  setInTeamsStatus: (status) => set({ isInTeamsStatus: status }),
  teamsContext: null,
  setTeamsContext: (context) => set({ teamsContext: context }),
  playerImageBase64: null,
  setPlayerImageBase64: (imageBase64) => set({ playerImageBase64: imageBase64 }),
});

export const useTomStore = create<TomsGlobalState>((set, get) => ({
  ...createUserSlice(set, get),
}));

type GlobalState = {
  playerRooms: Room[];
  addPlayerRoom: (room: Room) => void;
  soundOn: boolean;
  toggleSound: (toggle: boolean) => void;
  isInTeamsStatus: InTeamsStatus;
  setInTeamsStatus: (status: InTeamsStatus) => void;
  teamsContext: app.Context | null;
  setTeamsContext: (context: app.Context) => void;

  isAppIconHoveredOver: boolean;
  setIsAppIconHoveredOver: (isHovered: boolean) => void;

  playerImageBase64: string | null;
  setPlayerImageBase64: (imageBase64: string) => void;
  playerName: string;
  setPlayerName: (name) => void;
  isPlayerSidePanelUser: boolean;
  setIsPlayerSidePanelUser: (isSidePanel: boolean) => void;

  allPlayersCache: any[];
  addPlayerToAllPlayersCache: (player) => void;

  isPlayerLeader: boolean;
  setIsPlayerLeader: (isPlayerLeader: boolean) => void;

  debugMenuIn3DExperiencesOn: boolean;
  setDebugMenuIn3DExperiencesOn: (isOn: boolean) => void;

  debugMenuOn: boolean;
  setDebugMenuOn: (isOn: boolean) => void;

  enableVarChanger: boolean;
  setEnableVarChanger: (isOn: boolean) => void;

  teamsToken: string;
  setTeamsToken: (token: string) => void;

  onBehalfOfToken: string;
  setOnBehalfOfToken: (token: string) => void;

  hideUglyStuff: boolean;
  setHideUglyStuff: (shouldHide: boolean) => void;
};

export const useGlobalStore2 = create<GlobalState>((set) => ({
  playerRooms: [],
  addPlayerRoom: (by) => set((state) => ({ playerRooms: state.playerRooms.concat(by) })),
  soundOn: false,
  toggleSound: () => set((state) => ({ soundOn: !state.soundOn })),
  isInTeamsStatus: "unknown",
  setInTeamsStatus: (status) => set({ isInTeamsStatus: status }),

  isAppIconHoveredOver: false,
  setIsAppIconHoveredOver: (isHovered: boolean) => set({ isAppIconHoveredOver: isHovered }),
  teamsContext: null,
  setTeamsContext: (context) => set({ teamsContext: context }),
  playerImageBase64: null,
  playerName: "Unknown",
  setPlayerName: (name) => set({ playerName: name }),
  setPlayerImageBase64: (imageBase64) => set({ playerImageBase64: imageBase64 }),
  isPlayerSidePanelUser: false,
  setIsPlayerSidePanelUser: (isSidePanel) => set({ isPlayerSidePanelUser: isSidePanel }),

  allPlayersCache: [],
  addPlayerToAllPlayersCache: (player) => set((state) => ({ allPlayersCache: state.allPlayersCache.concat(player) })),

  isPlayerLeader: false,
  setIsPlayerLeader: (isPlayerLeader: boolean) => set({ isPlayerLeader: isPlayerLeader }),

  debugMenuIn3DExperiencesOn: false,
  setDebugMenuIn3DExperiencesOn: (isOn: boolean) => set({ debugMenuIn3DExperiencesOn: isOn }),

  debugMenuOn: false,
  setDebugMenuOn: (isOn: boolean) => set({ debugMenuOn: isOn }),

  enableVarChanger: false,
  setEnableVarChanger: (isOn: boolean) => set({ enableVarChanger: isOn }),

  teamsToken: "",
  setTeamsToken: (token: string) => set({ teamsToken: token }),

  onBehalfOfToken: "",
  setOnBehalfOfToken: (token: string) => set({ onBehalfOfToken: token }),

  hideUglyStuff: false,
  setHideUglyStuff: (shouldHide: boolean) => {
    set({ hideUglyStuff: shouldHide });
  },
}));
