import { video } from "@microsoft/teams-js";
import React, { useEffect, useRef, useState } from "react";
import twgl from "twgl.js";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { Dragon } from "../Dragon/Dragon";
import { FabricJSVideoBuilder } from "./FabricJSVideoBuilder";
import { VideoFilter } from "./VideoFilter";

export type VideoFilterSelection =
  | "none"
  | "grayscale"
  | "sepia"
  | "invert"
  | "blur"
  | "brightness"
  | "contrast"
  | "hue"
  | "tom"
  | "saturation";

export function Video() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
const teamsCtx = useGlobalStore2(state => state.teamsContext);

  const [selectedFilter, setSelectedFilter] = useState<VideoFilterSelection>();
  useEffect(() => {
    if (!canvasRef.current) return;
    if (!teamsCtx){
      return;
    }
    console.log("init video page");

    let canvas = new OffscreenCanvas(1920, 1080);
    const filter = new VideoFilter(canvas);
    filter.init(selectedFilter);
    console.log("after init filter");

    video.registerForVideoEffect(async (effectId) => {
      console.log("registerForVideoEffect", effectId);
    });

    let config = {
      format: "NV12",
    };
    
    let frameCallback: video.VideoFrameCallback = (frame, notifyProcessedFunction, notifyErrorFunction) => {
      // console.log("FRAME CALLBACK", frame);
      filter.processVideoFrame(frame);
      notifyProcessedFunction();
    };
    video.registerForVideoFrame(frameCallback, config as any);
  }, [canvasRef, selectedFilter]);
  return (
    <div>
      <div className={"text-primary"}>
        Spark Video Effects
      </div>
      <div className={"flex"}>
      <button
        className="btn btn-xs btn-primary gap-2"
        onClick={() => {
          setSelectedFilter("sepia");
        }}>
        Sepia
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </button>
      <button
        className="btn gap-2"
        onClick={() => {
          setSelectedFilter("tom");
        }}>
        Tom
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </button>
      <button
        className="btn gap-2"
        onClick={() => {
          setSelectedFilter("blur");
        }}>
        Blur
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </button>
      <div>
        {JSON.stringify(selectedFilter)}
       
        <button
          className="btn gap-2"
          onClick={() => {
            setSelectedFilter("none");
          }}>
          None
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </button>
        <button
          className="btn gap-2"
          onClick={() => {
            setSelectedFilter("grayscale");
          }}>
          Grayscale
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </button>
      </div>
     

        <div>
        <canvas ref={canvasRef} id={"c"}></canvas>
        <FabricJSVideoBuilder id={"test"} width={300} height={200} />
        <Dragon />
        <canvas id="textCanvas" width={1920} height={1080}></canvas>
        <video id="videoElement"></video>
        <canvas id="canvas"></canvas>
        </div>
      </div>
    </div>
  );
}
