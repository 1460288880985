import React, { useEffect } from "react";
import "./accordion.scss";

export function AccordionMenu({
  child1,
  child2,
  child3,
}: {
  child1: React.ReactChild;
  child2: React.ReactChild;
  child3: React.ReactChild;
}) {
  const handleClick = React.useCallback((e) => {
    if (e.target && e.target.classList.contains("item-header")) {
      document.querySelectorAll(".active").forEach((item) => item.classList.remove("active"));
      e.target.closest(".item").classList.add("active");
    }
  }, []);
  const ref = React.useRef<HTMLElement>();

  const callbackRef = React.useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener("click", handleClick);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("click", handleClick);
      }
    },
    [handleClick]
  );

  useEffect(() => {
    const listener = document.addEventListener("click", (e) => {
      const isTarget =
        e.target && (e.target.classList.contains("item-header") || e.target.classList.contains("item-target"));
      if (isTarget) {
        document.querySelectorAll(".active").forEach((item) => item.classList.remove("active"));
        e.target.closest(".item").classList.add("active");
      }
    });

    return () => document.removeEventListener("click", listener);
  });
  return (
    <div className={"accordionMenu"}>
      <div className="accordionContainer">
        <div className="item active">
          <div className="item-wrap">
            <div className="item-header flex items-center">
              <div className={""}>
                <div className={"item-target px-2"}>Soundboard</div>
              </div>
            </div>
            <div className="item-content px-4">{child1}</div>
          </div>
        </div>
        <div className="item">
          <div className="item-wrap">
            <div className="item-header flex items-center bg-primary text-primary-content">
              <div className={""}>
                <div className={"item-target px-2"}>Distractions</div>
              </div>
            </div>
            <div className="item-content px-4">{child2}</div>
          </div>
        </div>
        <div className="item">
          <div className="item-wrap">
            <div className="item-header flex items-center bg-primary text-primary-content">
              <div className={""}>
                <div className={"item-target px-2"}>Other</div>
              </div>
            </div>
            <div className="item-content">{child3}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
