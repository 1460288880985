import { useState, useCallback, useMemo } from "react";
import { isFunction } from "@rpldy/shared";
import { PREVIEW_TYPES } from "./consts";
import { getWithMandatoryOptions, getFallbackUrlData, getFileObjectUrlByType } from "./utils";

const getFilePreviewUrl = (file, options) => {
  let data = getFileObjectUrlByType(PREVIEW_TYPES.IMAGE, options.imageMimeTypes, options.maxPreviewImageSize || 0, file);

  if (!data) {
    data = getFileObjectUrlByType(PREVIEW_TYPES.VIDEO, options.videoMimeTypes, options.maxPreviewVideoSize || 0, file);
  }

  return data;
};

const getItemProps = (previewComponentProps, item, url, type) => {
  return isFunction(previewComponentProps) ? previewComponentProps(item, url, type) : previewComponentProps;
};

const loadPreviewData = (item, options) => {
  let data,
      isFallback = false;

  if (item.file) {
    const file = item.file;
    data = getFilePreviewUrl(item.file, options);

    if (!data) {
      data = getFallbackUrlData(options.fallbackUrl, file);
      isFallback = true;
    }
  } else {
    data = {
      url: item.url,
      name: item.url,
      type: PREVIEW_TYPES.IMAGE
    };
  }

  return data && { ...data,
    id: item.id,
    isFallback
  };
};

const mergePreviewData = (prev, next) => {
  const newItems = []; //dedupe and merge new with existing

  next.forEach(n => {
    const existingIndex = prev.findIndex(p => p.id === n.id);

    if (~existingIndex) {
      prev.splice(existingIndex, 1, n);
    } else {
      newItems.push(n);
    }
  });
  return prev.concat(newItems);
};

const getPreviewsDataWithItemProps = (previewsData, items, previewComponentProps) => {
  let newData = previewsData;

  if (previewComponentProps) {
    newData = previewsData.map(pd => ({ ...pd,
      props: getItemProps(previewComponentProps, items.find(_ref => {
        let {
          id
        } = _ref;
        return id === pd.id;
      }), pd.url, pd.type)
    }));
  }

  return newData;
};

const getPreviewsLoaderHook = batchItemsMethod => {
  return props => {
    const previewComponentProps = props.previewComponentProps;
    const [previews, setPreviews] = useState({
      previews: [],
      items: []
    });
    const previewOptions = getWithMandatoryOptions(props);
    const clearPreviews = useCallback(() => {
      setPreviews({
        previews: [],
        items: []
      });
    }, []);
    batchItemsMethod(batch => {
      const items = previewOptions.loadFirstOnly ? batch.items.slice(0, 1) : batch.items;
      const previewsData = items.map(item => loadPreviewData(item, previewOptions)).filter(Boolean);
      setPreviews({
        previews: props.rememberPreviousBatches ? mergePreviewData(previews.previews, previewsData) : previewsData,
        items: props.rememberPreviousBatches ? previews.items.concat(items) : items
      });
    });
    const previewsWithItemProps = useMemo(() => getPreviewsDataWithItemProps(previews.previews, previews.items, previewComponentProps), [previews, previewComponentProps]);
    return {
      previews: previewsWithItemProps,
      clearPreviews
    };
  };
};

export { getPreviewsLoaderHook };