import { Room } from "colyseus.js";
import React, { useEffect, useState } from "react";
import { Player } from "../../../state/PlayersView";
import { TomsState } from "../../../state/TomsState";
import { TOM_IMAGE_BASE64 } from "../../Constants";
import "./players.scss";
import { useSoundManager } from "../../hooks/useSoundManager";
import soundUrl from "url:../../soundscrate-coin-01.mp3";
import backgroundMusicSound from "url:../../soundscrate-playground-day.mp3";
import { DropdownMenuDemo, PlayerPopover } from "../PlayerPopover/PlayerPopover";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { useGlobalStore2 } from "../../hooks/useGlobalState";

export function PlayerIcon({ player }: { player: Player }) {
  let soundManager = useSoundManager();

  if (!player.imageBase64) {
    return (
      <div
        onClick={() => {
          soundManager.playSound(soundUrl);
        }}
        className="relative z-30 object-cover w-24 h-24 border-2 border-white rounded-full bg-red-600"
      />
    );
  } else if (player.imageBase64) {
    return (
      <img
        className="relative z-30 object-cover w-24 h-24 border-2 border-white rounded-full"
        src={player.imageBase64}
        alt="Profile image"
      />
    );
  }
}

export function DaisyPlayer({ player, room, showMenu }: { player: Player; room: Room; showMenu: boolean }) {
  const isPlayerLeader = useGlobalStore2((state) => state.isPlayerLeader);
  let playerName = player.playerName ? player.playerName : player.id;
  let letters = playerName.split(" ").reduce((prev, current, idx, arr) => {
    return prev[0] + current[0];
  });
  let shouldUseImage = player.imageBase64 ? true : false;
  return (
    <div className={"group"}>
      <div className={"absolute z-30 transition opacity-0 group-hover:opacity-100"}>
        <div className="badge badge-primary">
          {playerName}
          {showMenu && isPlayerLeader && (
            <div className="dropdown dropdown-right dropdown-hover">
              <div tabIndex={0}>
                {" "}
                <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                  <polygon points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
                  <path d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z" />
                </svg>
              </div>
              <ul tabIndex={0} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-48 cursor-pointer">
                <li>
                  <div
                    onClick={() => {
                      room.send("newLeader", { id: player.id });
                    }}
                    className={"text-primary hover:text-primary-content cursor-pointer"}>
                    Make user the Leader
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>{" "}
      </div>

      <div className="avatar placeholder">
        <div className="bg-neutral-focus text-primary rounded-full w-24 h-24">
          {shouldUseImage ? (
            <img className="relative z-0 object-cover rounded-full" src={player.imageBase64} alt="Profile image" />
          ) : (
            <span className="text-2xl">{letters}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export function PlayerImageWithMenu({ player, room, showMenu }: { player: Player; room: Room; showMenu: boolean }) {
  const isPlayerLeader = useGlobalStore2((state) => state.isPlayerLeader);
  let playerName = player.playerName ? player.playerName : player.id;
  let letters = playerName.split(" ").reduce((prev, current, idx, arr) => {
    return prev[0] + current[0];
  });
  let shouldUseImage = player.imageBase64 ? true : false;
  return (
    <div className={"group"}>
      <div className={"absolute z-30 transition opacity-0 group-hover:opacity-100"}>
        <div className="badge badge-primary">
          {playerName}
          {showMenu && isPlayerLeader && (
            <div className="dropdown dropdown-right dropdown-hover">
              <div tabIndex={0}>
                {" "}
                <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                  <polygon points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
                  <path d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z" />
                </svg>
              </div>
              <ul tabIndex={0} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-48 cursor-pointer">
                <li>
                  <div
                    onClick={() => {
                      room.send("newLeader", { id: player.id });
                    }}
                    className={"text-primary hover:text-primary-content cursor-pointer"}>
                    Make user the Leader
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>{" "}
      </div>

      <div className="avatar placeholder">
        <div className="bg-neutral-focus text-primary rounded-full w-24 h-24">
          {shouldUseImage ? (
            <img className="relative z-0 object-cover rounded-full" src={player.imageBase64} alt="Profile image" />
          ) : (
            <span className="text-2xl">{letters}</span>
          )}
        </div>
      </div>
    </div>
  );
}

function PlayerWithHeading({
  title,
  name,
  player,
  room,
  showMenu,
}: {
  title: string;
  name: string;
  player: Player;
  room: Room;
  showMenu: boolean;
}) {
  return (
    <div className={"flex flex-col w-36"}>
      <div className={"flex justify-center text-primary"}>{title}</div>
      <div className="flex justify-center">
        <DaisyPlayer player={player} key={player.id} room={room} showMenu={false} />
      </div>
      <div className={"flex justify-center text-primary"}>{name}</div>
    </div>
  );
}

export function SmallPlayerIcon({ player }: { player: Player }) {
  let soundManager = useSoundManager();

  if (!player.imageBase64) {
    return (
      <div
        onClick={() => {
          soundManager.playSound(soundUrl);
        }}
        className="relative z-30 object-cover w-20 h-20 border-2 border-white rounded-full bg-red-600"
      />
    );
  } else if (player.imageBase64) {
    return (
      <img
        className="relative z-30 object-cover w-20 h-20 border-2 border-white rounded-full"
        src={player.imageBase64}
        alt="Profile image"
      />
    );
  }
}

function AppTitle() {
  return (
    <>
      <div className={"text-4xl px-12 text-primary"}>Welcome to Spark Moments!</div>
    </>
  );
}

function AppStartText({ isLeader }: { isLeader: boolean }) {
  let textToUse = isLeader
    ? "Spark Moments is an app that helps you connect with your colleagues with shared experiences! You are the leader! Get started now by clicking the random moment button, or select one on your own."
    : "Please wait while the leader chooses an experience...";
  return <div className={"text-xs text-secondary px-12 py-6 animate-pulse"}>{textToUse}</div>;
}
function StartRandomMomentButton(props) {
  return (
    <button className="btn btn-primary btn-small" {...props}>
      <span>Start Random Moment</span>

      <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
        <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
        <path
          className="play-btn__svg"
          d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
        />
      </svg>
    </button>
  );
}

function StartRandomMomentButton2(props) {
  return (
    <button
      className="inline-flex items-center h-10 px-5 text-white transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline hover:bg-green-800"
      {...props}>
      <span>Start Random Moment</span>

      <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
        <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
        <path
          className="play-btn__svg"
          d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
        />
      </svg>
    </button>
  );
}

function ChooseExperienceButton(props) {
  return (
    <button className="btn btn-secondary btn-small" {...props}>
      <span>Choose an Experience</span>

      <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
        <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
        <path
          className="play-btn__svg"
          d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
        />
      </svg>
    </button>
  );
}

function ChooseExperienceButton2(props) {
  return (
    <button
      className="inline-flex items-center h-10 px-5 text-white transition-colors duration-150 bg-blue-700 rounded-lg focus:shadow-outline hover:bg-blue-800"
      {...props}>
      <span>Choose an Experience</span>

      <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
        <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
        <path
          className="play-btn__svg"
          d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
        />
      </svg>
    </button>
  );
}

export const PlayersScreen = ({ room }: { room: Room }) => {
  let [players, setPlayers] = useState<Player[]>([]);
  let roomState = room.state;

  useEffect(() => {
    debugger;
    room.state.players.players.onAdd = (entity, sessionId) => {
      setPlayers((prev) => {
        return [...prev, entity];
      });
    };
  }, [room]);
  let soundManager = useSoundManager();
  let foundLeader = players.find((p) => p.id === room.sessionId && p.isLeader);
  let isPlayerLeader = foundLeader ? true : false;
  const history = useHistory();
  return (
    <div>
      <div className={"player-flex-container"}>
        <AppTitle />
        <AppStartText isLeader={isPlayerLeader} />

        <div>{roomState.gameMessage}</div>

        {isPlayerLeader && (
          <div className={"py-6"}>
            <div className={"sm:columns-1 md:columns-2"}>
              <div className={"px-4"}>
                <StartRandomMomentButton
                  onClick={() => {
                    room.send("selectRandomExperience", { milliSecondsToWait: 5000 });
                  }}
                />
              </div>
              <div className={"px-4"}>
                <ChooseExperienceButton
                  onClick={() => {
                    history.push("/choose");
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className={"flex"}>
          <div className="flex-auto w-64">
            {players
              .filter((p) => p.isLeader)
              .map((p) => {
                return (
                  <PlayerWithHeading
                    title={"Leader"}
                    name={p.playerName}
                    player={p}
                    key={p.id}
                    room={room}
                    showMenu={false}
                  />
                );
              })}
          </div>
          <div className="flex-auto">
            <div className="flex flex-row -space-x-12 md:-space-x-4 self-end py-6">
              {players
                .filter((p) => !p.isLeader)
                .map((p) => {
                  return <DaisyPlayer player={p} key={p.id} room={room} showMenu={true} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
