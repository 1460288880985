import { AudioDeviceInfo, Call, CallClient, DtmfTone, Features, LocalAudioStream, LocalVideoStream, RemoteParticipant, VideoDeviceInfo, VideoStreamRenderer, VideoStreamRendererView } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationIdentityClient, CommunicationUserToken } from "@azure/communication-identity";
import { Room } from "colyseus.js";
import create from "zustand";
import { initVoiceCommands } from "../../Voice";
import { ACS_TOKEN_ENDPOINT } from "../../Constants";
import { ACSRoom } from "../../../backend/ACSRoom";
import { ChatClient } from "@azure/communication-chat";
import * as FaceMesh from "@mediapipe/face_mesh";
import * as Hands from "@mediapipe/hands";
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { HAND_CONNECTIONS } from "@mediapipe/hands";



export type SoundboardStore = {
    hasInitialized: boolean;
    call: Call | null;
    communicationUserId: string;
    communicationUserToken: string;
    callClient: CallClient | null;
    callIsConnecting: boolean;
    callIsConnected: boolean;
    room: Room | null;
    audioGainNode: GainNode | null;
    audioContext: AudioContext | null;
    mediaDestinationNode: MediaStreamAudioDestinationNode | null;
    volumeLevel: number;
    videoElement: HTMLMediaElement | null;
    localVideoStream: LocalVideoStream | null;
    connectedCanvasElement: HTMLCanvasElement | null;
    currentlyPlayingSource: AudioBufferSourceNode | null;
    allSourcesArray: Array<AudioBufferSourceNode>;

    availableCameras: Array<VideoDeviceInfo> | null;
    availableMicrophones: Array<AudioDeviceInfo> | null;

    chatClient: ChatClient | null;
    soundboardData: Map<string, Array<{ url: string; name: string; metadata?: any }>>;
    incomingAudioMuted: boolean;

    api: {
        getACSToken: () => Promise<CommunicationUserToken>;
        initializeWithJoinUrl: (acsRoom: Room<ACSRoom>, joinUrl: string, displayName: string) => void;
        initializeWithPhoneNumber: (acsRoom: Room, phoneNumber: string, displayName: string, callingFromPhoneNumber: string) => void;
        initializeWithSingleUser: (joinUrl: string, displayName: string) => void;

        disconnectCall: () => void;
        getAudioTrack: (fileUrl: string) => Promise<{ source: AudioBufferSourceNode, track: MediaStreamTrack, stream: MediaStream }>;

        sendAudioTrack: (fileUrl: string) => void;
        sendVideoTrack: (fileUrl: string) => void;

        sendDtmfTones: () => void;
        createGainNode: () => void;
        setVolumeLevel: (volumeLevel: number) => void;

        createVideoElement: (src: string) => void;

        stopCurrentlyPlayingSource: () => void;
        stopAllSources: () => void;

        setupChatClient: (token: string) => void;
        setupRoomEvents: () => void;

        setIncomingAudioMuted: (isMuted: boolean) => void;

        testVideoCanvas: (id: string) => void;

        setupRenderingCanvas: () => void;
        switchToUserCamera: () => void;
        setVideoToIdle: () => void;

        turnOffVideo: () => void;

        loadAvailableCamerasAndMicrophones: () => void;
    }
}

export const useSoundboardStore = create<SoundboardStore>((set, get) => ({
    hasInitialized: false,
    call: null,
    communicationUserId: "",
    communicationUserToken: "",
    callClient: null,
    callIsConnecting: false,
    callIsConnected: false,
    room: null,
    audioGainNode: null,
    audioContext: null,
    mediaDestinationNode: null,
    volumeLevel: 1,
    videoElement: null,
    localVideoStream: null,
    connectedCanvasElement: null,
    currentlyPlayingSource: null,
    allSourcesArray: [],

    availableCameras: null,
    availableMicrophones: null,

    chatClient: null,
    soundboardData: new Map(),
    incomingAudioMuted: false,
    api: {
        getACSToken: () => {
            return new Promise((resolve, reject) => {
                let url = process.env.NODE_ENV === "production" ? ACS_TOKEN_ENDPOINT : 'http://localhost:2567/acstoken'

                fetch(url, { method: "GET" }).then((r) => r.json()).then((resp) => {
                    return resolve(resp)
                })
            })
        },
        initializeWithJoinUrl: async (acsRoom: Room, joinUrl: string, displayName: string) => {
            // const identityClient = new CommunicationIdentityClient(connectionString);
            // let identityResponse = await identityClient.createUserAndToken(["voip", "chat"]);
            let identityResponse = await get().api.getACSToken();

            const callClient = new CallClient();
            const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
            let agent = await callClient.createCallAgent(tokenCredential, { displayName: displayName });

            let call = agent.join(
                {
                    meetingLink: joinUrl,
                },
                {}
            );

            set({ call: call })

            call?.on("stateChanged", async () => {
                if (call.state === "Connecting") {
                    set({ callIsConnecting: true });
                }
                if (call.state === "Connected") {
                    console.log("We are connected 1");
                    // setTimeout(() => {
                    //     get().api.createVideoElement();
                    //     console.log("CREATED AFTER 15")
                    // }, 15000)

                    set({ callIsConnected: true, callIsConnecting: false });
                    // await get().api.setupRenderingCanvas();
                    // setTimeout(() => {
                    //     get().api.setVideoToIdle();

                    // }, 5000)
                    // call.on("remoteAudioStreamsUpdated", async (ev) => {
                    //     console.log(ev)
                    //     if (ev.added.length > 0) {
                    //         let mst2 = ev.added[0];
                    //         let ms3 = await mst2.getMediaStreamTrack()
                    //         debugger;
                    //     }
                    //     call.remoteAudioStreams.forEach(async (remoteAudioStream) => {
                    //         debugger;
                    //         let mst = remoteAudioStream.getMediaStreamTrack()
                    //         debugger;
                    //     })
                    // })
                }

                if (call.state === "InLobby") {
                    console.log("We are in the lobby!");
                    set({ callIsConnected: true, callIsConnecting: false });
                }
            });


            call?.muteIncomingAudio().then(() => {
                console.log("Muted incoming audio");
                set({ incomingAudioMuted: true })
            });

            get().api.createGainNode();
            set({ room: acsRoom });

            await get().api.setupRoomEvents();
            await get().api.setupChatClient(identityResponse.token);


            return set({ communicationUserId: identityResponse.user.communicationUserId, communicationUserToken: identityResponse.token, call: call, callClient: callClient });
        },
        initializeWithSingleUser: async (joinUrl: string, displayName: string) => {

            let identityResponse = await get().api.getACSToken();

            const callClient = new CallClient();
            const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
            let agent = await callClient.createCallAgent(tokenCredential, { displayName: displayName });

            let call = agent.join(
                {
                    meetingLink: joinUrl,
                },
                {}
            );

            set({ call: call, callClient: callClient })

            call?.on("stateChanged", async () => {
                if (call.state === "Connecting") {
                    set({ callIsConnecting: true });
                }
                if (call.state === "Connected") {
                    console.log("We are connected 1");
                    // setTimeout(() => {
                    //     get().api.createVideoElement();
                    //     console.log("CREATED AFTER 15")
                    // }, 15000)

                    set({ callIsConnected: true, callIsConnecting: false });
                    await get().api.setupRenderingCanvas();
                    setTimeout(() => {
                        get().api.setVideoToIdle();

                    }, 5000)
                    // call.on("remoteAudioStreamsUpdated", async (ev) => {
                    //     console.log(ev)
                    //     if (ev.added.length > 0) {
                    //         let mst2 = ev.added[0];
                    //         let ms3 = await mst2.getMediaStreamTrack()
                    //         debugger;
                    //     }
                    //     call.remoteAudioStreams.forEach(async (remoteAudioStream) => {
                    //         debugger;
                    //         let mst = remoteAudioStream.getMediaStreamTrack()
                    //         debugger;
                    //     })
                    // })
                }

                if (call.state === "InLobby") {
                    console.log("We are in the lobby!");
                    set({ callIsConnected: true, callIsConnecting: false });
                }
            });


            call?.muteIncomingAudio().then(() => {
                console.log("Muted incoming audio");
                set({ incomingAudioMuted: true })
            });

            get().api.createGainNode();

            await get().api.loadAvailableCamerasAndMicrophones();


            return set({ communicationUserId: identityResponse.user.communicationUserId, communicationUserToken: identityResponse.token, call: call, callClient: callClient });
        },
        initializeWithPhoneNumber: async (acsRoom: Room, phoneNumber: string, displayName: string, callingFromPhoneNumber: string) => {
            // const identityClient = new CommunicationIdentityClient(connectionString);
            // let identityResponse = await identityClient.createUserAndToken(["voip", "chat"]);

            let identityResponse = await get().api.getACSToken();

            const callClient = new CallClient();
            const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
            let agent = await callClient.createCallAgent(tokenCredential, { displayName: displayName });

            let call = agent.startCall([{ phoneNumber: phoneNumber }], { alternateCallerId: { phoneNumber: callingFromPhoneNumber } });

            const meetingId = "91429745402";
            const meetingPassword = "553708"

            let waitMilliseconds = (millisecondsToWait: number) => {
                return new Promise<void>((resolve, reject) => {
                    setTimeout(() => {
                        resolve();
                    }, millisecondsToWait)
                })
            }

            call?.on("stateChanged", async () => {
                if (call.state === "Connecting") {
                    set({ callIsConnecting: true })
                }
                if (call.state === "Disconnected") {
                    set({ callIsConnected: false, callIsConnecting: false })
                }
                if (call.state === "Connected") {
                    set({ callIsConnected: true, callIsConnecting: false });
                }
            });

            // const captionsFeature = call?.feature(Features.Captions);
            // captionsFeature.startCaptions();
            // captionsFeature.on("captionsReceived", (data) => {
            //     console.log("CAPTIONS RECEIVED", data);
            // })
            // call?.muteIncomingAudio().then(() => {
            //     console.log("Muted incoming audio");
            // });
            get().api.createGainNode();


            return set({ room: acsRoom, communicationUserId: identityResponse.user.communicationUserId, communicationUserToken: identityResponse.token, call: call, callClient: callClient });
        },
        disconnectCall: () => {
            let call = get().call;
            call?.hangUp();
        },

        getAudioTrack: async (fileUrl: string) => {
            const context = get().audioContext;

            if (!context) throw new Error("audioContext should not be null");

            const gainNode = get().audioGainNode;
            if (!gainNode) throw new Error("gainNode was null");

            const mediaDestinationNode = get().mediaDestinationNode;
            if (!mediaDestinationNode) throw new Error("mediaDestinationNode was null");
            return new Promise<{ source: AudioBufferSourceNode, track: MediaStreamTrack, stream: MediaStream }>((resolve, reject) => {
                window
                    .fetch(fileUrl)
                    .then((response) => response.arrayBuffer())
                    .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
                    .then((audioBuffer) => {
                        const mediaDestinationNode = context.createMediaStreamDestination();
                        const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
                        set({ currentlyPlayingSource: source, allSourcesArray: [...get().allSourcesArray, source] });

                        // const gainNode = context.createGain();

                        gainNode.gain.value = get().volumeLevel;
                        source.connect(gainNode)
                        gainNode.connect(mediaDestinationNode);
                        let track = mediaDestinationNode.stream.getAudioTracks()[0];
                        // let cloned = mediaDestinationNode.stream.clone();
                        let newMediaStream = new MediaStream([track])
                        return resolve({ source: source, track: track, stream: newMediaStream });
                    });
            })

        },
        sendAudioTrack: async (fileUrl: string) => {
            const getAudioTrack = () => {
                return new Promise<{ track1: MediaStreamTrack, videoStream: MediaStream }>((resolve, reject) => {
                    // const context = new AudioContext();
                    // window
                    //     .fetch(fileUrl)
                    //     .then((response) => response.arrayBuffer())
                    //     .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
                    //     .then((audioBuffer) => {
                    //         const mediaDestinationNode = context.createMediaStreamDestination();
                    //         const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
                    //         const gainNode = context.createGain();

                    //         gainNode.gain.value = volumeLevel;
                    //         source.connect(gainNode)
                    //         gainNode.connect(mediaDestinationNode);
                    //         source.start();
                    //         let track = mediaDestinationNode.stream.getAudioTracks()[0];
                    //         resolve(track);
                    //     });
                    const context = get().audioContext;

                    if (!context) throw new Error("audioContext should not be null");

                    const gainNode = get().audioGainNode;
                    if (!gainNode) throw new Error("gainNode was null");

                    const mediaDestinationNode = get().mediaDestinationNode;
                    if (!mediaDestinationNode) throw new Error("mediaDestinationNode was null");

                    // const context = get().audioContext;
                    window
                        .fetch(fileUrl)
                        .then((response) => response.arrayBuffer())
                        .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
                        .then((audioBuffer) => {

                            const mediaDestinationNode = context.createMediaStreamDestination();
                            const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
                            set({ currentlyPlayingSource: source, allSourcesArray: [...get().allSourcesArray, source] });

                            // const gainNode = context.createGain();

                            gainNode.gain.value = get().volumeLevel;
                            source.connect(gainNode)
                            gainNode.connect(mediaDestinationNode);
                            source.start();

                            let track = mediaDestinationNode.stream.getAudioTracks()[0];
                            let track2 = mediaDestinationNode.stream;

                            resolve({ track1: track, videoStream: track2 });
                        });
                });
            }

            getAudioTrack().then(track => {
                let newMediaStream = new MediaStream(track.videoStream)
               //let las = get().call?.localAudioStreams[0] as LocalAudioStream;
                const localAudioStream = new LocalAudioStream(newMediaStream);
                // debugger;
                get().call?.startAudio(localAudioStream).then(() => {

                    //
                })

                // localAudioStream.getMediaStreamTrack().then((t) => {
                //     t.addEventListener("ended", () => {
                //         alert("ended?")
                //     })
                //     get().call?.startAudio(localAudioStream).then(() => {

                //         //
                //     })



                // })


                // setTimeout(async () => {
                //     console.log("AFTER FIRST TIMEOUT, CAPTURING STREAM");

                //     const captureStream = videoElement?.captureStream();
                //     get().localVideoStream?.setMediaStream(captureStream);
                //     const localVideoStream = new LocalVideoStream(captureStream);

                //     setTimeout(async () => {
                //         console.log("AFTER WAIT 15");
                //         await call.startVideo(localVideoStream);
                //         console.log("AFTER AWAITING");
                //         videoElement.play()
                //         videoElement.onended = async () => {
                //             console.log("FIRED ONENDED");
                //             await call.stopVideo(localVideoStream);
                //         }
                //         // console.log("AFTER STARTING VIDEO, WAIT 10 SECONDS AND STOP IT")
                //         // setTimeout(() => {
                //         //     call.stopVideo(localVideoStream);
                //         //     console.log("AFTER STOPPING VIDEO");

                //         //     setTimeout(() => {
                //         //         console.log("WAITED 10 SECONDS BEFORE STARTING VIDEO AGAIN");
                //         //         videoElement.play()

                //         //         call.startVideo(localVideoStream);
                //         //     }, 10000)
                //         // }, 10000)
                //     }, 15000)

                // }, 5000)
                // let videoElement = document.createElement("video");
                // videoElement.src = "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/What%20Can%20I%20Say%20Except%20Your%20Welcome.mp4?sv=2020-10-02&se=2022-11-09T20%3A51%3A53Z&sr=c&sp=rade&sig=9twH7CpLteF42Fxni9UdzQfp%2BxJaG%2Fxn7Kl%2FHWiCexU%3D"
                // videoElement.crossOrigin = "anonymous";
                // document.body.append(videoElement);
                // videoElement.play();


                // // const playbackElement: HTMLMediaElement = document.getElementById("inputVideo") as HTMLMediaElement;
                // // playbackElement.src = "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/What%20Can%20I%20Say%20Except%20Your%20Welcome.mp4?sv=2020-10-02&se=2022-11-09T20%3A51%3A53Z&sr=c&sp=rade&sig=9twH7CpLteF42Fxni9UdzQfp%2BxJaG%2Fxn7Kl%2FHWiCexU%3D"

                // const captureStream = videoElement.captureStream();
                // const localVideoStream = new LocalVideoStream(captureStream);

                // videoElement.onended = () => {
                //     console.log("ON ENDED?")
                //     get().call?.stopVideo(localVideoStream)
                // }

                // // playbackElement.play();
                // console.log("BEFORE START VIDEO");

                // get().call?.startVideo(localVideoStream);
                // console.log("AFTER START VIDEO");


            })
        },

        sendVideoTrack: (fileUrl: string) => {

        },

        sendDtmfTones: () => {

            let call = get().call;
            if (!call) return;

            const meetingId = "91429745402";
            const meetingPassword = "553708"

            let waitMilliseconds = (millisecondsToWait: number) => {
                return new Promise<void>((resolve, reject) => {
                    setTimeout(() => {
                        resolve();
                    }, millisecondsToWait)
                })
            }

            setTimeout(async () => {
                for (const c of meetingId) {
                    await waitMilliseconds(500);
                    let dtmfString = "Num" + c;
                    console.log("Sending DTMF", dtmfString);
                    await call?.sendDtmf(dtmfString as DtmfTone)
                }

                await call?.sendDtmf("Pound");

                await waitMilliseconds(1000);
                await call?.sendDtmf("Pound");
                console.log("I am before waiting")
                await waitMilliseconds(5000);
                console.log("I am after waiting")
                // call.sendDtmf("Star")

                for (const c of meetingPassword) {
                    await waitMilliseconds(2000);
                    let dtmfString = "Num" + c;
                    console.log("Sending DTMF", dtmfString);
                    await call?.sendDtmf(dtmfString as DtmfTone)
                }

                await call?.sendDtmf("Pound");

            }, 5000)
        },
        createGainNode: () => {

            const context = new AudioContext();
            const mediaDestinationNode = context.createMediaStreamDestination();

            const gainNode = context.createGain();
            gainNode.connect(mediaDestinationNode);
            set({ audioContext: context, audioGainNode: gainNode, mediaDestinationNode: mediaDestinationNode })
        },
        setVolumeLevel: (volumeLevel: number) => {
            const gn = get().audioGainNode;
            if (!gn) throw new Error("gainNode was null");
            gn.gain.value = volumeLevel;
            set({ volumeLevel: volumeLevel })
        },

        createVideoElement: async (src) => {

            let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
            let renderCtx = renderTarget.getContext("2d");

            let alreadyPlayingVideoElement = get().videoElement;
            if (alreadyPlayingVideoElement) {
                console.log("WE ARE ALREADY PLAYING A VIDEO!");
                alreadyPlayingVideoElement.pause();

                get().api.stopCurrentlyPlayingSource();
            }

            let videoElement = document.createElement("video");
            videoElement.src = src;
            videoElement.crossOrigin = "anonymous";
            videoElement.controls = true;
            videoElement.muted = true;
            videoElement.hidden = true;

            videoElement.addEventListener("loadedmetadata", function () {
                // retrieve dimensions
                const height = this.videoHeight;
                const width = this.videoWidth;

                videoElement.height = height;
                videoElement.width = width;

                renderTarget.height = height;
                renderTarget.width = width;
            }, false);


            // document.body.append(videoElement);

            let { source, track, stream } = await get().api.getAudioTrack(src);
            debugger;
            const localAudioStream = new LocalAudioStream(stream);

            videoElement.onplay = async () => {
                console.log("VIDEO ELEMENT ON PLAY");
                get().call?.startAudio(localAudioStream).then(() => {

                    //
                })
                source.start()
                console.log("VIDEO ELEMENT ON PLAY");

            }

            videoElement.onended = () => {
                console.log("VIDEO ENDED?");
                get().api.setVideoToIdle();
                set({ videoElement: null })
            }
            videoElement.play();
            const call = get().call;
            if (!call) throw new Error("call should not be null");

            // if (call.isLocalVideoStarted && get().localVideoStream !== null) {
            //     const lv = get().localVideoStream;
            //     if (lv) {
            //         call.stopVideo(lv);
            //     }
            // }


            // renderTarget.width = videoElement.videoWidth;
            // renderTarget.height = videoElement.videoHeight;

            // let width = renderTarget.width;
            // let height = renderTarget.height;

            let paintCount = 0;
            let startTime = 0.0;

            const updateCanvas = (now, metadata) => {
                if (startTime === 0.0) {
                    startTime = now;
                }
                renderCtx.drawImage(videoElement, 0, 0, renderTarget.width, renderTarget.height);
                // renderCtx.font = "30px Arial";
                // renderCtx.fillStyle = "white";

                // renderCtx.fillText("Hello World", 20, 500);
                const elapsed = (now - startTime) / 1000.0;
                const fps = (++paintCount / elapsed).toFixed(3);
                //   fpsInfo.innerText = `video fps: ${fps}`;
                //   metadataInfo.innerText = JSON.stringify(metadata, null, 2);

                videoElement.requestVideoFrameCallback(updateCanvas);
            };

            videoElement.requestVideoFrameCallback(updateCanvas);

            set({ connectedCanvasElement: null, videoElement: videoElement })
            // const captureStream = videoElement?.captureStream();
            // const localVideoStream = new LocalVideoStream(captureStream);

            // videoElement.onended = async () => {
            //     console.log("ON ENDED?")
            //     console.log("isLocalVideoStarted", call.isLocalVideoStarted);
            //     if (call.isLocalVideoStarted) {
            //         await call.stopVideo(localVideoStream)
            //     }
            // }
            // try {
            //     console.log("TRYING TO START VIDEO", src)
            //     await call.startVideo(localVideoStream);
            //     console.log("AFTER START VIDEO");
            // }
            // catch (err) {
            //     console.log("ERROR STARTING VIDEO")
            //     console.error(err);


            //     try {
            //         console.log("TRYING TO START VIDEO AGAIN");
            //         await call.startVideo(localVideoStream);
            //     }
            //     catch (err) {
            //         console.log("ERROR STARTING VIDEO 2")
            //         console.error(err);
            //     }
            // }

            // setTimeout(async () => {
            //     console.log("AFTER FIRST TIMEOUT, CAPTURING STREAM");

            //     const captureStream = videoElement?.captureStream();
            //     // get().localVideoStream?.setMediaStream(captureStream);
            //     const localVideoStream = new LocalVideoStream(captureStream);

            //     // setTimeout(async () => {
            //     //     console.log("AFTER WAIT 15");
            //     //     // await call.startVideo(localVideoStream);
            //     //     // console.log("AFTER AWAITING");
            //     //     // videoElement.play()
            //     //     // videoElement.onended = async () => {
            //     //     //     console.log("FIRED ONENDED");
            //     //     //     await call.stopVideo(localVideoStream);
            //     //     // }
            //     //     // console.log("AFTER STARTING VIDEO, WAIT 10 SECONDS AND STOP IT")
            //     //     setTimeout(() => {
            //     //         call.stopVideo(localVideoStream);
            //     //         console.log("AFTER STOPPING VIDEO");

            //     //         setTimeout(() => {
            //     //             console.log("WAITED 10 SECONDS BEFORE STARTING VIDEO AGAIN");
            //     //             videoElement.play()

            //     //             call.startVideo(localVideoStream);
            //     //         }, 10000)
            //     //     }, 10000)
            //     // }, 15000)

            //     setTimeout(async () => {
            //         await call.stopVideo(localVideoStream);
            //         console.log("AFTER STOPPING VIDEO");

            //         setTimeout(async () => {
            //             console.log("WAITED 10 SECONDS BEFORE STARTING VIDEO AGAIN");
            //             videoElement.play()

            //             await call.startVideo(localVideoStream);
            //         }, 10000)
            //     }, 10000)

            // }, 5000)

            // videoElement.play();

            // get().videoElement?.play()

            // set({ videoElement: videoElement, localVideoStream: localVideoStream })
        },
        stopCurrentlyPlayingSource: () => {
            let source = get().currentlyPlayingSource;
            if (!source) return;

            source.stop();
            set({ currentlyPlayingSource: null });
        },
        stopAllSources: () => {
            let allSources = get().allSourcesArray;
            allSources.forEach((s) => {
                s.stop();
            })
            set({ currentlyPlayingSource: null, allSourcesArray: [] })
        },

        setupChatClient: async (token) => {
            const acsUrl = process.env.REACT_APP_ACS_URL;
            if (!acsUrl) throw new Error("Missing setting for REACT_APP_ACS_URL");
            let chatClient = new ChatClient(acsUrl, new AzureCommunicationTokenCredential(token));
            await chatClient.startRealtimeNotifications();
            // subscribe to new message notifications
            chatClient.on("chatMessageReceived", (e) => {
                console.log("Notification chatMessageReceived!", e);
                let messageWithPRemoved = e.message.replace("<p>", "").replace("</p>", "");
                if (messageWithPRemoved.indexOf("stop") !== -1) {
                    get().api.stopAllSources();
                    return;
                }
                for (let [key, value] of get().soundboardData) {
                    console.log(JSON.stringify(value));
                    for (var sound of value) {
                        if (sound.name.toLowerCase().indexOf(messageWithPRemoved) !== -1) {
                            get().api.sendAudioTrack(sound.url);
                            let chatThreadClient = chatClient.getChatThreadClient(
                                "19:meeting_MDFhNDdmYWYtZTg0ZS00OGIwLTlhNmEtMjE3MTIxN2I0YTJi@thread.v2"
                            );

                            let sendMessageRequest = { content: "Hello" };
                            let sendMessageOptions = { senderDisplayName: "Jack" };
                            chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions).then(() => {
                                console.log("Message sent!");
                            });
                            break;

                        }
                    }
                    // value.forEach((sound) => {
                    //     if (sound.name.toLowerCase().indexOf(messageWithPRemoved) !== -1) {
                    //         get().api.sendAudioTrack(sound.url);
                    //         c
                    //     }
                    // })
                }
            });
        },

        setupRoomEvents: () => {
            let room = get().room;
            if (!room) throw new Error("Room was not initialized yet");

            room.onMessage("gotSharedSoundBoard", (message) => {
                console.log(message);
                if (message.data.length > 0) {
                    const groupedMap = message.data.reduce(
                        (entryMap, e) =>
                            entryMap.set(e.metadata?.category || "other", [
                                ...(entryMap.get(e.metadata?.category || "other") || []),
                                e,
                            ]),
                        new Map()
                    );
                    set({ soundboardData: groupedMap })
                }
            });

            room.send("getSharedSoundboardBlobs", {});
        },

        setIncomingAudioMuted: (isMuted: boolean) => {
            let call = get().call;
            if (!call) throw new Error("call was not initialized yet");

            if (isMuted) {
                call.muteIncomingAudio()
                set({ incomingAudioMuted: isMuted })
            }
            else {
                call.unmuteIncomingAudio();
                set({ incomingAudioMuted: isMuted })
            }

        },
        testVideoCanvas: async (id: string) => {
            let call = get().call;
            if (!call) throw new Error("call was not initialized yet");



            // if (call.isLocalVideoStarted && get().localVideoStream !== null) {
            //     const lv = get().localVideoStream;
            //     if (lv) {
            //         await call.stopVideo(lv);
            //     }
            // }
            let canvasElement = document.getElementById(id) as HTMLCanvasElement;

            if (canvasElement.tagName === "DIV") {
                canvasElement = canvasElement.children[0];
            }

            set({ connectedCanvasElement: canvasElement })
            // const offscreen = canvasElement.transferControlToOffscreen()


            // let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
            // let ctx = renderTarget.getContext("2d") as RenderingContext;

            // let width = renderTarget.width;
            // let height = renderTarget.height;


            // // const stream = canvasElement.captureStream(30);
            // // if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
            // //     // The API is supported! 
            // //     debugger;
            // // }
            // function render(time) {
            //     console.log(`inside render loop for ${canvasElement.id}`)
            //     ctx.clearRect(0, 0, width, height);

            //     ctx.drawImage(canvasElement, 0, 0, width, height);
            //     requestAnimationFrame(render);
            // }
            // requestAnimationFrame(render);

            // const localVideoStream = new LocalVideoStream(stream);
            // await call.startVideo(localVideoStream)
            // set({ localVideoStream: localVideoStream })
            // get().api.setupOffscreenCanvas();

        },
        setupRenderingCanvas: async () => {
            let call = get().call;
            if (!call) throw new Error("call was not initialized yet");



            let canvasElement = document.getElementById("renderTarget") as HTMLCanvasElement;
            canvasElement.hidden = true;
            const stream = canvasElement.captureStream(30);

            const localVideoStream = new LocalVideoStream(stream);
            await call.startVideo(localVideoStream)

            set({ localVideoStream: localVideoStream });
            let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
            let ctx = renderTarget.getContext("2d") as RenderingContext;

            let width = renderTarget.width;
            let height = renderTarget.height;

            function render(time) {
                let connectedCanvasElement = get().connectedCanvasElement;
                if (connectedCanvasElement) {

                    ctx.clearRect(0, 0, width, height);

                    ctx.drawImage(connectedCanvasElement, 0, 0, width, height);
                }

                requestAnimationFrame(render);
            }
            requestAnimationFrame(render);
        },
        switchToUserCamera: async () => {
            let call = get().call;
            if (!call) throw new Error("call was not initialized yet");
            let callClient = get().callClient;
            if (!callClient) throw new Error("callClient was not initialized yet");

            if (call.isLocalVideoStarted) {
                await call.stopVideo(get().localVideoStream);
            }
            console.log("SWITCHING TO USER CAMERA");
            const deviceManager = await callClient.getDeviceManager();
            const camera = (await deviceManager.getCameras())[7];

            if (camera) {
                const lv = new LocalVideoStream(camera);
                await call.startVideo(lv)
            } else {
                console.error(`No camera device found on the system`);
            }

            const mics = await deviceManager.getMicrophones();
            if (mics[0]) {
                const lv = new LocalAudioStream(mics[0]);
                await call.startAudio(call.localAudioStreams[0])

            }

        },
        setVideoToIdle: () => {
            console.log("SETTING VIDEO TO IDLE");
            setTimeout(() => {


                let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
                let ctx = renderTarget.getContext("2d") as RenderingContext;

                let currentVideoElement = get().videoElement;
                if (currentVideoElement) {
                    currentVideoElement.pause();
                }
                renderTarget.height = 720;
                renderTarget.width = 1280;
                let width = renderTarget.width;
                let height = renderTarget.height;
                ctx.clearRect(0, 0, width, height);
                ctx.font = "100px Arial";


                ctx.fillStyle = "red";
                ctx.textAlign = "center";
                ctx.fillText("Welcome to Shout", width / 2, height / 2);
            }, 1000)
        },
        turnOffVideo: async () => {
            let call = get().call;
            if (!call) throw new Error("call was not initialized yet");

            let currentVideoStream = get().localVideoStream;
            if (currentVideoStream) {
                await call.stopVideo(currentVideoStream)
            }

        },
        loadAvailableCamerasAndMicrophones: async () => {
            let callClient = get().callClient;
            if (!callClient) throw new Error("callClient was not intialized yet");

            const deviceManager = await callClient.getDeviceManager();
            let cameras = await deviceManager.getCameras();
            let microphones = await deviceManager.getMicrophones();

            set({ availableCameras: cameras, availableMicrophones: microphones });
        }
    }
}))