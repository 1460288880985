import React from "react";
import "./typingIndicator.css";

export function TypingIndicator() {
  return (
    <div className="typingIndicatorContainer">
      <div className="typingIndicatorBubble">
        <div className="typingIndicatorBubbleDot"></div>
        <div className="typingIndicatorBubbleDot"></div>
        <div className="typingIndicatorBubbleDot"></div>
      </div>
    </div>
  );
}
