import create from "zustand";
import { loadRemote, storeFS } from "./Helpers";

export type SpeechStore = {
  api: {
    downloadModel: () => void;
  };
};
export const useSpeechStore = create<SpeechStore>((set, get) => ({
  api: {
    downloadModel: () => {
      let urls = {
        "tiny.en": "https://whisper.ggerganov.com/ggml-model-whisper-tiny.en.bin",
        "base.en": "https://whisper.ggerganov.com/ggml-model-whisper-base.en.bin",
      };

      let sizes = {
        "tiny.en": 75,
        "base.en": 142,
      };
      let model = "tiny.en";
      let url = "https://collabtris.sparkworkspace.com/models/ggml-model-whisper-tiny.en.bin";
      if (window.location.href.indexOf("localhost") !== -1) {
        url = "http://localhost:1234/static/ggml-model-whisper-tiny.en.bin";
      }
      let dst = "whisper.bin";
      let size_mb = sizes[model];

      // model_whisper = model;

      // document.getElementById('fetch-whisper-tiny-en').style.display = 'none';
      // document.getElementById('fetch-whisper-base-en').style.display = 'none';
      // document.getElementById('model-whisper-status').innerHTML = 'loading "' + model + '" ... ';

      const cbProgress = function (p) {
        // let el = document.getElementById('fetch-whisper-progress');
        // el.innerHTML = Math.round(100*p) + '%';
      };

      const cbCancel = function () {
        // var el;
        // el = document.getElementById('fetch-whisper-tiny-en'); if (el) el.style.display = 'inline-block';
        // el = document.getElementById('fetch-whisper-base-en'); if (el) el.style.display = 'inline-block';
        // el = document.getElementById('model-whisper-status');  if (el) el.innerHTML = '';
      };
      const printTextarea = (text) => {
        console.log(text);
      };
      loadRemote(url, dst, size_mb, cbProgress, storeFS, cbCancel, printTextarea);
    },
  },
}));
