import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CallClient,
  CallAgent,
  VideoStreamRenderer,
  VideoStreamRendererView,
  LocalVideoStream,
  LocalAudioStream,
  CaptionsInfo,
  Features,
  RemoteParticipant,
} from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationIdentityClient } from "@azure/communication-identity";
import { ChatClient } from "@azure/communication-chat";
import { meeting } from "@microsoft/teams-js";
import { useACS } from "./useACS";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import {
  SoundboardPreviewButton,
  SVGPlayButton,
  SVGStar,
  SVGStar2,
  SVGStopButton,
  VideoOrSoundOnlyIconDisplay,
} from "../../experiences/Soundboard/Soundboard";
import { useColyseusRoom } from "../../hooks/useColyseusRoom";
import { ACSPlayer, ACSState } from "../../../state/ACSState";
import { ACSRoom } from "../../../backend/ACSRoom";
import { getSoundName } from "../../experiences/Soundboard/SoundboardHelpers";
import { Alerts, useAlertsStore } from "../Alerts/Alerts";
import { KeyBoardKey } from "./Hotkeys";

import { useHotkeys } from "react-hotkeys-hook";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDominantSpeakerStore } from "../../hooks/useDominantSpeakerStore";
import { useTimeout } from "../../hooks/useTimeout";
import { useInterval } from "react-use";
import { Howl } from "howler";
import { useSoundboardStore } from "./useSoundboardStore";
import { Room } from "colyseus.js";
import { Megaphone } from "../Megaphone/Megaphone";
import { FlappyGame } from "../../experiences/FlappyGame/FlappyGame";
import { PuzzleGame } from "../../experiences/PuzzleGame/PuzzleGame";
import { Dragon, Dragon2 } from "../../experiences/Dragon/Dragon";
import { MissionControl } from "../../experiences/MissionControl/MissionControl";
import { Pipes } from "../../experiences/Pipes/Pipes";
import { MeetingRPGDynamicWrapper } from "../../experiences/MeetingRPG/src/MeetingRPGDynamicWrapper";
import { P5Experience, P5Experience2, P5Experience3 } from "../../experiences/P5/P5";
import { HangUpIcon, MuteButton } from "./CallControls";

const subscribeToRemoteVideoStream = async (remoteVideoStream, container) => {
  // Create a video stream renderer for the remote video stream.
  let videoStreamRenderer = new VideoStreamRenderer(remoteVideoStream);
  let view: VideoStreamRendererView;
  const renderVideo = async () => {
    try {
      // Create a renderer view for the remote video stream.
      view = await videoStreamRenderer.createView();

      // Attach the renderer view to the UI.
      container.hidden = false;
      container.appendChild(view.target);
    } catch (e) {
      console.warn(`Failed to createView, reason=${e.message}, code=${e.code}`);
    }
  };

  remoteVideoStream.on("isAvailableChanged", async () => {
    // Participant has switched video on.
    if (remoteVideoStream.isAvailable) {
      await renderVideo();

      // Participant has switched video off.
    } else {
      if (view) {
        view.dispose();
        view = undefined;
      }
    }
  });

  // Participant has video on initially.
  if (remoteVideoStream.isAvailable) {
    await renderVideo();
  }
};
const subscribeToRemoteParticipant = (remoteParticipant, container, room) => {
  try {
    // Inspect the initial remoteParticipant.state value.
    console.log(`Remote participant state: ${remoteParticipant.state}`);
    // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
    remoteParticipant.on("stateChanged", () => {
      console.log(`Remote participant state changed: ${remoteParticipant.state}`);
    });

    // Inspect the remoteParticipants's current videoStreams and subscribe to them.
    remoteParticipant.videoStreams.forEach((remoteVideoStream) => {
      console.log("VIDEO SCREEN FOREACH");
      subscribeToRemoteVideoStream(remoteVideoStream, container);
    });
    // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
    // notified when the remoteParticiapant adds new videoStreams and removes video streams.
    remoteParticipant.on("videoStreamsUpdated", (e) => {
      // Subscribe to new remote participant's video streams that were added.
      e.added.forEach((remoteVideoStream) => {
        subscribeToRemoteVideoStream(remoteVideoStream, container);
      });
      // Unsubscribe from remote participant's video streams that were removed.
      e.removed.forEach((remoteVideoStream) => {
        console.log("Remote participant video stream was removed.");
      });
    });

    remoteParticipant.on("isSpeakingChanged", (e) => {
      console.log(`Remote participant is speaking: `, remoteParticipant.isSpeaking, remoteParticipant.displayName);
      console.log("ROOMID", room.id);
      if (remoteParticipant.isSpeaking) {
        console.log("SOMEBODY IS SPEAKING");
        room.send("changeWhoIsSpeaking", { whoIsSpeaking: remoteParticipant.displayName });
      } else {
        room.send("changeWhoIsSpeaking", { whoIsSpeaking: "" });
      }
      // console.log("SENDING EVENT");
      // room.send("changeWhoIsSpeaking", { whoIsSpeaking: remoteParticipant.displayName });
    });
  } catch (error) {
    console.error(error);
  }
};

const VolumeSlider = ({ room }) => {
  const soundboardApi = useSoundboardStore((state) => state.api);
  const currentVolumeLevel = useSoundboardStore((state) => state.volumeLevel);
  const callIsConnected = useSoundboardStore((state) => state.callIsConnected);

  if (!callIsConnected) return null;

  return (
    <div className={"w-40"}>
      <input
        onChange={(e) => {
          // soundboardApi.setVolumeLevel(e.target.valueAsNumber);
          room.send("setSoundLevel", { volumeLevel: e.target.valueAsNumber });
        }}
        type="range"
        min="0.0"
        max="1"
        value={currentVolumeLevel}
        className="range range-primary range-xs"
        step={0.05}
      />
    </div>
  );
};

const SoundboardConnectedRow = ({ call, isMuted, room }) => {
  const soundboardApi = useSoundboardStore((state) => state.api);
  const incomingAudioMuted = useSoundboardStore((state) => state.incomingAudioMuted);

  return (
    <div>
      <div className={"flex"}>
        <div className={""}>Connected to Shout!</div>
        <div
          onClick={() => {
            soundboardApi.disconnectCall();

            // // TODO - real reload
            // window.location.reload();
          }}
          className={"p-2 pr-2 tooltip tooltip-left cursor-pointer"}
          data-tip={"Disconnect soundboard"}>
          <HangUpIcon />
        </div>
        <div
          className={"tooltip tooltip-left cursor-pointer"}
          data-tip={isMuted ? "Unmute soundboard" : "Mute soundboard"}>
          <div className={"p-2 px-0"}>
            <div
              onClick={() => {
                if (isMuted) {
                  call?.unmute();
                } else {
                  call?.mute();
                }
              }}>
              <MuteButton isMuted={isMuted} />
            </div>

            {/* <div
              onClick={() => {
                soundboardApi.stopAllSources();
              }}>
              Stop
            </div> */}
          </div>
        </div>
        <div className={"p-2"}>
          <div
            onClick={() => {
              if (incomingAudioMuted) {
                soundboardApi.setIncomingAudioMuted(false);
              } else {
                soundboardApi.setIncomingAudioMuted(true);
              }
            }}>
            <MuteButton isMuted={incomingAudioMuted} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const createAudioTrackToSend = (fileUrl: string, volumeLevel: number) => {
  console.log("CREATING AUDIO TRACK");

  return new Promise<MediaStreamTrack>((resolve, reject) => {
    // let yodelBuffer;

    // const offlineCtx = new OfflineAudioContext(2, 44100 * 40, 44100);
    const context = new AudioContext();
    // const source = offlineCtx.createBufferSource();

    // const context2 = new AudioContext();

    window
      .fetch(fileUrl)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
      .then((audioBuffer) => {
        const mediaDestinationNode = context.createMediaStreamDestination();
        const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
        // source.connect(mediaDestinationNode);

        const gainNode = context.createGain();
        console.log("VOLUME IS", volumeLevel);
        gainNode.gain.value = volumeLevel;
        source.connect(gainNode).connect(mediaDestinationNode);
        source.start();
        let track = mediaDestinationNode.stream.getAudioTracks()[0];
        resolve(track);
      });
  });
};

function DominantSpeakersWatcher({ room }) {
  const dominantSpeakers = useDominantSpeakerStore((state) => state.dominantSpeakersList);
  const dominantSpeakerStoreApi = useDominantSpeakerStore((state) => state.api);
  const secondsSinceChanged = useDominantSpeakerStore((state) => state.secondsSinceLastChange);

  const [triggerFlag, setTriggerFlag] = useState<boolean>(false);

  useInterval(() => {
    dominantSpeakerStoreApi.updateSecondsSinceLastChange();
  }, 1000);

  useEffect(() => {
    if (secondsSinceChanged > 10) {
      // console.log("ITS BEEN MORE THAN 10s");
      setTriggerFlag(true);
    }
  }, [secondsSinceChanged]);

  // useEffect(() => {
  //   if (triggerFlag) {
  //     room?.send("playSoundThroughAcsConnection", {
  //       ...{
  //         url: "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Jaws%20Theme.mp3?sv=2020-10-02&se=2022-09-22T17%3A35%3A43Z&sr=c&sp=rade&sig=%2FyVHf8nb0hq0FyUmivK51%2BxClGuihCT52jmpVrsPkHE%3D",
  //         name: "COOL",
  //       },
  //       playerName: "TEST",
  //       playerImage: null,
  //     });
  //   }
  // }, [triggerFlag]);

  return (
    <div>
      {/* <div
        onClick={() => {
          setTriggerFlag(false);
        }}>
        Reset Flag
      </div> */}
      {secondsSinceChanged}
    </div>
  );
}

export function ShoutSplash() {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="flex items-center justify-center">
          <Megaphone />
        </div>
      </div>
    </div>
  );
}
export function WhoIsSpeakingLine({ room }) {
  let [whoIsSpeaking, setWhoIsSpeaking] = useState("");

  useEffect(() => {
    if (!room) return;
    let listener = room.onMessage("whoIsSpeakingChanged", (message) => {
      setWhoIsSpeaking(message.data.whoIsSpeaking);
    });

    return () => {
      listener();
    };
  }, [room]);

  return <div>{`Speaking: ${whoIsSpeaking}`}</div>;
}

export function VideoTests() {
  const soundboardApi = useSoundboardStore((state) => state.api);
  const room = useSoundboardStore((state) => state.room);

  return (
    <div>
      <div>
        <canvas className={"scale-50"} id="renderTarget"></canvas>
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.turnOffVideo();
        }}>
        Stop Video
      </div>

      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.setupRenderingCanvas();
        }}>
        Start Video
      </div>

      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.setVideoToIdle();
        }}>
        Set Video Idle
      </div>

      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.switchToUserCamera();
        }}>
        Set Video To User
      </div>
      {/* <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.createVideoElement(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/What%20Can%20I%20Say%20Except%20Your%20Welcome.mp4?sv=2020-10-02&se=2022-11-12T13%3A26%3A24Z&sr=c&sp=rade&sig=5AxDjwwTegm8B3lBRny01GRxYu8cWh89rQiF2NSoujA%3D"
          );
        }}>
        Attach Video Element 1
      </div>

      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.createVideoElement(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/We%20Dont%20Need%20Roads.mp4?sv=2020-10-02&se=2022-11-12T13%3A35%3A06Z&sr=c&sp=rade&sig=5pAsHLXYnUVKM0R7x1nGodklcjkCj2%2Bn7SFueE3QJH0%3D"
          );
        }}>
        Attach Video Element 3
      </div>

      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.setupOffscreenCanvas();
        }}>
        Setup canvas
      </div> */}

      {/* <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("bird");
        }}>
        Test Flappy
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("dragon");
        }}>
        Dragon
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("dragon2");
        }}>
        Dragon 2
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("missioncontrol");
        }}>
        Mission Control
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("threejs");
        }}>
        RPG
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("defaultCanvas0");
        }}>
        P5
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("defaultCanvas1");
        }}>
        P5 2
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          soundboardApi.testVideoCanvas("defaultCanvas2");
        }}>
        P5 4
      </div> */}
      {/* <FlappyGame />
      <Dragon />
      <P5Experience /> */}
      {/* {room && <MeetingRPGDynamicWrapper room={room} />} */}
      {/* <Dragon2 /> */}
      {/* <FlappyGame />
      
      <Dragon /> */}
      {/* <MissionControl /> */}
      {/* <Pipes /> */}
      {/* <P5Experience />
      <P5Experience2 />
      <P5Experience3 /> */}
    </div>
  );
}
export function ACSConnectionHolder({
  room,
  joinUrl,
  displayName,
}: {
  room: Room<ACSRoom>;
  joinUrl: string;
  displayName: string;
}) {
  const endpointUrl = process.env.REACT_APP_ACS_URL;
  const callingFromNumber = process.env.REACT_APP_ACS_PHONE_NUMBER;

  const addToDominantSpeakersStore = useDominantSpeakerStore((state) => state.api.addDominantSpeakerReference);

  const soundboardApi = useSoundboardStore((state) => state.api);
  const call = useSoundboardStore((state) => state.call);
  const callIsConnected = useSoundboardStore((state) => state.callIsConnected);
  const callIsConnecting = useSoundboardStore((state) => state.callIsConnecting);

  useEffect(() => {
    if (!room) return;
    console.log("JOIN URL IS ", joinUrl);
    console.log(typeof joinUrl);
    console.log(joinUrl.length);
    if (joinUrl.length === 0) return;
    if (joinUrl.indexOf("teams") !== -1) {
      soundboardApi.initializeWithJoinUrl(room, joinUrl, displayName);
    } else {
      soundboardApi.initializeWithPhoneNumber(room, joinUrl, displayName, callingFromNumber);
    }
  }, [room, joinUrl]);

  const [isMuted, setIsMuted] = useState(false);
  const addAlert = useAlertsStore((state) => state.addAlert);

  useEffect(() => {
    if (call && callIsConnected) {
      const silentAudioUrl = process.env.REACT_APP_SILENT_AUDIO_URL;
      debugger;
      if (!silentAudioUrl) throw new Error("Missing setting for REACT_APP_SILENT_AUDIO_URL");
      soundboardApi.sendAudioTrack(silentAudioUrl);

      const callDominantSpeakersFeature = call.feature(Features.DominantSpeakers);
      const dominantSpeakersChangedHandler = () => {
        // Get the most up to date list of dominant speakers
        let dominantSpeakers = callDominantSpeakersFeature.dominantSpeakers;
        console.log("DOMINANT SPEAKERS", dominantSpeakers);
        addToDominantSpeakersStore(dominantSpeakers.speakersList, dominantSpeakers.timestamp);
        // dominantSpeakersArray.push({
        //   speakersList: dominantSpeakers.speakersList,
        //   timestamp: dominantSpeakers.timestamp,
        // });
        // console.log(dominantSpeakersArray);
        // setTimeout(() => {
        //   console.log("TIMEOUT FIRED");
        //   let dominantSpeakers = callDominantSpeakersFeature.dominantSpeakers;
        //   console.log("DOMINANT SPEAKERS AFTER TIMEOUT", dominantSpeakers);
        // }, 5000);
      };
      callDominantSpeakersFeature.on("dominantSpeakersChanged", dominantSpeakersChangedHandler);
    }

    if (call) {
      call.on("isMutedChanged", (e) => {
        console.log("ISMUTED CHANGED", call.isMuted);
        setIsMuted(call.isMuted);
      });
    }
  }, [call, callIsConnected]);

  useEffect(() => {
    if (callIsConnected) {
      room.onMessage("playSoundThroughACS", (message) => {
        addAlert({ text: getSoundName(message.url) });
        // soundboardApi.sendAudioTrack(message.url);
        console.log("RECEIVED PLAY SOUND REQUEST", message);
        soundboardApi.createVideoElement(message.url);
      });

      room.onMessage("soundKillNotification", (message) => {
        soundboardApi.stopAllSources();
      });

      room.onMessage("soundLevelChanged", (message) => {
        soundboardApi.setVolumeLevel(message.volumeLevel);
      });
    }
  }, [callIsConnected]);

  return (
    <div>
      {callIsConnecting && <ShoutSplash />}

      {callIsConnected && <SoundboardConnectedRow call={call} isMuted={isMuted} room={room} />}
      {callIsConnected && (
        <div>
          <VolumeSlider room={room} />
        </div>
      )}
    </div>
  );
}
type SoundData = Map<string, { url: string; name: string; metadata?: any }>;

export function ACS() {
  let [queryComplete, setQueryComplete] = useState(false);
  let [soundboardData, setSoundboardData] = useState<SoundData>();
  let [favoritedData, setFavoritedData] = useState<SoundData>();

  let [activeTabName, setActiveTabName] = useState("");

  const soundboardApi = useSoundboardStore((state) => state.api);
  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  const playerName = useGlobalStore2((store) => store.playerName);
  const playerImage = useGlobalStore2((store) => store.playerImageBase64);

  const callIsConnecting = useSoundboardStore((state) => state.callIsConnecting);
  const callIsConnected = useSoundboardStore((state) => state.callIsConnected);

  let defaultName = "TOM";

  let toMerge = { name: playerName || defaultName };
  let joinObj = {
    ...teamsContext,
    ...toMerge,
    ...{ imageBase64: playerImage },
  };

  const params = new URLSearchParams(location.search);
  const joinUrlParam = params.get("url");
  const nameParam = params.get("name");
  const numberParam = params.get("number");

  let [acsState, setAcsState] = useState(false);
  const [threadId, setThreadId] = useState("");
  const [joinUrl, setJoinUrl] = useState(
    ""
    // "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDg5OTM3MWUtMTRhNS00YTI0LWIxYTQtN2UwZjcyZjVlNzgy%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%220786a28b-fd76-429c-8a34-df0ae9d35165%22%7d"
  );
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);

  let userId = "NONE";
  if (teamsCtx && teamsCtx.user && teamsCtx.user.id) {
    userId = teamsCtx.user.id;
  }

  // useHotkeys("right", () => {
  //   debugger;
  //   setActiveTabName("movies");
  // });

  const addAlert = useAlertsStore((store) => store.addAlert);
  const { client, room: acsRoom, roomState } = useColyseusRoom<ACSState>("acs", joinObj);

  const getTabClass = (tabName) => {
    if (tabName === activeTabName) return "tab tab-xs  tab-bordered   border-white tab-active";
    else return "tab tab-xs ";
  };

  const onDragEnd = useCallback(
    (result, provided) => {
      // the only one that is required
      console.log("ONDRAGEND", result, provided);
      console.log(activeTabName, soundboardData);
      let activeData;
      if (activeTabName === "favorited") {
        let soundArray = favoritedData?.get(activeTabName);
        console.log(soundArray);
        let soundThatWasDragged = soundArray[result.source.index];
        console.log(soundThatWasDragged);
        let destinationIndex = result.destination.index;
        console.log(`We need to move ${soundThatWasDragged.name} from ${result.source.index} to ${destinationIndex}`);

        var element = soundArray[result.source.index];
        soundArray.splice(result.source.index, 1);
        soundArray.splice(destinationIndex, 0, element);
        console.log("NEW ARRAY", soundArray);

        favoritedData?.set(activeTabName, soundArray);
        setFavoritedData(favoritedData);
      } else {
        let soundArray = soundboardData?.get(activeTabName);
        console.log(soundArray);
        let soundThatWasDragged = soundArray[result.source.index];
        console.log(soundThatWasDragged);
        let destinationIndex = result.destination.index;
        console.log(`We need to move ${soundThatWasDragged.name} from ${result.source.index} to ${destinationIndex}`);

        var element = soundArray[result.source.index];
        soundArray.splice(result.source.index, 1);
        soundArray.splice(destinationIndex, 0, element);
        console.log("NEW ARRAY", soundArray);

        soundboardData?.set(activeTabName, soundArray);
        setSoundboardData(soundboardData);
      }
    },
    [soundboardData, activeTabName]
  );

  // const onKeyboardKeyPressed = useCallback(
  //   (soundData) => {
  //     acsRoom?.send("playSoundThroughAcsConnection", {
  //       ...soundData,
  //       playerName: playerName,
  //       playerImage: playerImage,
  //     });
  //   },
  //   [acsRoom]
  // );

  useEffect(() => {
    if (joinUrlParam) {
      setJoinUrl(joinUrlParam);
      return;
    }
    meeting.getMeetingDetails((err, details) => {
      console.log("DETAILS", details, err);
      if (!err) {
        setJoinUrl(details?.details.joinUrl || "");
        setThreadId(details?.conversation.id || "");
        meeting.appShareButton.setOptions({ isVisible: false });
      }
    });

    console.log("JOIN URL", joinUrl);
  }, [teamsCtx]);

  useEffect(() => {
    if (!acsRoom) return;

    acsRoom?.onStateChange.once((state) => {
      console.log("this is the first room state!", state);
      if (state.userWithACSConnection === acsRoom.sessionId) {
        console.log("I SHOULD BE THE ONE WITH THE ACS CONNECTION", state);
        setAcsState(true);
      }
    });
    acsRoom?.state.listen("userWithACSConnection", (value, prevValue) => {
      console.log("USER WITH ACS CONNECTION", value);
      if (value === acsRoom.sessionId) {
        if (acsRoom.state.acsConnectionStatus === "notstarted") {
          setAcsState(true);
        }
      }
    });

    let listener = acsRoom?.onMessage("alertSound", (message) => {
      if (!message.url) return;

      addAlert({
        text: getSoundName(message.url),
        imageBase64: message.playerImage,
        userDisplayName: message.playerName,
      });
    });

    return () => listener();
  }, [acsRoom]);

  useEffect(() => {
    if (!acsRoom || !soundboardData) return;
    let listener = acsRoom?.onMessage("gotSoundBoard", (message) => {
      soundboardData?.set("yours", message.data);
      setSoundboardData(soundboardData);
    });
    acsRoom?.send("getSoundboardForUser", { id: userId });

    return () => {
      listener();
    };
  }, [acsRoom, soundboardData]);

  useEffect(() => {
    if (!acsRoom) return;
    let listener = acsRoom?.onMessage("gotSharedSoundBoard", (message) => {
      console.log(message);
      if (message.data.length > 0) {
        const groupedMap = message.data.reduce(
          (entryMap, e) =>
            entryMap.set(e.metadata?.category || "other", [
              ...(entryMap.get(e.metadata?.category || "other") || []),
              e,
            ]),
          new Map()
        );
        setSoundboardData(groupedMap);
        setActiveTabName("reaction");
      }
      setQueryComplete(true);
    });
    acsRoom?.send("getSharedSoundboardBlobs", {});
    return () => {
      listener();
    };
  }, [acsRoom]);

  useEffect(() => {
    if (!acsRoom) return;

    let listener = acsRoom.onMessage("gotMyReactions", (message) => {
      if (message.data.length > 0) {
        const groupedMap = message.data.reduce(
          (entryMap, e) => entryMap.set("favorited" || "other", [...(entryMap.get("favorited" || "other") || []), e]),
          new Map()
        );

        setFavoritedData(groupedMap);
      } else {
        let emptyMap = new Map();
        emptyMap.set("favorited", []);
        setFavoritedData(emptyMap);
      }
    });
    acsRoom.send("getMyReactionsRequest", { id: userId });

    return () => {
      listener();
    };
  }, [acsRoom]);

  const PlayInMeetingButton = ({ soundData }) => {
    return (
      <div>
        <div
          onClick={() => {
            acsRoom?.send("playSoundThroughAcsConnection", {
              ...soundData,
              playerName: playerName,
              playerImage: playerImage,
            });
          }}>
          <div className={"cursor-pointer tooltip"} data-tip={"Play in Meeting!"}>
            <SVGPlayButton />
          </div>
        </div>
      </div>
    );
  };

  const StarButton = ({ mediaUrl, room }) => {
    return (
      <div
        className="tooltip cursor-pointer"
        data-tip={"Add to My Reactions"}
        onClick={() => {
          const soundUrlWithoutAuth = mediaUrl.split("?")[0];
          console.log("ADDING REACTION", soundUrlWithoutAuth);
          room.send("addToMyReactionsRequest", {
            id: userId,
            soundUrl: soundUrlWithoutAuth,
          });
        }}>
        <SVGStar />
      </div>
    );
  };

  const StarButtonUnfavorite = ({ mediaUrl, room }) => {
    return (
      <div
        className="tooltip cursor-pointer"
        data-tip={"Remove from My Reactions"}
        onClick={() => {
          const soundUrlWithoutAuth = mediaUrl.split("?")[0];
          console.log("REMOVING REACTION", soundUrlWithoutAuth);
          room.send("removeFromMyReactionsRequest", {
            id: userId,
            soundUrl: soundUrlWithoutAuth,
          });
        }}>
        <SVGStar2 />
      </div>
    );
  };

  const SoundTitle = ({ soundData }) => {
    return (
      <div className={"flex"}>
        <div className={"text-secondary pr-1"}>{soundData.metadata?.reactionName || getSoundName(soundData.url)}</div>
      </div>
    );
  };

  if (!acsRoom) return null;

  let keyboardLetters = "qwertyuiopasdfghjklzxcvbnm";

  const SBInner = () => {
    if (!queryComplete || !favoritedData || !soundboardData) {
      return <div>Loading...</div>;
    } else {
      let allData = Array.from(new Map([...soundboardData.entries(), ...favoritedData.entries()]));

      return (
        <div className={"overflow-x-hidden"}>
          <div className={"pt-2"}>
            <div className="tabs">
              {queryComplete && soundboardData && soundboardData.get("reaction") && (
                <a
                  onClick={() => {
                    setActiveTabName("reaction");
                  }}
                  className={getTabClass("reaction")}>
                  {"reaction"}
                </a>
              )}
              {queryComplete &&
                soundboardData &&
                Array.from(soundboardData.entries()).map((s) => {
                  if (s[0] === "reaction") return null;

                  return (
                    <a
                      onClick={() => {
                        setActiveTabName(s[0]);
                      }}
                      className={getTabClass(s[0])}>
                      {s[0]}
                    </a>
                  );
                })}
              {queryComplete &&
                favoritedData &&
                Array.from(favoritedData.entries()).map((s) => {
                  if (s[0] === "reaction") return null;

                  return (
                    <a
                      onClick={() => {
                        acsRoom.send("getMyReactionsRequest", { id: userId });
                        setActiveTabName(s[0]);
                      }}
                      className={getTabClass(s[0])}>
                      {s[0]}
                    </a>
                  );
                })}
              {/* {queryComplete && soundboardData && (
              <a
                onClick={() => {
                  setActiveTabName("yours");
                }}
                className={getTabClass("yours")}>
                {"yours"}
              </a>
            )} */}
            </div>
          </div>

          <div>
            {allData.map((s) => {
              if (s[0] !== activeTabName) return null;
              return (
                <div className={"p-2"}>
                  {s[1].map((soundData, index) => {
                    return (
                      <div className={"ring-1 ring-base-300 rounded-sm p-2"}>
                        {" "}
                        <SoundTitle soundData={soundData} />
                        <div className={"flex"}>
                          <div className={"px-1"}>
                            <KeyBoardKey
                              key={keyboardLetters[index]}
                              letter={keyboardLetters[index]}
                              onPressedFn={() => {
                                console.log("RUNNING ONPRESSED FUNCTION 2", soundData);

                                acsRoom?.send("playSoundThroughAcsConnection", {
                                  ...soundData,
                                  playerName: playerName,
                                  playerImage: playerImage,
                                });
                              }}
                              onCntrlPressedFn={() => {
                                console.log("ON CNTRL PRESS");
                                var sound = new Howl({
                                  src: [soundData.url],
                                  html5: true,
                                  onend: () => {
                                    console.log("HOWLER ON END");
                                  },
                                });
                                sound.play();
                              }}
                            />
                          </div>
                          <div className={"px-1"}>
                            <PlayInMeetingButton soundData={soundData} />
                          </div>
                          <div className={"px-1"}>
                            <div
                              onClick={() => {
                                // soundOnSrc.stopAllSources();
                                // console.log("STOPPED AUDIO");
                                // acsRoom?.send("playSoundThroughAcsConnection", {
                                //   url: "https://sparkprovisioningstor.blob.core.windows.net/soundboard/stop.mp3?sv=2019-12-12&st=2022-08-17T16%3A05%3A19Z&se=2094-08-18T16%3A05%3A00Z&sr=b&sp=r&sig=yCiVgvUw7eJF9F1JCnMPxnrekLAyDAY%2BE%2FFl%2BfsMbpE%3D",
                                // });
                                acsRoom?.send("sendSoundKillNotification", {});
                              }}
                              className={"cursor-pointer tooltip"}
                              data-tip={"Stop"}>
                              <SVGStopButton />
                            </div>
                          </div>
                          <div className={"px-1"}>
                            <SoundboardPreviewButton soundUrl={soundData.url} />
                          </div>

                          {favoritedData && (
                            <div className={"px-1"}>
                              {Array.from(favoritedData)[0].map((t, idx) => {
                                if (idx === 1) {
                                  const found = t.find((p) => p.url.split("?")[0] === soundData.url.split("?")[0]);

                                  if (found) {
                                    return <StarButtonUnfavorite mediaUrl={soundData.url} room={acsRoom} />;
                                  } else {
                                    return <StarButton mediaUrl={soundData.url} room={acsRoom} />;
                                  }
                                }
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };
  const SBInner2 = () => {
    if (!queryComplete || !favoritedData || !soundboardData) {
      return <div></div>;
    } else {
      let allData = Array.from(new Map([...soundboardData.entries(), ...favoritedData.entries()]));

      return (
        <div className={"overflow-x-hidden"}>
          <div className={"pt-4"}>
            <div className="tabs">
              {queryComplete && soundboardData && soundboardData.get("reaction") && (
                <a
                  onClick={() => {
                    setActiveTabName("reaction");
                  }}
                  className={getTabClass("reaction")}>
                  {"reaction"}
                </a>
              )}
              {queryComplete &&
                soundboardData &&
                Array.from(soundboardData.entries()).map((s) => {
                  if (s[0] === "reaction") return null;

                  return (
                    <a
                      onClick={() => {
                        setActiveTabName(s[0]);
                      }}
                      className={getTabClass(s[0])}>
                      {s[0]}
                    </a>
                  );
                })}
              {queryComplete &&
                favoritedData &&
                Array.from(favoritedData.entries()).map((s) => {
                  if (s[0] === "reaction") return null;

                  return (
                    <a
                      onClick={() => {
                        acsRoom.send("getMyReactionsRequest", { id: userId });
                        setActiveTabName(s[0]);
                      }}
                      className={getTabClass(s[0])}>
                      {s[0]}
                    </a>
                  );
                })}
              {/* {queryComplete && soundboardData && (
              <a
                onClick={() => {
                  setActiveTabName("yours");
                }}
                className={getTabClass("yours")}>
                {"yours"}
              </a>
            )} */}
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={() => {}}>
            <Droppable droppableId="droppable-2" type="PERSON">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  // style={{ backgroundColor: snapshot.isDraggingOver ? "blue" : "grey" }}
                  {...provided.droppableProps}>
                  {provided.placeholder}
                  {allData.map((s) => {
                    if (s[0] !== activeTabName) return null;
                    return (
                      <div className={"p-2"}>
                        {s[1].map((soundData, index) => {
                          return (
                            <Draggable draggableId={"draggable-" + index} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  className={"ring-1 ring-base-300 rounded-sm p-2"}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  {" "}
                                  <SoundTitle soundData={soundData} />
                                  <div className={"flex"}>
                                    <div className={"px-1"}>
                                      <KeyBoardKey
                                        key={keyboardLetters[index]}
                                        letter={keyboardLetters[index]}
                                        onPressedFn={() => {
                                          console.log("RUNNING ONPRESSED FUNCTION 2", soundData);

                                          acsRoom?.send("playSoundThroughAcsConnection", {
                                            ...soundData,
                                            playerName: playerName,
                                            playerImage: playerImage,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className={"px-1"}>
                                      <PlayInMeetingButton soundData={soundData} />
                                    </div>
                                    <div className={"px-1"}>
                                      <div
                                        onClick={() => {
                                          console.log("STOPPED AUDIO");
                                          acsRoom?.send("playSoundThroughAcsConnection", {
                                            url: "https://sparkprovisioningstor.blob.core.windows.net/soundboard/stop.mp3?sv=2019-12-12&st=2022-08-17T16%3A05%3A19Z&se=2094-08-18T16%3A05%3A00Z&sr=b&sp=r&sig=yCiVgvUw7eJF9F1JCnMPxnrekLAyDAY%2BE%2FFl%2BfsMbpE%3D",
                                          });
                                        }}
                                        className={"cursor-pointer tooltip"}
                                        data-tip={"Stop"}>
                                        <SVGStopButton />
                                      </div>
                                    </div>
                                    <div className={"px-1"}>
                                      <SoundboardPreviewButton soundUrl={soundData.url} />
                                    </div>

                                    {favoritedData && (
                                      <div className={"px-1"}>
                                        {Array.from(favoritedData)[0].map((t, idx) => {
                                          if (idx === 1) {
                                            const found = t.find(
                                              (p) => p.url.split("?")[0] === soundData.url.split("?")[0]
                                            );

                                            if (found) {
                                              return <StarButtonUnfavorite mediaUrl={soundData.url} room={acsRoom} />;
                                            } else {
                                              return <StarButton mediaUrl={soundData.url} room={acsRoom} />;
                                            }
                                          }
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      );
    }
  };
  return (
    <div>
      {/* <div className={"p-2"}>
        <WhoIsSpeakingLine room={acsRoom} />
      </div> */}
      {!acsRoom && <ShoutSplash />}
      {acsState ? (
        <div className={"p-2"}>
          <ACSConnectionHolder room={acsRoom} joinUrl={joinUrl} displayName={nameParam || "Shout"} />
          {callIsConnected && <SBInner />}
          {/* <DominantSpeakersWatcher room={acsRoom} /> */}
        </div>
      ) : (
        <div className={"p-2"}>
          <VolumeSlider room={acsRoom} />

          {/* <div className={"text-primary"}>Shout</div> */}
          <SBInner2 />
        </div>
      )}
      <Alerts />
      <VideoTests />
    </div>
  );
}

export function ACSJoiner() {
  const [joinUrl, setJoinUrl] = useState("");
  const [name, setName] = useState("Shout");

  const [showConnectButton, setShowConnectButton] = useState(false);

  const isJoinUrlValid = (url) => {
    if (url.indexOf("https://teams.microsoft.com/l/meetup-join/") !== -1) {
      return true;
    } else return false;
    return true;
  };
  return (
    <div className={"flex items-center justify-center h-screen"}>
      <div>
        <div>
          <h1 className={"text-lg"}>Ad-Hoc Connect Soundboard</h1>
        </div>

        <div className={"pt-2"}>
          <input
            type="text"
            placeholder="Enter the Join Url for a Teams meeting"
            className="input input-sm input-bordered w-80"
            value={joinUrl}
            onChange={(e) => {
              setJoinUrl(e.target.value);
              if (isJoinUrlValid(e.target.value)) {
                setShowConnectButton(true);
              }
            }}></input>
        </div>

        <div className={"pt-2"}>
          <input
            type="text"
            placeholder="give it a name"
            className="input input-sm input-bordered w-80"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}></input>
        </div>

        <div className="pt-2">
          {!showConnectButton && <div className="btn btn-primary btn-xs btn-disabled">Connect</div>}
          {showConnectButton && (
            <div
              className="btn btn-primary btn-xs"
              onClick={() => {
                window.location.href = "/acs?url=" + encodeURIComponent(joinUrl) + "&name=" + encodeURIComponent(name);
              }}>
              Connect
            </div>
          )}
        </div>

        <div className={"pt-4 text-xs w-80"}>
          Use this page to connect the Soundboard to your meeting when adding a Teams app is not available. Useful for
          when you are not the meeting organizer or the meeting is scheduled by a user in another tenant.
        </div>
      </div>
    </div>
  );
}
export function ACS3({ room }) {
  const [hasInteracted, setHasInteracted] = useState(false);

  const [threadId, setThreadId] = useState("");
  const [joinUrl, setJoinUrl] = useState("");
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);

  useEffect(() => {
    meeting.getMeetingDetails((err, details) => {
      console.log("DETAILS", details, err);
      if (!err) {
        setJoinUrl(details?.details.joinUrl || "");
        setThreadId(details?.conversation.id || "");
      }
    });

    setThreadId("19:meeting_NTI0YmUxZGYtMjM4NS00Y2I2LTkyN2MtYTg1Nzg3YTYwMzFh@thread.v2");
    setJoinUrl(
      "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTI0YmUxZGYtMjM4NS00Y2I2LTkyN2MtYTg1Nzg3YTYwMzFh%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%220786a28b-fd76-429c-8a34-df0ae9d35165%22%7d"
    );
  }, [teamsCtx]);

  if (!joinUrl || !threadId) return null;

  return (
    <div>
      {/* <div
        onClick={() => {
          setHasInteracted(true);
        }}
        className={"btn btn-primary"}>
        Interact
      </div> */}
      <ACSInner2 room={room} joinUrl={joinUrl} threadId={threadId} />
    </div>
  );
}
export function ACSInner2({ room, joinUrl, threadId }) {
  const connectionString: string = ""; // process.env.REACT_APP_ACS_CONNECTION_STRING;
  const endpointUrl = process.env.REACT_APP_ACS_URL;
  const { call, callIsConnected } = useACS(connectionString, endpointUrl, joinUrl, threadId);

  const subscribe = (rp: RemoteParticipant, room) => {
    rp.on("isSpeakingChanged", (e) => {
      console.log(`Remote participant is speaking: `, rp.isSpeaking, rp.displayName);
      console.log("ROOMID", room.id);
      if (rp.isSpeaking) {
        console.log("SOMEBODY IS SPEAKING");
        room.send("changeWhoIsSpeaking", { whoIsSpeaking: rp.displayName });
      } else {
        room.send("changeWhoIsSpeaking", { whoIsSpeaking: "" });
      }
    });

    console.log("RAN SUBSCRIBE");
  };

  useEffect(() => {
    call?.remoteParticipants.forEach((remoteParticipant) => {
      console.log("FOUND REMOTE PARTICIPANT", remoteParticipant);
      subscribe(remoteParticipant, room);
    });
    // Subscribe to the call's 'remoteParticipantsUpdated' event to be
    // notified when new participants are added to the call or removed from the call.
    call?.on("remoteParticipantsUpdated", (e) => {
      // Subscribe to new remote participants that are added to the call.
      e.added.forEach((remoteParticipant) => {
        console.log("REMOTE PARTICIPANT ADDED", remoteParticipant, remoteParticipant.displayName, room);

        subscribe(remoteParticipant, room);
      });
      // Unsubscribe from participants that are removed from the call
      e.removed.forEach((remoteParticipant) => {
        console.log("Remote participant removed from the call.");
      });
    });
  }, [call]);

  const createAudioTrackToSend = (fileUrl: string) => {
    console.log("CREATING AUDIO TRACK");

    return new Promise<MediaStreamTrack>((resolve, reject) => {
      let yodelBuffer;

      const offlineCtx = new OfflineAudioContext(2, 44100 * 40, 44100);
      const context = new AudioContext();
      const source = offlineCtx.createBufferSource();

      const context2 = new AudioContext();

      window
        .fetch(fileUrl)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          const mediaDestinationNode = context.createMediaStreamDestination();
          const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
          source.connect(mediaDestinationNode);
          source.start();
          let track = mediaDestinationNode.stream.getAudioTracks()[0];
          resolve(track);
        });
    });
  };

  useEffect(() => {
    if (call && !callIsConnected) {
      createAudioTrackToSend("/tomhasenteredmeeting.mp4").then((track) => {
        console.log("OK");
        const localAudioStream = new LocalAudioStream(track);
        call?.startAudio(localAudioStream);
        console.log("AFTER START AUDIO");
      });
    }

    // if (call && callIsConnected) {
    //   createAudioTrackToSend("/tomhasenteredmeeting.mp4").then((track) => {
    //     console.log("OK");
    //     const localAudioStream = new LocalAudioStream(track);
    //     call?.startAudio(localAudioStream);
    //     console.log("AFTER START AUDIO");
    //   });
    // }
  }, [call, callIsConnected]);

  let [queryComplete, setQueryComplete] = useState(false);
  let [soundboardData, setSoundboardData] = useState([]);
  const [whoIsSpeaking, setWhoIsSpeaking] = useState("");

  const playSoundFn = (url) =>
    createAudioTrackToSend(url).then((track) => {
      console.log("OK");
      const localAudioStream = new LocalAudioStream(track);
      call?.startAudio(localAudioStream);
      console.log("AFTER START AUDIO");
    });

  useEffect(() => {
    let listener = room.onMessage("gotSharedSoundBoard", (message) => {
      console.log(message);
      if (message.data.length > 0) {
        setSoundboardData(message.data);
      }
      setQueryComplete(true);
    });
    room.send("getSharedSoundboardBlobs", {});
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    let listener = room.onMessage("whoIsSpeakingChanged", (message) => {
      setWhoIsSpeaking(message.data.whoIsSpeaking);
    });

    return () => {
      listener();
    };
  }, []);

  const PlayInMeetingButton = ({ soundData }) => {
    const soundboardApi = useSoundboardStore((state) => state.api);

    return (
      <div
        onClick={() => {
          playSoundFn(soundData.url);
        }}>
        <div className={"cursor-pointer tooltip"} data-tip={"Play in Meeting!"}>
          <SVGPlayButton />
        </div>
      </div>
    );
  };

  if (!call) return <div>Setting up ACS call...</div>;

  if (call && !callIsConnected) return <div>connecting to ACS...</div>;
  else
    return (
      <div className={"p-2"}>
        {call && callIsConnected && <div>call is connected</div>}
        {/* <div>{whoIsSpeaking} is speaking.</div> */}
        <div
          onClick={() => {
            call?.hangUp();
          }}
          className={"btn btn-primary btn-xs"}>
          Hang up
        </div>

        <div
          onClick={() => {
            call?.muteIncomingAudio();
          }}
          className={"btn btn-primary btn-xs"}>
          Mute Incoming
        </div>

        <div
          onClick={() => {
            call?.unmuteIncomingAudio();
          }}
          className={"btn btn-primary btn-xs"}>
          Unmute Incoming
        </div>

        <div>
          <input type="range" min="0" max="100" value="40" className="range range-primary" />
        </div>
        {queryComplete &&
          soundboardData.length > 0 &&
          soundboardData.map((soundData) => {
            return (
              <div className={"pb-2"}>
                <VideoOrSoundOnlyIconDisplay soundData={soundData} />
                <div className={"flex items-end justify-end"}>
                  <div className={"px-1"}>
                    <PlayInMeetingButton soundData={soundData} />
                  </div>
                  <div className={"px-1"}>
                    <div
                      onClick={() => {
                        console.log("STOPPED AUDIO");
                      }}
                      className={"cursor-pointer tooltip"}
                      data-tip={"Stop"}>
                      <SVGStopButton />
                    </div>
                  </div>
                  <div className={"px-1"}>
                    <SoundboardPreviewButton soundUrl={soundData.url} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
}
export function ACS2({ room }) {
  const [threadId, setThreadId] = useState("");
  const [joinUrl, setJoinUrl] = useState("");
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);

  useEffect(() => {
    if (false) {
      meeting.getMeetingDetails((err, details) => {
        console.log("DETAILS", details, err);
        if (!err) {
          setJoinUrl(details?.details.joinUrl || "");
          setThreadId(details?.conversation.id || "");
        }
      });
    } else {
      setThreadId("19:meeting_NTI0YmUxZGYtMjM4NS00Y2I2LTkyN2MtYTg1Nzg3YTYwMzFh@thread.v2");
      setJoinUrl(
        "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTI0YmUxZGYtMjM4NS00Y2I2LTkyN2MtYTg1Nzg3YTYwMzFh%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%220786a28b-fd76-429c-8a34-df0ae9d35165%22%7d"
      );
    }
  }, [teamsCtx]);

  if (!threadId || !joinUrl) return null;
  else
    return (
      <div>
        {threadId}
        {joinUrl}
        <ACSInner room={room} threadId={threadId} joinUrl={joinUrl} />
      </div>
    );
}
export function ACSInner({ room, threadId, joinUrl }) {
  const remoteVideoContainerRef = useRef();
  const localVideoContainerRef = useRef();

  useEffect(() => {
    const connectionString = ""; // process.env.REACT_APP_ACS_CONNECTION_STRING;
    const endpointUrl = process.env.REACT_APP_ACS_URL;

    const identityClient = new CommunicationIdentityClient(connectionString);

    let identityResponse = identityClient.createUser().then((response) => {
      let userId = response.communicationUserId;
      console.log(`\nCreated an identity with ID: ${response.communicationUserId}`);

      let tokenResponse = identityClient.getToken(response, ["voip", "chat"]).then((r2) => {
        const { token, expiresOn } = r2;
        console.log(`\nIssued an access token that expires at: ${expiresOn}`);
        console.log(token);

        const callClient = new CallClient();
        const tokenCredential = new AzureCommunicationTokenCredential(token);
        callClient.createCallAgent(tokenCredential).then((agent) => {
          let call = agent.join(
            {
              meetingLink: joinUrl, //"https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzIxMmNhOWEtOTBjNi00YTY3LTk0YzItZjE5ZTY1ODdjN2Ji%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%220786a28b-fd76-429c-8a34-df0ae9d35165%22%7d",
            },
            {}
          );
          call.on("stateChanged", () => {
            console.log("CALL STATE CHANGED", call.state);

            if (call.state === "Connected") {
              console.log("We are connected");

              let chatClient = new ChatClient(endpointUrl, new AzureCommunicationTokenCredential(token));

              console.log("Azure Communication Chat client created!");

              chatClient.startRealtimeNotifications().then(() => {
                // subscribe to new message notifications
                chatClient.on("chatMessageReceived", (e) => {
                  console.log("Notification chatMessageReceived!", e);

                  //   // check whether the notification is intended for the current thread
                  // if (threadIdInput.value != e.threadId) {
                  //   return;
                  // }

                  // if (e.sender.communicationUserId != userId) {
                  //    renderReceivedMessage(e.message);
                  // }
                  // else {
                  //    renderSentMessage(e.message);
                  // }
                });

                let chatThreadClient = chatClient.getChatThreadClient(
                  threadId // "19:meeting_NzIxMmNhOWEtOTBjNi00YTY3LTk0YzItZjE5ZTY1ODdjN2Ji@thread.v2"
                );

                let sendMessageRequest = { content: "Hello" };
                let sendMessageOptions = { senderDisplayName: "Jack" };
                chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions).then(() => {
                  console.log("Message sent!");
                });
              });
            }
          });

          const createAudioTrackToSend = (fileUrl: string) => {
            console.log("CREATING AUDIO TRACK");

            return new Promise<MediaStreamTrack>((resolve, reject) => {
              let yodelBuffer;

              const offlineCtx = new OfflineAudioContext(2, 44100 * 40, 44100);
              const context = new AudioContext();
              const source = offlineCtx.createBufferSource();

              const context2 = new AudioContext();

              window
                .fetch("/tomhasenteredmeeting.mp4")
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
                .then((audioBuffer) => {
                  const mediaDestinationNode = context.createMediaStreamDestination();
                  const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
                  source.connect(mediaDestinationNode);
                  source.start();
                  let track = mediaDestinationNode.stream.getAudioTracks()[0];
                  resolve(track);

                  // const source = new AudioBufferSourceNode(offlineCtx, { buffer: audioBuffer });
                  // source.connect(offlineCtx.destination);
                  // source.start();

                  // offlineCtx.startRendering().then((renderedBuffer) => {
                  //   console.log("Rendering completed successfully");
                  //   const song = new AudioBufferSourceNode(context, { buffer: renderedBuffer });
                  //   // song.buffer = renderedBuffer;
                  //   // song.connect(context.destination);
                  //   // song.start();

                  //   const mediaDestinationNode = context.createMediaStreamDestination();
                  //   song.connect(mediaDestinationNode);
                  //   song.start();
                  //   let track = mediaDestinationNode.stream.getAudioTracks()[0];
                  //   resolve(track);
                  // });
                });
            });
            //             const audio = new Audio("https://sparkprovisioningstor.blob.core.windows.net/soundboard/tomhasenteredmeeting.mp4?sv=2019-12-12&st=2022-03-07T14:58:31Z&se=2049-08-08T13:58:00Z&sr=b&sp=r&sig=j6pf7iwij9gQ2rtuG2f7ZQiorIvtfNcsD7Mv6Ef5mgg=");
            //             let mediaStream = new MediaStream();
            //             let mediaTrack = new MediaStreamTrack();

            // // const stream = audio();
            // return mediaTrack;
          };

          const createAudioTrackToSend2 = () => {
            return new Promise<MediaStreamTrack>((resolve, reject) => {
              // const audio = new Audio(
              //   "https://sparkprovisioningstor.blob.core.windows.net/soundboard/tomhasenteredmeeting.mp4?sv=2019-12-12&st=2022-03-07T14:58:31Z&se=2049-08-08T13:58:00Z&sr=b&sp=r&sig=j6pf7iwij9gQ2rtuG2f7ZQiorIvtfNcsD7Mv6Ef5mgg="
              // );
              const audio = new Audio("/tomhasenteredmeeting.mp4");
              audio.crossOrigin = "anonymous";

              audio.onplay = () => {
                console.log("ONPLAY EVENT");
                // audio.muted = false;
              };

              audio.onloadedmetadata = () => {
                console.log("ONLOADED");
                // const mediaStream = new MediaStream();
                console.log("BEFORE STREAM");
                // const stream = audio.captureStream();
                const mediaTrack: MediaStreamTrack = audio.captureStream().getAudioTracks()[0];

                // const audio2 = new Audio();
                // audio2.autoplay = true;
                // audio2.srcObject = audio.captureStream();

                let cloned = mediaTrack.clone();
                console.log("MEDIATRACK", cloned);
                audio.play();
                // mediaStream.addTrack(mediaTrack);
                resolve(mediaTrack);
              };

              // const stream = audio.captureStream();
              // const mediaTrack: MediaStreamTrack = stream.getAudioTracks()[0];
              // let audioCtx = new AudioContext();
              // let audioTracks = stream.getAudioTracks();
              // debugger;
              // let source = audioCtx.createMediaStreamTrackSource(mediaTrack);

              // audio.onloadeddata = (event) => {
              //   const mediaStream: MediaStream = audio.captureStream();
              //   const mediaTrack: MediaStreamTrack = mediaStream.getAudioTracks()[0];

              //   console.log(
              //     "Yay! The readyState just increased to  " + "HAVE_CURRENT_DATA or greater for the first time."
              //   );
              //   resolve(mediaTrack);
              // };
              // const stream = audio.captureStream();
              // audio.muted = false;
              // debugger;
              // audio.play().then(() => {
              //   const tracks = stream.getAudioTracks();
              //   const videoTracks = stream.getVideoTracks();
              //   resolve(tracks[0]);
              // });
            });
          };
          let btn = document.getElementById("playFunction");
          btn.onclick = () => {
            createAudioTrackToSend(
              "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Crickets.mp3?sv=2020-10-02&se=2022-08-09T14%3A57%3A39Z&sr=c&sp=rade&sig=45bPEkZvBW%2BgTi9NsK5LNBt4H4OUOTg8gKdl56Lpmfo%3D"
            ).then((track) => {
              console.log("OK");
              const localAudioStream = new LocalAudioStream(track);
              call.startAudio(localAudioStream);
              console.log("AFTER START AUDIO");
            });
          };

          let btn2 = document.getElementById("playFunction2");
          btn2.onclick = () => {
            createAudioTrackToSend(
              "https://sparkprovisioningstor.blob.core.windows.net/soundboard/0786a28b-fd76-429c-8a34-df0ae9d35165/What%20Can%20I%20Say%20Except%20Your%20Welcome.mp4?sv=2020-10-02&se=2022-08-09T14%3A57%3A39Z&sr=c&sp=rade&sig=45bPEkZvBW%2BgTi9NsK5LNBt4H4OUOTg8gKdl56Lpmfo%3D"
            ).then((track) => {
              console.log("OK");
              const localAudioStream = new LocalAudioStream(track);
              call.startAudio(localAudioStream);
              console.log("AFTER START AUDIO 2");
            });
          };
          // const localAudioStream = new LocalAudioStream(createAudioTrackToSend());

          // call.startAudio(localAudioStream);

          // call.on("localVideoStreamsUpdated", (e) => {
          //   console.log("STREAM UPDATED");
          // });

          // call.localVideoStreams.forEach(async (lvs) => {
          //   console.log("LOCAL VIDEO");
          // });
          // Inspect the call's current remote participants and subscribe to them.
          call.remoteParticipants.forEach((remoteParticipant) => {
            console.log("FOUND REMOTE PARTICIPANT", remoteParticipant);
            subscribeToRemoteParticipant(remoteParticipant, remoteVideoContainerRef.current, room);
          });
          // Subscribe to the call's 'remoteParticipantsUpdated' event to be
          // notified when new participants are added to the call or removed from the call.
          call.on("remoteParticipantsUpdated", (e) => {
            // Subscribe to new remote participants that are added to the call.
            e.added.forEach((remoteParticipant) => {
              console.log("REMOTE PARTICIPANT ADDED", remoteParticipant, remoteParticipant.displayName, room);

              subscribeToRemoteParticipant(remoteParticipant, remoteVideoContainerRef.current, room);
            });
            // Unsubscribe from participants that are removed from the call
            e.removed.forEach((remoteParticipant) => {
              console.log("Remote participant removed from the call.");
            });
          });

          return () => {
            call.hangUp({
              forEveryone: false,
            });
          };
        });
      });
    });

    // const { token, expiresOn } = tokenResponse;
    // console.log(`\nIssued an access token that expires at: ${expiresOn}`);
    // console.log(token);

    // const callClient = new CallClient();
    // const tokenCredential = new AzureCommunicationTokenCredential(token);
    // callAgent = await callClient.createCallAgent(tokenCredential);
  }, []);
  return (
    <div>
      ACS <button id="playFunction">Play</button> <button id="playFunction2">Play 2</button>
      <div ref={remoteVideoContainerRef} id="remoteVideoContainer" hidden>
        Remote participants' video streams:
      </div>
      <div ref={localVideoContainerRef}></div>
    </div>
  );
}

export function ACSView({ room }) {
  const [whoIsSpeaking, setWhoIsSpeaking] = useState("");
  useEffect(() => {
    room.onMessage("whoIsSpeakingChanged", (options) => {
      console.log("WHO IS SPEAKING CHANGED", options);
      setWhoIsSpeaking(JSON.stringify(options));
    });
  }, [room]);
  return <div>Who is speaking: {whoIsSpeaking}</div>;
}

export function ACS4() {
  const connectionString: string = ""; // process.env.REACT_APP_ACS_CONNECTION_STRING;
  const callingFromNumber: string = process.env.REACT_APP_ACS_PHONE_NUMBER;

  const soundboardApi = useSoundboardStore((state) => state.api);

  const params = new URLSearchParams(location.search);
  const phoneNumberParam = params.get("number");
  const nameParam = params.get("name");

  if (!phoneNumberParam) throw new Error("number is required");
  if (!nameParam) throw new Error("name is required");

  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  const playerName = useGlobalStore2((store) => store.playerName);
  const playerImage = useGlobalStore2((store) => store.playerImageBase64);

  let toMerge = { name: playerName || "Unknown" };
  let joinObj = {
    ...teamsContext,
    ...toMerge,
    ...{ imageBase64: playerImage },
  };

  const { client, room: acsRoom, roomState } = useColyseusRoom<ACSState>("acs", joinObj);

  useEffect(() => {
    if (!acsRoom) return;

    soundboardApi.initializeWithPhoneNumber(acsRoom, connectionString, phoneNumberParam, nameParam, callingFromNumber);
  }, [acsRoom]);
  return (
    <div>
      ACS4
      <div
        onClick={() => {
          soundboardApi.disconnectCall();
        }}>
        Disconnect
      </div>
      <div
        onClick={() => {
          soundboardApi.sendAudioTrack(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D",
            1
          );
        }}>
        Send Audio Track 1
      </div>
      <div
        onClick={() => {
          soundboardApi.sendAudioTrack(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D",
            0.5
          );
        }}>
        Send Audio Track 0.5
      </div>
      <div
        onClick={() => {
          soundboardApi.sendAudioTrack(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D",
            2
          );
        }}>
        Send Audio Track 2
      </div>
      <div
        onClick={() => {
          soundboardApi.sendAudioTrack(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D",
            0
          );
        }}>
        Send Audio Track 0
      </div>
      <div
        onClick={() => {
          soundboardApi.sendAudioTrack(
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D",
            0.1
          );
        }}>
        Send Audio Track 0.1
      </div>
    </div>
  );
}

export function ACSPhone() {
  const [joinUrl, setJoinUrl] = useState("");
  const [showConnectButton, setShowConnectButton] = useState(false);

  const isPhoneNumberValid = (url) => {
    return true; // TODO
  };
  return (
    <div className={"flex items-center justify-center h-screen"}>
      <div>
        <div>
          <h1 className={"text-lg"}>Phone Connect Soundboard</h1>
        </div>

        <div className={"pt-2"}>
          <input
            type="text"
            placeholder="Enter a phone number to call"
            className="input input-sm input-bordered w-80"
            value={joinUrl}
            onChange={(e) => {
              setJoinUrl(e.target.value);
              if (isPhoneNumberValid(e.target.value)) {
                setShowConnectButton(true);
              }
            }}></input>
        </div>

        <div className="pt-2">
          {!showConnectButton && <div className="btn btn-primary btn-xs btn-disabled">Connect</div>}
          {showConnectButton && (
            <div
              className="btn btn-primary btn-xs"
              onClick={() => {
                window.location.href =
                  "/acs?url=" + encodeURIComponent(joinUrl) + "&name=" + encodeURIComponent("Test Name");
              }}>
              Connect
            </div>
          )}
        </div>

        <div className={"pt-4 text-xs w-80"}>Use this page to connect the Soundboard to a phone call</div>
      </div>
    </div>
  );
}

export function WelcomeToShout() {
  return (
    <div className={"flex items-center justify-center p-4"}>
      <div className={"flex flex-col"}>
        <div className={"pb-2"}>
          Welcome to <span className={"text-secondary"}>Shout</span>!
        </div>
        <div>
          <span className={"text-secondary"}>Shout</span> is a meeting soundboard. During the meeting, you can use{" "}
          <span className={"text-secondary"}>Shout</span> to play audio effects into the call.
        </div>
        <div className={"pt-6"}>
          <div>When the meeting starts, look for the app at the top of your meeting window in Teams:</div>
          <img src="/shouthelp.png" alt="Shout help"></img>
        </div>
        <div className={"pt-4"}>Have fun!</div>
      </div>
    </div>
  );
}
