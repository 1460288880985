import { Call, CaptionsInfo, Features } from "@azure/communication-calling";
import create from "zustand";

export type TeamsCaptionsStore = {
  results: Array<CaptionsInfo>;
  api: {
    startTeamsCaptionsFeature: (call: Call) => void;
  };
};

export const useTeamsCaptionsStore = create<TeamsCaptionsStore>((set, get) => ({
  results: [],
  api: {
    startTeamsCaptionsFeature: async (call) => {
      let teamsCaptionsFeature = call.feature(Features.TeamsCaptions);
      console.log("CAPTIONS FEAUTURE", teamsCaptionsFeature);
      teamsCaptionsFeature.on("isCaptionsActiveChanged", () => {
        console.log("isCaptionsActiveChanged");
      });

      

      teamsCaptionsFeature.on("captionsReceived", (data) => {
        console.log("CAPTIONS DATA RECEIVED", data);
        if (data.resultType === "Final") {
          set((state) => {
            return { results: [...state.results, data] };
          });
        }
      });

      await teamsCaptionsFeature.startCaptions();
    },
  },
}));
