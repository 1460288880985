const finalizeItem = function (queue, id) {
  let delItem = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const state = queue.getState();
  const index = state.itemQueue.indexOf(id);

  if (~index) {
    queue.updateState(state => {
      if (delItem) {
        delete state.items[id];
      }

      state.itemQueue.splice(index, 1);
      const activeIndex = state.activeIds.indexOf(id);

      if (~activeIndex) {
        state.activeIds.splice(activeIndex, 1);
      }
    });
  }
};

const isItemBelongsToBatch = (queue, itemId, batchId) => queue.getState().items[itemId].batchId === batchId;

export { finalizeItem, isItemBelongsToBatch };