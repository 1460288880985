import React from "react";
import { useVapiStore } from "./vapiStore";

export function VapiExperience() {
  const vapiStore = useVapiStore();
  const finalTranscriptItems = vapiStore.messages.filter(
    (m) => m.transcriptType === "final"
  );
  const reversedFinalTranscriptItems = [...finalTranscriptItems].reverse();
  return (
    <div>
      <div>
        <input
          type="text"
          placeholder="First Message"
          className="input input-bordered w-full max-w-xs"
          value={vapiStore.firstMessage || ""}
          onChange={(e) => {
            vapiStore.api.setFirstMessage(e.target.value);
          }}
        />

      </div>
      <div className={'text-xs'}>What the AI will say at the beginning of the conversation.</div>
      <div className={'w-full'}>
        <textarea 
          className="textarea textarea-bordered w-full"
          placeholder="System Prompt"
          value={vapiStore.systemMessage}
          onChange={(e) => {
            vapiStore.api.setSystemMessage(e.target.value);
          }}
        ></textarea>
      </div>
      <div>
        <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
          {reversedFinalTranscriptItems.map((m) => {
            return (
              <TimelineItem
                time={m.timestamp}
                title={m.role}
                text={m.transcript}
                end={m.role === "user"}
              />
            );
          })}
          
        </ul>
       
       {!vapiStore.isRunning &&   <button  className="btn btn-primary"
          onClick={() => {
            vapiStore.api.start();
          }}
        >
          Start
        </button>}

      
       {vapiStore.isRunning && <button   className="btn btn-primary"
          onClick={() => {
            vapiStore.api.stop();
          }}
        >
          Stop
        </button>}
      </div>
    </div>
  );
}

function TimelineItem(props: {
  time: string;
  title: string;
  text: string;
  end: boolean;
}) {
  let endClass = "timeline-start";
  if (props.end) {
    endClass = "timeline-end";
  }
  return (
    <li>
      <div className="timeline-middle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="h-5 w-5"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className={`${endClass} md:text-end mb-10`}>
        <time className="font-mono italic">{props.time}</time>
        <div className="text-lg font-black">{props.title}</div>
        {props.text}
      </div>
      <hr />
    </li>
  );
}
