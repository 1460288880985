import create from "zustand";

type HandsPagePlayer = {
    id: string;
    position: Array<number>;
}
type HandsPageState = {
    players: {};

    player1Position: Array<number>;
    player2Position: Array<number>;

    api: {
        addPlayer: (player: HandsPagePlayer) => void;
        setPlayerPosition: (playerId: string, position: Array<number>) => void;
    }
}
export const useHandsPageStore = create<HandsPageState>((set, get) => ({
    players: {},

    player1Position: [0, 0, 0],
    player2Position: [0, 0, 0],

    api: {
        addPlayer: (player: HandsPagePlayer) => set((state) => ({ players: state.players[player.id] = player })),
        setPlayerPosition: (playerId: string, position: Array<number>) => {
            set({ player1Position: position })
            set((state) => {
                let currentPlayers = { ...state.players };
                currentPlayers[playerId] = { position: position };
                return ({
                    players: currentPlayers
                })
            })

            // set((state) => ({

            //     players: [...state.players]
            // }))
        }
    }
}))