import Vapi from "@vapi-ai/web";
import { CreateAssistantDTO, CreateWebCallDTO, OpenAIModel } from "@vapi-ai/web/dist/api";
import create from "zustand";
import { SERVER_URL, VAPICALLBACK_ENDPOINT } from "../../Constants";
import { Client, Room } from "colyseus.js";
import { VapiState } from "../../../state/VapiState";

type Message = {
  type: string, role: string, transcriptType: string, transcript: string, timestamp: string 
}

export type VapiStore = {
  isRunning: boolean;
  systemMessage: string;
  messageInput: string;
  firstMessage: null | string;
  messages: Array<Message>;

  api: {
    start: () => void;
    stop: () => void;
    setSystemMessage: (newSystemMessage: string) => void;
    setMessageInput: (message: string) => void;
    sendMessage: () => void;
    setFirstMessage: (firstMessage: string) => void;
    vapi: Vapi;
    joinRoom: () => Promise<string>;
    addMessage: (message: Message) => void;
  };
};

const vapi = new Vapi("0ef0e043-d287-4263-9d1e-bc41806768c4");
const JESSE_VOICE_ID = "TzRCJkJTIMeahYO4qInh";
const TOM_VOICE_ID = "QyeUySDFWWm34mYadxAo";
let vapiCallbackUrl =
  process.env.NODE_ENV === "production"
    ? VAPICALLBACK_ENDPOINT
    : "https://tunnel.sparkworkspace.com/vapiCallback";

const CUSTOM_LLM_URL = `https://azureopenaiproxy.twhite.workers.dev/v1`

export const useVapiStore = create<VapiStore>((set, get) => ({
  isRunning: false,
  systemMessage: "You are a helpful assistant.",
  messageInput: "",
  firstMessage: null,
  messages: [],

  api: {

    start: async () => {

      
    const openAiModelProvider: OpenAIModel = {
      provider: "openai",
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: get().systemMessage,
        },
      ],
    }
    
      const roomId = await get().api.joinRoom();

      const options: CreateAssistantDTO = {
        transcriber: {
          provider: "deepgram",
          model: "nova-2",
          language: "en-US",
        },
        model: openAiModelProvider,
        voice: {
          provider: "11labs",
          voiceId: JESSE_VOICE_ID,
        },

        name: "My Inline Assistant",
        serverUrl: vapiCallbackUrl,
        serverUrlSecret: roomId,
  //      clientMessages: ['track-started']
      };
      let f = get().firstMessage;
      if (f) {
        options.firstMessage = f;
      }

      // Listen for the event.
window.addEventListener(
  "track-updated",
  (e) => {
    console.log("HERE IS THE TRACK", e)
    // e.target matches elem
  },
  false,
);

      vapi.prependListener('tom-was-here', (e) => {
        console.log("TRACK STARTED", e)
      })

      vapi.start(options).then((call) => {
        console.log("VAPI", call);
        console.log("LISTENRES", vapi.rawListeners);
        console.log("EVENTS", vapi.eventNames());
        set({ isRunning: true });
      });
      vapi.on("message", (message) => { 
        console.log("VAPI MESSAGE", message);
        get().api.addMessage(message)
      });
   
   
    },
    stop: () => {
      vapi.stop();
      set({ isRunning: false });
    },
    setSystemMessage: (newSystemMessage: string) => {
      set({ systemMessage: newSystemMessage });
    },
    setMessageInput: (message: string) => {
      set({ messageInput: message });
    },
    sendMessage: () => {
      vapi.send({
        type: "add-message",
        message: { content: get().messageInput, role: "system" },
      });
    },
    setFirstMessage: (firstMessage: string) => {
      set({ firstMessage: firstMessage });
    },

    vapi: vapi,
    joinRoom: async () => {
      let serverUrl =
        process.env.NODE_ENV === "production"
          ? SERVER_URL
          : "ws://localhost:2567";
      const client = new Client(serverUrl);
      const room = await client.create<VapiState>("vapi");

      return room.id;
    },
    addMessage: (message: Message) => {
      set((state) => {
        return ({
          messages: [...state.messages, message]
        })
      })
    }
  },
}));
