import { isProduction } from "@rpldy/shared";
import { UPLOAD_OPTIONS_COMP } from "./consts";

const logWarning = (condition, msg) => {
  if (!isProduction() && !condition) {
    // eslint-disable-next-line no-console
    console.warn(msg);
  }
};

const markAsUploadOptionsComponent = Component => {
  Component[UPLOAD_OPTIONS_COMP] = true;
};

const getIsUploadOptionsComponent = Component => {
  var _Component$target, _Component$render;

  return Component[UPLOAD_OPTIONS_COMP] === true || ((_Component$target = Component.target) === null || _Component$target === void 0 ? void 0 : _Component$target[UPLOAD_OPTIONS_COMP]) === true || ((_Component$render = Component.render) === null || _Component$render === void 0 ? void 0 : _Component$render[UPLOAD_OPTIONS_COMP]) === true;
};

export { logWarning, markAsUploadOptionsComponent, getIsUploadOptionsComponent };