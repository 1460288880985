import React from "react";
import { useSpeechStore } from "./useSpeechStore";

export function Speech() {
  const speechApi = useSpeechStore((state) => state.api);

  return (
    <div>
      <div
        className="btn btn-xs btn-primary"
        onClick={() => {
          speechApi.downloadModel();
        }}>
        Dowload Model
      </div>
    </div>
  );
}
