import React from "react";
import * as Popover from "@radix-ui/react-popover";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

export function PlayerPopover() {
  return (
    <div>
      <Popover.Root>
        <Popover.Trigger>trigger</Popover.Trigger>
        <Popover.Anchor />
        <Popover.Content side={"top"}>
          <div>This is my content</div>
          <Popover.Close>Yes</Popover.Close>
          <Popover.Arrow>arrow</Popover.Arrow>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}

import { styled, keyframes } from "@stitches/react";
import { violet, mauve, blackA } from "@radix-ui/colors";
import { HamburgerMenuIcon, DotFilledIcon, CheckIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Client, Room } from "colyseus.js";
import { useGlobalStore2 } from "../../hooks/useGlobalState";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: 220,
  backgroundColor: "",
  borderRadius: 6,
  padding: 5,
  boxShadow: "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const itemStyles = {
  all: "unset",
  fontSize: 13,
  lineHeight: 1,
  // color: violet.violet11,
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  height: 25,
  padding: "0 5px",
  position: "relative",
  paddingLeft: 25,
  userSelect: "none",

  "&[data-disabled]": {
    color: mauve.mauve8,
    pointerEvents: "none",
  },

  "&:focus": {
    backgroundColor: "",
    color: violet.violet1,
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, { ...itemStyles });
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles });
const StyledTriggerItem = styled(DropdownMenuPrimitive.TriggerItem, {
  '&[data-state="open"]': {
    backgroundColor: violet.violet4,
    color: violet.violet11,
  },
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: "25px",
  color: mauve.mauve11,
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,

  // backgroundColor: violet.violet6,
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: "white",
});

// Exports
export const DropdownMenu = styled(DropdownMenuPrimitive.Root, { backgroundColor: "--pc" });
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuTriggerItem = StyledTriggerItem;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuArrow = StyledArrow;

// Your app...
const Box = styled("div", {});

const RightSlot = styled("div", {
  marginLeft: "auto",
  paddingLeft: 20,
  color: mauve.mauve11,
  ":focus > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 },
});

function TomButton(props) {
  return <button className={"bg-secondary rounded-full"}>{props.children}</button>;
}

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  // color: violet.violet11,
  // backgroundColor: "green",
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": { boxShadow: `0 0 0 2px black` },
});

//
export const LeaderMenu = ({ room, client }: { room: Room; client: Client }) => {
  const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
  const [urlsChecked, setUrlsChecked] = React.useState(false);
  const [person, setPerson] = React.useState("pedro");
  let history = useHistory();
  const isPlayerLeader = useGlobalStore2((state) => state.isPlayerLeader);
  const debugMenuIn3DExperiencesOn = useGlobalStore2((state) => state.debugMenuIn3DExperiencesOn);
  const setDebugMenuIn3DExperiencesOn = useGlobalStore2((state) => state.setDebugMenuIn3DExperiencesOn);

  const enableVarChanger = useGlobalStore2((state) => state.enableVarChanger);
  const setEnableVarChanger = useGlobalStore2((state) => state.setEnableVarChanger);

  const teamsCtx = useGlobalStore2((state) => state.teamsContext);

  function onSelectExperience(expName) {
    client
      .create(expName, { teamsContext: teamsCtx, fromSelectExperience: true })
      .then((newRoom) => {
        room.send("selectExperience", { experienceName: expName });
        setTimeout(() => {
          newRoom.leave();
        }, 3000);
      })
      .catch((err) => {
        room.send("selectExperience", { experienceName: expName });
      });
  }
  // if (!isPlayerLeader) return null;
  return (
    <Box className={"cursor-pointer"}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {/* <TomButton>
            <HamburgerMenuIcon />
          </TomButton> */}
          {/* <IconButton aria-label="Customise options" style={{ display: "flex" }} className={"bg-secondary"}>
            <HamburgerMenuIcon />
          </IconButton> */}
          {/* <button className={"bg-secondary"}>click</button> */}
          <button className={"bg-secondary rounded-full p-1 text-secondary-content"}>
            {" "}
            <HamburgerMenuIcon />
          </button>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5} className="bg-secondary text-secondary-content">
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              room.send("selectExperience", { experienceName: "" });
            }}>
            Send all players to Home screen
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              room.send("selectExperience", { experienceName: "Debug" });
            }}>
            Send all players to Debug screen
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              room.send("selectExperience", { experienceName: "player" });
            }}>
            Character Creator
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              history.push("/debug");
            }}>
            Go to Debug screen
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              history.push("/accordion");
            }}>
            Accordion
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("meetingrpg")}>
            Meeting RPG
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("acs")}>
            ACS Page
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("acsview")}>
            ACS View Page
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("drawing")}>
            Drawing
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => history.push("/liveshare")}>
            LiveShare
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("playground")}>
            Character Playground
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("breakroom")}>
            Break Room
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("snap21")}>
            Snap 21
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("video")}>
            Video
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("choose")}>
            Choose Experience
          </DropdownMenuItem>

          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("blockgame")}>
            Block Game
          </DropdownMenuItem>

          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("flappy")}>
            Flappy Meeting
          </DropdownMenuItem>

          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("cursor")}>
            Cursor Tracking
          </DropdownMenuItem>

          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("camerafun")}>
            Camera Test
          </DropdownMenuItem>

          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("sidepanel")}>
            Side Panel
          </DropdownMenuItem>

          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              room.send("selectExperience", { experienceName: "pingpong" });
            }}>
            Ping Pong
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("question")}>
            Question
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => onSelectExperience("missioncontrol")}>
            Mission Control
          </DropdownMenuItem>
          <DropdownMenuCheckboxItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            checked={debugMenuIn3DExperiencesOn}
            onCheckedChange={setDebugMenuIn3DExperiencesOn}>
            <DropdownMenuItemIndicator>
              <CheckIcon />
            </DropdownMenuItemIndicator>
            Debug Menu in 3d Experiences
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            checked={enableVarChanger}
            onCheckedChange={setEnableVarChanger}>
            <DropdownMenuItemIndicator>
              <CheckIcon />
            </DropdownMenuItemIndicator>
            Enable Var Changer
          </DropdownMenuCheckboxItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              room.send("selectExperience", { experienceName: "results" });
            }}>
            Log
          </DropdownMenuItem>
          <DropdownMenuItem
            className={"focus:bg-secondary-focus focus:text-secondary-focus"}
            onSelect={() => {
              room.send("selectExperience", { experienceName: "license" });
            }}>
            Licenses
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
};
