const isEmpty = val => val === null || val === undefined;

const triggerUpdater = function (trigger, event) {
  const doTrigger = function (event) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    return new Promise((resolve, reject) => {
      const results = trigger(event, ...args);

      if (results && results.length) {
        Promise.all(results).catch(reject).then(resolvedResults => {
          let result;

          if (resolvedResults) {
            while (isEmpty(result) && resolvedResults.length) {
              result = resolvedResults.pop();
            }
          }

          resolve(isEmpty(result) ? undefined : result);
        });
      } else {
        resolve();
      }
    });
  };

  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }

  return event ? doTrigger(event, ...args) : doTrigger;
};

export default triggerUpdater;