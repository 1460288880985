import { useState, useCallback, useEffect } from "react";
import { isFunction } from "@rpldy/shared";
import useUploadyContext from "./useUploadyContext";

const useEventEffect = (event, fn) => {
  const context = useUploadyContext();
  const {
    on,
    off
  } = context;
  useEffect(() => {
    on(event, fn);
    return () => {
      off(event, fn);
    };
  }, [event, fn, on, off]);
};

const generateUploaderEventHookWithState = (event, stateCalculator) => (fn, id) => {
  const [eventState, setEventState] = useState(null);

  if (fn && !isFunction(fn)) {
    id = fn;
    fn = undefined;
  }

  const eventCallback = useCallback(function (eventObj) {
    if (!id || eventObj.id === id) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      setEventState(stateCalculator(eventObj, ...args));

      if (isFunction(fn)) {
        fn(eventObj, ...args);
      }
    }
  }, [fn, id]);
  useEventEffect(event, eventCallback);
  return eventState;
};

const generateUploaderEventHook = function (event) {
  let canScope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return (fn, id) => {
    const eventCallback = useCallback(function (eventObj) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return fn && (!canScope || !id || eventObj.id === id) ? fn(eventObj, ...args) : undefined;
    }, [fn, id]);
    useEventEffect(event, eventCallback);
  };
};

export { generateUploaderEventHook, generateUploaderEventHookWithState };