import { isFunction } from "@rpldy/shared";
import { PREVIEW_TYPES } from "./consts";
import { PREVIEW_DEFAULTS } from "./defaults";

const getWithMandatoryOptions = options => {
  return { ...PREVIEW_DEFAULTS,
    ...options
  };
};

const getFallbackUrlData = (fallbackProp, file) => {
  let data = isFunction(fallbackProp) ? fallbackProp(file) : fallbackProp;

  if (typeof data === "string") {
    data = {
      id: "",
      url: data,
      name: file.name,
      type: PREVIEW_TYPES.IMAGE
    };
  }

  return data;
};

const getFileObjectUrlByType = (type, mimeTypes, max, file) => {
  let data;

  if (mimeTypes && ~mimeTypes.indexOf(file.type)) {
    if (!max || file.size <= max) {
      data = {
        url: URL.createObjectURL(file),
        name: file.name,
        type
      };
    }
  }

  return data;
};

export { isFunction, getWithMandatoryOptions, getFallbackUrlData, getFileObjectUrlByType };