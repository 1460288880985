import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { MsalProvider, useMsal } from "@azure/msal-react";
import {
  Configuration,
  PublicClientApplication,
  LogLevel,
} from "@azure/msal-browser";
import { MSAL_ENDPOINT } from "../../Constants";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { app, authentication } from "@microsoft/teams-js";
import jwt_decode from "jwt-decode";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: "https://login.microsoftonline.com/common",
    knownAuthorities: [],
    redirectUri: "https://localhost:1234",
    postLogoutRedirectUri: "https://localhost:1234/redirect",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

// MSAL configuration
// const configuration: Configuration = {
//     auth: {
//         clientId: process.env.REACT_APP_CLIENT_ID as string
//     }
// };
// debugger;
const pca = new PublicClientApplication(msalConfig);

// pca.loginPopup({
//     redirectUri: "http://localhost:1234",
//     scopes: []
// })
export function App() {
  const { instance, accounts, inProgress } = useMsal();
const teamsCtx = useGlobalStore2(state => state.teamsContext);
const [success, setSuccess] = useState(false)
  let redirectUri =
    process.env.NODE_ENV === "production"
      ? MSAL_ENDPOINT
      : "http://localhost:1234/redirect.html";

      useEffect(() => {
        if (teamsCtx && accounts.length === 0){
            

            var authTokenRequest = {
                successCallback: async function (result) {
                  const decodedToken = jwt_decode(result);
      
                  // const onBehalfOfToken = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/token?token=${result}`, { method: 'GET' });
                  // const j = await onBehalfOfToken.text();
                  // console.log("ON BEHALF OF TOKEN", j);
                  // const decodedONBehalfOfToken = jwt_decode(j);
                  // console.log(decodedONBehalfOfToken)
      
                  // if (context && context.loginHint) {
                  //   console.log("LOGIN HINT", context.loginHint);
                  //   //     accessTokenRequest.loginHint = this.teamsContext.loginHint;
                  // }
      
                  // const currentParent = window.parent;
                  // if (document.referrer.startsWith('https://teams.microsoft.com/')) {
                  //   (window as any).parent = window;
                  // }
                  try {
                    const decodedToken = jwt_decode(result) as any;
                    console.log("DECODED TOKEN", decodedToken)
                    const tokens = await instance.ssoSilent({
                      authority: `https://login.microsoftonline.com/${decodedToken.tid}`,
      
                      // scopes: [`754ad215-b82e-40e3-aa11-ac6b7d84b8d0`],
                      scopes: [decodedToken.aud + "/" + decodedToken.scp],
                      loginHint: teamsCtx.user?.loginHint,
                       redirectUri: redirectUri,
                      sid: decodedToken.sid
                    });
      
                    console.log(`SILENT TOKEN ACQUIRE 4`, tokens);
                    setSuccess(true);
                  } catch (err) {
                    console.log("ERROR", err);
                    //   await authProvider.loginPopup({ scopes: [decodedToken.aud + "/" + decodedToken.scp] })
                  }
      
                  // const tokens2 = await authProvider.acquireTokenSilent({ scopes: ["https://graph.microsoft.com/.default"], sid: decodedToken.sid })
                  // console.log("TOKENS2", tokens2);
                },
                failureCallback: function (error) {
                  console.log("Failure: " + error);
                  // auth.signinPopup();
                },
                //  url: url.href
                resources: [`api://collabtris.sparkworkspace.com/bf36a424-a5d3-4b40-ae8b-4d1ff61c5f12`],
              };
      
              authentication.getAuthToken(authTokenRequest);

        }
      }, [teamsCtx, accounts])
  if (accounts.length > 0) {
    return (
      <div>
        {success ? <div>Successfully logged in</div>: <div>not logged in</div>}
        There are currently {accounts.length} users signed in!
        <div
          className={"btn btn-xs btn-primary"}
          onClick={() => {
            instance.logoutPopup();
          }}
        >
          Logout
        </div>
        {JSON.stringify(accounts)}
      </div>
    );
  } else if (inProgress === "login") {
    return <span>Login is currently in progress!</span>;
  } else {
    return (
      <>
        <div>There are currently no users signed in!</div>
        <div
        className={"btn btn-xs btn-primary"}
          onClick={() =>
            instance.loginPopup({
              redirectUri: redirectUri,
              scopes: ["https://graph.microsoft.com/.default"],
            })
          }
        >
          Login
        </div>
      </>
    );
  }
}

// Component
export const MSALPage = () => (
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);
