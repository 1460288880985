import { State } from "pixi.js";
import React, { useEffect, useState } from "react";
import { ACSState } from "../../../state/ACSState";
import { useColyseusRoom, useColyseusRoom2 } from "../../hooks/useColyseusRoom";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { useMediapipeStore } from "./useMediapipeStore";
import { Canvas, useFrame, useLoader, useThree, extend } from "@react-three/fiber";
import { Physics, useBox } from "@react-three/cannon";
import { useHandsPageStore } from "./useHandsPageStore";

function MediapipePageInner({ joinUrl }) {
  const api = useMediapipeStore((state) => state.api);
  const callConnected = useMediapipeStore((state) => state.callIsConnected);
  const callIsConnecting = useMediapipeStore((state) => state.callIsConnecting);

  useEffect(() => {
    api.joinOrCreateRoom(joinUrl).then(() => {
      api.setupACSCall();
    });
  }, []);
  return (
    <div>
      {callIsConnecting && <div>connecting...</div>}
      {callConnected && <div>Connected to ACS Call!</div>}
      <div className={"remoteVideos"}></div>
      <div className={"handTrackingVideos"}></div>
    </div>
  );
}

export function MediapipePage() {
  const [joinUrl, setJoinUrl] = useState("");
  const [showConnectButton, setShowConnectButton] = useState(false);
  const [connected, setIsConnected] = useState(false);

  const isJoinUrlValid = (url) => {
    if (url.indexOf("https://teams.microsoft.com/l/meetup-join/") !== -1) {
      return true;
    } else return false;
    return true;
  };

  const mediapipeStore = useMediapipeStore();

  if (connected) return <MediapipePageInner joinUrl={joinUrl} />;
  else
    return (
      <div className={"flex items-center justify-center h-screen"}>
        <div>
          <div>
            <h1 className={"text-lg"}>Video Tracking</h1>
          </div>

          <div className={"pt-2"}>
            <input
              type="text"
              placeholder="Enter the Join Url for a Teams meeting"
              className="input input-sm input-bordered w-80"
              value={joinUrl}
              onChange={(e) => {
                setJoinUrl(e.target.value);
                if (isJoinUrlValid(e.target.value)) {
                  setShowConnectButton(true);
                }
              }}></input>
          </div>

          <div className="pt-2">
            {!showConnectButton && <div className="btn btn-primary btn-xs btn-disabled">Connect</div>}
            {showConnectButton && (
              <div
                className="btn btn-primary btn-xs"
                onClick={() => {
                  setIsConnected(true);
                }}>
                Connect
              </div>
            )}
          </div>

          <div className={"pt-4 text-xs w-80"}>Here we will have some fun with head and hand tracking</div>
        </div>
      </div>
    );
}

function Cube(props) {
  const [ref] = useBox(() => ({ type: "Static", mass: 1, ...props }));
  return (
    <mesh ref={ref}>
      <boxGeometry />
      <meshStandardMaterial color="red" />
    </mesh>
  );
}

export function HandsPage() {
  const teamsContext = useGlobalStore2((state) => state.teamsContext);
  const [position, setPosition] = useState([]);

  const { client, room } = useColyseusRoom2<ACSState>("acs", teamsContext);
  const handsPageApi = useHandsPageStore((state) => state.api);
  const players = useHandsPageStore((state) => state.players);

  useEffect(() => {
    if (!room) return;
    console.log("USE EFFECT", JSON.stringify(room.state));
    room.state.listen("players", (newPlayers, prevPlayers) => {
      console.log("PLAYERS", newPlayers.length, prevPlayers.length);
    });

    room.state.players.onAdd = (player, k) => {
      console.log("NEW PLAYER IS", player);
      if (player.aadId) {
        handsPageApi.addPlayer({ id: player.aadId || player.id, position: [0, 0, 0] });
      }
      player.onChange = (changes) => {
        console.log("PLAYER", player);
        console.log("CHANGES", changes);
        // console.log("PLAYER CHANGES", changes);
        // console.log("broadcast netowrk update")
        // console.log(changes)
        let positionChange;
        let rotationChange;

        for (const change of changes) {
          if (change.field === "handPositionTriple") {
            // console.log(change.field, change.value)
            // console.log(change.value[0], change.value[1], change.value[2])
            positionChange = [change.value[0], change.value[1], change.value[2]];
            if (player.aadId) {
              handsPageApi.setPlayerPosition(player.aadId, positionChange);
            }
          }
        }
      };
    };
  }, [room]);
  if (!room) return null;

  return <div>{JSON.stringify(players)}</div>;
}
