import { CommunicationIdentifierKind } from "@azure/communication-common";
import create from "zustand";

type DominantSpeakerReference = { kind: CommunicationIdentifierKind; rawId: string };

type DominantSpeakerStore = {
  dominantSpeakersList: Array<{ speakers: Array<DominantSpeakerReference>; timestamp: any }>;
  secondsSinceLastChange: number;

  api: {
    addDominantSpeakerReference: (speakers: any, timestamp: any) => void;
    updateSecondsSinceLastChange: (reset?: boolean) => void;
  };
};
export const useDominantSpeakerStore = create<DominantSpeakerStore>((set) => ({
  dominantSpeakersList: [],
  api: {
    addDominantSpeakerReference: (speakers, timestamp) =>
      set((state) => {
        let lastReference = state.dominantSpeakersList[state.dominantSpeakersList.length - 1];
        if (lastReference) {
          console.log("LAST REFERENCE", lastReference);
        }

        return {
          dominantSpeakersList: state.dominantSpeakersList.concat({ speakers: speakers, timestamp: timestamp }),
          secondsSinceLastChange: 0, // state.dominantSpeakersList[state.dominantSpeakersList.length - 1].timestamp - timestamp,
        };
      }),
    updateSecondsSinceLastChange: (reset?: boolean) =>
      set((state) => {
        if (reset) {
          return {
            secondsSinceLastChange: 0,
          };
        }
        let rightNow = new Date();

        let lastReference = state.dominantSpeakersList[state.dominantSpeakersList.length - 1];
        if (lastReference) {
          console.log("LAST REFERENCE", lastReference);
          let stampDate = new Date(lastReference.timestamp);

          // console.log("NOW", rightNow.getTime());
          // console.log("STAMp", stampDate);

          let timeDelta = rightNow.getTime() - stampDate.getTime();
          console.log("TIME DELAT", timeDelta);
          return {
            secondsSinceLastChange: timeDelta / 1000,
          };
        } else {
          return {
            secondsSinceLastChange: 0,
          };
        }
      }),
  },

  secondsSinceLastChange: 0,
}));
