import { DEBUG_LOG_KEY } from "./consts";
import hasWindow from "./utils/hasWindow";
let isDebug;

const isDebugOn = () => {
  if (typeof isDebug !== "boolean") {
    isDebug = hasWindow() && ("location" in window && !!~window.location.search.indexOf("rpldy_debug=true") || window[DEBUG_LOG_KEY] === true);
  }

  return !!isDebug;
};

const setDebug = debugOn => {
  if (hasWindow()) {
    window[DEBUG_LOG_KEY] = debugOn;
  }

  isDebug = debugOn ? true : null;
};

const debugLog = function () {
  if (isDebugOn()) {
    // eslint-disable-next-line no-console
    console.log(...arguments);
  }
};

export { isDebugOn, setDebug, debugLog };