import React, { useEffect, useRef } from "react";

import World from "./World";
import "./earth.scss";

export function Planet() {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    new World({
      dom: ref.current,
    });
  }, []);
  return (
    <div className={"earth-wrapper"}>
      <div id="loading">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
      <div id="html2canvas" className="css3d-wapper">
        <div className="fire-div"></div>
      </div>
      <div ref={ref} id="earth-canvas"></div>
    </div>
  );
}
