let experiences = [
    {
        experienceName: "planet",
        friendlyName: "The Planet",
        experienceDescription: "this is experimental for now",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "fallingdown",
        friendlyName: "Experimental",
        experienceDescription: "this is experimental for now",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "dragon",
        friendlyName: "Sneezing Dragon",
        experienceDescription: "a pet dragon",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "cube",
        friendlyName: "Meeting Cube",
        experienceDescription: "a Rubik's cube",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "drums",
        friendlyName: "Drums",
        experienceDescription: "play some drums",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "xylophone",
        friendlyName: "Xylophone",
        experienceDescription: "play an interactive xylophone",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "matrix",
        friendlyName: "The Matrix",
        experienceDescription: "Enter the Matrix",
        categories: ["Fun", "Two Player"],
        createRoomBefore: false,

    },
    {
        experienceName: "gameroom",
        friendlyName: "Meeting Attack",
        experienceDescription: "A multiplayer game.",
        categories: ["Fun", "Two Player"],
        createRoomBefore: true,
        defaultOptions: {
            playerName: "DEFAULT",
            roomName: "test",
            roomMap: "small",
            roomMaxPlayers: 2,
            mode: "deathmatch",
        }
    },
    {
        experienceName: "soundboard",
        friendlyName: "Soundboard",
        experienceDescription: "A fun reactionary soundboard.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "snap21",
        friendlyName: "Snap 21",
        experienceDescription: "Tom's classic Windows Phone 7 game recreated with Javascript.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "meetingrpg",
        friendlyName: "Tomniverse",
        experienceDescription: "Interactive Tomniverse meeting adventure!.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "missioncontrol",
        friendlyName: "Mission Control",
        experienceDescription: "Collaborate with coworkers to launch the rocket.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "flappy",
        friendlyName: "Flappy Meeting",
        experienceDescription: "Compete with coworkers in a round of the classic game with a twist.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "question",
        friendlyName: "Getting to Know You",
        experienceDescription: "Get to know the other participants with some icebreaker questions.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "breakroom",
        friendlyName: "Take a Break",
        experienceDescription:
            "Schedule a configurable break for your meeting with a recreation of the old school Windows 98 pipe screen saver.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "pingpong",
        friendlyName: "Ping Pong",
        experienceDescription: "Hit a ping pong ball around for some reason.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "blockgame",
        friendlyName: "Block Game",
        experienceDescription: "See who can make the tallest stack of blocks for some reason.",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "drawing",
        friendlyName: "Drawing Room",
        experienceDescription: "Draw a picture together in real time..",
        categories: ["Fun", "Two Player"],
    },
    {
        experienceName: "packabunchas",
        friendlyName: "Packabunchas",
        experienceDescription: "Play one of js13k's 2021 finalist browser games together.",
        categories: ["Fun", "One Player"],
    },
    {
        experienceName: "player",
        friendlyName: "Character Create",
        experienceDescription: "Use Ready Player Me to create a 3D avatar of yourself.",
        categories: ["Fun", "One Player"],
    },
];

export function experienceNameToFriendlyName(experienceName) {
    let experience = experiences.find((exp) => exp.experienceName === experienceName);
    return experience ? experience.friendlyName : experienceName;
}

export function getAllExperiences() {
    return experiences;
}

export function getExperiencesForCategory(categoryName) {
    let filteredExperiences = experiences.filter((exp) => {
        let foundCategory = exp.categories.find((c) => c === categoryName);
        if (foundCategory) {
            return true;
        }
    });
    return filteredExperiences;
}

export function getExperienceByName(expName) {
    return experiences.find((ex) => ex.experienceName === expName)
}