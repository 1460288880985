import * as React from 'react';
import { IconProps } from './types';

export const ShadowOuterIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          opacity=".05"
          d="M12.1398 3.88616C13.8553 4.94159 15 6.837 15 8.99999C15 12.3137 12.3137 15 9.00001 15C6.8435 15 4.95295 13.8621 3.89569 12.1552L4.32075 11.8919C5.29069 13.4578 7.02375 14.5 9.00001 14.5C12.0375 14.5 14.5 12.0375 14.5 8.99999C14.5 7.0178 13.4516 5.28026 11.8778 4.31202L12.1398 3.88616Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          opacity=".2"
          d="M12.851 5.0732C13.8684 6.07105 14.5 7.46198 14.5 8.99999C14.5 12.0375 12.0376 14.5 9.00002 14.5C7.46214 14.5 6.07131 13.8685 5.07348 12.8512L5.43042 12.5011C6.33809 13.4264 7.60185 14 9.00002 14C11.7614 14 14 11.7614 14 8.99999C14 7.6017 13.4264 6.33785 12.5009 5.43017L12.851 5.0732Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          opacity=".35"
          d="M13.3021 6.45071C13.7455 7.19737 14 8.06934 14 9C14 11.7614 11.7614 14 9.00001 14C8.04867 14 7.15867 13.7341 6.40118 13.2723L6.66141 12.8454C7.34274 13.2607 8.14305 13.5 9.00001 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 8.16164 13.271 7.37753 12.8722 6.70598L13.3021 6.45071Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          opacity=".5"
          d="M13.3744 7.94021C13.4566 8.2803 13.5 8.63524 13.5 9C13.5 11.4853 11.4853 13.5 9.00002 13.5C8.61103 13.5 8.23321 13.4506 7.87267 13.3576L7.99758 12.8734C8.31767 12.956 8.65352 13 9.00002 13C11.2091 13 13 11.2091 13 9C13 8.67507 12.9613 8.35952 12.8884 8.05756L13.3744 7.94021Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          opacity=".65"
          d="M12.9155 9.82132C12.5898 11.3813 11.3562 12.6072 9.79203 12.9215L9.69353 12.4313C11.0613 12.1565 12.1413 11.0833 12.4261 9.71913L12.9155 9.82132Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M1.2771 7.50252C1.2771 4.06455 4.06413 1.27753 7.50209 1.27753C10.94 1.27753 13.7271 4.06455 13.7271 7.50252C13.7271 10.9405 10.94 13.7275 7.50209 13.7275C4.06412 13.7275 1.2771 10.9405 1.2771 7.50252ZM7.50209 2.22752C4.5888 2.22752 2.2271 4.58922 2.2271 7.50252C2.2271 10.4158 4.5888 12.7775 7.50209 12.7775C10.4154 12.7775 12.7771 10.4158 12.7771 7.50252C12.7771 4.58922 10.4154 2.22752 7.50209 2.22752Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);

export default ShadowOuterIcon;
