import React, { useMemo } from "react";
import { useGraphBuilderStore } from "./useGraphBuilderStore";
import Graph from "react-graph-vis";
import { v4 as uuidv4 } from "uuid";

const options = {
  layout: {
    hierarchical: false,
  },
  edges: {
    color: "#34495e",
  },
};

export function GraphBuilder() {
  const userPrompt = useGraphBuilderStore((state) => state.userPrompt);
  const api = useGraphBuilderStore((state) => state.api);
  const graphState = useGraphBuilderStore((state) => state.graphState);
  const version = useMemo(uuidv4, [graphState]);

  return (
    <div className={"p-4"}>
      <div>Graph Builder</div>
      <div>
        <input
          type="text"
          placeholder="enter prompt"
          className="input input-sm input-bordered input-secondary w-full max-w-xl"
          onChange={(e) => {
            api.setUserPrompt(e.target.value);
          }}
        />
      </div>
      <div
        className={"btn btn-primary"}
        onClick={() => {
          api.generateNewGraphWithGPT3();
        }}>
        Generate
      </div>
      {userPrompt}
      <div>
        <div className="graphContainer">
          <Graph key={version} graph={graphState.graph} options={options} style={{ height: "640px" }} />
        </div>
      </div>
    </div>
  );
}
