import { FILE_STATES, logger } from "@rpldy/shared";
import { ITEM_FINALIZE_STATES, UPLOADER_EVENTS } from "../consts";
import processFinishedRequest from "./processFinishedRequest";
import { getItemsPrepareUpdater } from "./preSendPrepare";
const preparePreRequestItems = getItemsPrepareUpdater(UPLOADER_EVENTS.REQUEST_PRE_SEND, items => items, (items, options) => ({
  items,
  options
}));

const updateUploadingState = (queue, items, sendResult) => {
  queue.updateState(state => {
    items.forEach(bi => {
      const item = state.items[bi.id];
      item.state = FILE_STATES.UPLOADING;
      state.aborts[bi.id] = sendResult.abort;
    });
  });
};

const sendAllowedItems = (queue, itemsSendData, next) => {
  const {
    items,
    options
  } = itemsSendData;
  const batch = queue.getState().batches[items[0].batchId].batch;
  let sendResult;

  try {
    sendResult = queue.sender.send(items, batch, options);
  } catch (ex) {
    logger.debugLog(`uploader.queue: sender failed with unexpected error`, ex); //provide error result so file(s) are marked as failed

    sendResult = {
      request: Promise.resolve({
        status: 0,
        state: FILE_STATES.ERROR,
        response: ex.message
      }),
      abort: () => false,
      senderType: "exception-handler"
    };
  }

  const {
    request
  } = sendResult;
  updateUploadingState(queue, items, sendResult);
  return request //wait for server request to return
  .then(requestInfo => {
    const finishedData = items.map(item => ({
      id: item.id,
      info: requestInfo
    }));
    processFinishedRequest(queue, finishedData, next);
  });
};

const reportCancelledItems = (queue, items, cancelledResults, next) => {
  const cancelledItemsIds = cancelledResults.map((isCancelled, index) => isCancelled ? items[index].id : null).filter(Boolean);

  if (cancelledItemsIds.length) {
    const finishedData = cancelledItemsIds.map(id => ({
      id,
      info: {
        status: 0,
        state: FILE_STATES.CANCELLED,
        response: "cancel"
      }
    }));
    processFinishedRequest(queue, finishedData, next); //report about cancelled items
  }

  return !!cancelledItemsIds.length;
};

const reportPreparedError = (error, queue, items, next) => {
  const finishedData = items.map(_ref => {
    let {
      id
    } = _ref;
    return {
      id,
      info: {
        status: 0,
        state: FILE_STATES.ERROR,
        response: error
      }
    };
  });
  processFinishedRequest(queue, finishedData, next); //report about failed items
}; //make sure item is still pending. Something might have changed while waiting for ITEM_START handling. Maybe someone called abort...


const getAllowedItem = (id, queue) => {
  const item = queue.getState().items[id];
  return item && !ITEM_FINALIZE_STATES.includes(item.state) ? item : undefined;
};

const processAllowedItems = _ref2 => {
  let {
    allowedItems,
    cancelledResults,
    queue,
    items,
    ids,
    next
  } = _ref2;
  const afterPreparePromise = allowedItems.length ? preparePreRequestItems(queue, allowedItems) : Promise.resolve();
  return afterPreparePromise.catch(err => {
    logger.debugLog("uploader.queue: encountered error while preparing items for request", err);
    reportPreparedError(err, queue, items, next);
  }).then(itemsSendData => {
    let nextP;

    if (itemsSendData) {
      if (itemsSendData.cancelled) {
        cancelledResults = ids.map(() => true);
      } else {
        //we dont need to wait for the response here
        sendAllowedItems(queue, {
          items: itemsSendData.items,
          options: itemsSendData.options
        }, next);
      }
    } //if no cancelled we can go to process more items immediately (and not wait for upload responses)


    if (!reportCancelledItems(queue, items, cancelledResults, next)) {
      nextP = next(queue); //when concurrent is allowed, we can go ahead and process more
    } //returning promise for testing purposes


    return nextP;
  });
}; //send group of items to be uploaded


const processBatchItems = (queue, ids, next) => {
  const state = queue.getState(); //ids will have more than one when grouping is allowed

  let items = Object.values(state.items);
  items = items.filter(item => !!~ids.indexOf(item.id)); //allow user code cancel items from start event handler(s)
  //returning promise for testing purposes

  return Promise.all(items.map(i => queue.runCancellable(UPLOADER_EVENTS.ITEM_START, i))).then(cancelledResults => {
    let allowedItems = cancelledResults.map((isCancelled, index) => isCancelled ? null : getAllowedItem(items[index].id, queue)).filter(Boolean);
    return {
      allowedItems,
      cancelledResults,
      queue,
      items,
      ids,
      next
    };
  }).then(processAllowedItems);
};

export default processBatchItems;