import { isFunction, logger, throttle } from "@rpldy/shared";
import addLife from "@rpldy/life-events";
import defaultSend from "@rpldy/sender";
import { PROGRESS_DELAY, SENDER_EVENTS } from "./consts";
import { DEFAULT_OPTIONS, DEFAULT_PARAM_NAME } from "./defaults";

const reportItemsProgress = (items, completed, loaded, trigger) => {
  items.forEach(item => {
    logger.debugLog(`uploady.uploader.processor: file: ${item.id} progress event: loaded(${loaded}) - completed(${completed})`);
    trigger(SENDER_EVENTS.ITEM_PROGRESS, item, completed, loaded);
  });
};

const onItemUploadProgress = (items, batch, e, trigger) => {
  const completed = Math.min(e.loaded / e.total * 100, 100),
        completedPerItem = completed / items.length,
        loadedAverage = e.loaded / items.length;
  reportItemsProgress(items, completedPerItem, loadedAverage, trigger);
  trigger(SENDER_EVENTS.BATCH_PROGRESS, batch);
};

const createBatchItemsSender = () => {
  const {
    trigger,
    target: sender
  } = addLife({
    send: (items, batch, batchOptions) => {
      const destination = batchOptions.destination,
            url = destination === null || destination === void 0 ? void 0 : destination.url;
      const throttledProgress = throttle(e => onItemUploadProgress(items, batch, e, trigger), PROGRESS_DELAY, true);
      const send = isFunction(batchOptions.send) ? batchOptions.send : defaultSend;
      return send(items, url, {
        method: (destination === null || destination === void 0 ? void 0 : destination.method) || batchOptions.method || DEFAULT_OPTIONS.method,
        paramName: (destination === null || destination === void 0 ? void 0 : destination.filesParamName) || batchOptions.inputFieldName || DEFAULT_PARAM_NAME,
        params: { //TODO: might need to rethink the order here:
          ...batchOptions.params,
          ...(destination === null || destination === void 0 ? void 0 : destination.params)
        },
        forceJsonResponse: batchOptions.forceJsonResponse,
        withCredentials: batchOptions.withCredentials,
        formatGroupParamName: batchOptions.formatGroupParamName,
        headers: destination === null || destination === void 0 ? void 0 : destination.headers,
        sendWithFormData: batchOptions.sendWithFormData,
        formatServerResponse: batchOptions.formatServerResponse,
        formDataAllowUndefined: batchOptions.formDataAllowUndefined
      }, throttledProgress);
    }
  }, Object.values(SENDER_EVENTS));
  return sender;
};

export default createBatchItemsSender;