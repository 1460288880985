import { Call, CallClient, VideoStreamRenderer, VideoStreamRendererView } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationUserToken } from "@azure/communication-identity";
import { Client, Room } from "colyseus.js";
import create from "zustand";
import { ACSRoom } from "../../../backend/ACSRoom";
import { ACS_TOKEN_ENDPOINT, SERVER_URL } from "../../Constants";
import * as Hands from "@mediapipe/hands";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { HAND_CONNECTIONS } from "@mediapipe/hands";
import {
  GestureRecognizer,
  FilesetResolver,
  DrawingUtils
} from "@mediapipe/tasks-vision";

import "./mediapipePage.scss";
import { Holistic, VERSION } from "@mediapipe/holistic";

const subscribeToRemoteVideoStream = async (remoteVideoStream, container, callbackFn, trackingMethod: TrackingMethod) => {
  if (remoteVideoStream.mediaStreamType === "ScreenSharing") {
    return;
  }
  // Create a video stream renderer for the remote video stream.
  let videoStreamRenderer = new VideoStreamRenderer(remoteVideoStream);
  let view: VideoStreamRendererView;
  let newCanvasElement = document.createElement("canvas");
  newCanvasElement.id = `remotevideo${remoteVideoStream.id}`;
  let containerElement = document.getElementsByClassName("handTrackingVideos")[0];
  containerElement.appendChild(newCanvasElement);

  const canvasElement = document.getElementById(`remotevideo${remoteVideoStream.id}`);
  const canvasCtx = canvasElement.getContext("2d");
  // const facemesh = new FaceMesh.FaceMesh({
  //     locateFile: (file) => {
  //         return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
  //     },
  // });
  // // set facemesh config
  // facemesh.setOptions({
  //     maxNumFaces: 1,
  //     refineLandmarks: true,
  //     minDetectionConfidence: 0.5,
  //     minTrackingConfidence: 0.5,
  // });

  // facemesh.onResults((results) => {
  //     console.log("FACEMESH RESULT", results);
  // });

  function onResults(results) {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
    if (results.multiHandLandmarks) {
      for (let index = 0; index < results.multiHandLandmarks.length; index++) {
        const landmarks = results.multiHandLandmarks[index];

        const classification = results.multiHandedness[index];
        console.log(results, classification);

        drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS, { color: "#00FF00", lineWidth: 5 });
        drawLandmarks(canvasCtx, landmarks, { color: "#FF0000", lineWidth: 2 });
      }
      if (results.multiHandLandmarks[0]) {
        if (results.multiHandLandmarks[0][8]) {
          callbackFn(results.multiHandLandmarks[0][8]);
        }
      }
    }
    canvasCtx.restore();
  }

  function onGestureResults(results) {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    // canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
    if (results.landmarks) {
      for (let index = 0; index < results.landmarks.length; index++) {
        const landmarks = results.landmarks[index];

        // const classification = results.landmarks[index];
        // console.log(results, classification);

        drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS, { color: "#00FF00", lineWidth: 5 });
        drawLandmarks(canvasCtx, landmarks, { color: "#FF0000", lineWidth: 2 });
      }
      // if (results.landmarks[0]) {
      //   if (results.landmarks[0][8]) {
      //     callbackFn(results.landmarks[0][8]);
      //   }
      // }
      callbackFn(results);
    }
    canvasCtx.restore();
  }

  let holistic;

  if (trackingMethod === "holistic"){
  holistic = new Holistic({
    locateFile: (file) => {
      console.log(file)
      let url = `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@${VERSION}/${file}`
      console.log(url)
      url = `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`
      return url;
    },
  });
 

  holistic.setOptions({
    modelComplexity: 2,
    smoothLandmarks: true,
    minDetectionConfidence: 0.7,
    minTrackingConfidence: 0.7,
    refineFaceLandmarks: true,
  });

  const onHolisticResult = (results) => {
  //  console.log("HOLISTIC", results);
   callbackFn(results);
  };

  holistic.onResults(onHolisticResult);

await   holistic.initialize();
  }
  // const hands = new Hands.Hands({
  //   locateFile: (file) => {
  //     return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
  //   },
  // });
  // hands.setOptions({
  //   maxNumHands: 2,
  //   modelComplexity: 1,
  //   minDetectionConfidence: 0.5,
  //   minTrackingConfidence: 0.5,
  // });
  // hands.onResults(onResults);
let gestureRecognizer;
if (trackingMethod === "hands"){
  const createGestureRecognizer = async () => {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.1.0-alpha-11/wasm"
    );
   const gestureRecognizer = await GestureRecognizer.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath:
          "https://storage.googleapis.com/mediapipe-tasks/gesture_recognizer/gesture_recognizer.task"
      },
      runningMode: "VIDEO"
    });
   return gestureRecognizer;
  };
  gestureRecognizer = await createGestureRecognizer();
}

  const renderVideo = async (frameCallback?: (now, metadata) => void) => {
    try {
      // Create a renderer view for the remote video stream.
      view = await videoStreamRenderer.createView();
      console.log("VIEW TARGET", view.target);
      const videoElement: HTMLVideoElement = view.target.children[0] as HTMLVideoElement;
      console.log(videoElement);
      let newElement = document.createElement("div");
      newElement.className = "remoteVideo";
      const doSomethingWithTheFrame = async (now, metadata) => {
        // Do something with the frame.
        // console.log(now, metadata);
        // await hands.send({ image: videoElement });

        frameCallback && frameCallback(now, metadata)

        if (trackingMethod === "holistic"){
        holistic.send({ image: videoElement });
        }
        if (trackingMethod === "hands"){
        const gestureResult = gestureRecognizer.recognizeForVideo(videoElement, now);
        onGestureResults(gestureResult)
        }
        // Re-register the callback to be notified about the next frame.
        videoElement.requestVideoFrameCallback(doSomethingWithTheFrame);
      };
      // Initially register the callback to be notified about the first frame.
      videoElement.requestVideoFrameCallback(doSomethingWithTheFrame);

      newElement.appendChild(view.target);

      // Attach the renderer view to the UI.
      container.hidden = false;
      container.appendChild(newElement);
    } catch (e) {
      console.warn(`Failed to createView, reason=${e.message}, code=${e.code}`);
    }
  };

  remoteVideoStream.on("isAvailableChanged", async () => {
    // Participant has switched video on.
    if (remoteVideoStream.isAvailable) {
      await renderVideo();

      // Participant has switched video off.
    } else {
      if (view) {
        view.dispose();
        view = undefined;
      }
    }
  });

  // Participant has video on initially.
  if (remoteVideoStream.isAvailable) {
    await renderVideo();
  }
};
const subscribeToRemoteParticipant = (remoteParticipant, container, room, callbackFn, trackingMethod: TrackingMethod) => {
  try {
    // Inspect the initial remoteParticipant.state value.
    console.log(`Remote participant state: ${remoteParticipant.state}`);
    // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
    remoteParticipant.on("stateChanged", () => {
      console.log(`Remote participant state changed: ${remoteParticipant.state}`);
    });

    // Inspect the remoteParticipants's current videoStreams and subscribe to them.
    remoteParticipant.videoStreams.forEach((remoteVideoStream) => {
      console.log("VIDEO SCREEN FOREACH", remoteVideoStream);

      subscribeToRemoteVideoStream(remoteVideoStream, container, callbackFn, trackingMethod);
    });
    // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
    // notified when the remoteParticiapant adds new videoStreams and removes video streams.
    remoteParticipant.on("videoStreamsUpdated", (e) => {
      // Subscribe to new remote participant's video streams that were added.
      e.added.forEach((remoteVideoStream) => {
        subscribeToRemoteVideoStream(remoteVideoStream, container, callbackFn, trackingMethod);
      });
      // Unsubscribe from remote participant's video streams that were removed.
      e.removed.forEach((remoteVideoStream) => {
        console.log("Remote participant video stream was removed.");
      });
    });

    remoteParticipant.on("isSpeakingChanged", (e) => {
      console.log(`Remote participant is speaking: `, remoteParticipant.isSpeaking, remoteParticipant.displayName);
      console.log("ROOMID", room.id);
      if (remoteParticipant.isSpeaking) {
        console.log("SOMEBODY IS SPEAKING");
        room.send("changeWhoIsSpeaking", { whoIsSpeaking: remoteParticipant.displayName });
      } else {
        room.send("changeWhoIsSpeaking", { whoIsSpeaking: "" });
      }
      // console.log("SENDING EVENT");
      // room.send("changeWhoIsSpeaking", { whoIsSpeaking: remoteParticipant.displayName });
    });
  } catch (error) {
    console.error(error);
  }
};

type TrackingMethod = "hands" | "holistic";

type MediapipeStoreState = {
  room: Room | null;
  joinUrl: string;
  callIsConnecting: boolean;
  callIsConnected: boolean;
  call: Call | null;
  trackingMethod: TrackingMethod;

  remoteParticipants: Array<{ displayName: string }>;
  handPositionData: Array<{ userId: string, data: any }>;

  api: {
    getACSToken: () => Promise<CommunicationUserToken>;
    joinOrCreateRoom: (joinUrl: string) => Promise<void>;
    setupACSCall: () => void;
    setCall: (call: Call) => void;
    setupHandTracking: () => void;
    setHandPositionData: (userId: string, data: any) => void;
    setupHolistic: () => void;
    setTrackingMethod: (methodName: TrackingMethod) => void;
  };
};

const getMeetingIdFromJoinUrl = (joinUrl) => {
  if (!joinUrl) return "";
  let decoded = decodeURIComponent(joinUrl);
  let split = decoded.split("/meetup-join/")[1];
  let id = split.split("/")[0];
  return id;
};

export const useMediapipeStore = create<MediapipeStoreState>((set, get) => ({
  room: null,
  joinUrl: "",
  callIsConnecting: false,
  callIsConnected: false,
  call: null,
  trackingMethod: "hands",

  remoteParticipants: [],
  handPositionData: [],
  api: {
    getACSToken: () => {
      return new Promise((resolve, reject) => {
        let url = process.env.NODE_ENV === "production" ? ACS_TOKEN_ENDPOINT : "http://localhost:2567/acstoken";

        fetch(url, { method: "GET" })
          .then((r) => r.json())
          .then((resp) => {
            return resolve(resp);
          });
      });
    },
    joinOrCreateRoom: async (joinUrl: string) => {
      let serverUrl = process.env.NODE_ENV === "production" ? SERVER_URL : "ws://localhost:2567";
      const client = new Client(serverUrl);

      let meetingId = getMeetingIdFromJoinUrl(joinUrl);

      const room = await client.joinOrCreate<ACSRoom>("acs", { meetingId: meetingId });
      set({ room: room, joinUrl: joinUrl });
    },
    setupACSCall: async () => {
      let identityResponse = await get().api.getACSToken();
      let joinUrl = get().joinUrl;

      const callClient = new CallClient();
      const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
      let agent = await callClient.createCallAgent(tokenCredential, { displayName: "Test" });

      let call = agent.join(
        {
          meetingLink: joinUrl,
        },
        {}
      );
      set({ callIsConnecting: true, call: call });
      call.on("stateChanged", () => {
        if (call.state === "Connected") {
          console.log("We are connected 1");
          call.mute();
          call.muteIncomingAudio();
          set({ callIsConnected: true, callIsConnecting: false });
        }
      });

      call.remoteParticipants.forEach((remoteParticipant) => {
        console.log("FOUND REMOTE PARTICIPANT", remoteParticipant);
      });

      let videoContainer = document.getElementsByClassName("remoteVideos")[0];

      // Subscribe to the call's 'remoteParticipantsUpdated' event to be
      // notified when new participants are added to the call or removed from the call.
      call.on("remoteParticipantsUpdated", (e) => {
        // Subscribe to new remote participants that are added to the call.
        e.added.forEach((remoteParticipant) => {
          const handTrackingCallback = (data) => {
            let userId = remoteParticipant.identifier.microsoftTeamsUserId;
            get().room?.send("changeHandPosition", { handPositionTriple: [data.x, data.y, data.z], userId: userId });
          };

          console.log("REMOTE PARTICIPANT ADDED", remoteParticipant, remoteParticipant.displayName, get().room);
          subscribeToRemoteParticipant(remoteParticipant, videoContainer, get().room, handTrackingCallback);
        });
        // Unsubscribe from participants that are removed from the call
        e.removed.forEach((remoteParticipant) => {
          console.log("Remote participant removed from the call.");
        });
      });
    },

    setCall: (call) => {
      set({ call: call});
    },
    setupHandTracking: () => {
      let videoContainer = document.getElementsByClassName("remoteVideos")[0];

      const call = get().call;
      if (!call) throw new Error("call should not be null");
      call.remoteParticipants.forEach((remoteParticipant) => {
        console.log("FOUND REMOTE PARTICIPANT 3", remoteParticipant);
        const handTrackingCallback = (data) => {
          let userId = remoteParticipant.identifier.microsoftTeamsUserId;
          get().api.setHandPositionData(userId, data)
          get().room?.send("changeHandPosition", { handPositionTriple: [data.x, data.y, data.z], userId: userId });
        };

        let trackingMethod = get().trackingMethod;

  
        subscribeToRemoteParticipant(remoteParticipant, videoContainer, get().room, handTrackingCallback, trackingMethod);
      });


      // Subscribe to the call's 'remoteParticipantsUpdated' event to be
      // notified when new participants are added to the call or removed from the call.
      call.on("remoteParticipantsUpdated", (e) => {
        // Subscribe to new remote participants that are added to the call.
        e.added.forEach((remoteParticipant) => {
          const handTrackingCallback = (data) => {
            let userId = remoteParticipant.identifier.microsoftTeamsUserId;
            get().api.setHandPositionData(userId, data)

            get().room?.send("changeHandPosition", { handPositionTriple: [data.x, data.y, data.z], userId: userId });
          };

          console.log("REMOTE PARTICIPANT ADDED 2", remoteParticipant, remoteParticipant.displayName, get().room);
          debugger;
          let trackingMethod = get().trackingMethod;
          subscribeToRemoteParticipant(remoteParticipant, videoContainer, get().room, handTrackingCallback, trackingMethod);
        });
        // Unsubscribe from participants that are removed from the call
        e.removed.forEach((remoteParticipant) => {
          console.log("Remote participant removed from the call.");
        });
      });
    },

    setHandPositionData: (userId: string, data: any) => {

      set((state) => {
        const updated = [...state.handPositionData];
        const userIndex = updated.findIndex((u) => u.userId === userId);
        if (userIndex === -1){
          let newArr = updated.concat({ userId: userId, data });
          return ({ handPositionData: newArr })
        }
        else {
          updated[userIndex].data = data;
          return ({ handPositionData: updated })

        }
       
    });


    },
    setupHolistic: () => {
      console.log("Setting up holistic")

      const holistic = new Holistic({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@0.5.1635989137/${file}`;
        },
      });
  
      holistic.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        minDetectionConfidence: 0.7,
        minTrackingConfidence: 0.7,
        refineFaceLandmarks: true,
      });
    },
    setTrackingMethod: (trackingMethod: TrackingMethod) => {
      set({ trackingMethod: trackingMethod });
    }
  },
}));
