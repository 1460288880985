import merge, { isMergeObj } from "./merge";

/**
 * does deep clone to the passed object, returning a new object
 * @param obj
 * @param mergeFn the merge function to use (default: utils/merge)
 * @returns {Object}
 */
const clone = function (obj) {
  let mergeFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : merge;
  return isMergeObj(obj) ? mergeFn(Array.isArray(obj) ? [] : {}, obj) : obj;
};

export default clone;