import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useSoundManager } from "../../hooks/useSoundManager";
import easyLooping from "url:./../../sounds/soundscrate-easy-background-looping-120-bpm.mp3";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { useColyseusRoom } from "../../hooks/useColyseusRoom";
import { BreakRoomState } from "../../../state/BreakRoomState";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { motion } from "framer-motion";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function IncreaseBreakTimeButton(props) {
  return (
    <button className="btn btn-xs gap-1 bg-success" {...props}>
      <span>{props.amount}</span>

      <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
        <path
          className="play-btn__svg"
          d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
        />
      </svg>
    </button>
  );
}

function DecreaseBreakTimeButton(props) {
  return (
    <button className="btn btn-xs gap-1 bg-error" {...props}>
      <span>{props.amount}</span>

      <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
        <path
          className=""
          d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
        />
      </svg>
    </button>
  );
}

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

function BreakTimeText({ roomState }: { roomState: BreakRoomState }) {
  if (!roomState) return;
  if (roomState.breakTimeSeconds <= 0) {
    return <div>Break time is now over. Please wait for the meeting to continue.</div>;
  }
  return <div>Meeting resumes in: {roomState ? fancyTimeFormat(roomState.breakTimeSeconds) : ""}</div>;
}

export function Pipes( { showControls, canvasId, soundEnabled } : { showControls: boolean, canvasId: string, soundEnabled: boolean }) {
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  const soundManager = useSoundManager();

  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  const playerName = useGlobalStore2((store) => store.playerName);
  const playerImage = useGlobalStore2((store) => store.playerImageBase64);

  let toMerge = { name: playerName || "Unknown" };
  let joinObj = {
    ...teamsContext,
    ...toMerge,
    ...{ imageBase64: playerImage },
  };

  const { client, room, roomState } = useColyseusRoom<BreakRoomState>("breakroom", joinObj);

  let query = useQuery();
  const [breakEndTime, setBreakEndTime] = useState("");

  useEffect(() => {
    // document.body.onclick = () => {
    //   soundManager.startBackgroundMusic(easyLooping);
    // };
    if (soundEnabled){
    soundManager.startBackgroundMusic(easyLooping);
    }

    return () => {
      soundManager.stopBackgroundMusic();
    };
  }, []);

  useEffect(() => {
    const endTime = query.get("endTime");
    if (!endTime) {
      console.log("NO ENDTIME");
    } else {
      setBreakEndTime(endTime);
    }
  }, [query]);
  useEffect(() => {
    var gridBounds = new THREE.Box3(new THREE.Vector3(-10, -10, -10), new THREE.Vector3(10, 10, 10));
    var nodes = {};
    function setAt(position, value) {
      nodes["(" + position.x + ", " + position.y + ", " + position.z + ")"] = value;
    }
    function getAt(position, value) {
      return nodes["(" + position.x + ", " + position.y + ", " + position.z + ")"];
    }
    function clearGrid() {
      nodes = {};
    }

    var textures = {};
    var Pipe = function (scene, options) {
      var self = this;
      var pipeRadius = 0.2;
      var ballJointRadius = pipeRadius * 1.5;
      var teapotSize = ballJointRadius;

      self.currentPosition = randomIntegerVector3WithinBox(gridBounds);
      self.positions = [self.currentPosition];
      self.object3d = new THREE.Object3D();
      scene.add(self.object3d);
      if (options.texturePath) {
        self.material = new THREE.MeshLambertMaterial({
          map: textures[options.texturePath],
        });
      } else {
        var color = randomInteger(0, 0xffffff);
        var emissive = new THREE.Color(color).multiplyScalar(0.3);
        self.material = new THREE.MeshPhongMaterial({
          specular: 0xa9fcff,
          color: color,
          emissive: emissive,
          shininess: 100,
        });
      }
      var makeCylinderBetweenPoints = function (fromPoint, toPoint, material) {
        var deltaVector = new THREE.Vector3().subVectors(toPoint, fromPoint);
        var arrow = new THREE.ArrowHelper(deltaVector.clone().normalize(), fromPoint);
        var geometry = new THREE.CylinderGeometry(pipeRadius, pipeRadius, deltaVector.length(), 10, 4, true);
        var mesh = new THREE.Mesh(geometry, material);

        mesh.rotation.setFromQuaternion(arrow.quaternion);
        mesh.position.addVectors(fromPoint, deltaVector.multiplyScalar(0.5));
        mesh.updateMatrix();

        self.object3d.add(mesh);
      };
      var makeBallJoint = function (position) {
        var ball = new THREE.Mesh(new THREE.SphereGeometry(ballJointRadius, 8, 8), self.material);
        ball.position.copy(position);
        self.object3d.add(ball);
      };
      //   var makeTeapotJoint = function (position) {
      //     //var teapotTexture = textures[options.texturePath].clone();
      //     //teapotTexture.repeat.set(1, 1);

      //     // THREE.TeapotBufferGeometry = function ( size, segments, bottom, lid, body, fitLid, blinn )
      //     var teapot = new THREE.Mesh(
      //       new THREE.TeapotBufferGeometry(teapotSize, true, true, true, true, true),
      //       self.material
      //       //new THREE.MeshLambertMaterial({ map: teapotTexture })
      //     );
      //     teapot.position.copy(position);
      //     teapot.rotation.x = (Math.floor(random(0, 50)) * Math.PI) / 2;
      //     teapot.rotation.y = (Math.floor(random(0, 50)) * Math.PI) / 2;
      //     teapot.rotation.z = (Math.floor(random(0, 50)) * Math.PI) / 2;
      //     self.object3d.add(teapot);
      //   };
      var makeElbowJoint = function (fromPosition, toPosition, tangentVector) {
        // elbow
        // var r = 0.2;
        // elbow = new THREE.Mesh(
        //   new THREE.TorusGeometry(r, pipeRadius, 8, 8, Math.PI / 2),
        //   self.material
        // );
        // elbow.position.copy(fromPosition);
        // self.object3d.add(elbow);

        // "elball" (not a proper elbow)
        var elball = new THREE.Mesh(new THREE.SphereGeometry(pipeRadius, 8, 8), self.material);
        elball.position.copy(fromPosition);
        self.object3d.add(elball);

        // extrude an elbow joint

        // there's THREE.EllipseCurve... but that's 2D

        // function ArcCurve(scale) {
        //   THREE.Curve.call(this);
        //   this.scale = scale === undefined ? 1 : scale; // TODO: remove me probably
        // }

        // ArcCurve.prototype = Object.create(THREE.Curve.prototype);
        // ArcCurve.prototype.constructor = ArcCurve;

        // ArcCurve.prototype.getPoint = function(t) {
        //   function circ(t) {
        //     return Math.sqrt(1 - t * t);
        //   }

        //   var tx = t;
        //   var ty = circ(t);
        //   var tz = 0;

        //   return new THREE.Vector3(tx, ty, tz).multiplyScalar(this.scale);
        // };

        // var extrudePath = new ArcCurve(0.1);

        // var extrudePath = new THREE.CatmullRomCurve3([fromPosition, toPosition], false); // not enough to define the curve

        // var extrusionSegments = 100;
        // var radiusSegments = 10;
        // var radius = pipeRadius;
        // var tubeGeometry = new THREE.TubeBufferGeometry(
        //   extrudePath,
        //   extrusionSegments,
        //   radius,
        //   radiusSegments,
        //   false
        // );

        // var elbow = new THREE.Mesh(tubeGeometry, self.material);
        // elbow.position.copy(toPosition);
        // self.object3d.add(elbow);
      };

      // if (getAt(self.currentPosition)) {
      //   return; // TODO: find a position that's free
      // }
      setAt(self.currentPosition, self);

      makeBallJoint(self.currentPosition);

      self.update = function () {
        if (self.positions.length > 1) {
          var lastPosition = self.positions[self.positions.length - 2];
          var lastDirectionVector = new THREE.Vector3().subVectors(self.currentPosition, lastPosition);
        }
        if (chance(1 / 2) && lastDirectionVector) {
          var directionVector = lastDirectionVector;
        } else {
          var directionVector = new THREE.Vector3();
          directionVector[chooseFrom("xyz")] += chooseFrom([+1, -1]);
        }
        var newPosition = new THREE.Vector3().addVectors(self.currentPosition, directionVector);

        // TODO: try other possibilities
        // ideally, have a pool of the 6 possible directions and try them in random order, removing them from the bag
        // (and if there's truly nowhere to go, maybe make a ball joint)
        if (!gridBounds.containsPoint(newPosition)) {
          return;
        }
        if (getAt(newPosition)) {
          return;
        }
        setAt(newPosition, self);

        // joint
        // (initial ball joint is handled elsewhere)
        if (lastDirectionVector && !lastDirectionVector.equals(directionVector)) {
          if (chance(options.teapotChance)) {
            makeBallJoint(self.currentPosition);
          } else if (chance(options.ballJointChance)) {
            makeBallJoint(self.currentPosition);
          } else {
            makeElbowJoint(self.currentPosition, newPosition, lastDirectionVector);
          }
        }

        // pipe
        makeCylinderBetweenPoints(self.currentPosition, newPosition, self.material);

        // update
        self.currentPosition = newPosition;
        self.positions.push(newPosition);

        // var extrudePath = new THREE.CatmullRomCurve3(self.positions, false, "catmullrom");

        // var extrusionSegments = 10 * self.positions.length;
        // var radiusSegments = 10;
        // var tubeGeometry = new THREE.TubeBufferGeometry( extrudePath, extrusionSegments, pipeRadius, radiusSegments, false );

        // if(self.mesh){
        // 	self.object3d.remove(self.mesh);
        // }
        // self.mesh = new THREE.Mesh(tubeGeometry, self.material);
        // self.object3d.add(self.mesh);
      };
    };

    var JOINTS_ELBOW = "elbow";
    var JOINTS_BALL = "ball";
    var JOINTS_MIXED = "mixed";
    var JOINTS_CYCLE = "cycle";

    var jointsCycleArray = [JOINTS_ELBOW, JOINTS_BALL, JOINTS_MIXED];
    var jointsCycleIndex = 0;

    var jointTypeSelect = document.getElementById("joint-types");

    var pipes = [];
    var options = {
      multiple: true,
      texturePath: null,
      joints: jointTypeSelect.value,
      interval: [16, 24], // range of seconds between fade-outs... not necessarily anything like how the original works
    };
    jointTypeSelect.addEventListener("change", function () {
      options.joints = jointTypeSelect.value;
    });

    var canvasContainer = document.getElementById("canvas-container");

    // 2d canvas for dissolve effect
    var canvas2d = canvasRef2.current;
    var ctx2d = canvas2d.getContext("2d");

    // renderer
    var canvasWebGL = canvasRef.current; //document.getElementById("canvas-webgl");
    var renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: canvasWebGL,
      
    });
    renderer.domElement.id = canvasId;
    renderer.setSize(1920, 1080);
    window.__tom = renderer;
    // camera
    var camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 100000);

    // controls
    var controls = new OrbitControls(camera, renderer.domElement);
    controls.enabled = true;
    // controls.autoRotate = true;

    // scene
    var scene = new THREE.Scene();

    // lighting
    var ambientLight = new THREE.AmbientLight(0x111111);
    scene.add(ambientLight);

    var directionalLightL = new THREE.DirectionalLight(0xffffff, 0.9);
    directionalLightL.position.set(-1.2, 1.5, 0.5);
    scene.add(directionalLightL);

    // dissolve transition effect

    var dissolveRects = [];
    var dissolveRectsIndex = -1;
    var dissolveRectsPerRow = 50;
    var dissolveRectsPerColumn = 50;
    var dissolveTransitionSeconds = 2;
    var dissolveTransitionFrames = dissolveTransitionSeconds * 60;
    var dissolveEndCallback;

    function dissolve(seconds, endCallback) {
      // TODO: determine rect sizes better and simplify
      // (silly approximation of squares of a particular size:)
      dissolveRectsPerRow = Math.ceil(window.innerWidth / 20);
      dissolveRectsPerColumn = Math.ceil(window.innerHeight / 20);

      dissolveRects = new Array(dissolveRectsPerRow * dissolveRectsPerColumn).fill(null).map(function (_null, index) {
        return {
          x: index % dissolveRectsPerRow,
          y: Math.floor(index / dissolveRectsPerRow),
        };
      });
      shuffleArrayInPlace(dissolveRects);
      dissolveRectsIndex = 0;
      dissolveTransitionSeconds = seconds;
      dissolveTransitionFrames = dissolveTransitionSeconds * 60;
      dissolveEndCallback = endCallback;
    }
    function finishDissolve() {
      dissolveEndCallback();
      dissolveRects = [];
      dissolveRectsIndex = -1;
      ctx2d.clearRect(0, 0, canvas2d.width, canvas2d.height);
    }

    var clearing = false;
    var clearTID = -1;
    function clear(fast) {
      clearTimeout(clearTID);
      clearTID = setTimeout(clear, random(options.interval[0], options.interval[1]) * 1000);
      if (!clearing) {
        clearing = true;
        var fadeOutTime = fast ? 0.2 : 2;
        dissolve(fadeOutTime, reset);
      }
    }
    clearTID = setTimeout(clear, random(options.interval[0], options.interval[1]) * 1000);

    function reset() {
      renderer.clear();
      for (var i = 0; i < pipes.length; i++) {
        scene.remove(pipes[i].object3d);
      }
      pipes = [];
      clearGrid();
      look();
      clearing = false;
    }

    // this function is executed on each animation frame
    function animate() {
      controls.update();
      if (options.texturePath && !textures[options.texturePath]) {
        var texture = THREE.ImageUtils.loadTexture(options.texturePath);
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(2, 2);
        textures[options.texturePath] = texture;
      }
      // update
      for (var i = 0; i < pipes.length; i++) {
        pipes[i].update(scene);
      }
      if (pipes.length === 0) {
        var jointType = options.joints;
        if (options.joints === JOINTS_CYCLE) {
          jointType = jointsCycleArray[jointsCycleIndex++];
        }
        var pipeOptions = {
          teapotChance: 1 / 200, // 1 / 1000 in the original
          ballJointChance: jointType === JOINTS_BALL ? 1 : jointType === JOINTS_MIXED ? 1 / 3 : 0,
          texturePath: options.texturePath,
        };
        if (chance(1 / 20)) {
          pipeOptions.teapotChance = 1 / 20; // why not? :)
          pipeOptions.texturePath = "images/textures/candycane.png";
          // TODO: DRY
          if (!textures[pipeOptions.texturePath]) {
            var texture = THREE.ImageUtils.loadTexture(pipeOptions.texturePath);
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(2, 2);
            textures[pipeOptions.texturePath] = texture;
          }
        }
        // TODO: create new pipes over time?
        for (var i = 0; i < 1 + options.multiple * (1 + chance(1 / 10)); i++) {
          pipes.push(new Pipe(scene, pipeOptions));
        }
      }

      if (!clearing) {
        renderer.render(scene, camera);
      }

      if (canvas2d.width !== window.innerWidth || canvas2d.height !== window.innerHeight) {
        canvas2d.width = window.innerWidth;
        canvas2d.height = window.innerHeight;
        // TODO: DRY!
        // actually: TODO: make the 2d canvas really low resolution, and stretch it with CSS, with pixelated interpolation
        if (dissolveRectsIndex > -1) {
          for (var i = 0; i < dissolveRectsIndex; i++) {
            var rect = dissolveRects[i];
            // TODO: could precompute rect in screen space, or at least make this clearer with "xIndex"/"yIndex"
            var rectWidth = innerWidth / dissolveRectsPerRow;
            var rectHeight = innerHeight / dissolveRectsPerColumn;
            ctx2d.fillStyle = "black";
            ctx2d.fillRect(
              Math.floor(rect.x * rectWidth),
              Math.floor(rect.y * rectHeight),
              Math.ceil(rectWidth),
              Math.ceil(rectHeight)
            );
          }
        }
      }
      if (dissolveRectsIndex > -1) {
        // TODO: calibrate based on time transition is actually taking
        var rectsAtATime = Math.floor(dissolveRects.length / dissolveTransitionFrames);
        for (var i = 0; i < rectsAtATime && dissolveRectsIndex < dissolveRects.length; i++) {
          var rect = dissolveRects[dissolveRectsIndex];
          // TODO: could precompute rect in screen space, or at least make this clearer with "xIndex"/"yIndex"
          var rectWidth = innerWidth / dissolveRectsPerRow;
          var rectHeight = innerHeight / dissolveRectsPerColumn;
          ctx2d.fillStyle = "black";
          ctx2d.fillRect(
            Math.floor(rect.x * rectWidth),
            Math.floor(rect.y * rectHeight),
            Math.ceil(rectWidth),
            Math.ceil(rectHeight)
          );
          dissolveRectsIndex += 1;
        }
        if (dissolveRectsIndex === dissolveRects.length) {
          finishDissolve();
        }
      }

      requestAnimationFrame(animate);
    }

    function look() {
      // TODO: never don't change the view (except maybe while clearing)
      if (chance(1 / 2)) {
        // head-on view

        camera.position.set(0, 0, 14);
      } else {
        // random view

        var vector = new THREE.Vector3(14, 0, 0);

        var axis = new THREE.Vector3(random(-1, 1), random(-1, 1), random(-1, 1));
        var angle = Math.PI / 2;
        var matrix = new THREE.Matrix4().makeRotationAxis(axis, angle);

        vector.applyMatrix4(matrix);
        camera.position.copy(vector);
      }
      var center = new THREE.Vector3(0, 0, 0);
      camera.lookAt(center);
      // camera.updateProjectionMatrix(); // maybe?
      controls.update();
    }
    look();

    addEventListener(
      "resize",
      function () {
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
      },
      false
    );

    canvasContainer.addEventListener("mousedown", function (e) {
      e.preventDefault();
      if (!controls.enabled) {
        if (e.button) {
          clear(true);
        } else {
          look();
        }
      }
      window.getSelection().removeAllRanges();
      document.activeElement.blur();
    });

    canvasContainer.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );

    var fullscreenButton = document.getElementById("fullscreen-button");
    fullscreenButton.addEventListener(
      "click",
      function (e) {
        if (canvasContainer.requestFullscreen) {
          // W3C API
          canvasContainer.requestFullscreen();
        } else if (canvasContainer.mozRequestFullScreen) {
          // Mozilla current API
          canvasContainer.mozRequestFullScreen();
        } else if (canvasContainer.webkitRequestFullScreen) {
          // Webkit current API
          canvasContainer.webkitRequestFullScreen();
        }
      },
      false
    );

    var toggleControlButton = document.getElementById("toggle-controls");
    toggleControlButton.addEventListener(
      "click",
      function (e) {
        controls.enabled = !controls.enabled;
        showElementsIf(".normal-controls-enabled", !controls.enabled);
        showElementsIf(".orbit-controls-enabled", controls.enabled);
      },
      false
    );

    // parse URL parameters
    // support e.g. <iframe src="https://1j01.github.io/pipes/#{%22hideUI%22:true}"/>
    function updateFromParametersInURL() {
      var paramsJSON = decodeURIComponent(location.hash.replace(/^#/, ""));
      if (paramsJSON) {
        try {
          var params = JSON.parse(paramsJSON);
          if (typeof params !== "object") {
            alert("Invalid URL parameter JSON: top level value must be an object");
            params = null;
          }
        } catch (error) {
          alert("Invalid URL parameter JSON syntax\n\n" + error + "\n\nRecieved:\n" + paramsJSON);
        }
      }
      params = params || {};

      // update based on the parameters
      // TODO: support more options
      showElementsIf(".ui-container", !params.hideUI);
    }

    updateFromParametersInURL();
    window.addEventListener("hashchange", updateFromParametersInURL);

    // start animation
    animate();

    /**************\
          |boring helpers|
          \**************/
    function random(x1, x2) {
      return Math.random() * (x2 - x1) + x1;
    }
    function randomInteger(x1, x2) {
      return Math.round(random(x1, x2));
    }
    function chance(value) {
      return Math.random() < value;
    }
    function chooseFrom(values) {
      return values[Math.floor(Math.random() * values.length)];
    }
    function shuffleArrayInPlace(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    }
    function randomIntegerVector3WithinBox(box) {
      return new THREE.Vector3(
        randomInteger(box.min.x, box.max.x),
        randomInteger(box.min.y, box.max.y),
        randomInteger(box.min.z, box.max.z)
      );
    }
    function showElementsIf(selector, condition) {
      Array.from(document.querySelectorAll(selector)).forEach(function (el) {
        if (condition) {
          el.removeAttribute("hidden");
        } else {
          el.setAttribute("hidden", "hidden");
        }
      });
    }
  }, []);

  return (
    <div>
      <div className="controls ui-container" style={{ display: "none" }}>
        <button id="fullscreen-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438.543 438.543">
            <path
              d="M407.42 159.029c3.62 3.616 7.898 5.428 12.847 5.428 2.282 0 4.668-.476 7.139-1.429 7.426-3.235 11.136-8.853 11.136-16.846V18.276c0-4.949-1.807-9.231-5.428-12.847-3.61-3.617-7.898-5.424-12.847-5.424H292.36c-7.991 0-13.607 3.805-16.848 11.419-3.23 7.423-1.902 13.99 4 19.698l41.111 41.112-101.352 101.355L117.917 72.231l41.112-41.112c5.901-5.708 7.232-12.275 3.999-19.698C159.789 3.807 154.175 0 146.182 0H18.276C13.324 0 9.041 1.809 5.425 5.426 1.808 9.042.001 13.324.001 18.273V146.18c0 7.996 3.809 13.61 11.419 16.846 2.285.948 4.57 1.429 6.855 1.429 4.948 0 9.229-1.812 12.847-5.427l41.112-41.109 101.354 101.354L72.234 320.622l-41.112-41.113c-5.711-5.903-12.275-7.231-19.702-4.001C3.806 278.749.001 284.364.001 292.362v127.906c0 4.948 1.807 9.229 5.424 12.847 3.619 3.614 7.902 5.421 12.851 5.421h127.906c7.996 0 13.61-3.806 16.846-11.416 3.234-7.427 1.903-13.99-3.999-19.705l-41.112-41.106L219.271 264.95l101.353 101.361-41.114 41.11c-5.899 5.708-7.228 12.279-3.997 19.698 3.237 7.617 8.856 11.423 16.851 11.423h127.907c4.948 0 9.232-1.813 12.847-5.428 3.613-3.613 5.42-7.898 5.42-12.847V292.362c0-7.994-3.709-13.613-11.136-16.851-7.802-3.23-14.462-1.903-19.985 4.004l-41.106 41.106-101.359-101.35L366.31 117.917l41.11 41.112z"
              fill="#FFF"
            />
          </svg>
          Enter Fullscreen
        </button>
        <label>
          Joint Type:
          <select id="joint-types">
            <option value="elbow">Elbow</option>
            <option value="ball">Ball</option>
            <option value="mixed" selected>
              Mixed
            </option>
            <option value="cycle">Cycle</option>
          </select>
        </label>
        <button id="toggle-controls">
          <span className="normal-controls-enabled">Control Camera</span>
          <span hidden className="orbit-controls-enabled">
            Relinquish Camera
          </span>
        </button>
        <table>
          <thead>
            <tr>
              <th>Mouse button</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr className="normal-controls-enabled">
              <td>Primary</td>
              <td>Change view</td>
            </tr>
            <tr className="normal-controls-enabled">
              <td>Secondary</td>
              <td>Clear pipes and restart</td>
            </tr>
            <tr hidden className="orbit-controls-enabled">
              <td>Primary (Drag)</td>
              <td>Rotate view</td>
            </tr>
            <tr hidden className="orbit-controls-enabled">
              <td>Secondary (Drag) (or arrow keys)</td>
              <td>Pan around</td>
            </tr>
            <tr hidden className="orbit-controls-enabled">
              <td>Middle (Drag or Wheel)</td>
              <td>Zoom</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="canvas-container">
        <canvas ref={canvasRef} id={canvasId} ></canvas>
        <canvas style={{ display: "none" }} ref={canvasRef2} id="canvas-2d"></canvas>
        { showControls && 
        <motion.div
          className={"rounded-md drop-shadow-md bg-primary-content"}
          key={"breaktime"}
          initial={{
            opacity: 0,
            position: "absolute",
            left: "0px",
            bottom: "0px",
            color: "white",
            padding: "5px",
          }}
          animate={{ opacity: 1, left: "50px", bottom: "50px" }}
          exit={{ opacity: 0 }}>
          <div className={"p-4"}>
            <div className={"text-3xl"}>Break Time</div>
            <div className={"text-primary"}>
              {roomState && <BreakTimeText roomState={roomState} />}
              {/* Meeting resumes in: {roomState ? fancyTimeFormat(roomState.breakTimeSeconds) : ""} */}
            </div>
            <div className={"flex flex-row pt-4"}>
              Increase Break Time:
              <div className={"px-1"}>
                <IncreaseBreakTimeButton
                  amount={30}
                  onClick={() => {
                    room.send("increaseBreakTime", { amount: 30 });
                  }}
                />
              </div>
              <div className={"px-1"}>
                <IncreaseBreakTimeButton
                  amount={60}
                  onClick={() => {
                    room.send("increaseBreakTime", { amount: 60 });
                  }}
                />
              </div>
            </div>

            <div className={"flex flex-row"}>
              Decrease Break Time:
              <div className={"px-1"}>
                <DecreaseBreakTimeButton
                  amount={30}
                  onClick={() => {
                    room.send("increaseBreakTime", { amount: -30 });
                  }}
                />
              </div>
              <div className={"px-1"}>
                <DecreaseBreakTimeButton
                  amount={60}
                  onClick={() => {
                    room.send("increaseBreakTime", { amount: -60 });
                  }}
                />
              </div>
            </div>
          </div>
        </motion.div> }
      </div>
    </div>
  );
}
