import React from "react";
import "./animatedVisualizer.scss";

export function AnimatedVisualizer() {
  return (
    <div style={{ transform: "scale(0.15)" }}>
      <div></div>
      <div className={"animatedVisualizer"}></div>
      <div></div>
    </div>
  );
}
