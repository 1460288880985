import React, { useEffect, useRef, useState } from "react";
import Deck from "deck-of-cards/lib/deck";
import "./snap21.scss"
import { Card } from "@arwes/core/lib/Card/Card.component";
import { useSnap21GameState, select, createCardStackState } from "./useSnap21GameStore";

const cardStackState1 = createCardStackState();
const cardStackState2 = createCardStackState();


const CardStack = (props) => {
    const container = useRef();
  //   const [cards, setCards] = useState([]);
const topCards = useSnap21GameState(select.topCards);


  return (
      <div style={{
        position: "absolute",
        bottom: "5px",
        left: props.left,
        height: "300px",
        width: "80px",
        border: "1px solid black",
      }}
      onClick={() => {
        // topCards[0].unmount();
        //   addCard(0, topCards[0].rank);
        topCards[0].setSide("front")
          topCards[0].mount(container.current)
        props.stack.addCard(topCards[0].rank)
      }}>
    <div
    ref={container}

      ></div>
      {JSON.stringify(cardStackState2)}
      </div>
  );
});

const CardStack1 = (props) => {
    let cardStackState = cardStackState1(state => state.cards);

    return <CardStack stack={cardStackState} />
}
const TopCardContainer = (props) => {
    const container = useRef();
    const topCards = useSnap21GameState(select.topCards);

    useEffect(() => {
        let startingX = 100;

        topCards.forEach((card) => {
            card.unmount();
            card.mount(container.current)
            card.setSide("front")
        })

        // let startingX = 100;
        // topCards.forEach((card, i) => {
        //   card.animateTo({ x: startingX, y: 5, delay: 1000, duration: 1000 });
        //   startingX = startingX + 15;
        //   card.setSide("front");
        // });
     }, [topCards])
    return <div  ref={container}       style={{
        position: "absolute", "left": "200px",         top: "100px"
    }}>
top card container
    </div>
}

export function Snap21() {
  const rootRef = useRef();
  let snap21GameState = useSnap21GameState();

  let [deck, setDeck] = useState();
  let [topStack, setTopStack] = useState([]);

  let [firstStack, setFirstStack] = useState([]);

  const getFirstCardFromTopStack = () => {
    let firstCard = topStack[topStack.length - 1];
    topStack.pop();
    setTopStack(topStack);
    return firstCard;
  };
  useEffect(() => {
    if (!rootRef.current) return;
    console.log("DECK", Deck);
    const theDeck = new Deck();
    setDeck(theDeck);
    // snap21GameState.setDeck(theDeck);
    // snap21GameState.addStack()
    // snap21GameState.addStack()

    // snap21GameState.addStack()

    // snap21GameState.addStack()
    // snap21GameState.addStack()

    // add to DOM
    theDeck.mount(rootRef.current);
    theDeck.cards.forEach((card) => {
        card.setSide("front");
    })
    theDeck.fan();
    setTimeout(() => {
        theDeck.cards.forEach((card) => {
            card.setSide("back")
                  card.enableDragging();
      card.enableFlipping();
        })
        theDeck.shuffle();
        let top3 = theDeck.cards.splice(0, 3);
        // top3.cards.forEach((card) => {
        //     card.unmount();
        // })
        setTopStack(top3);
    }, 2000)
    // theDeck.shuffle();


    // let startingX = 100;
    // top3.forEach((card, i) => {
    //     card.rot = 20;
    //   card.animateTo({ x: startingX, y: 5 });
    //   startingX = startingX + 15;
    //   card.setSide("front");
    // });
    // theDeck.cards.forEach(function (card, i) {
    //   card.enableDragging();
    //   card.enableFlipping();
    //   card.setSide(Math.random() < 0.5 ? "front" : "back");

    //   // explode
    // //   card.animateTo({
    // //     delay: 1000 + i * 2, // wait 1 second + i * 2 ms
    // //     duration: 500,
    // //     ease: "quartOut",

    // //     x: Math.random() * window.innerWidth - window.innerWidth / 2,
    // //     y: Math.random() * window.innerHeight - window.innerHeight / 2,
    // //   });
    // });

    // theDeck.fan();
  }, []);
  return (
    <div className={"snap21"}>
      <div id="container">
          <div   ref={rootRef}>

          </div>
          <div style={{border: "1px solid green", top: 200, width: 300, height: 500 }}>

          </div>
      </div>
      {/* <TopCardContainer /> */}
      <div  
        onClick={(ev) => {
          console.log("clicked in here", ev);
          let topCard = getFirstCardFromTopStack();
          setFirstStack([...firstStack, topCard])
          topCard.unmount();
          topCard.mount(ev.target)
          topCard.animateTo({
            delay: 0,
            duration: 500,
            ease: "quartOut",
            x: 0,
            y: firstStack.length + 15,
            onComplete: () => {
              console.log("CARD ON COMPLETE");
              topCard.animateTo({         x: 0,
                y: firstStack.length + 15, });
            },
          });
        }
    }
        style={{
          position: "fixed",
          bottom: "5px",
          left: "5px",
          height: "300px",
          width: "80px",
          border: "1px solid black",
        }}></div>
        {/* <CardStack1 />
      <CardStack
        left={150}
        onClick={(ev) => {
          console.log("clicked in here", ev);
          let topCard = getFirstCardFromTopStack();
          topCard.animateTo({
            delay: 0,
            duration: 500,
            ease: "quartOut",
            x: ev.target.offsetLeft,
            y: ev.target.offsetTop,
            onComplete: () => {
              console.log("CARD ON COMPLETE");
              topCard.animateTo({ x: ev.target.offsetLeft, y: ev.target.offsetTop });
            },
          });
        }}
      /> */}
      {/* <CardStack left={250} />
      <CardStack left={350} />
      <CardStack left={450} />
      {
          snap21GameState.stacks.map((stack, idx) => {
              return <div
              
              style={{
                position: "absolute",
                bottom: "5px",
                left: idx * 100,
                height: "300px",
                width: "80px",
                border: "1px solid black",
              }}>STACK!!! {idx + JSON.stringify(stack.cards2)}</div>
          })
      } */}
    </div>
  );
}
