import { BATCH_STATES, FILE_STATES, logger } from "@rpldy/shared";
import { UPLOADER_EVENTS } from "../consts";
import { triggerUploaderBatchEvent, getBatchFromState, getIsBatchFinalized } from "./batchHelpers";
import processFinishedRequest from "./processFinishedRequest";

const abortNonUploadingItem = (queue, item, next) => {
  logger.debugLog(`uploader.queue: aborting ${item.state} item  - `, item); //manually finish request for item that hasnt reached the sender yet

  processFinishedRequest(queue, [{
    id: item.id,
    info: {
      status: 0,
      state: FILE_STATES.ABORTED,
      response: "aborted"
    }
  }], next);
  return true;
};

const ITEM_STATE_ABORTS = {
  [FILE_STATES.UPLOADING]: (queue, item) => {
    logger.debugLog(`uploader.queue: aborting uploading item  - `, item);
    return queue.getState().aborts[item.id]();
  },
  [FILE_STATES.ADDED]: abortNonUploadingItem,
  [FILE_STATES.PENDING]: abortNonUploadingItem
};

const callAbortOnItem = (queue, id, next) => {
  const state = queue.getState(),
        item = state.items[id],
        itemState = item === null || item === void 0 ? void 0 : item.state; //$FlowIssue[prop-missing]

  return ITEM_STATE_ABORTS[itemState] ? //$FlowExpectedError[extra-arg]
  //$FlowIssue[prop-missing]
  ITEM_STATE_ABORTS[itemState](queue, item, next) : false;
};

const abortAll = (queue, next) => {
  const items = queue.getState().items;
  Object.keys(items).forEach(id => callAbortOnItem(queue, id, next));
  queue.trigger(UPLOADER_EVENTS.ALL_ABORT);
};

const abortItem = (queue, id, next) => callAbortOnItem(queue, id, next);

const abortBatch = (queue, id, next) => {
  const state = queue.getState(),
        batchData = state.batches[id],
        batch = batchData === null || batchData === void 0 ? void 0 : batchData.batch;

  if (batch && !getIsBatchFinalized(batch)) {
    queue.updateState(state => {
      getBatchFromState(state, id).state = BATCH_STATES.ABORTED;
    });
    triggerUploaderBatchEvent(queue, id, UPLOADER_EVENTS.BATCH_ABORT);
    batch.items.forEach(bi => callAbortOnItem(queue, bi.id, next));
  }
};

export { abortAll, abortItem, abortBatch };