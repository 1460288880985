import React, { Profiler, useEffect, useState } from "react";
import { useGlobalStore2 } from "../hooks/useGlobalState";
import {
  app,
  video,
  meeting,
  chat,
  OpenConversationRequest,
  sharing,
  remoteCamera,
  monetization,
  meetingRoom,
  calendar,
  pages,
  people,
  teams,
  stageView,
  media,
  dialog,
  OpenSingleChatRequest,
  profile,
  search,
  ShareDeepLinkParameters,
  teamsCore,
  
} from "@microsoft/teams-js";
import { v4 as uuidv4 } from "uuid";
import { getTeamsAuthToken } from "../Helpers";
import { ACS_TOKEN_ENDPOINT, TEAMS_TOKEN_ENDPOINT } from "../Constants";
import jwtDecode from "jwt-decode";

const AppHoverControl = () => {
  const isHoveredOverAppIcon = useGlobalStore2((state) => state.isAppIconHoveredOver);
  const ctx = useGlobalStore2((state) => state.teamsContext);

  return (
    <div>
      App Icon is hovered over?: {isHoveredOverAppIcon ? <div>yes {ctx.app.iconPositionVertical}</div> : <div>no</div>}
    </div>
  );
};

const DebugTeamsContext = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  return (
    <div className="alert alert-warning">
      <div className="flex-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-6 h-6 mx-2 stroke-current">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <label>In Microsoft Teams?: {ctx ? "Yes" : "No"}</label>
      </div>
    </div>
  );
};
const StartConversationButton = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [shareWebContentMessage, setShareWebContentMessage] = useState("");
  return (
    <div>
      {" "}
      <button
        onClick={() => {
          let openRequest: OpenConversationRequest = {
            subEntityId: "theSubentity",
            title: "title of my convo",
            // channelId: "19:1b11e2e529b941aaa2e0a8347d4417de@thread.skype",
            entityId: "",
            onStartConversation: (args) => {
              console.log("ON START CONVERSATION", args);
            },
            onCloseConversation: (args) => {
              console.log("ON CLOSE CONVERSATION", args);
            },
          };
          const singleChatRequest: OpenSingleChatRequest = {
            user: "",
            message: "This is the message",
          };
          chat.openChat(singleChatRequest);
        }}>
        Start Conversation
      </button>
      <button
        onClick={() => {
          console.log("SHARING WEBCONTENT");
          sharing
            .shareWebContent({
              content: [
                {
                  type: "URL",
                  url: `https://collabtris.sparkworkspace.com/gameroom?direct=true&r=${uuidv4()}`,
                  message: "Check this out!",
                  preview: true,
                },
              ],
            })
            .then(() => {
              console.log("SHARED?");
            })
            .catch((err) => {
              setShareWebContentMessage(`Error: ${JSON.stringify(err)}`);
            });
        }}>
        Share WebContent
      </button>
      {shareWebContentMessage}
    </div>
  );
};

function ShareWebContentDeepLinkTest() {
  const [shareWebContentMessage, setShareWebContentMessage] = useState("");
  let deepLink = `https://teams.microsoft.com/l/entity/5b81432a-7abc-41de-8cdc-86b9be1ab866/Tomflix2?webUrl=${encodeURIComponent(
    "https://collabtris.sparkworkspace.com/gameroom"
  )}&label=Tomflix&context=${encodeURIComponent("")}`;
  return (
    <div>
      <button
        onClick={() => {
          console.log("SHARING WEBCONTENT");
          sharing
            .shareWebContent({
              content: [
                {
                  type: "URL",
                  url: deepLink,
                  message: "Check this out!",
                  preview: true,
                },
              ],
            })
            .then(() => {
              console.log("SHARED?");
            })
            .catch((err) => {
              setShareWebContentMessage(`Error: ${JSON.stringify(err)}`);
            });
        }}>
        Share WebContent Deep Link
      </button>
      {shareWebContentMessage}
    </div>
  );
}

const CameraControlButton = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [cameraParticipantsMessage, setCameraParticipantsMessage] = useState("");

  return (
    <div>
      <button
        onClick={() => {
          remoteCamera.getCapableParticipants((err, participants) => {
            if (err) {
              setCameraParticipantsMessage(JSON.stringify(err));
            } else {
              setCameraParticipantsMessage(JSON.stringify(participants));
            }
          });
        }}>
        Get Remote Camera Participants
      </button>
      {cameraParticipantsMessage}
    </div>
  );
};

const PurchaseExperience = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          monetization.openPurchaseExperience((err) => {
            setMessage(JSON.stringify(err));
          });
        }}>
        Open Purchase Experience
      </button>
      {message}
    </div>
  );
};

const GetAppContentSharingCapability = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          meeting.getAppContentStageSharingCapabilities((err, capabilities) => {
            if (err) {
              setMessage("Error " + JSON.stringify(err));
            } else {
              setMessage("Capabilities " + JSON.stringify(capabilities));
            }
          });
        }}>
        Get Sharing Capabilities
      </button>
      {message}
    </div>
  );
};

const ShareAppContent = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          const callback = (err, result) => {
            if (err) {
              setMessage(JSON.stringify(err));
              return;
            }
            setMessage(JSON.stringify(result));
          };
          meeting.shareAppContentToStage(callback, "https://collabtris.sparkworkspace.com/meetingrpg?direct=true");
        }}>
        Share App Content
      </button>
      {message}
    </div>
  );
};

const StopShareAppContent = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          const callback = (err, result) => {
            if (err) {
              setMessage(JSON.stringify(err));
              return;
            }
            setMessage(JSON.stringify(result));
          };
          meeting.stopSharingAppContentToStage(callback);
        }}>
        Stop Share App Content
      </button>
      {message}
    </div>
  );
};

const TestThing = () => {
  let [teamsCtx, setTeamsCtx] = useState({});
  const getTeamsContext = () => {
    console.log("TRYING TO INIT TEAMS");
    app
      .initialize()
      .then(() => {
        console.log("inside Teams init");
        app.getContext().then((context) => {
          console.log("inside get context");
          setTeamsCtx(context);
        });
      })
      .catch((err) => {
        console.log("Error on teams init", err);
      });
  };

  return (
    <div>
      <button className="btn btn-sm btn-primary" onClick={() => getTeamsContext()}>
        Get Teams Context
      </button>
      {teamsCtx && JSON.stringify(teamsCtx)}
    </div>
  );
};

const VideoFrameTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("clicked video test button");
          console.log("video.isSupported", video.isSupported());
          setMessage(`Video Support: ${video.isSupported()}`);
          let config = {
            format: video.VideoFrameFormat.NV12,
          };

          let frameCallback: video.VideoFrameCallback = (frame, notifyProcessedFunction, notifyErrorFunction) => {
            console.log("FRAME CALLBACK", frame);
            notifyProcessedFunction();
          };
          video.registerForVideoFrame(frameCallback, config);
        }}>
        Video Frame Test
      </button>
      {message}
    </div>
  );
};

const VideoFrameNotifyTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("clicked VideoFrameNotifyTest");
          console.log("video.isSupported", video.isSupported());
          setMessage(`Video Support: ${video.isSupported()}`);
          let config = {
            format: video.VideoFrameFormat.NV12,
          };
          video.notifySelectedVideoEffectChanged(
            video.EffectChangeType.EffectChanged,
            "c2cf81fd-a1c0-4742-b41a-ef969b3ed490"
          );
        }}>
        VideoFrameNotifyTest
      </button>
      {message}
    </div>
  );
};

const VideoFrameSetupTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          app.notifySuccess();
          console.log("clicked video test button");
          console.log("video.isSupported", video.isSupported());
          setMessage(`Video Support: ${video.isSupported()}`);

          video.registerForVideoEffect((effectId) => {
            console.log("registerForVideoEffect", effectId);
          });
        }}>
        Video Frame registerForVideoEffect
      </button>
      {message}
    </div>
  );
};

const FullVideoTestSetup = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          video.registerForVideoEffect((effectId) => {
            console.log("registerForVideoEffect", effectId);
          });

          let config = {
            format: "NV12",
          };

          let frameCallback: video.VideoFrameCallback = (frame, notifyProcessedFunction, notifyErrorFunction) => {
            console.log("FRAME CALLBACK", frame);
            notifyProcessedFunction();
          };
          video.registerForVideoFrame(frameCallback, config as any);
        }}>
        Full Video App Test
      </button>
      {message}
    </div>
  );
};
const UpdateSettingsTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("clicked update setting button");
          console.log("pages.config.isSupported", pages.config.isSupported());
          pages
            .getConfig()
            .then((config) => {
              setMessage(JSON.stringify(config));
              config.contentUrl = "https://" + window.location.host + "/meetingrpg";
              pages.config
                .setConfig(config)
                .then(() => {
                  console.log("successfully set new config");
                })
                .catch((reason) => {
                  console.log("UNABLE TO UPDATE SETTINGS", JSON.stringify(reason));
                });
            })
            .catch((err) => {
              console.log("unable to get config", JSON.stringify(err));
              setMessage(JSON.stringify(err));
            });
        }}>
        Update Settings Test
      </button>
      {message}
    </div>
  );
};

const PeopleTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("clicked people test button");
          console.log("people.isSupported", people.isSupported());
          people
            .selectPeople({ title: "Test Picker", openOrgWideSearchInChatOrChannel: true })
            .then((val) => {
              setMessage(JSON.stringify(val));
            })
            .catch((err) => setMessage("Error - " + JSON.stringify(err)));
        }}>
        People Picker Test
      </button>
      {message}
    </div>
  );
};

const NavigateToAppTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("clicked people test button");
          console.log("people.isSupported", people.isSupported());

          people
            .selectPeople({ title: "Test Picker", openOrgWideSearchInChatOrChannel: true })
            .then((val) => {
              setMessage(JSON.stringify(val));
            })
            .catch((err) => setMessage("Error - " + JSON.stringify(err)));
        }}>
        Navigate to App Test
      </button>
      {message}
    </div>
  );
};

const CalendarTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("clicked calendar button");
          calendar
            .composeMeeting({})
            .then(() => {
              setMessage("calendar success");
            })
            .catch((err) => setMessage(JSON.stringify(err)));
        }}>
        Calendar Test
      </button>
      {message}
      {calendar.isSupported()}
    </div>
  );
};

const MeetingRoomTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log(`Meeting Room isSupported ${JSON.stringify(meetingRoom.isSupported())}`);
          meetingRoom.getPairedMeetingRoomInfo().then((mr) => {
            setMessage(JSON.stringify(mr));
          });
        }}>
        Meeting Room Test
      </button>
      {message}
      {calendar.isSupported()}
    </div>
  );
};

const ShareDeepLinkTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          const deepLinkParameters: ShareDeepLinkParameters = {
            subPageId: "ABCD",
            subPageLabel: "The Label",
            subPageWebUrl: "",
          };
          pages.shareDeepLink(deepLinkParameters);
        }}>
        Share Deep Link Test
      </button>
      {message}
    </div>
  );
};

const GetMeetingDetailsTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          meeting.getMeetingDetails((err, meetingDetails) => {
            if (err) {
              setMessage(`Error: ${JSON.stringify(err)}`);
            }
            setMessage(JSON.stringify(meetingDetails));
          });
        }}>
        Get Meeting Details
      </button>
      {message}
    </div>
  );
};

const GetClientAudioTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          const callback = (err, result) => {
            if (err) {
              setMessage(`Error: ${JSON.stringify(err)}`);
              return;
            }
            setMessage(JSON.stringify(result));
          };
          meeting.getIncomingClientAudioState(callback);
        }}>
        Get Incoming Client Audio State
      </button>
      {message}
    </div>
  );
};

const ToggleClientAudioTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          meeting.toggleIncomingClientAudio((err, results) => {
            if (err) {
              setMessage(JSON.stringify(err));
              return;
            }
            setMessage(JSON.stringify(results));
          });
        }}>
        Toggle Incoming Client Audio
      </button>
      {message}
    </div>
  );
};

const StageViewTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          stageView
            .open({
              appId: "5b81432a-7abc-41de-8cdc-86b9be1ab866",
              title: "Tom's Stage View",
              threadId: "",
              contentUrl: "https://collabtris.sparkworkspace.com/soundboard?direct=true",
            })
            .then(() => {
              setMessage("SUCCESS?");
            })
            .catch((...reason) => {
              setMessage(JSON.stringify(...reason));
            });
        }}>
        Stage View Test
      </button>
      {message}
    </div>
  );
};

const EnterFullscreenTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          pages.fullTrust.enterFullscreen();
        }}>
        Enter Fullscreen
      </button>
      {message}
    </div>
  );
};

const BarCodeTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          const callback = (err, decodedText) => {
            if (err) {
              setMessage(JSON.stringify(err));
              return;
            }
            setMessage(JSON.stringify(decodedText));
          };
          media.scanBarCode(callback);
        }}>
        Scan BarCode Test
      </button>
      {message}
    </div>
  );
};

const RegisterSpeakingEventHandlerTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const handler = (speakingState: meeting.ISpeakingState): void => {
    const res = `Speaking state changed to ${JSON.stringify(speakingState)}`;
    setMessage(res);
  };

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("Registered for speaking events");
          meeting.registerSpeakingStateChangeHandler(handler);
        }}>
        Register Speaking Handler
      </button>
      {message}
    </div>
  );
};

const RegisterHandStateRaisedEvent = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("Registering for Hand State Raised event?");
          meeting.registerRaiseHandStateChangedHandler((eventData) => {
            setMessage(JSON.stringify(eventData));
          });
        }}>
        Register Hand State Raised
      </button>
      {message}
    </div>
  );
};

const RegisterUnloadHandler = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("Registering for Hand State Raised event?");
          teamsCore.registerBeforeUnloadHandler(() => {
            console.log("inside registerBeforeUnloadHandler");
            setMessage("returned false");
            return false;
          });
        }}>
        Register Unload Handler
      </button>
      {message}
    </div>
  );
};

const ShowProfileTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("Showing profile?");
          var isSupported = profile.isSupported();
          console.log("isSupported", isSupported);
          let showProfileRequest: profile.ShowProfileRequest = {
            modality: "Card",
            persona: { identifiers: { Upn: "twhite@rightpoint.com" } },
            targetElementBoundingRect: new DOMRect(100, 100, 100, 100),
            triggerType: "MouseHover",
          };
          profile
            .showProfile(showProfileRequest)
            .then((res) => {
              setMessage("Opened profile");
            })
            .catch((err) => {
              setMessage("Error opening profile");
            });
        }}>
        Show Profile
      </button>
      {message}
    </div>
  );
};

const RegisterReactionEvents = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("Registering for registerMeetingReactionReceivedHandler");
          meeting.registerMeetingReactionReceivedHandler((eventData) => {
            setMessage(JSON.stringify(eventData));
          });
        }}>
        Register Meeting Reaction Received
      </button>
      {message}
    </div>
  );
};

const SearchTest = () => {
  const ctx = useGlobalStore2((state) => state.teamsContext);

  const [message, setMessage] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          console.log("Registering for Search events?");
          const isSupported = search.isSupported();
          console.log("isSupported", isSupported);

          search.registerHandlers(
            (query) => {
              console.log("FIRED ONCLOSE", query);
            },
            (query) => {
              console.log("FIRED ONEXECUTE", query);
            },
            (query) => {
              console.log("Fired onChange", query);
            }
          );
        }}>
        Search Test
      </button>
      {message}
    </div>
  );
};


const TokenTest = () => {
  const [token, setToken] = useState("");
  const [token2, setToken2] = useState("");

  useEffect(() => {
    getTeamsAuthToken()
      .then((token) => {
        setToken(token);
        let decoded = jwtDecode<{ oid: string }>(token);
        let url = process.env.NODE_ENV === "production" ? TEAMS_TOKEN_ENDPOINT : "http://localhost:2567/teamstoken";
        let body = JSON.stringify({ oid: decoded.oid });
        fetch(url, { method: "POST", body: body, headers: { Authorization: `Bearer ${token}` } })
          .then((r) => r.json())
          .then((resp) => {
            console.log("TEAMS TOKEN IS", resp);
            setToken2(JSON.stringify(resp));
          });
      })
      .catch((err) => {
        setToken(JSON.stringify(err));
      });
  }, []);
  return (
    <div>
      Teams Token:{token} | Token 2 {token2}
    </div>
  );
};

export function DebugScreen() {
  return (
    <div className={"p-2"}>
      <DebugTeamsContext />
      <TestThing />
      <AppHoverControl />
      <StartConversationButton />
      <CameraControlButton />
      <PurchaseExperience />
      <GetAppContentSharingCapability />
      <VideoFrameSetupTest />
      <VideoFrameTest />
      <VideoFrameNotifyTest />
      <CalendarTest />
      <UpdateSettingsTest />
      <PeopleTest />
      <ShareAppContent />
      <StopShareAppContent />
      <ShareDeepLinkTest />
      <GetMeetingDetailsTest />
      <GetClientAudioTest />
      <ToggleClientAudioTest />
      <StageViewTest />
      <ShareWebContentDeepLinkTest />
      <EnterFullscreenTest />
      <RegisterSpeakingEventHandlerTest />
      <BarCodeTest />
      <FullVideoTestSetup />
      <RegisterHandStateRaisedEvent />
      <RegisterReactionEvents />
      <ShowProfileTest />
      <SearchTest />
      <RegisterUnloadHandler />
      {/* <TokenTest /> */}
    </div>
  );
}
