import { BATCH_STATES, logger, merge, FILE_STATES } from "@rpldy/shared";
import { unwrap } from "@rpldy/simple-state";
import { UPLOADER_EVENTS } from "../consts";
import { getItemsPrepareUpdater } from "./preSendPrepare";
import { finalizeItem } from "./itemHelpers";
const prepareBatchStartItems = getItemsPrepareUpdater(UPLOADER_EVENTS.BATCH_START, batch => batch.items, null, function () {
  let {
    batch
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (batch) {
    throw new Error(`BATCH_START event handlers cannot update batch data. Only items & options`);
  }
});
const BATCH_READY_STATES = [BATCH_STATES.ADDED, BATCH_STATES.PROCESSING, BATCH_STATES.UPLOADING];
const BATCH_FINISHED_STATES = [BATCH_STATES.ABORTED, BATCH_STATES.CANCELLED, BATCH_STATES.FINISHED, BATCH_STATES.ERROR];

const getBatchFromState = (state, id) => state.batches[id].batch;

const getBatch = (queue, id) => {
  return getBatchFromState(queue.getState(), id);
};

const getBatchDataFromItemId = (queue, itemId) => {
  const state = queue.getState();
  const item = state.items[itemId];
  return state.batches[item.batchId];
};

const getBatchFromItemId = (queue, itemId) => {
  return getBatchDataFromItemId(queue, itemId).batch;
};

const removeBatchItems = (queue, batchId) => {
  const batch = getBatch(queue, batchId);
  batch.items.forEach(_ref => {
    let {
      id
    } = _ref;
    return finalizeItem(queue, id, true);
  });
};

const removeBatch = (queue, batchId) => {
  queue.updateState(state => {
    delete state.batches[batchId];
  });
};

const finalizeBatch = (queue, batchId, eventType) => {
  triggerUploaderBatchEvent(queue, batchId, eventType);
  triggerUploaderBatchEvent(queue, batchId, UPLOADER_EVENTS.BATCH_FINALIZE);
};

const cancelBatchForItem = (queue, itemId) => {
  const batch = getBatchFromItemId(queue, itemId),
        batchId = batch.id;
  logger.debugLog("uploady.uploader.batchHelpers: cancelling batch: ", {
    batch
  });
  queue.updateState(state => {
    const batch = getBatchFromState(state, batchId);
    batch.state = BATCH_STATES.CANCELLED;
  });
  finalizeBatch(queue, batchId, UPLOADER_EVENTS.BATCH_CANCEL);
  removeBatchItems(queue, batchId);
  removeBatch(queue, batchId);
};

const failBatchForItem = (queue, itemId, err) => {
  const batch = getBatchFromItemId(queue, itemId),
        batchId = batch.id;
  logger.debugLog("uploady.uploader.batchHelpers: failing batch: ", {
    batch
  });
  queue.updateState(state => {
    const batch = getBatchFromState(state, batchId);
    batch.state = BATCH_STATES.ERROR;
    batch.additionalInfo = err.message;
  });
  finalizeBatch(queue, batchId, UPLOADER_EVENTS.BATCH_ERROR);
  removeBatchItems(queue, batchId);
  removeBatch(queue, batchId);
};

const isNewBatchStarting = (queue, itemId) => {
  const batch = getBatchFromItemId(queue, itemId);
  return queue.getState().currentBatch !== batch.id;
};

const loadNewBatchForItem = (queue, itemId) => {
  const batch = getBatchFromItemId(queue, itemId);
  return prepareBatchStartItems(queue, batch).then(_ref2 => {
    let {
      cancelled
    } = _ref2;

    if (!cancelled) {
      queue.updateState(state => {
        state.currentBatch = batch.id;
      });
    }

    return !cancelled;
  });
};

const cleanUpFinishedBatches = queue => {
  //TODO: schedule clean up on requestIdle
  const state = queue.getState();
  Object.keys(state.batches).forEach(batchId => {
    const {
      batch,
      finishedCounter
    } = state.batches[batchId];
    const {
      orgItemCount
    } = batch; //shouldnt be the case, but if wasnt cleaned before, it will now

    const alreadyFinalized = getIsBatchFinalized(batch);

    if (orgItemCount === finishedCounter) {
      //batch may not be updated with completed/loaded with 100% values
      if (!alreadyFinalized && batch.completed !== 100) {
        queue.updateState(state => {
          const batch = getBatchFromState(state, batchId);
          batch.completed = 100;
          batch.loaded = batch.items.reduce((res, _ref3) => {
            let {
              loaded
            } = _ref3;
            return res + loaded;
          }, 0);
        }); //ensure we trigger progress event with completed = 100 for all items

        triggerUploaderBatchEvent(queue, batchId, UPLOADER_EVENTS.BATCH_PROGRESS);
      }

      queue.updateState(state => {
        const batch = getBatchFromState(state, batchId); //set batch state to FINISHED before triggering event and removing it from queue

        batch.state = alreadyFinalized ? batch.state : BATCH_STATES.FINISHED;

        if (state.currentBatch === batchId) {
          state.currentBatch = null;
        }
      });
      logger.debugLog(`uploady.uploader.batchHelpers: cleaning up batch: ${batch.id}`);

      if (!alreadyFinalized) {
        finalizeBatch(queue, batchId, UPLOADER_EVENTS.BATCH_FINISH);
      }

      removeBatchItems(queue, batchId);
      removeBatch(queue, batchId);
    }
  });
};

const triggerUploaderBatchEvent = (queue, batchId, event) => {
  const state = queue.getState(),
        batch = getBatchFromState(state, batchId),
        //get the most uptodate batch data
  stateItems = state.items;
  const eventBatch = { ...unwrap(batch),
    items: batch.items.map(_ref4 => {
      let {
        id
      } = _ref4;
      return unwrap(stateItems[id]);
    })
  };
  queue.trigger(event, eventBatch);
};

const getIsItemBatchReady = (queue, itemId) => {
  const batch = getBatchFromItemId(queue, itemId);
  return BATCH_READY_STATES.includes(batch.state);
};

const detachRecycledFromPreviousBatch = (queue, item) => {
  const {
    previousBatch
  } = item;

  if (item.recycled && previousBatch && queue.getState().batches[previousBatch]) {
    const {
      id: batchId
    } = getBatchFromItemId(queue, item.id);

    if (batchId === previousBatch) {
      queue.updateState(state => {
        const batch = getBatchFromState(state, batchId);
        const index = batch.items.findIndex(_ref5 => {
          let {
            id
          } = _ref5;
          return id === item.id;
        });

        if (~index) {
          batch.items.splice(index, 1);
        }
      });
    }
  }
};

const preparePendingForUpload = (queue, uploadOptions) => {
  queue.updateState(state => {
    //remove pending state from pending batches
    Object.keys(state.batches).forEach(batchId => {
      const batchData = state.batches[batchId];
      const {
        batch,
        batchOptions
      } = batchData;

      if (batch.state === BATCH_STATES.PENDING) {
        batch.items.forEach(item => {
          item.state = FILE_STATES.ADDED;
        });
        batch.state = BATCH_STATES.ADDED;
        batchData.batchOptions = merge({}, batchOptions, uploadOptions);
      }
    });
  });
};

const removePendingBatches = queue => {
  const batches = queue.getState().batches;
  Object.keys(batches).filter(batchId => batches[batchId].batch.state === BATCH_STATES.PENDING).forEach(batchId => {
    removeBatchItems(queue, batchId);
    removeBatch(queue, batchId);
  });
};

const incrementBatchFinishedCounter = (queue, batchId) => {
  queue.updateState(state => {
    state.batches[batchId].finishedCounter += 1;
  });
};

const getIsBatchFinalized = batch => BATCH_FINISHED_STATES.includes(batch.state);

export { loadNewBatchForItem, isNewBatchStarting, cancelBatchForItem, getBatchFromItemId, getBatchDataFromItemId, cleanUpFinishedBatches, triggerUploaderBatchEvent, getIsItemBatchReady, getBatchFromState, detachRecycledFromPreviousBatch, preparePendingForUpload, removePendingBatches, incrementBatchFinishedCounter, getIsBatchFinalized, failBatchForItem };