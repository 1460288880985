import create from "zustand";

export type PlayerApi = {
    isWalking: boolean;
    walk: (isWalking: boolean) => void;


}

export const createPlayerApi = () => create<PlayerApi>((set, get) => {
    return ({
        isWalking: false,
        walk: (isWalking) => set({ isWalking: isWalking }),
       
    })
})