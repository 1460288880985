import create from "zustand";
import { Howl, Howler } from "howler";

type SoundManagerState = {
  soundIsOn: boolean;
  toggleSound: (toggle: boolean) => void;
  playSound: (soundUrl: string) => void;

  backgroundMusicSound: any;
  backgroundMusicIsPlaying: boolean;
  startBackgroundMusic: (soundUrl: string) => void;
  stopBackgroundMusic: () => void;
};

export const useSoundManager = create<SoundManagerState>((set, get) => ({
  soundIsOn: true,
  toggleSound: () =>
    set((state) => {
      !state.soundIsOn ? Howler.mute(false) : Howler.mute(true);
      return { soundIsOn: !state.soundIsOn };
    }),
  playSound: (soundUrl) => {
    console.log("PLAYING SOUND");
    var sound = new Howl({
      src: [soundUrl],
      html5: true,
    });

    sound.play();
  },

  backgroundMusicSound: null,
  backgroundMusicIsPlaying: false,
  startBackgroundMusic: (musicUrl) => {
    if (get().backgroundMusicIsPlaying) {
      return;
    }
    var sound = new Howl({
      src: [musicUrl],
      html5: true,
    });

    sound.play();
    return set({ backgroundMusicIsPlaying: true, backgroundMusicSound: sound });
  },
  stopBackgroundMusic: () => {
    let currentBackgroundSound = get().backgroundMusicSound;
    currentBackgroundSound && currentBackgroundSound.stop();
    return set({ backgroundMusicIsPlaying: false, backgroundMusicSound: null });
  },
}));
