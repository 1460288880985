import addLife, { createLifePack } from "@rpldy/life-events";
import { BATCH_STATES, invariant, logger, triggerCancellable, devFreeze, merge, clone } from "@rpldy/shared";
import getProcessor from "./processor";
import { UPLOADER_EVENTS } from "./consts";
import { getMandatoryOptions, deepProxyUnwrap } from "./utils";
const EVENT_NAMES = Object.values(UPLOADER_EVENTS);
const EXT_OUTSIDE_ENHANCER_TIME = "Uploady - uploader extensions can only be registered by enhancers",
      EXT_ALREADY_EXISTS = "Uploady - uploader extension by this name [%s] already exists";
let counter = 0;

const createUploader = options => {
  counter += 1;
  const uploaderId = `uploader-${counter}`;
  let enhancerTime = false;
  const extensions = {};
  logger.debugLog(`uploady.uploader: creating new instance (${uploaderId})`, {
    options,
    counter
  });
  let uploaderOptions = getMandatoryOptions(options);

  const update = updateOptions => {
    //TODO: updating concurrent and maxConcurrent means we need to update the processor - not supported yet!
    uploaderOptions = merge({}, uploaderOptions, updateOptions); //need deep merge for destination

    return uploader;
  };

  const add = (files, addOptions) => {
    const processOptions = merge({}, uploaderOptions, addOptions);

    if (processOptions.clearPendingOnAdd) {
      clearPending();
    }

    return processor.addNewBatch(files, uploader.id, processOptions).then(batch => {
      let resultP;

      if (batch.items.length) {
        resultP = processor.runCancellable(UPLOADER_EVENTS.BATCH_ADD, batch, processOptions).then(isCancelled => {
          if (!isCancelled) {
            logger.debugLog(`uploady.uploader [${uploader.id}]: new items added - auto upload =
                        ${String(processOptions.autoUpload)}`, batch.items);

            if (processOptions.autoUpload) {
              processor.process(batch);
            }
          } else {
            batch.state = BATCH_STATES.CANCELLED;
            triggerWithUnwrap(UPLOADER_EVENTS.BATCH_CANCEL, batch);
          }
        });
      } else {
        logger.debugLog(`uploady.uploader: no items to add. batch ${batch.id} is empty. check fileFilter if this isn't intended`);
      }

      return resultP;
    });
  };

  const clearPending = () => {
    processor.clearPendingBatches();
  };
  /**
   * process batches that weren't auto-uploaded
   */


  const getOptions = () => {
    return clone(uploaderOptions);
  };

  const registerExtension = (name, methods) => {
    invariant(enhancerTime, EXT_OUTSIDE_ENHANCER_TIME);
    invariant(!extensions[name], EXT_ALREADY_EXISTS, name);
    logger.debugLog(`uploady.uploader: registering extension: ${name.toString()}`, methods);
    extensions[name] = methods;
  };

  let {
    trigger,
    target: uploader
  } = addLife({
    id: uploaderId,
    update,
    add,
    upload: uploadOptions => {
      processor.processPendingBatches(uploadOptions);
    },
    abort: id => {
      processor.abort(id);
    },
    abortBatch: id => {
      processor.abortBatch(id);
    },
    getOptions,
    clearPending,
    registerExtension,
    getExtension: name => {
      return extensions[name];
    }
  }, EVENT_NAMES, {
    canAddEvents: false,
    canRemoveEvents: false
  });
  /**
   * ensures that data being exposed to client-land isnt a proxy, only pojos
   */

  const triggerWithUnwrap = function (name) {
    for (var _len = arguments.length, data = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      data[_key - 1] = arguments[_key];
    }

    //delays unwrap to the very last time on trigger. Will only unwrap if there are listeners
    const lp = createLifePack(() => data.map(deepProxyUnwrap));
    return trigger(name, lp);
  };

  const cancellable = triggerCancellable(triggerWithUnwrap);

  if (uploaderOptions.enhancer) {
    enhancerTime = true;
    const enhanced = uploaderOptions.enhancer(uploader, triggerWithUnwrap);
    enhancerTime = false; //graceful handling for enhancer forgetting to return uploader

    uploader = enhanced || uploader;
  }

  const processor = getProcessor(triggerWithUnwrap, cancellable, uploaderOptions, uploader.id);
  return devFreeze(uploader);
};

export default createUploader;