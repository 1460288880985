// © Microsoft Corporation. All rights reserved.

import React, { useCallback, useEffect, useState, useRef } from "react";

import { RemoteVideoStream, VideoStreamRenderer, VideoStreamRendererView } from "@azure/communication-calling";

export interface RemoteStreamMediaProps {
  label: string;
  stream: RemoteVideoStream | undefined;
  isParticipantStreamSelected: boolean;
}

export const RemoteStreamMedia = (props: RemoteStreamMediaProps): JSX.Element => {
  console.log("BEGIN REMOTERENDER");
  const rendererView = useRef<VideoStreamRendererView | undefined>();

  const streamId = props.stream
    ? `${props.label}-${props.stream.id}-${props.stream.mediaStreamType}`
    : `${props.label} - no stream`;

  const [activeStreamBeingRendered, setActiveStreamBeingRendered] = useState(false);
  const [showRenderLoading, setShowRenderLoading] = useState(false);

  //   const imageProps = {
  //     src: staticMediaSVG.toString(),
  //     imageFit: ImageFit.contain,
  //     styles: {
  //       root: {
  //         width: "100%",
  //         height: "100%",
  //         display: activeStreamBeingRendered ? "none" : "block",
  //       },
  //     },
  //   };

  //   const loadingStyle = {
  //     height: "100%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   };
  const { label, stream, isParticipantStreamSelected } = props;

  const renderRemoteStream = useCallback(async () => {
    console.log("STREAM AVAILABILITY CHANGED", stream?.isAvailable);
    const container = document.getElementById(streamId);
    console.log("CONTAINER IS", container);

    if (container && stream && stream.isAvailable && isParticipantStreamSelected) {
      // if we are already rendering a stream we don't want to start rendering the same stream
      if (activeStreamBeingRendered) {
        return;
      }

      // set the flag that a stream is being rendered
      setActiveStreamBeingRendered(true);
      setShowRenderLoading(true);
      const renderer: VideoStreamRenderer = new VideoStreamRenderer(stream);
      // this can block a really long time if we fail to be subscribed to the call and it has to retry
      rendererView.current = await renderer.createView({ scalingMode: "Crop" });
      setShowRenderLoading(false);
      if (container && container.childElementCount === 0) {
        container.appendChild(rendererView.current.target);
      }
    } else {
      setActiveStreamBeingRendered(false);

      if (rendererView.current) {
        rendererView.current.dispose();
        rendererView.current = undefined;
      }
    }
  }, [
    stream,
    isParticipantStreamSelected,
    setShowRenderLoading,
    setActiveStreamBeingRendered,
    activeStreamBeingRendered,
    rendererView,
    streamId,
  ]);

  useEffect(() => {
    if (!stream) {
      console.log("STREAM IS NULL?");
      return;
    }

    stream.on("isAvailableChanged", renderRemoteStream);
    console.log("AFTER SETUP AVAILABLE CHANGE", props);
    if (stream.isAvailable) {
      console.log("STREAM IS AVAILABLE?");
      renderRemoteStream();
    }
    console.log("AT THE BOTTOM");
  }, [stream, isParticipantStreamSelected, renderRemoteStream]);

  return (
    <div className={"w-48 h-48"}>
      <div
        className={"mediaContainer"}
        style={{ position: "relative", height: "100%", width: "100%", background: "transparent" }}>
        <div style={{ display: activeStreamBeingRendered ? "block" : "none" }} id={streamId}>
          {showRenderLoading && <div>Loading...</div>}
        </div>
        {/* <Image {...imageProps} />
      <Label className={videoHint}>{label}</Label> */}
      </div>
    </div>
  );
};
