import React, { useEffect, useRef, useState } from "react";
import { ACSState } from "../../../state/ACSState";
import { useColyseusRoom } from "../../hooks/useColyseusRoom";
import { useSoundboardStore } from "./useSoundboardStore";
import { useCallingStore, useCallingStore2 } from "./useCallingStore";
import {
  Features,
  LocalAudioStream,
  LocalVideoStream,
  RaiseHandCallFeature,
  RemoteParticipant,
  VideoStreamRenderer,
  VideoStreamRendererView,
  VideoEffect,
  BackgroundBlurEffect,
} from "@azure/communication-calling";
import { RemoteStreamMedia } from "./RemoteStreamMedia";
import { OPENAIIMAGE_ENDPOINT, OPENAI_ENDPOINT, SOUNDBOARD_DATA_ENDPOINT, SPEECHTOKEN_ENDPOINT } from "../../Constants";
import { P5Experience } from "../../experiences/P5/P5";
import ReactPlayer from "react-player";
import { fabric } from "fabric";
import { SpeechSynthesisRecorder } from "./SpeechSynthesisRecorder";
import {
  AudioConfig,
  AudioOutputStream,
  IntentRecognizer,
  KeywordRecognitionModel,
  LanguageUnderstandingModel,
  PullAudioInputStream,
  PullAudioOutputStream,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
  SpeechSynthesisOutputFormat,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { useSpeechRecognitionStore } from "./useSpeechRecognitionStore";
import { App } from "./store/App";
import { startVisualizer } from "../../experiences/Soundboard/SoundVisualizer";
import { Dragon } from "../../experiences/Dragon/Dragon";
import { Planet } from "../../experiences/Planet/Planet";
import shallow from "zustand";
import { Lightbulb } from "./Lightbulb";
import { ParticleVisualizer } from "../ParticleVisualizer/ParticleVisualizer";
import { BuiltInPrompts } from "./Prompts";
import { useChatUIStore } from "./useChatUIStore";
import { HangUpIcon, MuteButton } from "./CallControls";
import ReactMarkdown from "react-markdown";
import { meeting } from "@microsoft/teams-js";
import { useMicrosoftTeams } from "../../hooks/useMicrosoftTeams";
import { useHotkeys } from "react-hotkeys-hook";
import emoji from "remark-emoji";
import { TypingIndicator } from "./TypingIndicator";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import Canvas from "../Wave/WaveCanvas";
import { AddToPromptBuilder } from "./PromptBuilder/AddToPromptBuilder";
import remarkMermaid from "remark-mermaidjs/browser";
import { useTeamsCaptionsStore } from "./useTeamsCaptionsStore";
import html2canvas from "html2canvas";
import { useAzureLogger } from "../../hooks/useAzureLogger";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

function ShoutConnect() {
  const [joinUrl, setJoinUrl] = useState("");
  const [name, setName] = useState("Steve Jobs");

  const [showConnectButton, setShowConnectButton] = useState(false);

  const callingApi = useCallingStore((state) => state.api);
  const call = useCallingStore((state) => state.call);

  const availableCameras = useCallingStore((state) => state.availableCameras);
  const availableMicrophones = useCallingStore((state) => state.availableMicrophones);

  useEffect(() => {
    if (call) {
      console.log("MUTE INCOMING");
      call.muteIncomingAudio();
    }
  }, [call]);
  const isJoinUrlValid = (url) => {
    if (url.indexOf("https://teams.microsoft.com/l/meetup-join/") !== -1) {
      return true;
    } else return false;
  };
  return (
    <div className={"flex items-center justify-center h-screen"}>
      <div>
        <div>
          <h1 className={"text-lg"}>Bicycle of the Mind</h1>
        </div>
        <div className={"pt-2 text-xs"}>inspire your meeting with a visit from a legend</div>
        <ReactPlayer
          url={
            "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Bicycle%20of%20the%20Mind.mp4?sv=2019-12-12&st=2022-11-21T17%3A11%3A15Z&se=2092-01-22T17%3A11%3A00Z&sr=b&sp=r&sig=iPSNc%2FvIrQoOn79c7x%2FtjUiBdk43u4v9MwcvQ2fp7WU%3D"
          }
          muted={false}
          playing={false}
          controls={true}
          onSeek={(seconds) => {}}
        />
        <div className={"pt-2"}>
          <input
            type="text"
            placeholder="Enter the Join Url for a Teams meeting"
            className="input input-sm input-bordered w-80"
            value={joinUrl}
            onChange={(e) => {
              setJoinUrl(e.target.value);
              if (isJoinUrlValid(e.target.value)) {
                setShowConnectButton(true);
              }
            }}></input>
        </div>
        {/* 
        <div className={"pt-2"}>
          <input
            type="text"
            placeholder="give it a name"
            className="input input-sm input-bordered w-80"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}></input>
        </div> */}

        {/* <div className="dropdown">
          <label tabIndex={0} className="btn btn-xs m-1">
            Select Camera
          </label>
          <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            {availableCameras?.map((ac) => {
              return (
                <li
                  onClick={() => {
                    callingApi.setSelectedCamera(ac);
                    document.activeElement?.blur();
                  }}>
                  <a>{ac.name}</a>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="dropdown">
          <label tabIndex={0} className="btn btn-xs m-1">
            Select Microphone
          </label>
          <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            {availableMicrophones?.map((ac) => {
              return (
                <li
                  onClick={() => {
                    callingApi.setSelectedMicrophone(ac);
                    document.activeElement?.blur();
                  }}>
                  <a>{ac.name}</a>
                </li>
              );
            })}
          </ul>
        </div> */}

        <div className="pt-2">
          {/* {!showConnectButton && <div className="btn btn-primary btn-xs btn-disabled">Connect</div>}
          {showConnectButton && (
            <div
              className="btn btn-primary btn-xs"
              onClick={() => {
                callingApi.joinCall(joinUrl, name);
              }}>
              Connect
            </div>
          )} */}

          {showConnectButton && (
            <div
              className="btn btn-primary btn-xs"
              onClick={async () => {
                await callingApi.joinCall(joinUrl);
                setTimeout(async () => {
                  await callingApi.createVideoElement(
                    "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Bicycle%20of%20the%20Mind.mp4?sv=2019-12-12&st=2022-11-21T17%3A11%3A15Z&se=2092-01-22T17%3A11%3A00Z&sr=b&sp=r&sig=iPSNc%2FvIrQoOn79c7x%2FtjUiBdk43u4v9MwcvQ2fp7WU%3D",
                    async () => {
                      await callingApi.hangUpCall();
                    }
                  );
                }, 3000);
              }}>
              GO
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

let rendererView: VideoStreamRendererView | undefined;

function VideoLocalPreview() {
  const selectedCamera = useCallingStore((state) => state.selectedCamera);
  const localVideoStream = useCallingStore((state) => state.localVideoStream);

  const callingApi = useCallingStore((state) => state.api);

  useEffect(() => {
    if (selectedCamera) {
      const stream = new LocalVideoStream(selectedCamera);
      callingApi.setLocalVideoStream(stream);
    }
  }, [selectedCamera]);

  useEffect(() => {
    (async () => {
      if (localVideoStream) {
        const renderer: VideoStreamRenderer = new VideoStreamRenderer(localVideoStream);
        rendererView = await renderer.createView({ scalingMode: "Crop" });

        const container = document.getElementById("localPreview");

        if (container && container.childElementCount === 0) {
          container.appendChild(rendererView.target);
        }
      }
    })();

    return () => {
      if (rendererView) {
        rendererView.dispose();
        rendererView = undefined;
      }
    };
  }, [localVideoStream]);

  if (selectedCamera) {
    return (
      <div>
        <div
          className={"btn btn-xs"}
          onClick={() => {
            callingApi.setLocalVideoStream(null);
          }}>
          Stop Local Video
        </div>
        <div
          className={"btn btn-xs"}
          onClick={() => {
            callingApi.stopVideo();
          }}>
          Stop Video
        </div>
        <div
          className={"btn btn-xs"}
          onClick={() => {
            callingApi.setLocalVideoStreamToSelectedCamera();
          }}>
          Start Video
        </div>
        <div
          id="localPreview"
          style={{
            maxWidth: "25rem",
            minWidth: "12.5rem",
            width: "100%",
            height: "100%",
            maxHeight: "18.75rem",
            minHeight: "12.5rem",
          }}>
          Camera was selected
        </div>
      </div>
    );
  }
  return (
    <div>
      Local Preview
      <div
        className={"btn btn-xs"}
        onClick={() => {
          callingApi.setLocalVideoStream(null);
        }}>
        Stop Video
      </div>
    </div>
  );
}

export function SoundboardButtons() {
  let [sbData, setSbData] = useState<Array<any>>();
  let callingApi = useCallingStore((state) => state.api);
  let call = useCallingStore((state) => state.call);

  useEffect(() => {
    let url = process.env.NODE_ENV === "production" ? SOUNDBOARD_DATA_ENDPOINT : "http://localhost:2567/soundboard";

    fetch(url, { method: "GET" }).then(async (sbData) => {
      let json = await sbData.json();
      console.log("SBDATA", sbData.body);
      let filteredData = json.filter((t) => t.url.indexOf(".mp4") !== -1);
      setSbData(filteredData);
    });
  }, []);

  return (
    <div>
      {sbData?.map((item) => {
        return (
          <div
            className={"btn btn-xs"}
            onClick={async () => {
              await callingApi.createVideoElement(item.url, () => {
                call?.hangUp();
              });
            }}>
            {item.name}
          </div>
        );
      })}
      {/* {sbData?.filter((item) => {
        return item.url.indexOf(".mp4") !== -1;
      })} */}
    </div>
  );
}

const VolumeSlider = () => {
  const callingApi = useCallingStore((state) => state.api);
  const currentVolumeLevel = useCallingStore((state) => state.volumeLevel);
  const callIsConnected = useCallingStore((state) => state.callIsConnected);

  if (!callIsConnected) return null;

  return (
    <div className={"w-40"}>
      <input
        onChange={(e) => {
          callingApi.setVolumeLevel(e.target.valueAsNumber);
        }}
        type="range"
        min="0.0"
        max="1"
        value={currentVolumeLevel}
        className="range range-primary range-xs"
        step={0.05}
      />
    </div>
  );
};

export type PageName = "home" | "configuration" | "call" | "callended";

export function Inspire() {
  const [page, setPage] = useState<PageName>("configuration");

  const callIsConnected = useCallingStore((state) => state.callIsConnected);
  const callingApi = useCallingStore((state) => state.api);
  const remoteParticipants = useCallingStore((state) => state.remoteParticipants);
  const call = useCallingStore((state) => state.call);

  useEffect(() => {
    callIsConnected && setPage("call");
  }, [callIsConnected]);

  const init = async () => {
    await callingApi.initCallClient();
    await callingApi.initCallAgent("Steve Jobs");
    await callingApi.loadAvailableCamerasAndMicrophones();
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {callIsConnected ? <div>Connected</div> : <ShoutConnect />}
      <canvas className={"scale-50"} id="renderTarget"></canvas>
      <VideoLocalPreview />
      {/* <SoundboardButtons /> */}

      <div
        className={"btn btn-xs"}
        onClick={() => {
          call?.hangUp();
        }}>
        Hang up call
      </div>

      <div
        className={"btn btn-xs"}
        onClick={() => {
          callingApi.muteOutgoingAudio();
        }}>
        Mute Outgoing Audio
      </div>

      <div
        className={"btn btn-xs"}
        onClick={() => {
          callingApi.unmuteOutgoingAudio();
        }}>
        Unmute Outgoing Audio
      </div>
      <VolumeSlider />

      {remoteParticipants?.length}
      <div className={"flex"}>
        {remoteParticipants?.map((rp) => {
          console.log("MAPPING REMOTE PARTICIAPANTS", rp);
          return (
            <RemoteStreamMedia
              label={rp.identifier.rawId ? rp.identifier.rawId : rp.identifier.communicationUserId}
              stream={rp.videoStreams[0]}
              isParticipantStreamSelected={true}
            />
          );
        })}
      </div>
    </div>
  );
}

export type PageState = "home" | "configuration" | "call" | "callended";

function ShoutConnect2() {
  const [joinUrl, setJoinUrl] = useState("");
  const name = useCallingStore((state) => state.botDisplayName);

  const [showConnectButton, setShowConnectButton] = useState(false);
  const [showCallButton, setShowCallButton] = useState(false);

  const callingApi = useCallingStore((state) => state.api);
  const call = useCallingStore((state) => state.call);
  const callIsConnected = useCallingStore((state) => state.callIsConnected);

  const availableCameras = useCallingStore((state) => state.availableCameras);
  const availableMicrophones = useCallingStore((state) => state.availableMicrophones);

  useEffect(() => {
    if (call && callIsConnected) {
      // console.log("MUTE INCOMING");
      // call.muteIncomingAudio();
      // console.log("MUTE OUTGOING");
      // call.mute();
    }
  }, [call, callIsConnected]);
  const isJoinUrlValid = (url) => {
    if (url.indexOf("https://teams.microsoft.com/l/meetup-join/") !== -1) {
      return true;
    } else return false;
    return true;
  };

  const isPhoneNumber = (input) => {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input);
  };

  return (
    <div>
      <div className={"flex items-center justify-center h-screen"}>
        <div>
          <div className="chat chat-start">
            <div
              className="chat-bubble chat-bubble-primary"
              style={{ paddingTop: "1rem", paddingLeft: "1rem", paddingBottom: "1rem", paddingRight: "1rem" }}>
              <div className={"text-xs"}>Meeting AI is here! </div>
            </div>
          </div>
          <div>
            <h1 className={"text-lg"}>Connect Meeting AI</h1>
          </div>
          <div className={"pt-2 text-xs"}>add a GPT3 powered AI to your meeting</div>

          <div className={"pt-2"}>
            <input
              type="text"
              placeholder="Enter the Join Url for a Teams meeting"
              className="input input-sm input-bordered w-80"
              value={joinUrl}
              onChange={(e) => {
                setJoinUrl(e.target.value);
                if (isJoinUrlValid(e.target.value)) {
                  setShowConnectButton(true);
                }

                if (isPhoneNumber(e.target.value)) {
                  setShowCallButton(true);
                }
              }}></input>
          </div>

          <div className={"pt-2"}>
            <input
              type="text"
              placeholder="Enter name"
              className="input input-sm input-bordered w-80"
              value={name}
              onChange={(e) => {
                callingApi.setBotDisplayName(e.target.value);
              }}></input>
          </div>
          {/* 
        <div className={"pt-2"}>
          <input
            type="text"
            placeholder="give it a name"
            className="input input-sm input-bordered w-80"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}></input>
        </div> */}

          {/* <div className="dropdown">
          <label tabIndex={0} className="btn btn-xs m-1">
            Select Camera
          </label>
          <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            {availableCameras?.map((ac) => {
              return (
                <li
                  onClick={() => {
                    callingApi.setSelectedCamera(ac);
                    document.activeElement?.blur();
                  }}>
                  <a>{ac.name}</a>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="dropdown">
          <label tabIndex={0} className="btn btn-xs m-1">
            Select Microphone
          </label>
          <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            {availableMicrophones?.map((ac) => {
              return (
                <li
                  onClick={() => {
                    callingApi.setSelectedMicrophone(ac);
                    document.activeElement?.blur();
                  }}>
                  <a>{ac.name}</a>
                </li>
              );
            })}
          </ul>
        </div> */}

          <div className="pt-2">
            {/* {!showConnectButton && <div className="btn btn-primary btn-xs btn-disabled">Connect</div>}
          {showConnectButton && (
            <div
              className="btn btn-primary btn-xs"
              onClick={() => {
                callingApi.joinCall(joinUrl, name);
              }}>
              Connect
            </div>
          )} */}

            {showConnectButton && (
              <div
                className="btn btn-primary btn-xs"
                onClick={async () => {
                  await callingApi.initCallAgent(name);
                  await callingApi.joinCall(joinUrl);
                  // setTimeout(async () => {
                  //   await callingApi.createVideoElement(
                  //     "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Bicycle%20of%20the%20Mind.mp4?sv=2019-12-12&st=2022-11-21T17%3A11%3A15Z&se=2092-01-22T17%3A11%3A00Z&sr=b&sp=r&sig=iPSNc%2FvIrQoOn79c7x%2FtjUiBdk43u4v9MwcvQ2fp7WU%3D",
                  //     async () => {
                  //       await callingApi.hangUpCall();
                  //     }
                  //   );
                  // }, 3000);
                }}>
                GO
              </div>
            )}
            {showCallButton && (
              <div
                className="btn btn-primary btn-xs"
                onClick={async () => {
                  // await callingApi.initCallAgent(name);
                  await callingApi.startCallWithPhoneNumber(joinUrl);
                }}>
                Make call
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function RemoteParticipantTracker() {
  const remoteParticipants = useCallingStore((state) => state.remoteParticipants);
  const speechRecognitionApi = useSpeechRecognitionStore((state) => state.api);
  // const rps = useCallingStore((state) => Object.keys(state.remoteParticipants), shallow)
  // const { remoteParticipants } = useCallingStore(
  //   (state) => ({ remoteParticipants: state.remoteParticipants }),
  //   shallow
  // );
  // const compare = (oldRp, newRp) => {
  //   console.log(oldRp, newRp);
  //   debugger;
  //   return newRp;
  // };
  // const remoteParticipants = useCallingStore(
  //   (state) => state.remoteParticipants,
  //   (oldTreats, newTreats) => compare(oldTreats, newTreats)
  // );
  const [twIsSpeaking, setTwIsSpeaking] = useState<boolean>(false);
  const isSpeakingRef = useRef<boolean>();
  useEffect(() => {
    const tw = remoteParticipants?.find((rp) => {
      return rp.displayName === "Tom White";
    });
    if (tw) {
      if (isSpeakingRef.current === true && !tw.isSpeaking) {
        console.log("Tom was speaking but he has now stopped!");
      }
      if (tw.isSpeaking) {
        isSpeakingRef.current = true;
        // speechRecognitionApi.startSingleSpeechRecognition();
      }
      if (!tw.isSpeaking) {
        isSpeakingRef.current = false;
      }
    }
  }, [remoteParticipants]);

  useEffect(() => {}, [twIsSpeaking]);
  return (
    <div>
      <div className={"text-primary"}>Remote Participants</div>
      <div>
        {remoteParticipants?.map((rp) => {
          return (
            <div>
              {rp.displayName} - {JSON.stringify(rp.isSpeaking)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function RenderCanvas() {
  const ref = useRef<any>();
  const [height, setHeight] = useState(800);
  const [width, setWidth] = useState(800);

  useEffect(() => {
    ref.current.height = height;
    ref.current.width = width;
  }, [height, width]);
  return (
    <div>
      <div className={"btn btn-primary btn-sx"}></div>
      <input
        type={"text"}
        onChange={(e) => {
          setHeight(e.target.value);
        }}
      />
      <input
        type={"text"}
        onChange={(e) => {
          setWidth(e.target.value);
        }}
      />
      <canvas ref={ref} style={{ border: "1px solid green" }} id="renderTarget"></canvas>
    </div>
  );
}

// export function VideoEffectsFeature(){
//     const call = useCallingStore(state => state.call);

//   useEffect(() => {
//     if (!call) return;
//     const vf = call.localVideoStreams[0].feature(Features.VideoEffects)
//     let ve = VideoEffect
//     vf.startEffects(BackgroundBlurEffect.configure())
//   }, [call])
//   return <div>Video Effects</div>
// }
export function CustomTeamsClient() {
  const hideUglyStuff = useGlobalStore2((state) => state.hideUglyStuff);

  return (
    <div>
      <CustomTeamsClientInner />
      {/* <GridLayoutExample /> */}
      <RenderCanvas />

      {!hideUglyStuff && (
        <div>
          <RemoteParticipantTracker />
          {/* <canvas style={{ border: "1px solid white" }} width={800} height={800} id="renderTarget"></canvas> */}
          <canvas style={{ border: "1px solid white" }} id="fabricCanvas" width={800} height={800}></canvas>
        </div>
      )}
    </div>
  );
}

export function MeetingAI() {
  const hideUglyStuff = useGlobalStore2((state) => state.hideUglyStuff);

  return (
    <div>
      <CustomTeamsClientInner />
      {/* {!hideUglyStuff && (
        <div>
          <canvas style={{ border: "1px solid white" }} width={300} height={300} id="renderTarget"></canvas>
          <canvas style={{ border: "1px solid white" }} id="fabricCanvas" width={300} height={300}></canvas>
        </div>
      )} */}
    </div>
  );
}

const getCognitiveServicesToken = () => {
  return new Promise((resolve, reject) => {
    let url = process.env.NODE_ENV === "production" ? SPEECHTOKEN_ENDPOINT : "http://localhost:2567/get-speech-token";

    fetch(url, { method: "GET" })
      .then((r) => r.json())
      .then((resp) => {
        return resolve(resp);
      });
  });
};

const speakTheText = async (inputText, cognitiveContext, audioContext, call) => {
  return new Promise<void>((resolve, reject) => {
    try {
      const speechConfig = SpeechConfig.fromAuthorizationToken(cognitiveContext.token, cognitiveContext.region);

      speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
      speechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat.Webm16Khz16BitMonoOpus;

      var synthesizer = new SpeechSynthesizer(speechConfig, null);

      synthesizer.speakTextAsync(
        inputText,
        async (result) => {
          try {
            const audioData = result.audioData;
            console.log(`Audio data byte size: ${audioData.byteLength}.`);
            const decodedData = await audioContext?.decodeAudioData(audioData);
            const destNode = audioContext?.createMediaStreamDestination();
            const source = new AudioBufferSourceNode(audioContext, { buffer: decodedData });
            source.connect(destNode);
            source.start();
            source.onended = () => {
              resolve();
            };
            let track = destNode.stream.getAudioTracks()[0];
            const localAudioStream = new LocalAudioStream(track);
            await call?.startAudio(localAudioStream);
            synthesizer.close();
          } catch (err) {
            synthesizer.close();
            reject(err);
          }
        },
        (error) => {
          console.log(error);
          synthesizer.close();
          reject(error);
        }
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

const speakTheText2 = async (inputText, cognitiveContext, audioContext: AudioContext, call, visualizerRef) => {
  return new Promise<{ source: AudioBufferSourceNode }>((resolve, reject) => {
    try {
      const speechConfig = SpeechConfig.fromAuthorizationToken(cognitiveContext.token, cognitiveContext.region);

      speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
      speechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat.Webm24Khz16Bit24KbpsMonoOpus; //SpeechSynthesisOutputFormat.Webm16Khz16BitMonoOpus;

      var synthesizer = new SpeechSynthesizer(speechConfig, null);

      synthesizer.speakSsmlAsync(
        inputText,
        async (result) => {
          const audioData = result.audioData;
          console.log(`Audio data byte size: ${audioData.byteLength}.`);

          const decodedData = await audioContext?.decodeAudioData(audioData);
          const destNode = audioContext?.createMediaStreamDestination();
          const source = new AudioBufferSourceNode(audioContext, { buffer: decodedData });

          if (visualizerRef && visualizerRef.current) {
            const visualizer = visualizerRef.current;
            visualizer.connectSound(source);
          }

          source.connect(destNode);
          console.log("MEDIASTREAM", destNode.stream instanceof MediaStream);
          let track = destNode.stream.getAudioTracks()[0];
          const localAudioStream = new LocalAudioStream(track);
          await call?.startAudio(localAudioStream);
          console.log("after start audio");
          synthesizer.close();
          resolve({ source: source });
        },
        (error) => {
          console.log(error);
          synthesizer.close();
          reject(error);
        }
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

const askChatGPT = (textToAsk) => {
  // const modifiedText = `Respond to this text in one sentence: ${textToAsk}`;
  let payload = {
    content: `Respond to this text in one sentence: ${textToAsk}`,
  };
  if (chatGPTConversationId) {
    payload.conversation_id = chatGPTConversationId;
  }

  if (chatGPTParentId) {
    payload.parent_id = chatGPTParentId;
  }

  return new Promise((resolve, reject) => {
    fetch("https://tunnel.sparkworkspace.com/api/ask", {
      method: "POST",
      headers: {
        Authorization: "<API_KEY>",
      },
      body: JSON.stringify(payload),
    })
      .then(async (resp) => {
        return await resp.json();
      })
      .then((resp) => {
        console.log(resp);
        let conversation_id = resp.conversation_id;
        let content = resp.content;
        let parent_id = resp.response_id;
        resolve({ conversation_id, content, parent_id });
      });
  });
};

const askOpenAI = (textToAsk, modelName) => {
  // const modifiedText = `Respond to this text in one sentence: ${textToAsk}`;
  let payload = {
    prompt: `${textToAsk}`,
    model: modelName,
  };

  let url = process.env.NODE_ENV === "production" ? OPENAI_ENDPOINT : "http://localhost:2567/openai";
  console.log(`Asking OpenAI: `, JSON.stringify(payload));
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (resp) => {
        return await resp.json();
      })
      .then((resp) => {
        console.log(resp);

        resolve(resp);
      });
  });
};

const getOpenAIImage = (prompt, size) => {
  let url = process.env.NODE_ENV === "production" ? OPENAIIMAGE_ENDPOINT : "http://localhost:2567/openaiimage";

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: prompt, size: size, n: 3 }),
    })
      .then(async (resp) => {
        return await resp.json();
      })
      .then((resp) => {
        console.log(resp);

        resolve(resp);
      });
  });
};

function DtmfToneSender() {
  const callingApi = useCallingStore((state) => state.api);
  let [tonesToSend, setTonesToSend] = useState("");
  let [isNumber, setIsNumbers] = useState();

  return (
    <div>
      <div>Send Dtmf Tones</div>
      <input
        type={"text"}
        onChange={(e) => {
          setTonesToSend(e.target.value);
        }}></input>
      <div
        className={"btn btn-xs btn-primary"}
        onClick={() => {
          callingApi.sendDtmfTones(tonesToSend, true);
        }}>
        Send
      </div>
    </div>
  );
}
const customThingsToSay = [
  "Let me think on that a moment",
  "processing... processing...",
  "Well that wasn't very nice",
  "I think I can help you",
  "May I interject",
  "Thanks",
  "I just have to say... that was terrible...",
];

function HotkeyFeature() {
  const speechRecognitionApi = useSpeechRecognitionStore((state) => state.api);
  const call = useCallingStore((state) => state.call);

  useHotkeys("q", () => {
    if (!call) return;

    speechRecognitionApi.startRemoteSpeechRecognition(call);
  });

  useHotkeys("w", () => {
    if (!call) return;

    speechRecognitionApi.stopRemoteSpeechRecognition();
  });

  return <div>Hotkeys</div>;
}
function HandRaisingFeature() {
  const call = useCallingStore((state) => state.call);
  const [raiseHandFeature, setRaiseHandFeature] = useState<RaiseHandCallFeature>();
  const hideUglyStuff = useGlobalStore2((state) => state.hideUglyStuff);

  useEffect(() => {
    if (!call) return;

    let raiseHandFeature = call.feature(Features.RaiseHand);

    raiseHandFeature.on("raiseHandChanged", (data) => {
      console.log(data);
    });

    setRaiseHandFeature(raiseHandFeature);
  }, [call]);

  return (
    <div>
      {!hideUglyStuff && (
        <div>
          <div
            className={"btn btn-xs btn-primary"}
            onClick={() => {
              raiseHandFeature?.raiseHand();
            }}>
            Raise Hand
          </div>
          <div
            className={"btn btn-xs btn-primary"}
            onClick={() => {
              raiseHandFeature?.lowerHand();
            }}>
            Lower Hand
          </div>

          <div
            className={"btn btn-xs btn-primary"}
            onClick={() => {
              raiseHandFeature?.lowerHandForEveryone();
            }}>
            Lower Hand for Everyone
          </div>
        </div>
      )}
    </div>
  );
}

function OpenAIAutocompleter({ cognitiveContext, visualizerRef }) {
  const speechRecognitionStoreApi = useSpeechRecognitionStore((state) => state.api);
  const lastSpeechResult = useSpeechRecognitionStore((state) => state.lastResult);
  const allSpeechResults = useSpeechRecognitionStore((state) => state.results);

  const chatUIStore = useChatUIStore();
  const promptText = useChatUIStore((state) => state.promptText);
  const chatUIApi = useChatUIStore((state) => state.api);
  const autoQuery = useChatUIStore((state) => state.autoQuery);
  const usePromptChaining = useChatUIStore((state) => state.usePromptChaining);

  let [thinking, setThinking] = useState("thinking");
  let [results, setResults] = useState<Array<{ response: string; mood: string }>>();
  let [autoRespond, setAutoRespond] = useState(false);

  const callingApi = useCallingStore((state) => state.api);
  const audioContext = useCallingStore((state) => state.audioContext);
  const call = useCallingStore((state) => state.call);
  const selectedMicrophone = useCallingStore((state) => state.selectedMicrophone);
  const [prosodyRate, setProsodyRate] = useState(0);

  let [voiceName, setVoiceName] = useState("en-US-JennyNeural");
  let [modelName, setModelName] = useState("text-davinci-003");

  const currentlyPlayingSourceRef = useRef<AudioBufferSourceNode>();
  let [currentlyPlayingSource, setCurrentlyPlayingSource] = useState<AudioBufferSourceNode>();
  let hideUglyStuff = useGlobalStore2((state) => state.hideUglyStuff);

  const convertSpeechResultToPrompt = (text) => {
    return promptText + text;
  };

  useEffect(() => {
    if (currentlyPlayingSourceRef.current) {
      currentlyPlayingSourceRef.current.stop();
    }

    if (!currentlyPlayingSource) return;

    currentlyPlayingSourceRef.current = currentlyPlayingSource;
    currentlyPlayingSourceRef.current.start();
    // currentlyPlayingSourceRef.current.onended = async () => {
    //   if (!currentlyPlayingSource) {
    //     await callingApi.muteOutgoingAudio();
    //   } else {
    //     setCurrentlyPlayingSource(null);
    //   }
    // };
  }, [currentlyPlayingSource]);

  useEffect(() => {
    chatUIApi.setPromptText(
      `You are a conversational meeting AI named Tom AI. You are on expert in many technologies, especially Microsoft technologies. For each prompt, you will generate a list of possible responses to be said in a meeting. The list should include a response written to sound excited, joking, serious, agreeable, or positive. The list should be output as an array of json objects with keys response and mood. The json should use double quotes and be valid json. Your first prompt is: `
    );
  }, []);

  useEffect(() => {
    if (!lastSpeechResult) return;

    setThinking("thinking");
    const responseId = chatUIStore.api.addResponse(lastSpeechResult);
    if (!autoQuery) return;
    // visualizerRef.current.webgl.changeText("thinking");
    chatUIStore.api.setShowTypingIndicator(true);

    let textToPromptWith = "";
    if (!usePromptChaining) {
      textToPromptWith = convertSpeechResultToPrompt(lastSpeechResult);
    } else {
      textToPromptWith = convertSpeechResultToPrompt(allSpeechResults.join("\n"));
    }
    console.log("Prompting with", textToPromptWith);
    askOpenAI(textToPromptWith, modelName)
      .then((result) => {
        setThinking("complete");
        chatUIStore.api.setShowTypingIndicator(false);

        // visualizerRef.current.webgl.changeText("");

        try {
          console.log("result is", result.choices[0].text);
          let json = JSON.parse(result.choices[0].text);
          setResults(json);
          let mappedJson = json.map((t) => {
            return {
              text: t.response,
            };
          });
          chatUIStore.api.addSuggestionsToResponse(mappedJson, responseId);
          if (autoRespond) {
            const sayIt = async () => {
              await callingApi.unmuteOutgoingAudio();
              let modifiedSSMLContent = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="${voiceName}"><mstts:express-as style="hopeful" ><prosody rate="${prosodyRate}%" pitch="0%">${json[0].response}</prosody></mstts:express-as></voice></speak>`;

              await speakTheText2(modifiedSSMLContent, cognitiveContext, audioContext, call, visualizerRef);
              await callingApi.muteOutgoingAudio();
            };
            sayIt();
          }
        } catch (err) {
          console.error(err);
          let fakeResults = [
            {
              response: result.choices[0].text,
              mood: "none",
            },
          ];
          chatUIStore.api.addSuggestionsToResponse([{ text: result.choices[0].text }], responseId);
          setResults(fakeResults);
        }
      })
      .catch((err) => {
        let fakeResults = [
          {
            response: "Sorry, the API appears to be down. Don't blame Tom for this because its not his fault.",
            mood: "angry",
          },
          {
            response: "OpenAI, please fix your shit so I can make queries!",
            mood: "angry",
          },
        ];
        setResults(fakeResults);
      });
  }, [lastSpeechResult]);

  useEffect(() => {
    if (call) {
      // const silentAudioUrl = process.env.REACT_APP_SILENT_AUDIO_URL;
      // if (!silentAudioUrl) throw new Error("Missing setting for REACT_APP_SILENT_AUDIO_URL");
      // console.log("sending silent audio track");
      // callingApi.sendAudioTrack(silentAudioUrl);
    }
  }, [call]);

  useEffect(() => {}, [allSpeechResults]);
  return (
    <div>
      {!hideUglyStuff && (
        <div className="form-control w-52">
          <label className="cursor-pointer label">
            <span className="label-text">Enable Speaker Bot</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={chatUIStore.speakerBotEnabled}
              onClick={() => {
                chatUIStore.api.toggleSpeakerBot();
              }}
            />
          </label>
        </div>
      )}
      {!hideUglyStuff && (
        <div className="form-control max-w-xs">
          <label className="cursor-pointer label">
            <span className="label-text">Prosody Rate</span>
            <input
              type="number"
              value={prosodyRate}
              onChange={(e) => {
                setProsodyRate(e.target.value);
              }}
            />
          </label>
        </div>
      )}

      {!hideUglyStuff && (
        <div className="form-control max-w-xs">
          <label className="cursor-pointer label">
            <span className="label-text">Autorespond</span>
            <input
              type="checkbox"
              onChange={(e) => {
                setAutoRespond(e.target.checked);
              }}
              checked={autoRespond}
              className="checkbox checkbox-xs"
            />
          </label>
        </div>
      )}

      {!hideUglyStuff && (
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">Select Voice</span>
          </label>
          <select
            className="select select-bordered"
            onChange={(e) => {
              setVoiceName(e.target.value);
            }}>
            <option value="en-US-JennyNeural">Jenny (Neural)</option>
            <option value="en-US-JennyMultilingualNeural">Jenny Multilingual (Neural)</option>
            <option value="en-US-GuyNeural">Guy (Neural)</option>
            <option value="en-US-AmberNeural">Amber (Neural)</option>
            <option value="en-US-AnaNeural">Ana (Neural)</option>
            <option value="en-US-AriaNeural">Aria (Neural)</option>
            <option value="en-US-AshleyNeural">Ashley (Neural)</option>
            <option value="en-US-BrandonNeural">Brandon (Neural)</option>
            <option value="en-US-ChristopherNeural">Christopher (Neural)</option>
            <option value="en-US-CoraNeural">Cora (Neural)</option>
            <option value="en-US-DavisNeural">Davis (Neural)</option>
            <option value="en-US-ElizabethNeural">Elizabeth (Neural)</option>
            <option value="en-US-EricNeural">Eric (Neural)</option>
            <option value="en-US-JacobNeural">Jacob (Neural)</option>
            <option value="en-US-JaneNeural">Jane (Neural)</option>
            <option value="en-US-JasonNeural">Jason (Neural)</option>
            <option value="en-US-MichelleNeural">Michelle (Neural)</option>
            <option value="en-US-MonicaNeural">Monica (Neural)</option>
            <option value="en-US-NancyNeural">Nancy (Neural)</option>
            <option value="en-US-SaraNeural">Sara (Neural)</option>
            <option value="en-US-TonyNeural">Tony (Neural)</option>
            <option value="en-US-AIGenerate1Neural">AIGenerate1 (Neural) - Preview</option>
            <option value="en-US-AIGenerate2Neural">AIGenerate2 (Neural) - Preview</option>
            <option value="en-US-RogerNeural">Roger (Neural) - Preview</option>
            <option value="en-US-SteffanNeural">Steffan (Neural) - Preview</option>
          </select>
          <label className="label">
            <span className="label-text-alt">Prompt:</span>
          </label>
        </div>
      )}
      {!hideUglyStuff && (
        <textarea
          className={"input input-bordered input-primary w-full max-w-xs"}
          // type={"text"}
          value={promptText}
          onChange={(e) => {
            chatUIApi.setPromptText(e.target.value);
          }}></textarea>
      )}

      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">Select Prompt:</span>
        </label>
        <select
          className="select select-bordered select-xs"
          onChange={(e) => {
            chatUIApi.setPromptText(e.target.value);
          }}>
          {BuiltInPrompts.map((prompt) => {
            return <option value={prompt.text}>{prompt.friendlyName}</option>;
          })}
        </select>
      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Autoquery</span>
          <input
            type="checkbox"
            className="toggle"
            checked={autoQuery}
            onChange={(e) => {
              if (e.target.checked) {
                chatUIApi.setAutoQuery(true);
              } else {
                chatUIApi.setAutoQuery(false);
              }
            }}
          />
        </label>
      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Use Prompt Chaining</span>
          <input
            type="checkbox"
            className="toggle"
            checked={usePromptChaining}
            onChange={(e) => {
              if (e.target.checked) {
                chatUIApi.setUsePromptChaining(true);
              } else {
                chatUIApi.setUsePromptChaining(false);
              }
            }}
          />
        </label>
      </div>

      {!hideUglyStuff && (
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">Model</span>
          </label>
          <select
            className="select select-bordered"
            onChange={(e) => {
              setModelName(e.target.value);
            }}>
            <option value="text-davinci-003">Davinci</option>
            <option value="text-curie-001">Curie</option>
            <option value="text-babbage-001">Babbage</option>
            <option value="text-ada-001">Ada</option>
          </select>
        </div>
      )}
      <div id={"chatui"} className={"max-w-lg"}>
        {[...chatUIStore.responses].reverse().map((response) => {
          return (
            <div key={response.id}>
              <div id={response.id} className="chat chat-start">
                <div className="chat-bubble chat-bubble-primary" style={{ padding: "1rem" }}>
                  <div className={"text-xs"}>{response.inputText} </div>
                </div>
              </div>
              {chatUIStore.showTypingIndicator && response.suggestions.length === 0 && (
                <div className="chat chat-end">
                  <div className="chat-bubble" style={{ padding: "1rem" }}>
                    <TypingIndicator />
                  </div>
                </div>
              )}
              {response.suggestions.map((r) => {
                return (
                  <div className="chat chat-end">
                    <div className="chat-image avatar">
                      <div style={{ transform: "scale(1.7)", marginBottom: "5px" }}>
                        <Lightbulb />
                      </div>
                    </div>
                    <div className="chat-bubble text-sm" style={{ padding: "1rem" }}>
                      <ReactMarkdown
                        components={{
                          strong: ({ node, ...props }) => (
                            <AddToPromptBuilder text={props.children ? props.children[0] : "Error"} />
                          ),
                        }}
                        remarkPlugins={[emoji]}>
                        {r.text}
                      </ReactMarkdown>
                      {chatUIStore.speakerBotEnabled && (
                        <div>
                          <div
                            className={"btn btn-xs btn-secondary"}
                            onClick={async () => {
                              await callingApi.unmuteOutgoingAudio();
                              let modifiedSSMLContent = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="${voiceName}"><mstts:express-as style="hopeful" ><prosody rate="${prosodyRate}%" pitch="0%">${r.text}</prosody></mstts:express-as></voice></speak>`;

                              let { source } = await speakTheText2(
                                modifiedSSMLContent,
                                cognitiveContext,
                                audioContext,
                                call,
                                visualizerRef
                              );
                              setCurrentlyPlayingSource(source);
                            }}>
                            {"Say"}
                          </div>
                          <div
                            className="btn btn-xs btn-secondary"
                            onClick={() => {
                              if (currentlyPlayingSourceRef.current) {
                                currentlyPlayingSourceRef.current.stop();
                              }
                            }}>
                            Stop
                          </div>

                          {/* <div
                          className={"btn btn-xs btn-secondary"}
                          onClick={async () => {
                            let modifiedSSMLContent = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="${voiceName}"><mstts:express-as style="hopeful" ><prosody rate="0%" pitch="0%">${r.text}</prosody></mstts:express-as></voice></speak>`;
  
                            await speakTheText2(modifiedSSMLContent, cognitiveContext, audioContext, call, visualizerRef);
                            if (!call) return;
                            let las = new LocalAudioStream(selectedMicrophone);
                            call?.startAudio(las);
                          }}>
                          {" "}
                          Say then switch back
                        </div> */}
                          <div
                            className={"btn btn-xs btn-secondary"}
                            onClick={async () => {
                              callingApi.sendMessageInChatThread(r.text);
                            }}>
                            {" "}
                            Chat
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function AvailableMicrophonesDropdown() {
  const callingApi = useCallingStore((state) => state.api);
  const availableMicrophones = useCallingStore((state) => state.availableMicrophones);

  return (
    <div className="dropdown">
      <label tabIndex={1} className="btn btn-xs m-1">
        Select Microphone
      </label>
      <ul tabIndex={1} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
        {availableMicrophones?.map((ac) => {
          return (
            <li
              key={ac.id}
              onClick={() => {
                callingApi.setSelectedMicrophone(ac);
                document.activeElement?.blur();
              }}>
              <a>{ac.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function AvailableSpeakersDropdown() {
  const callingApi = useCallingStore((state) => state.api);
  const availableSpeakers = useCallingStore((state) => state.availableSpeakers);

  return (
    <div className="dropdown">
      <label tabIndex={0} className="btn btn-xs m-1">
        Select Speaker
      </label>
      <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
        {availableSpeakers?.map((ac) => {
          return (
            <li
              key={ac.id}
              onClick={() => {
                callingApi.setSelectedSpeaker(ac);
                document.activeElement?.blur();
              }}>
              <a>{ac.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function AvailableCamerasDropdown() {
  const callingApi = useCallingStore((state) => state.api);
  const availableCameras = useCallingStore((state) => state.availableCameras);

  return (
    <div className="dropdown">
      <label tabIndex={0} className="btn btn-xs m-1">
        Select Camera
      </label>
      <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
        {availableCameras?.map((ac) => {
          return (
            <li
              key={ac.id}
              onClick={() => {
                callingApi.setSelectedCamera(ac);
                document.activeElement?.blur();
              }}>
              <a>{ac.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ExperimentalToolbox() {
  const call = useCallingStore((state) => state.call);
  const teamsCaptionsApi = useTeamsCaptionsStore((state) => state.api);
  const teamsCaptionsResults = useTeamsCaptionsStore((state) => state.results);
  const callingApi = useCallingStore((state) => state.api);

  return (
    <div>
      <div className="collapse">
        <input type="checkbox" />
        <div className="collapse-title text-xl font-medium">Experimental Toolbox</div>
        <div className="collapse-content">
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let ras = call.remoteAudioStreams[0];
              debugger;
            }}>
            Play Remote Audio to local audio
          </div>
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              setInterval(() => {
                html2canvas(
                  document.querySelector("#chatui"),
                  window.html2CanvasSettings
                    ? window.html2CanvasSettings
                    : {
                        height: 1080,
                        width: 600,
                        canvas: document.getElementById("dvdLogo")[0],
                      }
                ).then((canvas) => {
                  callingApi.setConnectedCanvasElement(canvas);
                });
              }, 1000);
            }}>
            html2canvas
          </div>
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let track = await call.remoteAudioStreams[0].getMediaStreamTrack();
              let las = new LocalAudioStream(track);

              call?.startAudio(las);
            }}>
            Play RAS
          </div>
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let las = new LocalAudioStream(selectedMicrophone);
              call?.startAudio(las);
            }}>
            Use Microphone Output Audio
          </div>
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              teamsCaptionsApi.startTeamsCaptionsFeature(call);
            }}>
            Teams Captions
          </div>
          {JSON.stringify(teamsCaptionsResults)}
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let teamsCaptionsFeature = call.feature(Features.Captions);
              console.log("CAPTIONS FEAUTURE", teamsCaptionsFeature);
              teamsCaptionsFeature.on("isCaptionsActiveChanged", () => {
                console.log("isCaptionsActiveChanged");
              });

              teamsCaptionsFeature.on("captionsReceived", (data) => {
                console.log("CAPTIONS DATA RECEIVED", data);
              });
            }}>
            ACS Captions
          </div>
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let composedStreamFeature = call.feature(Features.ComposedStream);

              console.log("Composed Stream FEAUTURE", composedStreamFeature);
              composedStreamFeature.on("composedStreamsUpdated", (...args) => {
                console.log("composedStreamsUpdated", ...args);
              });
            }}>
            Composed Streams
          </div>
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let mediaStatsFeature = call.feature(Features.MediaStats);

              console.log("media stats feature", mediaStatsFeature);
              let collector = mediaStatsFeature.createCollector();
              collector.on("sampleReported", (...args) => {
                console.log("sampleReported");
                console.log(...args);
              });

              collector.on("summaryReported", (...args) => {
                console.log("summaryReported");
                console.log(...args);
              });
            }}>
            Diagnostics
          </div>
          <DtmfToneSender />
        </div>
      </div>
    </div>
  );
}

function CallControls() {
  const [call, outgoingAudioIsMuted, incomingAudioIsMuted, api] = useCallingStore((state) => [
    state.call,
    state.outgoingAudioIsMuted,
    state.incomingAudioIsMuted,
    state.api,
  ]);
  if (!call) return null;

  return (
    <div>
      <div>
        <div className="text-xl font-medium">Call Controls</div>
        <div>
          <div>
            <div
              onClick={() => {
                if (call.isMuted) {
                  api.unmuteOutgoingAudio();
                }
                if (!call.isMuted) {
                  api.muteOutgoingAudio();
                }
              }}>
              <div className={"flex"}>
                <div className={"text-primary text-sm"}>Outgoing Audio</div>
                <MuteButton isMuted={outgoingAudioIsMuted} />
              </div>
            </div>

            <div
              onClick={() => {
                if (incomingAudioIsMuted) {
                  api.unmuteIncomingAudio();
                }
                if (!incomingAudioIsMuted) {
                  api.muteIncomingAudio();
                }
              }}>
              <div className={"flex"}>
                <div className={"text-primary text-sm"}>Incoming Audio</div>
                <MuteButton isMuted={incomingAudioIsMuted} />
              </div>
            </div>

            <AvailableMicrophonesDropdown />
            <AvailableSpeakersDropdown />
            <AvailableCamerasDropdown />
          </div>
        </div>
      </div>
    </div>
  );
}

function AlreadyInTeamsMeetingJoiner() {
  const callingApi = useCallingStore((state) => state.api);
  const call = useCallingStore((state) => state.call);
  const callIsConnected = useCallingStore((state) => state.callIsConnected);

  const [name, setName] = useState("Meeting AI");
  const [joinUrl, setJoinUrl] = useState("");
  const speechRecognitionApi = useSpeechRecognitionStore((state) => state.api);
  const [hideUglyStuff, setHideUglyStuff] = useGlobalStore2((state) => [state.hideUglyStuff, state.setHideUglyStuff]);

  useEffect(() => {
    meeting.getMeetingDetails((error, details) => {
      if (!error) {
        setJoinUrl(details?.details.joinUrl || "");

        meeting.appShareButton.setOptions({ isVisible: false });
      }
    });
  }, []);

  const muteAll = async () => {
    await callingApi.muteOutgoingAudio();
    await callingApi.muteIncomingAudio();
  };
  const unmuteAll = async () => {
    await callingApi.unmuteOutgoingAudio();
    await callingApi.unmuteIncomingAudio();
  };

  const startSpeechRecognition = async (call) => {
    await speechRecognitionApi.startRemoteSpeechRecognition(call);
  };
  useEffect(() => {
    if (!callIsConnected) return;
    setTimeout(async () => {
      setHideUglyStuff(true);
      await muteAll();
      await unmuteAll();
      await muteAll();
      await startSpeechRecognition(call);
    }, 1000);
  }, [callIsConnected]);

  return (
    <div>
      <div>
        <div
          className={"btn btn-xs btn-primary"}
          onClick={async () => {
            await callingApi.initCallAgent(name);
            await callingApi.joinCall(joinUrl);
          }}>
          Go
        </div>
      </div>
    </div>
  );
}

export function CustomTeamsClientInner() {
  const [page, setPage] = useState<PageState>("configuration");
  const [{ inTeams, context }] = useMicrosoftTeams({});

  const callIsConnected = useCallingStore((state) => state.callIsConnected);
  const callingApi = useCallingStore((state) => state.api);
  const remoteParticipants = useCallingStore((state) => state.remoteParticipants);
  const callAgent = useCallingStore((state) => state.callAgent);
  const call = useCallingStore((state) => state.call);
  const callClient = useCallingStore((state) => state.callClient);

  const chatClient = useCallingStore((state) => state.call);

  const lvs = useCallingStore((state) => state.localVideoStream);
  const availableCameras = useCallingStore((state) => state.availableCameras);
  const availableMicrophones = useCallingStore((state) => state.availableMicrophones);
  const botDisplayName = useCallingStore((state) => state.botDisplayName);

  const selectedCamera = useCallingStore((state) => state.selectedCamera);
  const selectedMicrophone = useCallingStore((state) => state.selectedMicrophone);

  const mediaDestinationNode = useCallingStore((state) => state.mediaDestinationNode);
  const audioContext = useCallingStore((state) => state.audioContext);
  const connectedCanvasElement = useCallingStore((state) => state.connectedCanvasElement);

  const [fabricJsCanvas, setFabricJsCanvas] = useState();
  const [cognitiveContext, setCognitiveContext] = useState<{ token: string; region: string }>();

  const speechRecognitionStoreApi = useSpeechRecognitionStore((state) => state.api);
  const speechResults = useSpeechRecognitionStore((state) => state.lastResult);
  const continuousRecognitionInProgress = useSpeechRecognitionStore((state) => state.continuousRecognitionInProgress);
  const localRecognitionInProgress = useSpeechRecognitionStore((state) => state.localRecognitionInProgress);

  const [sendChatToDalle, setSendChatToDalle] = useState(false);
  const recentChats = useChatUIStore((state) => state.responses);

  const [textToSpeak, setTextToSpeak] = useState("");
  const [ssml, setSsml] = useState(
    `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-JennyNeural"><mstts:express-as style="hopeful" ><prosody rate="0%" pitch="0%">Hello World</prosody></mstts:express-as></voice></speak>`
  );

  const visualizerDomNode = useRef();
  const visualizerRef = useRef<any>();

  const [chatGPTConversationId, setChatGPTConversationId] = useState("");
  const [chatGPTParentId, setChatGPTParentId] = useState("");

  const [speechRecognizer, setSpeechRecognizer] = useState<SpeechRecognizer>();
  const [hideUglyStuff, setHideUglyStuff] = useGlobalStore2((state) => [state.hideUglyStuff, state.setHideUglyStuff]);

  const logApi = useAzureLogger();
  
  useEffect(() => {
    callIsConnected && setPage("call");
  }, [callIsConnected]);

  const init = async () => {
    await callingApi.initCallClient();
    await callingApi.loadAvailableCamerasAndMicrophones();


    let result = await getCognitiveServicesToken();

    // const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(result.token, result.region);
    // speechConfig.speechRecognitionLanguage = "en-US";

    // const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    // const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
    // recognizer.startContinuousRecognitionAsync(() => {
    //   console.log("continuous recognition started");
    // });

    // recognizer.recognized = (sender, evt) => {
    //   console.log("RECOGNIZED", sender, evt);
    // };

    // setTimeout(() => {
    //   recognizer.recognizeOnceAsync((result) => {
    //     let displayText;
    //     if (result.reason === ResultReason.RecognizedSpeech) {
    //       displayText = `RECOGNIZED: Text=${result.text}`;
    //     } else {
    //       displayText =
    //         "ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.";
    //     }

    //     console.log(displayText);
    //   });
    // }, 5000);

    setCognitiveContext(result);
  };

  const startSpeechRecognition = async () => {
    if (!cognitiveContext) throw new Error("cognitiveContext not found");
    if (!call) throw new Error("call not found");
    await callingApi.unmuteIncomingAudio();
    await callingApi.muteIncomingAudio();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(cognitiveContext.token, cognitiveContext.region);
    speechConfig.speechRecognitionLanguage = "en-US";

    let ras = call.remoteAudioStreams[0];
    let s = await ras.source.getMediaStream();

    const audioConfig = AudioConfig.fromStreamInput(s);
    const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
    // const recognizer = new IntentRecognizer(speechConfig, audioConfig);
    // var intentModel = LanguageUnderstandingModel.fromAppId("5239e313-7320-4bdb-9824-16d9f0d440c5");
    // // debugger;
    // recognizer.addAllIntents(intentModel);

    recognizer.recognized = (sender, evt) => {
      // console.log("RECOGNIZED REMOTE", sender, evt);
      console.log("RECOGNIZED REMOTE", sender, evt.result.text);
      debugger;
      speechRecognitionStoreApi.addRecognitionResult(evt.result.text);
    };

    recognizer.startContinuousRecognitionAsync(() => {
      console.log("continuous recognition started 2");
    });

    return recognizer;
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {}, [call]);

  useEffect(() => {
    if (!fabricJsCanvas) return;

    var neftText = new fabric.Text(`${speechResults}`, {
      fontSize: 45,
      // originX: "center",
      originY: "center",
      top: 1024 - 30,
      // fontStyle: "italic",
      // fontFamily: "Verdana",
      fill: "white",
      fontFamily: "proxima-nova, sans-serif",
    });

    var group = new fabric.Group([neftText], {
      left: 0,
      top: 0,
      angle: 0,
      height: 1024,
      width: 1024,
      scaleX: 1,
      scaleY: 1,
    });

    fabricJsCanvas.add(group);

    // setTimeout(() => {
    //   fabricJsCanvas.remove(group);
    // }, 3000);
  }, [fabricJsCanvas, speechResults]);

  // useEffect(() => {
  //   if (!fabricJsCanvas) return;
  //   const firstSuggestion = recentChats[recentChats.length -1].suggestions;
  //   if (firstSuggestion)

  //   var neftText = new fabric.Text(`${recentChats[recentChats.length -1].suggestions}`, {
  //     fontSize: 45,
  //     // originX: "center",
  //     originY: "center",
  //     top: 1024 - 30,
  //     // fontStyle: "italic",
  //     // fontFamily: "Verdana",
  //     fill: "white",
  //     fontFamily: "proxima-nova, sans-serif",
  //   });

  //   var group = new fabric.Group([neftText], {
  //     left: 0,
  //     top: 0,
  //     angle: 0,
  //     height: 1024,
  //     width: 1024,
  //     scaleX: 1,
  //     scaleY: 1,
  //   });

  //   fabricJsCanvas.add(group);

  //   // setTimeout(() => {
  //   //   fabricJsCanvas.remove(group);
  //   // }, 3000);
  // }, [fabricJsCanvas, recentChats]);

  useEffect(() => {
    if (call) {
      call.on("stateChanged", async () => {
        console.log("CALL STATE CHANGED", call.state);
        if (call.state === "Disconnected") {
          alert(JSON.stringify(call.callEndReason))
          setPage("callended");
          
          // window.location.reload();
        }
      });

      
    }
  }, [call]);

  useEffect(() => {
    if (!audioContext || !visualizerDomNode.current) return;
    if (page === "call") {
      const visualizer = startVisualizer(audioContext, visualizerDomNode.current);
      visualizerRef.current = visualizer;
      // setTimeout(() => {
      //   callingApi.setConnectedCanvasElement("visualizer");
      //   callingApi.playVideoFromRenderCanvas();
      // }, 3000);
    }
  }, [audioContext, visualizerDomNode, page]);

  const doTheThing = async (text) => {
    await callingApi.unmuteOutgoingAudio();
    await speakTheText(text, cognitiveContext, audioContext, call);
    await callingApi.muteOutgoingAudio();
  };

  const doTheThing2 = async () => {
    await callingApi.unmuteOutgoingAudio();
    await callingApi.sendAudioTrack(
      "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/What%20Can%20I%20Say%20Except%20Your%20Welcome.mp4?sv=2019-12-12&st=2022-12-21T16%3A09%3A35Z&se=2070-12-22T16%3A09%3A00Z&sr=b&sp=r&sig=2ZFiMB39alCHpkMUV%2F0DCEiRMtx4vwsLdnTmNIof6y0%3D"
    );
    await callingApi.muteOutgoingAudio();
  };

  if (page === "configuration") {
    if (inTeams) {
      return <AlreadyInTeamsMeetingJoiner />;
    } else
      return (
        <div>
          <ShoutConnect2 />
          <VideoLocalPreview />

          <div className="dropdown">
            <label tabIndex={0} className="btn btn-xs m-1">
              Select Camera
            </label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
              {availableCameras?.map((ac) => {
                return (
                  <li
                    key={ac.id}
                    onClick={() => {
                      callingApi.setSelectedCamera(ac);
                      document.activeElement?.blur();
                    }}>
                    <a>{ac.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <AvailableMicrophonesDropdown />
        </div>
      );
  }

  if (page === "call") {
    return (
      <div className={"p-2"}>
        {!hideUglyStuff && <CallControls />}
        {!hideUglyStuff && (
          <div className="collapse">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium collapse-plus border border-base-300 bg-base-100 rounded-box">
              <div className={"text-primary text-lg"}>Meeting AI Controls</div>
            </div>
            <div className="collapse-content">
              <div className={"pt-4"}>
                <div className="form-control max-w-xs">
                  <label className="cursor-pointer label">
                    <span className="label-text">Recognize Incoming Speech</span>
                    <input
                      type="checkbox"
                      onChange={async (e) => {
                        if (!call) throw new Error("call should not be null");

                        if (e.target.checked) {
                          await speechRecognitionStoreApi.startRemoteSpeechRecognition(call);
                        } else {
                          await speechRecognitionStoreApi.stopRemoteSpeechRecognition();
                        }
                      }}
                      checked={continuousRecognitionInProgress}
                      className="checkbox checkbox-xs"
                    />
                  </label>
                </div>

                <div className="form-control max-w-xs">
                  <label className="cursor-pointer label">
                    <span className="label-text">Recognize Local Speech</span>
                    <input
                      type="checkbox"
                      onChange={async (e) => {
                        if (!call) throw new Error("call should not be null");

                        if (e.target.checked) {
                          await speechRecognitionStoreApi.startLocalSpeechRecognition(selectedMicrophone);
                        } else {
                          await speechRecognitionStoreApi.stopLocalSpeechRecognition();
                        }
                      }}
                      checked={localRecognitionInProgress}
                      className="checkbox checkbox-xs"
                    />
                  </label>
                </div>

                <div className="form-control max-w-xs">
                  <label className="cursor-pointer label">
                    <span className="label-text">Send chat to DALL-E</span>
                    <input
                      type="checkbox"
                      onChange={async (e) => {
                        if (!call) throw new Error("call should not be null");
                        if (!chatClient) throw new Error("chatClient should not be null");

                        callingApi.subscribeToChatMessages(async (message) => {
                          console.log("MESSAGE IN subsbcriber", message);

                          const strippedMessage = message.message
                            .replace("<p>", "")
                            .replace("</p>", "")
                            .replace(`<span style="font-size:inherit">`, "")
                            .replace("</span>", "");

                          let results = await getOpenAIImage(strippedMessage, "1024x1024");

                          for (let i = 0; i < results.data.length; i++) {
                            let imageB64 = results.data[i].b64_json;

                            fabric.Image.fromURL("data:image/png;base64," + imageB64, function (img) {
                              img.set({ left: 0, top: 0 });

                              var text = new fabric.Text(strippedMessage, {
                                fontSize: 30,
                                // originX: "center",
                                originY: "center",
                                top: 1024 - 130,
                                fontStyle: "italic",
                                fontFamily: "Verdana",
                              });

                              var authorText = new fabric.Text(message.senderDisplayName + ` x Tom AI`, {
                                fontSize: 45,
                                // originX: "center",
                                originY: "center",
                                top: 1024 - 80,
                                fontStyle: "italic",
                                fontFamily: "Verdana",
                              });

                              var neftText = new fabric.Text(`NFT Series 1 ${i}`, {
                                fontSize: 45,
                                // originX: "center",
                                originY: "center",
                                top: 1024 - 30,
                                fontStyle: "italic",
                                fontFamily: "Verdana",
                              });

                              var group = new fabric.Group([img, text, authorText, neftText], {
                                left: 0,
                                top: 0,
                                angle: 0,
                                height: 1024,
                                width: 1024,
                                scaleX: 0.25,
                                scaleY: 0.25,
                              });

                              fabricJsCanvas.add(group);
                            });
                          }
                        });
                      }}
                      checked={sendChatToDalle}
                      className="checkbox checkbox-xs"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="form-control w-52">
          <label className="cursor-pointer label">
            <span className="label-text">Hide Ugly Stuff</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={hideUglyStuff}
              onChange={(e) => {
                setHideUglyStuff(e.currentTarget.checked);
              }}
            />
            <div
              onClick={() => {
                call?.hangUp();

                // // TODO - real reload
                // window.location.reload();
              }}
              className={"p-2 pr-2 tooltip tooltip-left cursor-pointer"}
              data-tip={"Hang up"}>
              <HangUpIcon />
            </div>
          </label>
        </div>
        <OpenAIAutocompleter cognitiveContext={cognitiveContext} visualizerRef={visualizerRef} />
        <HandRaisingFeature />
        {!hideUglyStuff && <HotkeyFeature />}
        {!hideUglyStuff && <ExperimentalToolbox />}
        {!hideUglyStuff && <div id={"visualizer"} ref={visualizerDomNode}></div>}

        {/* <Dragon /> */}
        {!hideUglyStuff && (
          <div>
            <div
              className="btn btn-xs"
              onClick={() => {
                callingApi.showRenderTargetCanvas();
              }}>
              Show Render Canvas
            </div>

            <div
              className="btn btn-xs"
              onClick={() => {
                callingApi.createVideoElement(
                  "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D"
                );
              }}>
              Thank You
            </div>

            <div
              className="btn btn-xs"
              onClick={() => {
                logApi.setLogLevel("verbose");
                logApi.setLogFunction((args) => {
                  console.log("TOM WAS HERE", args)
                })
              }}>
              Set Log Fn
            </div>

            <div
              className="btn btn-xs"
              onClick={() => {
                call?.stopAudio();
              }}>
              Stop Audio
            </div>

            <div
              className="btn btn-xs"
              onClick={() => {
                callingApi.hideRenderTargetCanvas();
              }}>
              Hide Render Canvas
            </div>

            <div
              className="btn btn-xs"
              onClick={async () => {
                await askChatGPT(textToSpeak).then(async (result) => {
                  if (result.conversation_id) {
                    setChatGPTConversationId(result.conversation_id);
                  }
                  if (result.parent_id) {
                    setChatGPTParentId(result.parent_id);
                  }
                  await callingApi.unmuteOutgoingAudio();
                  let modifiedSSMLContent = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="en-US-JennyNeural"><mstts:express-as style="hopeful" ><prosody rate="0%" pitch="0%">${result.content}</prosody></mstts:express-as></voice></speak>`;

                  await speakTheText2(modifiedSSMLContent, cognitiveContext, audioContext, call, visualizerRef);
                  await callingApi.muteOutgoingAudio();
                });
              }}>
              Ask ChatGPT
            </div>

            <input
              className={"input input-bordered input-primary w-full max-w-xs"}
              type={"text"}
              value={textToSpeak}
              onChange={(e) => {
                setTextToSpeak(e.target.value);
              }}></input>
            <div
              className="btn btn-xs"
              onClick={async () => {
                await callingApi.unmuteOutgoingAudio();
                await speakTheText(textToSpeak, cognitiveContext, audioContext, call);
                await callingApi.muteOutgoingAudio();

                // setTimeout(async () => {
                //   await callingApi.muteOutgoingAudio();
                // }, 1000);
              }}>
              Speech Synthesis
            </div>
            <div
              className="btn btn-xs"
              onClick={async () => {
                await callingApi.unmuteOutgoingAudio();
                await speakTheText2(ssml, cognitiveContext, audioContext, call, visualizerRef);
                await callingApi.muteOutgoingAudio();

                // setTimeout(async () => {
                //   await callingApi.muteOutgoingAudio();
                // }, 1000);
              }}>
              Speech Synthesis SSML
            </div>
            <div
              className="btn btn-xs"
              onClick={async () => {
                await call?.startScreenSharing();
              }}>
              Start Screensharing
            </div>
            <textarea
              className={"textarea-secondary text-secondary w-3/4"}
              value={ssml}
              onChange={(e) => {
                setSsml(e.target.value);
              }}></textarea>

            <div>
              Speech Recognition Results:
              {JSON.stringify(speechResults)}
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.setConnectedCanvasElement("dvdLogo");
              }}>
              Test
            </div>

            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.setConnectedCanvasElement("particleVisualizer");
              }}>
              Particles
            </div>

            <div
              className={"btn btn-xs"}
              onClick={async () => {
                let results = await askOpenAI(textToSpeak, "text-davinci-003");
                await callingApi.unmuteOutgoingAudio();
                await speakTheText(results[0].text, cognitiveContext, audioContext, call);
                await callingApi.muteOutgoingAudio();
              }}>
              Ask OpenAI
            </div>

            <div
              className={"btn btn-xs"}
              onClick={async () => {
                let c = document.createElement("canvas");
                c.height = 1024;
                c.width = 1024;

                let results = await getOpenAIImage(textToSpeak, "1024x1024");
                let imageB64 = results.data[0].b64_json;

                var ctx = c.getContext("2d");

                var image = new Image();
                image.onload = function () {
                  ctx.drawImage(image, 0, 0);
                };
                image.src = "data:image/png;base64," + imageB64;

                callingApi.setConnectedCanvasElement(c);
                await callingApi.setupRenderingCanvas();

                // document.body.append(c);
                // await callingApi.playVideoFromRenderCanvas();
              }}>
              OpenAI Image
            </div>

            <div
              className={"btn btn-xs"}
              onClick={async () => {
                if (!call) return;
                let ras = call.remoteAudioStreams[0];
                let s = await ras.source.getMediaStream();
                const track = s.getAudioTracks()[0];
                const audioConfig = AudioConfig.fromStreamInput(s);
                const localAudioStream = new LocalAudioStream(track);
                debugger;
                // let mediaStream = await ras.source.getRawStream();
                // let pullStream = PullAudioInputStream.createPullStream((cb) => {

                // });
                // pullStream.
                debugger;
                if (visualizerRef.current) {
                  visualizerRef.current.connectSoundWithBuffer(ras.source);
                }
              }}>
              Input Audio
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                const parent = document.getElementById("visualizer");
                callingApi.setConnectedCanvasElement(parent.children[0]);
                await callingApi.setupRenderingCanvas();
                // await callingApi.playVideoFromRenderCanvas();
              }}>
              Tom AI
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                visualizerRef.current.webgl.changeText("WTF");
              }}>
              Change Text
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                await speakTheText("Sorry, I am not that smart... yet", cognitiveContext, audioContext, call);
              }}>
              Sorry
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                await callingApi.setupRenderingCanvas();
              }}>
              Setup Rendering Canvas
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                const parent = document.getElementById("visualizer");
                callingApi.setConnectedCanvasElement(parent.children[0]);
              }}>
              Visualizer
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.setConnectedCanvasElement("world");
              }}>
              Dragon
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.setConnectedCanvasElement("earth-canvas");
              }}>
              Planet
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                // let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
                // let renderCtx = renderTarget.getContext("2d");
                var canvas = new fabric.Canvas("fabricCanvas");
                var video1El = document.getElementById("video1");

                // callingApi.setConnectedCanvasElement("fabricCanvas");
                var rect = new fabric.Rect({
                  top: 0,
                  left: 0,
                  width: 190,
                  height: 70,
                  fill: "#E4002B",
                });

                let text = new fabric.Text("Tom NFT", {
                  fill: "white",
                  fontFamily: "proxima-nova, sans-serif",
                  top: 10,
                });

                var group = new fabric.Group([rect, text], {
                  left: 200,
                  top: 200,
                  angle: 0,
                });

                canvas.add(group);
                // let sideBar = new fabric.Rect({
                //   top: 0,
                //   left: 0,
                //   width: 1920 - 1080,
                //   height: 1080,
                //   fill: "yellow",
                // });

                // canvas.add(sideBar);
                setFabricJsCanvas(canvas);

                callingApi.setConnectedCanvasElement(canvas.getElement());

                // video1.getElement().play();
                // if (lvs) {
                //   await call?.stopVideo(lvs);
                // }
                // const stream = canvas.getElement().captureStream(30);
                // const localVideoStream = new LocalVideoStream(stream);
                // await call?.startVideo(localVideoStream);
                // await callingApi.playVideoFromRenderCanvas();
                // fabric.util.requestAnimFrame(function render() {
                //   canvas.renderAll();
                //   fabric.util.requestAnimFrame(render);
                // });
              }}>
              Fabric thing
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                let results = await getOpenAIImage(textToSpeak, "1024x1024");
                let imageB64 = results.data[0].b64_json;

                fabric.Image.fromURL("data:image/png;base64," + imageB64, function (img) {
                  img.set({ left: 0, top: 0 });

                  var text = new fabric.Text(textToSpeak, {
                    fontSize: 60,
                    // originX: "center",
                    originY: "center",
                    top: 1024 - 100,
                    fontFamily: "Pacifico",
                  });

                  var group = new fabric.Group([img, text], {
                    left: 0,
                    top: 0,
                    angle: 0,
                    height: 1024,
                    width: 1024,
                    scaleX: 0.25,
                    scaleY: 0.25,
                  });

                  fabricJsCanvas.add(group);
                });
              }}>
              Add To Fabric
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                await callingApi.playVideoFromRenderCanvas();
              }}>
              Play from render canvas
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.setLocalVideoStreamToSelectedCamera();
              }}>
              Start Video
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.muteIncomingAudio();
              }}>
              Mute Incoming Audio
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.unmuteIncomingAudio();
              }}>
              Unmute Incoming Audio
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.muteOutgoingAudio();
              }}>
              Mute Outgoing Audio
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                callingApi.unmuteOutgoingAudio();
              }}>
              Unmute Outgoing Audio
            </div>
            <div
              className={"btn btn-xs"}
              onClick={() => {
                let ttsRecorder = new SpeechSynthesisRecorder({
                  text: "The revolution will not be televised",
                  utteranceOptions: {
                    voice: "english-us espeak",
                    lang: "en-US",
                    pitch: 0.75,
                    rate: 1,
                    volume: 1,
                  },
                  audioContext: audioContext,
                  // dataType: "mediaStream",
                });

                // ttsRecorder
                //   .start()
                //   .then((tts) => tts.mediaSource())
                //   .then(({ tts, data }) => {
                //     console.log(tts, data);
                //     // `data` : `MediaSource`
                //     tts.audioNode.srcObj = data;
                //     tts.audioNode.title = tts.utterance.text;
                //     tts.audioNode.onloadedmetadata = () => {
                //       console.log(tts.audioNode.duration);
                //       debugger;
                //       tts.audioNode.play();
                //     };
                //   });

                // ttsRecorder
                //   .start()
                //   .then(({ tts, data }) => {
                //     // `data` : `MediaStream`
                //     let track = data.getTracks()[0];
                //     const localAudioStream = new LocalAudioStream(track);

                //     call?.startAudio(track);
                //   })
                //   .catch((err) => console.log(err));

                // ttsRecorder
                //   .start()
                //   .then((tts) => tts.audioBuffer())
                //   .then(async ({ tts, data }) => {
                //     const mediaDestinationNode = tts.audioContext.createMediaStreamDestination();
                //     const source = new AudioBufferSourceNode(tts.audioContext, { buffer: data });
                //     // `data` : `AudioBuffer`
                //     // let source = tts.audioContext.createBufferSource();
                //     // source.buffer = data;
                //     source.connect(mediaDestinationNode);

                //     let track = mediaDestinationNode?.stream.getAudioTracks()[0];
                //     const localAudioStream = new LocalAudioStream(track);
                //     source.start();

                //     await call?.startAudio(localAudioStream);
                //   });

                (async () => {
                  const sink = document.createElement("video");
                  document.body.appendChild(sink);
                  sink.controls = true;
                  sink.autoplay = false;
                  navigator.mediaDevices.ondevicechange = (e) => console.log(e);
                  const devices = await navigator.mediaDevices.enumerateDevices();
                  const { deviceId } = devices.find(({ kind, label }) => kind === "audiooutput");
                  console.log(devices);
                  let stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                      deviceId: {
                        exact: deviceId,
                      },
                    },
                  });
                  sink.srcObject = stream;
                  console.log(devices, deviceId);
                  const text = [...Array(4).keys()].join(" ");
                  const handleVoicesChanged = async (e) => {
                    const voice = speechSynthesis.getVoices().find(({ name }) => name.includes("English"));
                    const utterance = new SpeechSynthesisUtterance(text);
                    utterance.voice = voice;
                    utterance.pitch = 0.33;
                    utterance.rate = 0.1;
                    const recorder = new MediaRecorder(stream);
                    recorder.start();
                    speechSynthesis.speak(utterance);
                    recorder.ondataavailable = async ({ data }) => {
                      console.log(URL.createObjectURL(data));
                      await callingApi.sendAudioTrack(URL.createObjectURL(data));
                      debugger;
                    };
                    utterance.onend = (e) => (recorder.stop(), stream.getAudioTracks()[0].stop());
                  };
                  speechSynthesis.onvoiceschanged = handleVoicesChanged;
                  let voices = speechSynthesis.getVoices();
                  if (voices.length) {
                    handleVoicesChanged();
                    console.log(voices);
                  }
                })().catch(console.error);

                // ttsRecorder
                //   .start()
                //   .then((tts) => tts.blob())
                //   .then(({ tts, data }) => {
                //     // `data` : `Blob`
                //     tts.audioNode.src = URL.createObjectURL(blob);
                //     tts.audioNode.title = tts.utterance.text;
                //     tts.audioNode.onloadedmetadata = () => {
                //       console.log(tts.audioNode.duration);
                //       tts.audioNode.play();
                //     };

                //   });

                // let utterance = new SpeechSynthesisUtterance("Hello world!");
                // speechSynthesis.speak(utterance);
              }}>
              Say Something
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                var video1El = document.getElementById("video1");

                // var video1 = new fabric.Image(video1El, {
                //   left: 0,
                //   top: 0,
                //   angle: 0,
                //   originX: "center",
                //   originY: "center",
                //   objectCaching: false,
                //   crossOriginIsolated: false,
                // });
                // fabricJsCanvas?.add(video1);

                var rect = new fabric.Rect({
                  top: 100,
                  left: 100,
                  width: 60,
                  height: 70,
                  fill: "green",
                });

                fabricJsCanvas?.add(rect);
              }}>
              Fabric 2
            </div>
            <div
              className={"btn btn-xs"}
              onClick={async () => {
                var video1El = document.getElementById("video1");

                // var video1 = new fabric.Image(video1El, {
                //   left: 0,
                //   top: 0,
                //   angle: 0,
                //   originX: "center",
                //   originY: "center",
                //   objectCaching: false,
                //   crossOriginIsolated: false,
                // });
                // fabricJsCanvas?.add(video1);
                fabric.Image.fromURL(
                  "/audio-speaker-on.12d3eb07.png",
                  // "https://sparkprovisioningstor.file.core.windows.net/fileshare/collabtris/audio-speaker-on.12d3eb07.png?st=2022-11-29T19%3A05%3A37Z&se=2069-11-30T19%3A05%3A00Z&sp=rl&sv=2018-03-28&sr=f&sig=iwmxv8%2FIC10IC7RdnchN%2F32VA254LgO1B%2B8RMpuR6tM%3D",
                  function (image) {
                    fabricJsCanvas?.add(image);
                  },
                  {}
                );
              }}>
              Fabric 3
            </div>
            <div>
              <P5Experience />
              {/* <P5Experience />
          <Planet /> */}
            </div>

            <ParticleVisualizer height={"800px"} width={"800px"} />
          </div>
        )}
      </div>
    );
  }

  if (page === "callended") {
    if (inTeams) {
      return <AlreadyInTeamsMeetingJoiner />;
    } else {
      return <div>The call ended</div>;
    }
  }
  return (
    <div>
      {callIsConnected ? <div>Connected</div> : <ShoutConnect2 />}

      <canvas className={"scale-50"} id="renderTarget"></canvas>
      <VideoLocalPreview />
      {/* <SoundboardButtons /> */}

      <div
        className={"btn btn-xs"}
        onClick={() => {
          call?.hangUp();
        }}>
        Hang up call
      </div>

      <div
        className={"btn btn-xs"}
        onClick={() => {
          callingApi.muteOutgoingAudio();
        }}>
        Mute Outgoing Audio
      </div>

      <div
        className={"btn btn-xs"}
        onClick={() => {
          callingApi.unmuteOutgoingAudio();
        }}>
        Unmute Outgoing Audio
      </div>
      <VolumeSlider />

      {remoteParticipants?.length}
      <div className={"flex"}>
        {remoteParticipants?.map((rp) => {
          console.log("MAPPING REMOTE PARTICIAPANTS", rp);
          return (
            <RemoteStreamMedia
              label={rp.identifier.rawId ? rp.identifier.rawId : rp.identifier.communicationUserId}
              stream={rp.videoStreams[0]}
              isParticipantStreamSelected={true}
            />
          );
        })}
      </div>
      <div
        className={"btn btn-xs"}
        onClick={() => {
          callingApi.setConnectedCanvasElement("dvdLogo");
        }}>
        Test
      </div>

      <div>
        <P5Experience />
      </div>
    </div>
  );
}

//   if (!callIsConnected && !callIsConnecting) {
//     return <ShoutConnect />;
//   }
//   if (callIsConnecting && !callIsConnected) {
//     return <div>Connecting...</div>;
//   }

//   if (callIsConnected) {
//     return <div>Connected!!!!</div>;

//   return null;
// }
