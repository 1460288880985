import create from "zustand";
import { JESSEENDPOINT } from "../../Constants";

let resultsUrl =
  process.env.NODE_ENV === "production"
    ? JESSEENDPOINT
    : "http://localhost:2567/jess-e";

type VectorSearchResult = {
  id: string;
  score: number;
  metadata: {
    title?: string;
    description?: string;
    token_count_estimate?: number;
    text: string;
  };
};

type JesseState = {
  queryText: string;
  setQueryText: (text: string) => void;
  isLoading: boolean;
  results: Array<VectorSearchResult>;
  namespace: string;
  setNamespace: (ns: string) => void;
  model: string;
  setModel: (model: string) => void;
  createLLMResponse: boolean;
  setCreateLLMResponse: (shouldCreateResponse: boolean) => void;

  executeQuery: () => Promise<void>;
};

export const useJesseState = create<JesseState>((set, get) => ({
  queryText: "",
  setQueryText: (text: string) => {
    set({ queryText: text });
  },
  isLoading: false,
  results: [],
  namespace: "ns4",
  setNamespace: (ns) => {
    set({ namespace: ns });
  },
  model: "gpt-4-8k",
  setModel: (model) => {
    set({ model: model });
  },
  createLLMResponse: true,
  setCreateLLMResponse(shouldCreateResponse) {
    set({ createLLMResponse: shouldCreateResponse });
  },

  executeQuery: async () => {
    set({ isLoading: true });
    let response = await fetch(resultsUrl, {
      method: "POST",
      body: JSON.stringify({
        queryText: get().queryText,
        model: get().model,
        namespace: get().namespace,
        createLLMResponse: get().createLLMResponse,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    let json = await response.json();
    set({ results: json.results, isLoading: false });
  },
}));
