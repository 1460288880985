import { getMerge, isSamePropInArrays, logger, triggerUpdater } from "@rpldy/shared";
const mergeWithUndefined = getMerge({
  undefinedOverwrites: true
});

const processPrepareResponse = (eventType, items, options, updated) => {
  if (updated) {
    logger.debugLog(`uploader.queue: REQUEST_PRE_SEND(${eventType}) event returned updated items/options`, updated);

    if (updated.items) {
      //can't change items count at this point.
      if (updated.items.length !== items.length || !isSamePropInArrays(updated.items, items, ["id", "batchId", "recycled"])) {
        throw new Error(`REQUEST_PRE_SEND(${eventType}) event handlers must return same items with same ids`);
      }

      items = updated.items;
    }

    if (updated.options) {
      options = mergeWithUndefined({}, options, updated.options);
    }
  }

  return {
    items,
    options,
    cancelled: updated === false
  };
};

const triggerItemsPrepareEvent = (queue, eventSubject, items, options, eventType, validateResponse) => triggerUpdater(queue.trigger, eventType, eventSubject, options) // $FlowIssue - https://github.com/facebook/flow/issues/8215
.then(updated => {
  validateResponse === null || validateResponse === void 0 ? void 0 : validateResponse(updated);
  return processPrepareResponse(eventType, items, options, updated);
});

const persistPrepareResponse = (queue, prepared) => {
  //update potentially changed data back into queue state
  queue.updateState(state => {
    prepared.items.forEach(i => {
      state.items[i.id] = i;
    });
    state.batches[prepared.items[0].batchId].batchOptions = prepared.options;
  }); //use objects from internal state(proxies) - not objects from user-land!

  const updatedState = queue.getState();
  prepared.items = prepared.items.map(item => updatedState.items[item.id]);
  prepared.options = updatedState.batches[prepared.items[0].batchId].batchOptions;
};

const prepareItems = (queue, subject, retrieveItemsFromSubject, createEventSubject, validateResponse, eventType) => {
  const items = retrieveItemsFromSubject(subject);
  const batchOptions = queue.getState().batches[items[0].batchId].batchOptions;
  const eventSubject = (createEventSubject === null || createEventSubject === void 0 ? void 0 : createEventSubject(subject, batchOptions)) || subject;
  return triggerItemsPrepareEvent(queue, eventSubject, items, batchOptions, eventType, validateResponse).then(prepared => {
    if (!prepared.cancelled) {
      persistPrepareResponse(queue, prepared);
    }

    return prepared;
  });
};

const getItemsPrepareUpdater = function (eventType, retrieveItemsFromSubject) {
  let createEventSubject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  let validateResponse = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return (queue, subject) => prepareItems(queue, subject, retrieveItemsFromSubject, createEventSubject, validateResponse, eventType);
};

export { getItemsPrepareUpdater };