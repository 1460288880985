import React, { useEffect, useRef, useState } from "react";

export function MatrixRain() {
  const ref = useRef<HTMLCanvasElement>();

  let [width, setWidth] = useState(window.innerWidth);
  let [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (!ref.current) return;
    // 1. Create canvas
    const canvas = ref.current;
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    // document.body.appendChild(canvas);

    // 2. Rain
    const fontSize = 10;
    const columns = canvas.width / fontSize;
    const drops = [];

    for (let x = 0; x < columns; x++) {
      drops[x] = 1;
    }

    function draw() {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "#0F0";
      ctx.font = fontSize + "px arial";

      for (let i = 0; i < drops.length; i++) {
        const text = String.fromCharCode(Math.random() * 128);
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    }

    let int = setInterval(draw, 33);

    return () => clearInterval(int);
  }, [height, width]);

  useEffect(() => {
    const resizerFunc = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", resizerFunc);

    return () => window.removeEventListener("resize", resizerFunc);
  }, []);

  return <canvas ref={ref} />;
}
