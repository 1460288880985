import Artyom from "artyom.js"
// const artyom = require("artyom.js");
// const artyom = new Artyom();

export function initVoiceCommands() {
    var Jarvis = new Artyom();
    Jarvis.initialize({
        continuous: false,// Artyom will listen forever
        listen: true, // Start recognizing

        debug: true
    })
    var commandHello = {
        indexes: ["change color to *",], // These spoken words will trigger the execution of the command
        smart: true,
        action: function (i, wildcard) { // Action to be executed when a index match with spoken word
            var database = ["red", "green", "blue", "yellow", "black"];
            // Jarvis.say(i);
            // Jarvis.say(wildcard);
            // Jarvis.say(`Changing block color to ${wildcard}`);
            // console.log(database.indexOf(wildcard))
            if (database.indexOf(wildcard) !== -1) {
                Jarvis.say(`Changing block color to ${wildcard}`);
            }
        }
    };

    var commandReset = {
        indexes: ["reset", "start over"], // These spoken words will trigger the execution of the command
        smart: false,
        action: function (i, wildcard) {
            // room.send("resetGame");
        }
    };

    var commandReady = {
        indexes: ["ready", "i'm ready"], // These spoken words will trigger the execution of the command
        smart: false,
        action: function (i, wildcard) {
            // room.send("ready");


        }
    };

    Jarvis.addCommands(commandHello);
    Jarvis.addCommands(commandReset);
    Jarvis.addCommands(commandReady);

    // Needed user interaction at least once in the website to make
    // it work automatically without user interaction later... thanks google .i.
    // document.getElementById("players").addEventListener("click", function () {
    //     Jarvis.say("Hello World !");
    //     Jarvis.say("twhite@rightpoint.com")
    //     Jarvis.say("jmurray@rightpoint.com")
    //     Jarvis.say("ajohnson@rightpoint.com")
    // }, false);
    return Jarvis;
}