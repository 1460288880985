import React, { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import "./fabricJSVideoBuilder.css";
import { meeting } from "@microsoft/teams-js";
import { VIDEOFILTERSAVE_ENDPOINT, MYVIDEOFILTERS_ENDPOINT } from "../../Constants";

// fabric.Object.prototype.objectCaching = true;



export function FabricJSVideoBuilder({ width, height, id, userId }) {
  const canvasRef = useRef<fabric.Canvas>();
  const canvasRef2 = useRef<fabric.Canvas>();
  const [fabricJSCanvas, setFabricJSCanvas] = useState();
  const [fabricJSCanvas2, setFabricJSCanvas2] = useState();

  const images = [
    { name: "rightpoint_logo_white.svg", friendlyName: "Rightpoint" },
    { name: "manulife_wordmark_white.svg", friendlyName: "Manulife" },
    { name: "kroger_white.svg", friendlyName: "Kroger" },
    { name: "Comcast.svg", friendlyName: "Comcast" },
    { name: "staples_logo_transparent.svg", friendlyName: "Staples" },
    { name: "dte.svg", friendlyName: "DTE" },
    { name: "General-Mills-logo_white.svg", friendlyName: "General Mills" },
    { name: "keller_logo.svg", friendlyName: "Keller" },
    { name: "intermountainhealth.svg", friendlyName: "Intermountain Health" },
    { name: "Universal_Pictures_logo.svg", friendlyName: "Universal Pictures" },
    { name: "angelo-gordon-logo.svg", friendlyName: "Angelo Gordon" },
    { name: "JohnHancock_rgb.svg", friendlyName: "John Hancock" },
    { name: "novant.svg", friendlyName: "Novant" },
    { name: "afgroup.svg", friendlyName: "AF Group" },
    { name: "KPMG_logo.svg", friendlyName: "KPMG" },
    { name: "idc.svg", friendlyName: "IDC" },
    
    {
      name: "Paramount_Pictures_Corporation_logo.svg",
      friendlyName: "Paramount",
    },
  ];

  const colors = ["red", "blue", "green", "white", "black"];

  const addImageToCanvas = (imageName: string) => {
    fabric.loadSVGFromURL(`/${imageName}`, function (objects, options) {
      var obj_2 = fabric.util.groupSVGElements(objects, options);
      const scale = fabric.util.findScaleToFit(obj_2, fabricJSCanvas);
      obj_2.set({ scaleX: scale, scaleY: scale });
      fabricJSCanvas?.add(obj_2);
    });
  };

  const addRectangleToCanvas = (color: string) => {
    let rectangle = new fabric.Rect({
      width: 300,
      height: 200,
      fill: color,
      stroke: color,
      strokeWidth: 1,
    });
    rectangle.sendToBack();
    // var obj_2 = fabric.util.groupSVGElements(objects, options);
    // const scale = fabric.util.findScaleToFit(obj_2, fabricJSCanvas);
    // obj_2.set({ scaleX: scale, scaleY: scale });
    fabricJSCanvas.add(rectangle);
  };

  const addTextInputToCanvas = () => {
    let textBox = new fabric.Textbox("Enter your text", {
      fill: "white",
      fontFamily: "proxima-nova, sans-serif",
      top: 100,
      width: 100,
      height: 150,
    });

    fabricJSCanvas.add(textBox);
  };

  const drawGrid = (theCanvas, cellSize, gridWidth, gridHeight, xPos, yPos) => {
    var bkgndrect = new fabric.Rect({
      width: gridWidth + 50,
      height: gridHeight + 50,
      stroke: "#ccc",
      fill: "transparent",
      selectable: false,
    });

    var rect = new fabric.Rect({
      left: 25,
      top: 25,
      width: gridWidth,
      height: gridHeight,
      stroke: "#000000",
      fill: "#cccccc",
      selectable: false,
    });

    var gridGroup = new fabric.Group([bkgndrect, rect], {
      left: xPos,
      top: yPos,
      selectable: false,
    });

    theCanvas.add(gridGroup);

    for (var i = 1; i < gridWidth / cellSize; i++) {
      var line = new fabric.Line([0, 0, 0, gridHeight], {
        left: gridWidth / 2 - i * cellSize,
        top: -gridHeight / 2,
        stroke: "#000000",
        selectable: false,
      });
      gridGroup.add(line);
    }

    for (var i = 1; i < gridHeight / cellSize; i++) {
      var line = new fabric.Line([0, 0, gridWidth, 0], {
        left: -gridWidth / 2,
        top: gridHeight / 2 - i * cellSize,
        stroke: "#000000",
        selectable: false,
      });
      gridGroup.add(line);
    }

    for (var i = 0; i < gridWidth / cellSize; i++) {
      var text = new fabric.Text(String(i * 5), {
        left: -(gridWidth / 2) + i * cellSize,
        top: -(gridHeight / 2) - 20,
        fontSize: 14,
        selectable: false,
      });
      gridGroup.add(text);
    }

    for (var i = 0; i < gridHeight / cellSize; i++) {
      var text = new fabric.Text(String(i * 5), {
        left: -(gridWidth / 2) - 20,
        top: -(gridHeight / 2) + i * cellSize,
        fontSize: 14,
        textAlign: "right",
        selectable: false,
      });
      gridGroup.add(text);
    }

    theCanvas.renderAll();
  };

  const createCanvasEl2 = useCallback(() => {
    if (!fabricJSCanvas) return;
    if (!fabricJSCanvas2) return;

    var designSize = { width: 299, height: 144 };
    var originalVPT = fabricJSCanvas.viewportTransform;
    // zoom to fit the design in the display canvas
    var designRatio = fabric.util.findScaleToFit(designSize, fabricJSCanvas);

    // zoom to fit the display the design in the minimap.
    var minimapRatio = fabric.util.findScaleToFit(
      fabricJSCanvas,
      fabricJSCanvas2
    );

    var scaling = fabricJSCanvas2.getRetinaScaling();

    var finalWidth = designSize.width * designRatio;
    var finalHeight = designSize.height * designRatio;

    fabricJSCanvas.viewportTransform = [
      designRatio,
      0,
      0,
      designRatio,
      (fabricJSCanvas.getWidth() - finalWidth) / 2,
      (fabricJSCanvas.getHeight() - finalHeight) / 2,
    ];
    var cs = fabricJSCanvas.toCanvasElement(minimapRatio * scaling);
    cs.viewportTransform = originalVPT;
    return cs;
  }, [fabricJSCanvas, fabricJSCanvas2]);

  const updateMinimap2 = useCallback(() => {
    var c = createCanvasEl2();
    fabricJSCanvas2.backgroundImage._element = c;
    fabricJSCanvas2.requestRenderAll();
  }, [fabricJSCanvas, fabricJSCanvas2]);

  useEffect(() => {
    if (!canvasRef.current || !canvasRef2.current) return;
    

    // var canvas = new fabric.Canvas(canvasRef.current, { isDrawingMode: true,
    //     freeDrawingBrush: new fabric.PencilBrush({ decimate: 8 })});

    var canvas = new fabric.Canvas(canvasRef.current, {
      containerClass: "design",
      preserveObjectStacking: false,
    });
    canvas.setDimensions({ width: 299, height: 144 });
    setFabricJSCanvas(canvas);

    var canvas2 = new fabric.Canvas(canvasRef2.current, {
      containerClass: "textCanvas",
      selection: false,
    });
    setFabricJSCanvas2(canvas2);

    const createCanvasEl = () => {
      var designSize = { width: 299, height: 144 };
      var originalVPT = canvas.viewportTransform;
      // zoom to fit the design in the display canvas
      var designRatio = fabric.util.findScaleToFit(designSize, canvas);
      // zoom to fit the display the design in the minimap.
      var minimapRatio = fabric.util.findScaleToFit(canvas, canvas2);
      var scaling = canvas2.getRetinaScaling();
      var finalWidth = designSize.width * designRatio;
      var finalHeight = designSize.height * designRatio;


      canvas.viewportTransform = [
        designRatio,
        0,
        0,
        designRatio,
        (canvas.getWidth() - finalWidth) * 2,
        (canvas.getHeight() - finalHeight) * 2,
        // (canvas.getWidth() - finalWidth) / 2,
        // (canvas.getHeight() - finalHeight) / 2,
      ];
      var cs = canvas.toCanvasElement(minimapRatio * scaling);
      cs.viewportTransform = originalVPT;
      return cs;
    };

    const updateMiniMap = () => {
      var c = createCanvasEl();
      canvas2.backgroundImage._element = c;
      canvas2.requestRenderAll();
    };

    const updateMiniMapVP = () => {
      var designSize = { width: 297, height: 167 };
      var rect = canvas2.getObjects()[0];
      var designRatio = fabric.util.findScaleToFit(designSize, canvas);
      var totalRatio = fabric.util.findScaleToFit(designSize, canvas2);
      var finalRatio = designRatio / canvas.getZoom();
      rect.scaleX = finalRatio;
      rect.scaleY = finalRatio;
      rect.top =
        canvas2.backgroundImage.top -
        (canvas.viewportTransform[5] * totalRatio) / canvas.getZoom();
      rect.left =
        canvas2.backgroundImage.left -
        (canvas.viewportTransform[4] * totalRatio) / canvas.getZoom();
      canvas2.requestRenderAll();
    };

    const initMinimap = () => {
      var canvasIn = createCanvasEl();
      var backgroundImage = new fabric.Image(canvasIn);
      backgroundImage.scaleX = 1 / canvas.getRetinaScaling();
      backgroundImage.scaleY = 1 / canvas.getRetinaScaling();
      canvas2.centerObject(backgroundImage);
      // canvas2.backgroundColor = 'black';
      canvas2.backgroundImage = backgroundImage;
      canvas2.requestRenderAll();
      // var minimapView = new fabric.Rect({
      //   top: backgroundImage.top,
      //   left: backgroundImage.left,
      //   width: backgroundImage.width / canvas.getRetinaScaling(),
      //   height: backgroundImage.height/ canvas.getRetinaScaling(),
      // //   fill: 'rgba(0, 0, 255, 0.3)',
      //   cornerSize: 6,
      //   transparentCorners: false,
      //   cornerColor: 'blue',
      //   strokeWidth: 0,
      // });
      // minimapView.controls = {
      //   br: fabric.Object.prototype.controls.br,
      // };
      // canvas2.add(minimapView);
    };

    initMinimap();

    canvas.on("object:modified", function () {
      console.log("Modified object");
      updateMiniMap();
    });

    canvas.on("object:added", function () {
      console.log("Modified object");
      updateMiniMap();
    });

    canvas.on("object:removed", function () {
      console.log("Modified object");
      updateMiniMap();
    });

    canvas.on("object:moving", function () {
      console.log("moving object");
      updateMiniMap();
    });

    var grid = 50;
    var unitScale = 10;
    var canvasWidth = 297;
    var canvasHeight = 167;

    // for (var i = 0; i < canvasWidth / grid; i++) {
    //   canvas.add(
    //     new fabric.Line([i * grid, 0, i * grid, canvasHeight], {
    //       type: "line",
    //       stroke: "#ccc",
    //       selectable: false,
    //     })
    //   );
    //   canvas.add(
    //     new fabric.Line([0, i * grid, canvasWidth, i * grid], {
    //       type: "line",
    //       stroke: "#ccc",
    //       selectable: false,
    //     })
    //   );
    // }

    canvas.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.setZoom(zoom);
      updateMiniMapVP();

      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // canvas.on('before:path:created', function(opt) {
    //     var path = opt.path;
    //     var pathInfo = fabric.util.getPathSegmentsInfo(path.path);
    //     path.segmentsInfo = pathInfo;
    //     var pathLength = pathInfo[pathInfo.length - 1].length;
    //     var text = 'This is a demo of text on a path. This text should be small enough to fit in what you drawn.';
    //     var fontSize = 2.5 * pathLength / text.length;
    //     var text = new fabric.Text(text, { fontSize: fontSize, path: path, top: path.top, left: path.left });
    //     canvas.add(text);
    //     });

    //     canvas.on('path:created', function(opt) {
    //     canvas.remove(opt.path);
    //     })

    // let text = new fabric.Text("Tom White", {
    //   fill: "white",
    //   fontFamily: "proxima-nova, sans-serif",
    //   top: 100,
    //   id: "tom",
    // });
    // canvas.add(text);
    // text.centerH();
    // text.centerV();

    // fabric.Image.fromURL("https://collabtris.sparkworkspace.com/rightpoint_logo_1080_white.svg", function (img) {
    //     canvas.add(img)
    // })

    // fabric.loadSVGFromURL('https://collabtris.sparkworkspace.com/keller_logo.svg', function(objects, options) {
    //     var obj_2 = fabric.util.groupSVGElements(objects, options);
    //     canvas.add(obj_2);
    // });

    // fabric.loadSVGFromURL('https://collabtris.sparkworkspace.com/General-Mills-logo_white.svg', function(objects, options) {
    //     var obj_2 = fabric.util.groupSVGElements(objects, options);
    //     canvas.add(obj_2);
    // });

    // canvas.loadFromJSON(dummyObj, () => {
    //     canvas.renderAll();
    //     updateMiniMap()
    //     fetch("https://tunnel.sparkworkspace.com/madeittohere", { method: "GET" });

    // }, function(o, object) {
    //     fabric.log(o, object);
    // });
  }, [canvasRef]);

  useEffect(() => {
    let callback = function (event) {
      const key = event.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        console.log(key);
        console.log(fabricJSCanvas.getActiveObject());
        fabricJSCanvas.remove(fabricJSCanvas.getActiveObject());
        // return false;
      }
    };
    let listener = document.addEventListener("keydown", callback);

    return () => document.removeEventListener("keydown", callback);
  }, [fabricJSCanvas]);
  return (
    <div style={{ border: "1px solid white" }}>
      <div className="dropdown">
        <label tabIndex={1} className="btn btn-xs m-1">
          Add Image
        </label>
        <ul
          tabIndex={1}
          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          {images?.map((ac) => {
            return (
              <li
                key={ac.name}
                onClick={() => {
                  addImageToCanvas(ac.name);
                  document.activeElement?.blur();
                }}
              >
                {/* <a>{ac.friendlyName}</a> */}
                <img src={"/" + ac.name} />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="dropdown">
        <label tabIndex={1} className="btn btn-xs m-1">
          Add Rectangle
        </label>
        <ul
          tabIndex={1}
          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          {colors?.map((c) => {
            return (
              <li
                key={c}
                onClick={() => {
                  addRectangleToCanvas(c);
                  document.activeElement?.blur();
                }}
              >
                {/* <a>{ac.friendlyName}</a> */}
                {c}
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          addTextInputToCanvas();
        }}
      >
        Add Text
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          fabricJSCanvas.clear();
        }}
      >
        Clear Canvas
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          const dragonCanvas = document.getElementById("dragon") as HTMLCanvasElement;
     

        var dragon = new fabric.Image(dragonCanvas, {
          left: 0,
          top: 0,
          angle: 0,
          originX: 'center',
          originY: 'center',
          objectCaching: false,
        });
       fabricJSCanvas.add(dragon);
       fabric.util.requestAnimFrame(function render() {
        fabricJSCanvas.renderAll();
        updateMinimap2();
        fabric.util.requestAnimFrame(render);
      });
       
        }}
      >
        Dragon
      </div>
 

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          drawGrid(fabricJSCanvas, 50, 600, 400, 0, 0);
        }}
      >
        Draw Grid
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObj = fabricJSCanvas.getActiveObject();

          var color = "white";

          if (activeObj && activeObj._objects) {
            for (var i = 0; i < activeObj._objects.length; i++) {
              activeObj._objects[i].set({
                fill: color,
                stroke: color

              });
            }
          }

          fabricJSCanvas.requestRenderAll();
          updateMinimap2();
        }}
      >
        Update SVG Color White
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObj = fabricJSCanvas.getActiveObject();

          var color = "black";

          if (activeObj && activeObj._objects) {
            for (var i = 0; i < activeObj._objects.length; i++) {
              activeObj._objects[i].set({
                fill: color,
                stroke: color
              });
            }
          }

          fabricJSCanvas.requestRenderAll();
          updateMinimap2();
        }}
      >
        Update SVG Color Black
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          let objectJSON = fabricJSCanvas.toObject();
          console.log(objectJSON);
        }}
      >
        Get Object JSON
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObject = fabricJSCanvas.getActiveObject();
          if (activeObject.type == "group") {
            var items = activeObject._objects;
            // alert(items);
            activeObject._restoreObjectsState();
            fabricJSCanvas.remove(activeObject);
            for (var i = 0; i < items.length; i++) {
              fabricJSCanvas.add(items[i]);
              fabricJSCanvas.item(fabricJSCanvas.size() - 1).hasControls = true;
            }

            fabricJSCanvas.renderAll();
          }
        }}
      >
        Ungroup
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObject = fabricJSCanvas.getActiveObject();

          activeObject.animate("left", activeObject.left === 100 ? 400 : 100, {
            duration: 1000,
            onChange: () => {
              fabricJSCanvas.renderAll();
              updateMinimap2();
            },
            onComplete: function () {
              activeObject.disabled = false;
            },
            easing: fabric.util.ease["easeInQuad"],
          });
        }}
      >
        Animate
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObj = fabricJSCanvas.getActiveObject();
          fabricJSCanvas.sendToBack(activeObj);
          fabricJSCanvas.renderAll();
        }}
      >
        Send to back
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObj = fabricJSCanvas.getActiveObject();
          activeObj.toggle("flipX");
        }}
      >
        Flip X
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObj = fabricJSCanvas.getActiveObject();
          activeObj.set("cropX", 100);
        }}
      >
        Crop X
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={() => {
          var activeObj = fabricJSCanvas.getActiveObject();
          activeObj.set("cropY", 100);
        }}
      >
        Crop Y
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={async () => {
          let objectToSave = {
            userId: userId,
            data: fabricJSCanvas.toObject(),
          };
          let url = process.env.NODE_ENV === "production" ? VIDEOFILTERSAVEENDPOINT : "http://localhost:2567/videofiltersave";
          let status = await fetch(
            url,
            {
              method: "POST",
              body: JSON.stringify(objectToSave),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }}
      >
        Save JSON
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={async () => {
          var webcam = new fabric.Image(
            document.getElementById("otherCanvas"),
            {
              left: 0,
              top: 0,
              originX: "center",
              originY: "center",
              objectCaching: false,
            }
          );
          fabricJSCanvas.add(webcam);

          fabric.util.requestAnimFrame(function render() {
            fabricJSCanvas.renderAll();
            updateMinimap2();
            fabric.util.requestAnimFrame(render);
          });
        }}
      >
        Add Face Mask
      </div>

      <div
        className={"btn btn-primary btn-xs"}
        onClick={async () => {
          let q = { userId: userId };
          let data = await fetch(
            MYVIDEOFILTERS_ENDPOINT,
            {
              method: "POST",
              body: JSON.stringify(q),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then((res) => res.json());

          let firstFilterUrl = data[0].url;
          let f = await fetch(firstFilterUrl, {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }).then((res) => res.json());

          console.log(f);
          fabricJSCanvas.loadFromJSON(
            f,
            () => {
              fabricJSCanvas.renderAll();
              updateMinimap2();
            },
            function (o, object) {
              fabric.log(o, object);
            }
          );
        }}
      >
        Load from Saved Filter
      </div>

      <canvas ref={canvasRef} id={id}></canvas>
      <canvas
        ref={canvasRef2}
        id="textCanvas"
        width="1920"
        height="1080"
        style={
          {
            //   display: "none",
            //   position: "absolute",
            //   top: "10px",
            //   left: "10px",
          }
        }
      ></canvas>
    </div>
  );
}
