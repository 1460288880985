import { AudioDeviceInfo, Call, CallAgent, CallClient, DtmfTone, Features, LocalAudioStream, LocalVideoStream, RemoteParticipant, VideoDeviceInfo, VideoStreamRenderer, VideoStreamRendererView, CallAgentFeature, DeviceManager } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { CommunicationIdentityClient, CommunicationUserToken } from "@azure/communication-identity";
import { Room } from "colyseus.js";
import create, { StateCreator } from "zustand";
import { initVoiceCommands } from "../../Voice";
import { ACS_TOKEN_ENDPOINT, CHATGPT_ENDPOINT } from "../../Constants";
import { ACSRoom } from "../../../backend/ACSRoom";
import { ChatClient, ChatMessage, ChatThreadClient } from "@azure/communication-chat";
import * as FaceMesh from "@mediapipe/face_mesh";
import * as Hands from "@mediapipe/hands";
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { HAND_CONNECTIONS } from "@mediapipe/hands";
import { getThreadIdFromJoinUrl } from "../../Helpers";



export type CallingStore = {
    callClient: CallClient | null;
    callAgent: CallAgent | null;
    deviceManager: DeviceManager | null;
    availableCameras: Array<VideoDeviceInfo> | null;
    availableMicrophones: Array<AudioDeviceInfo> | null;
    availableSpeakers: Array<AudioDeviceInfo> | null;
    selectedCamera: VideoDeviceInfo | null;
    selectedMicrophone: AudioDeviceInfo | null;
    selectedSpeaker: AudioDeviceInfo | null;
    localVideoStream: LocalVideoStream | null;
    connectedCanvasElement: HTMLCanvasElement | null;

    currentlyPlayingSource: AudioBufferSourceNode | null;

    remoteParticipants: Array<RemoteParticipant> | null;
    volumeLevel: number;

    videoElement: HTMLMediaElement | null;
    call: Call | null;

    audioGainNode: GainNode | null;
    audioContext: AudioContext | null;
    mediaDestinationNode: MediaStreamAudioDestinationNode | null;
    allSourcesArray: Array<AudioBufferSourceNode>;

    acsToken: CommunicationUserToken | null;

    callIsConnected: boolean;

    botDisplayName: string;
    chatClient: ChatClient | null;
    chatThreadClient: ChatThreadClient | null;

    outgoingAudioIsMuted: boolean;
    incomingAudioIsMuted: boolean;

    api: {
        getACSToken: () => Promise<CommunicationUserToken>;
        initCallClient: () => Promise<CallClient>;
        initCallAgent: (displayName: string) => Promise<void>;
        joinCall: (joinUrl: string) => void;
        startCallWithPhoneNumber: (phoneNumber: string, audioDeviceInfo: AudioDeviceInfo) => Promise<void>;
        listenForIncomingCalls: () => void;

        loadAvailableCamerasAndMicrophones: () => void;
        setSelectedCamera: (camera: VideoDeviceInfo) => void;
        setSelectedMicrophone: (camera: AudioDeviceInfo) => void;
        setSelectedSpeaker: (camera: AudioDeviceInfo) => void;

        setLocalVideoStream: (stream: LocalVideoStream | null) => void;
        setLocalVideoStreamToSelectedCamera: () => void;

        stopVideo: () => void;
        subscribeToParticipant: (participant: RemoteParticipant, call: Call) => void;
        getAudioTrack: (fileUrl: string) => Promise<{ source: AudioBufferSourceNode, track: MediaStreamTrack, stream: MediaStream }>;
        sendAudioTrack: (fileUrl: string) => void;
        sendAudioTrack2: (fileUrl: string) => void;

        createGainNode: () => void;
        setVolumeLevel: (volumeLevel: number) => void;

        createVideoElement: (src: string, onEndCallback?: () => void) => void;

        stopCurrentlyPlayingSource: () => void;

        setupRenderingCanvas: () => void;
        playVideoFromRenderCanvas: () => void;
        setConnectedCanvasElement: (id: string) => void

        muteOutgoingAudio: () => void;
        unmuteOutgoingAudio: () => void;

        muteIncomingAudio: () => void;
        unmuteIncomingAudio: () => void;

        hangUpCall: () => void;

        showRenderTargetCanvas: () => void;
        hideRenderTargetCanvas: () => void;

        setBotDisplayName: (displayName: string) => void;

        sendMessageInChatThread: (message: string) => void;
        subscribeToChatMessages: (callBackFn: (message: ChatMessage) => void) => void;

        sendDtmfTones: (tonesToSend: string, isNumbers) => void;
    }
}

const createCallingStore: StateCreator<
    CallingStore,
    [],
    [],
    CallingStore
> = (set, get) => ({
    callAgent: null,
    callClient: null,
    deviceManager: null,
    availableCameras: null,
    availableMicrophones: null,
    availableSpeakers: null,

    selectedCamera: null,
    selectedMicrophone: null,
    selectedSpeaker: null,
    localVideoStream: null,
    connectedCanvasElement: null,
    currentlyPlayingSource: null,

    remoteParticipants: [],
    volumeLevel: 1,
    videoElement: null,
    call: null,

    audioGainNode: null,
    audioContext: null,
    mediaDestinationNode: null,

    allSourcesArray: [],

    callIsConnected: false,

    acsToken: null,

    botDisplayName: "",
    chatThreadClient: null,
    chatClient: null,

    outgoingAudioIsMuted: false,
    incomingAudioIsMuted: false,
    api: {
        getACSToken: () => {
            return new Promise((resolve, reject) => {
                let url = process.env.NODE_ENV === "production" ? ACS_TOKEN_ENDPOINT : 'http://localhost:2567/acstoken'

                fetch(url, { method: "GET" }).then((r) => r.json()).then((resp) => {
                    return resolve(resp)
                })
            })
        },
        initCallClient: async () => {
            const callClient = new CallClient();
            set({ callClient: callClient });
            return callClient;
        },
        initCallAgent: async (displayName: string) => {
            const callClient = get().callClient;
            if (!callClient) throw new Error("callClient should not be null");

            let identityResponse = await get().api.getACSToken();
            console.log(identityResponse)
            set({ acsToken: identityResponse });
            const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
            let callAgent = await callClient.createCallAgent(tokenCredential, { displayName: displayName });


            set({ callAgent, botDisplayName: displayName });
        },
        joinCall: async (joinUrl: string) => {
            const callClient = get().callClient;
            if (!callClient) throw new Error("callClient should not be null");

            const callAgent = get().callAgent;
            if (!callAgent) throw new Error("callAgent should not be null");


            // let identityResponse = await get().api.getACSToken();

            // const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
            // let agent = await callClient.createCallAgent(tokenCredential, { displayName: displayName });

            let videoOptions = get().localVideoStream ? { localVideoStreams: [get().localVideoStream] } : null;

            let call = callAgent.join(
                {
                    meetingLink: joinUrl,
                },
                {
                    videoOptions: {
                        ...videoOptions
                    },
                    audioOptions: {

                    }
                }
            );


            call?.on("stateChanged", async () => {
                if (call.state === "Connecting") {

                }
                if (call.state === "Connected") {
                    await get().api.setupRenderingCanvas();
                    set({ callIsConnected: true });

                    const endpointUrl = process.env.REACT_APP_ACS_URL as string;

                    let chatClient = new ChatClient(endpointUrl, new AzureCommunicationTokenCredential(get().acsToken?.token));

                    console.log("Azure Communication Chat client created!");

                    let threadId = getThreadIdFromJoinUrl(joinUrl);
                    let chatThreadClient = chatClient.getChatThreadClient(
                        threadId // "19:meeting_NzIxMmNhOWEtOTBjNi00YTY3LTk0YzItZjE5ZTY1ODdjN2Ji@thread.v2"
                    );

                    set({ chatClient: chatClient, chatThreadClient: chatThreadClient })
                }

            })
            call.remoteParticipants.forEach((remoteParticipant) => {
                console.log("FOUND REMOTE PARTICIPANT", remoteParticipant);
            });

            call.on("remoteParticipantsUpdated", (e) => {
                // Subscribe to new remote participants that are added to the call.
                e.added.forEach((remoteParticipant) => {
                    console.log("REMOTE PARTICPANT ADDED", remoteParticipant);
                    get().api.subscribeToParticipant(remoteParticipant, call);

                    // set({ remoteParticipants: [...get().remoteParticipants || [], remoteParticipant] })
                });
                set({ remoteParticipants: [...call.remoteParticipants.values()] })
                // set((state) => {
                //     if (!state.remoteParticipants) {
                //         return { remoteParticipants: [...e.added] }
                //     }
                //     else
                //         return {
                //             remoteParticipants: [...state.remoteParticipants, ...e.added]
                //         }
                // })


                // Unsubscribe from participants that are removed from the call
                // e.removed.forEach((remoteParticipant) => {
                //     console.log("Remote participant removed from the call.");
                //     const existingRemoteParticipants = get().remoteParticipants;
                //     if (!existingRemoteParticipants) return;

                //     const userToRemoveIndex = existingRemoteParticipants.findIndex((rp) => {
                //         return rp.identifier === remoteParticipant.identifier;
                //     })
                //     existingRemoteParticipants.splice(userToRemoveIndex, 1);
                //     set({ remoteParticipants: [...existingRemoteParticipants] });

                // });
            });

            get().api.createGainNode();


            // const captionsFeature = call.feature(Features.Captions)
            // captionsFeature.on("captionsReceived", (data) => {
            //     console.log("RECEIVED CAPTIONS DATA", data);
            // })


            set({ call });
        },
        startCallWithPhoneNumber: async (phoneNumber: string, audioDeviceInfo: AudioDeviceInfo) => {
            let identityResponse = await get().api.getACSToken();

            const callClient = new CallClient();
            const tokenCredential = new AzureCommunicationTokenCredential(identityResponse.token);
            let agent = await callClient.createCallAgent(tokenCredential, { displayName: "hidden" });

            // const callClient = get().callClient;
            // if (!callClient) throw new Error("callClient should not be null");

            // const callAgent = get().callAgent;
            // if (!callAgent) throw new Error("callAgent should not be null");
            // let las = new LocalAudioStream(audioDeviceInfo);
            let call = agent.startCall([{ phoneNumber: phoneNumber }], { alternateCallerId: { phoneNumber: process.env.REACT_APP_ACS_PHONE_NUMBER } });
            call?.on("stateChanged", async () => {
                if (call.state === "Connecting") {
                    console.log("connecting...")
                    // set({ callIsConnecting: true })
                }
                if (call.state === "Disconnected") {
                    set({ callIsConnected: false });

                    // set({ callIsConnected: false, callIsConnecting: false })
                }
                if (call.state === "Connected") {
                    set({ callIsConnected: true });
                }
            });


            get().api.createGainNode();
            set({ call });

        },
        listenForIncomingCalls: () => {
            const callClient = get().callClient;
            if (!callClient) throw new Error("callClient should not be null");

            const callAgent = get().callAgent;
            if (!callAgent) throw new Error("callAgent should not be null");

            callAgent.on("incomingCall", async ({ incomingCall }) => {
                console.log("Incoming call detected", incomingCall)
                let call = await incomingCall.accept();
                set({ call })
            });

            console.log("started listneing for incoming calls")
        },
        loadAvailableCamerasAndMicrophones: async () => {
            let callClient = get().callClient;
            if (!callClient) throw new Error("callClient was not initialized yet");

            const deviceManager = await callClient.getDeviceManager();
            let cameras = await deviceManager.getCameras();
            let microphones = await deviceManager.getMicrophones();
            let speakers = await deviceManager.getSpeakers();

            set({ deviceManager: deviceManager, availableCameras: cameras, availableMicrophones: microphones, availableSpeakers: speakers });
        },
        setSelectedCamera: (camera: VideoDeviceInfo) => {

            set({ selectedCamera: camera })
        },
        setSelectedMicrophone: async (microphone: AudioDeviceInfo) => {
            let deviceManager = get().deviceManager;
            if (!deviceManager) throw new Error("deviceManager was not initialized yet");
            await deviceManager.selectMicrophone(microphone)
            set({ selectedMicrophone: microphone })
        },
        setSelectedSpeaker: async (speaker: AudioDeviceInfo) => {
            let deviceManager = get().deviceManager;
            if (!deviceManager) throw new Error("deviceManager was not initialized yet");
            await deviceManager.selectSpeaker(speaker)
            set({ selectedSpeaker: speaker })
        },
        setLocalVideoStream: (stream: LocalVideoStream | null) => {
            set({ localVideoStream: stream });
        },
        setLocalVideoStreamToSelectedCamera: async () => {
            const selectedCamera = get().selectedCamera;
            if (!selectedCamera) throw new Error("no camera was selected");

            const call = get().call;
            if (!call) throw new Error("call should not be null");

            if (call.isLocalVideoStarted) {
                await call.stopVideo(call.localVideoStreams[0])
                // await call.stopVideo(get().localVideoStream);
            }
            const stream = new LocalVideoStream(selectedCamera);
            get().api.setLocalVideoStream(stream);
            await call.startVideo(stream);
        },
        stopVideo: () => {
            const call = get().call;
            if (!call) throw new Error("call should not be null");

            const localVideoStream = get().localVideoStream;
            if (localVideoStream) {
                call.stopVideo(localVideoStream);
                set({ localVideoStream: null })
            }
        },
        subscribeToParticipant: (participant: RemoteParticipant, call: Call): void => {
            // const dominantParticipantCount = utils.isSafari()
            //   ? Constants.DOMINANT_PARTICIPANTS_COUNT_SAFARI
            //   : Constants.DOMINANT_PARTICIPANTS_COUNT;
            // const remoteStreamSelector = RemoteStreamSelector.getInstance(dominantParticipantCount, dispatch);

            participant.on('stateChanged', () => {
                //   remoteStreamSelector.participantStateChanged(
                //     utils.getId(participant.identifier),
                //     participant.displayName ?? '',
                //     participant.state,
                //     !participant.isMuted,
                //     participant.videoStreams[0].isAvailable
                //   );
                set({ remoteParticipants: [...call.remoteParticipants.values()] })
                //   dispatch(setParticipants([...call.remoteParticipants.values()]));
            });

            participant.on('isMutedChanged', () => {
                console.log("SOMEBODIES MUTE CHANGED")
                //   remoteStreamSelector.participantAudioChanged(utils.getId(participant.identifier), !participant.isMuted);
            });

            participant.on('isSpeakingChanged', () => {
                console.log("SOMEBODIES SPEAKING CHANGED", participant.isSpeaking)
                console.log(participant.displayName)
                set({ remoteParticipants: [...call.remoteParticipants.values()] })
            });

            participant.on('videoStreamsUpdated', (e): void => {
                e.added.forEach((addedStream) => {
                    if (addedStream.mediaStreamType === 'ScreenSharing') {
                        //   addedStream.on('isAvailableChanged', () => {
                        //     if (addedStream.isAvailable) {
                        //       dispatch(addScreenShareStream(addedStream, participant));
                        //     } else {
                        //       dispatch(removeScreenShareStream(addedStream, participant));
                        //     }
                        //   });

                        //   if (addedStream.isAvailable) {
                        //     dispatch(addScreenShareStream(addedStream, participant));
                        //   }
                    } else if (addedStream.mediaStreamType === 'Video') {
                        addedStream.on('isAvailableChanged', () => {
                            console.log("VIDEO ISAVAILABLE CHANGED", addedStream.isAvailable)
                            // remoteStreamSelector.participantVideoChanged(utils.getId(participant.identifier), addedStream.isAvailable);
                        });
                    }
                });
                set({ remoteParticipants: [...call.remoteParticipants.values()] })
            });
        },
        createGainNode: () => {

            const context = new AudioContext();
            const mediaDestinationNode = context.createMediaStreamDestination();

            const gainNode = context.createGain();
            gainNode.connect(mediaDestinationNode);

            set({ audioContext: context, audioGainNode: gainNode, mediaDestinationNode: mediaDestinationNode })
        },
        setVolumeLevel: (volumeLevel: number) => {
            const gn = get().audioGainNode;
            if (!gn) throw new Error("gainNode was null");
            gn.gain.value = volumeLevel;
            set({ volumeLevel: volumeLevel })
        },

        createVideoElement: async (src: string, onEndCallback?: () => void) => {
            await get().api.setupRenderingCanvas();

            let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
            let renderCtx = renderTarget.getContext("2d");

            let alreadyPlayingVideoElement = get().videoElement;
            if (alreadyPlayingVideoElement) {
                console.log("WE ARE ALREADY PLAYING A VIDEO!");
                alreadyPlayingVideoElement.pause();

                get().api.stopCurrentlyPlayingSource();
            }

            let videoElement = document.createElement("video");
            videoElement.src = src;
            videoElement.crossOrigin = "anonymous";
            videoElement.controls = true;
            videoElement.muted = true;
            videoElement.hidden = true;

            videoElement.addEventListener("loadedmetadata", function () {
                // retrieve dimensions
                const height = this.videoHeight;
                const width = this.videoWidth;

                videoElement.height = height;
                videoElement.width = width;

                renderTarget.height = height;
                renderTarget.width = width;
            }, false);


            // document.body.append(videoElement);

            let { source, track, stream } = await get().api.getAudioTrack(src);
            const localAudioStream = new LocalAudioStream(stream);

            videoElement.onplay = async () => {
                console.log("VIDEO ELEMENT ON PLAY IS HERE!!!");
                get().call?.startAudio(localAudioStream).then(() => {

                    //
                })
                source.start()

            }

            videoElement.onended = async () => {
                console.log("VIDEO ENDED?");
                // get().api.setVideoToIdle();
                onEndCallback && await onEndCallback();
                set({ videoElement: null })
            }
            videoElement.play();
            const call = get().call;
            if (!call) throw new Error("call should not be null");

            // if (call.isLocalVideoStarted && get().localVideoStream !== null) {
            //     const lv = get().localVideoStream;
            //     if (lv) {
            //         call.stopVideo(lv);
            //     }
            // }


            // renderTarget.width = videoElement.videoWidth;
            // renderTarget.height = videoElement.videoHeight;

            // let width = renderTarget.width;
            // let height = renderTarget.height;

            let paintCount = 0;
            let startTime = 0.0;

            const updateCanvas = (now, metadata) => {
                if (startTime === 0.0) {
                    startTime = now;
                }
                // renderCtx.drawImage(videoElement, 0, 0, renderTarget.width, renderTarget.height);
                // renderCtx.fillRect(0, 0, renderTarget.width, renderTarget.height);
                // renderCtx.font = "30px Arial";
                // renderCtx.fillStyle = "white";

                // renderCtx.fillText("Hello World", 20, 500);
                const elapsed = (now - startTime) / 1000.0;
                const fps = (++paintCount / elapsed).toFixed(3);

                renderCtx.font = "30px Arial";
                renderCtx.fillStyle = "red";
                renderCtx.clearRect(0, 0, renderTarget.width, renderTarget.height);
                renderCtx.drawImage(videoElement, 0, 0, renderTarget.width, renderTarget.height);
                renderCtx.fillText(fps, renderTarget.width / 2, renderTarget.height / 2);
                //   fpsInfo.innerText = `video fps: ${fps}`;
                //   metadataInfo.innerText = JSON.stringify(metadata, null, 2);

                videoElement.requestVideoFrameCallback(updateCanvas);
            };

            videoElement.requestVideoFrameCallback(updateCanvas);
            await get().api.playVideoFromRenderCanvas();
            set({ connectedCanvasElement: null, videoElement: videoElement })

        },
        stopCurrentlyPlayingSource: () => {
            let source = get().currentlyPlayingSource;
            if (!source) return;

            source.stop();
            set({ currentlyPlayingSource: null });
        },
        getAudioTrack: async (fileUrl: string) => {
            const context = get().audioContext;

            if (!context) throw new Error("audioContext should not be null");

            const gainNode = get().audioGainNode;
            if (!gainNode) throw new Error("gainNode was null");

            const mediaDestinationNode = get().mediaDestinationNode;
            if (!mediaDestinationNode) throw new Error("mediaDestinationNode was null");
            return new Promise<{ source: AudioBufferSourceNode, track: MediaStreamTrack, stream: MediaStream }>((resolve, reject) => {
                window
                    .fetch(fileUrl)
                    .then((response) => response.arrayBuffer())
                    .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
                    .then((audioBuffer) => {
                        const mediaDestinationNode = context.createMediaStreamDestination();
                        const source = new AudioBufferSourceNode(context, { buffer: audioBuffer });
                        set({ currentlyPlayingSource: source, allSourcesArray: [...get().allSourcesArray, source] });

                        // const gainNode = context.createGain();

                        gainNode.gain.value = get().volumeLevel;
                        source.connect(gainNode)
                        gainNode.connect(mediaDestinationNode);
                        let track = mediaDestinationNode.stream.getAudioTracks()[0];

                        return resolve({ source: source, track: track, stream: mediaDestinationNode.stream });
                    });
            })

        },
        sendAudioTrack: async (fileUrl: string) => {
            return new Promise<void>(async (resolve, reject) => {
                let { source, track, stream } = await get().api.getAudioTrack(fileUrl)
                const localAudioStream = new LocalAudioStream(stream);
                source.start()
                
                    source.onended = () => {
                        console.log("AUDIO ENDED?");
                        resolve();
                    }
                    get().call?.startAudio(localAudioStream).then(() => {

                        //
                    })
              
            })

        },
        sendAudioTrack2: async (fileUrl: string) => {
            // return new Promise<void>(async (resolve, reject) => {
            //     let { source, track } = await get().api.getAudioTrack(fileUrl)
            //     const localAudioStream = new LocalAudioStream(track);
            //     source.start()
            //     localAudioStream.getMediaStreamTrack().then((t) => {
            //         source.onended = () => {
            //             console.log("AUDIO ENDED?");
            //             resolve();
            //         }
            //         get().call?.startAudio(localAudioStream).then(() => {

            //             //
            //         })
            //     })
            // })

        },
        setupRenderingCanvas: async () => {

            let canvasElement = document.getElementById("renderTarget") as HTMLCanvasElement;
            if (!canvasElement) {
                console.log("Couldn't setupRenderingCanvas because it was null")
                return;
            }
            canvasElement.hidden = true;

            let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
            let ctx = renderTarget.getContext("2d") as RenderingContext;

            let width = renderTarget.width;
            let height = renderTarget.height;

            function render(time) {
                let connectedCanvasElement = get().connectedCanvasElement;
                if (connectedCanvasElement) {

                    ctx.clearRect(0, 0, width, height);

                    ctx.drawImage(connectedCanvasElement, 0, 0, width, height);
                }
                // else {
                //     console.log("no connected element")
                // }

                requestAnimationFrame(render);
            }
            requestAnimationFrame(render);
        },
        playVideoFromRenderCanvas: async () => {
            let call = get().call;
            if (!call) throw new Error("call was not initialized yet");

            let currentlyPlayingStream = get().localVideoStream;
            if (currentlyPlayingStream && call.isLocalVideoStarted) {
                await call.stopVideo(currentlyPlayingStream);
            }

            console.log("PLAYING FROM RENDER CANVAS");
            let canvasElement = document.getElementById("renderTarget") as HTMLCanvasElement;

            const stream = canvasElement.captureStream(30);
            const localVideoStream = new LocalVideoStream(stream);
            set({ localVideoStream: localVideoStream });
            await call.startVideo(localVideoStream)


        },
        
        setConnectedCanvasElement: async (id: string) => {
            if ((typeof id) === "object") {

                set({ connectedCanvasElement: id })
                return;
            }

            let canvasElement = document.getElementById(id) as HTMLCanvasElement;

            if (canvasElement.tagName && canvasElement.tagName === "DIV") {
                canvasElement = canvasElement.children[0];
            }

            // let renderTarget = document.getElementById("renderTarget") as HTMLCanvasElement;
            // renderTarget.height = canvasElement.height;
            // renderTarget.width = canvasElement.width;
            set({ connectedCanvasElement: canvasElement })
        },
        muteOutgoingAudio: async () => {
            let call = get().call;

            if (!call?.isMuted) {
                await call?.mute()
            }
            set({ outgoingAudioIsMuted: true })
        },
        unmuteOutgoingAudio: async () => {
            let call = get().call;
            if (call?.isMuted) {
                await call?.unmute()
            }
            set({ outgoingAudioIsMuted: false })

        },
        muteIncomingAudio: async () => {
            let call = get().call;
            await call?.muteIncomingAudio()
            set({ incomingAudioIsMuted: true })

        },
        unmuteIncomingAudio: async () => {
            let call = get().call;
            await call?.unmuteIncomingAudio()
            set({ incomingAudioIsMuted: false })

        },
        hangUpCall: async () => {
            let call = get().call;
            await call?.hangUp()
        },
        showRenderTargetCanvas: () => {
            let canvasElement = document.getElementById("renderTarget") as HTMLCanvasElement;
            canvasElement.hidden = false;
        },
        hideRenderTargetCanvas: () => {
            let canvasElement = document.getElementById("renderTarget") as HTMLCanvasElement;
            canvasElement.hidden = true;
        },
        setBotDisplayName: (displayName: string) => {
            set({ botDisplayName: displayName })
        },
        sendMessageInChatThread: async (message: string) => {
            let chatThreadClient = get().chatThreadClient;
            if (!chatThreadClient) throw new Error("chatThreadClient was not initialized yet");

            let sendMessageRequest = { content: message };
            let sendMessageOptions = { senderDisplayName: "Tom AI" };
            chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions).then(() => {
                console.log("Message sent!");
            });
        },
        subscribeToChatMessages: (callbackFn: (message) => void) => {
            let chatClient = get().chatClient;
            if (!chatClient) throw new Error("chatThreadClient was not initialized yet");
            chatClient.startRealtimeNotifications().then(() => {

                // subscribe to new message notifications
                chatClient?.on("chatMessageReceived", (e) => {
                    console.log("Notification chatMessageReceived!", e);
                    console.log("MESSAGE IS", e.message);

                    callbackFn(e);
                    //   // check whether the notification is intended for the current thread
                    // if (threadIdInput.value != e.threadId) {
                    //   return;
                    // }

                    // if (e.sender.communicationUserId != userId) {
                    //    renderReceivedMessage(e.message);
                    // }
                    // else {
                    //    renderSentMessage(e.message);
                    // }

                    // if (e.senderDisplayName === "ChatGPT") {
                    //     return;
                    // }
                    // chatThreadClient.sendTypingNotification({ senderDisplayName: "ChatGPT" });


                    // let url = process.env.NODE_ENV === "production" ? CHATGPT_ENDPOINT : 'http://localhost:2567/chatgpt'

                    // const toPost = { "prompt": e.message.replace("<p>", "").replace("</p>", "").replace(`<span style="font-size:inherit">`, "").replace("</span>", "") };

                    // fetch(url, { method: "POST", body: JSON.stringify(toPost), headers: { "Content-Type": "application/json" } }).then((r) => r.text()).then((resp) => {
                    //     console.log(resp)
                    //     let sendMessageRequest = { content: resp };
                    //     let sendMessageOptions = { senderDisplayName: "ChatGPT" };
                    //     chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions).then(() => {
                    //         console.log("Message sent!");
                    //     });
                    // })

                });




            });
        },
        sendDtmfTones: async (tonesToSend, isNumbers: boolean) => {
            console.log("sending dtmf tones", tonesToSend)
            let call = get().call;
            if (!call) return;

            const meetingId = "91429745402";
            const meetingPassword = "553708"

            let waitMilliseconds = (millisecondsToWait: number) => {
                return new Promise<void>((resolve, reject) => {
                    setTimeout(() => {
                        resolve();
                    }, millisecondsToWait)
                })
            }

            setTimeout(async () => {
                if (isNumbers) {
                    for (const c of tonesToSend) {
                        await waitMilliseconds(500);
                        let dtmfString = "Num" + c;
                        console.log("Sending DTMF", dtmfString);
                        await call?.sendDtmf(dtmfString as DtmfTone)
                    }
                }
                else {
                    await call?.sendDtmf(tonesToSend as DtmfTone)
                }

                // await call?.sendDtmf("Pound");

                // await waitMilliseconds(1000);
                // await call?.sendDtmf("Pound");
                // console.log("I am before waiting")
                // await waitMilliseconds(5000);
                // console.log("I am after waiting")
                // // call.sendDtmf("Star")

                // for (const c of meetingPassword) {
                //     await waitMilliseconds(2000);
                //     let dtmfString = "Num" + c;
                //     console.log("Sending DTMF", dtmfString);
                //     await call?.sendDtmf(dtmfString as DtmfTone)
                // }

                // await call?.sendDtmf("Pound");

            }, 5000)
        },
    },

})


// export const useCallingStore = create<CallingStore>((set, get) => ({

// }))

export const useCallingStore = create<CallingStore>()((...a) => ({
    ...createCallingStore(...a),

}));

export const useCallingStore2 = create<CallingStore>()((...a) => ({
    ...createCallingStore(...a),

}));