import { UPLOADER_EVENTS } from "@rpldy/uploader";
import { createRequestUpdateHoc } from "./createRequestUpdateHoc";
const withRequestPreSendUpdate = createRequestUpdateHoc({
  eventType: UPLOADER_EVENTS.REQUEST_PRE_SEND,
  getIsValidEventData: (id, _ref) => {
    let {
      items
    } = _ref;
    return !!items.find(item => item.id === id);
  },
  getRequestData: _ref2 => {
    let {
      items,
      options
    } = _ref2;
    return {
      items,
      options
    };
  }
});
export default withRequestPreSendUpdate;