import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { SplashScreen } from "./components/SplashScreen";
import { PingPong } from "./PingPong";
import { Link, NavLink } from "react-router-dom";
import { useColyseusRoom, useColyseusRoom2 } from "./hooks/useColyseusRoom";
import { TomsState } from "../state/TomsState";
import { PlayersScreen } from "./components/PlayersScreen/PlayersScreen";
import { defaultPageFadeInVariants } from "./motionUtils";
import "./app.scss";
import "./index.css";
import { ExperienceWrapper } from "./components/ExperienceWrapper/ExperienceWrapper";
import { FlappyGame } from "./experiences/FlappyGame/FlappyGame";
import { PuzzleGame } from "./experiences/PuzzleGame/PuzzleGame";
import { CameraFun } from "./experiences/CameraFun/CameraFun";
import { useTeams } from "msteams-react-base-component";
// import { WatchParty } from "./experiences/WatchParty";
import { app, teamsCore, pages } from "@microsoft/teams-js";

import {
  getMicrosoftTeamsContext,
  getMicrosoftTeamsContextOrNull2,
  getMyPhotoBase64,
  getOnBehalfOfToken,
  getTeamsAuthToken,
  isUserInSidePanel,
} from "./Helpers";
import { useGlobalStore2, useTomStore } from "./hooks/useGlobalState";
import soundOff from "url:./sound-off.png";
import soundOnSrc from "url:./audio-speaker-on.png";
import { SoundBoard, SoundBoard2 } from "./experiences/Soundboard/Soundboard";
import { MissionControl } from "./experiences/MissionControl/MissionControl";
import { QuestionPrompt } from "./experiences/QuestionPrompt/QuestionPrompt";
import jwt_decode from "jwt-decode";
import { DebugScreen } from "./components/DebugScreen";
// import { CameraFun } from "./experiences/CameraFun/CameraFun";
import { SoundButton } from "./components/SoundButton";
import { Drawing } from "./experiences/Drawing/Drawing";
import { ConfigurePage } from "./components/ConfigurePage/ConfigurePage";
import { useWebWorker } from "./hooks/useWebWorker";
import { Shaders } from "./experiences/Shaders/Shaders";
import { ExperienceDisplay } from "./components/ExperienceDisplay/ExperienceDisplay";
import { LeaderMenu } from "./components/LeaderMenu/LeaderMenu";
import { ChooseExperience } from "./components/ChooseExperience/ChooseExperience";
import { Pipes } from "./experiences/Pipes/Pipes";
import { BlockGame } from "./experiences/BlockGame/BlockGame";
import { MissionControlDynamicWrapper } from "./experiences/MissionControl/MissionControlDynamicImportWrapper";
import {
  CharacterCreated,
  ReadyPlayerMeWrapper,
} from "./components/ReadyPlayerMe/ReadyPlayerMe";
import {
  MissionUIController,
  MissionUIManager,
} from "./experiences/MissionControl/MissionUI/MissionUI";
import { MissionControl2 } from "./experiences/MissionControl/MissionControl2";
import { ResultsPage } from "./components/ResultsPage/ResultsPage";
import { License } from "./components/License/License";
// import { VR } from "./experiences/VR/VR";
import { VarChanger } from "./components/VarChanger/VarChanger";
import { SidePanel } from "./components/SidePanel/SidePanel";
// import { Playground } from "./experiences/Playground/Playground";
// import { Search } from "./components/Search/Search";
import { MeetingRPGDynamicWrapper } from "./experiences/MeetingRPG/src/MeetingRPGDynamicWrapper";
import { useMicrosoftTeams } from "./hooks/useMicrosoftTeams";
import { Snap21 } from "./experiences/Snap21/Snap21";
import { SoundboardStage } from "./experiences/Soundboard/SoundboardStage";
import { Home } from "./experiences/TOSIOS/scenes/Home";
// import { MatchWrapper, PlayernameWrapper } from "./experiences/TOSIOS/scenes/Match";
import { MatrixRain } from "./components/MatrixRain/MatrixRain";
import { Xylophone } from "./experiences/Xylophone/Xylophone";
import { RubiksCube3 } from "./experiences/RubiksCube/RubiksCube";
import { CursorTracker } from "./experiences/CursorTracker/CursorTracker";
import { MeetingBall } from "./experiences/MeetingBall/MeetingBall";
import { P5Experience } from "./experiences/P5/P5";
import { MatchWrapper } from "./experiences/TOSIOS/scenes/MatchWrapper";
import { Drums } from "./experiences/Drums/Drums";
import { Video } from "./experiences/Video/Video";
import { ShaderDemo } from "./experiences/ShaderDemo/ShaderDemo";
import { SoundboardPopup } from "./experiences/Soundboard/SoundboardPopup";
import { LiveShare } from "./components/LiveShare/LiveShare";
import { Compose } from "./components/Compose/Compose";
import { AccordionMenu } from "./experiences/Soundboard/MenuAccordion";
import { Timesheet } from "./experiences/Timesheet/Timesheet";
import { Dragon } from "./experiences/Dragon/Dragon";
import { FallingDown } from "./experiences/FallingDown/FallingDown";
import {
  ACS,
  ACS4,
  ACSJoiner,
  ACSPhone,
  ACSView,
  WelcomeToShout,
} from "./components/ACS/ACS";
import { Planet } from "./experiences/Planet/Planet";
import { DownloadPage } from "./components/Download/DownloadPage";
import { HandsPage, MediapipePage } from "./components/Mediapipe/MediapipePage";
import { Megaphone } from "./components/Megaphone/Megaphone";
import { CustomTeamsClient, Inspire, MeetingAI } from "./components/ACS/Shout";
import { Speech } from "./components/Speech/Speech";
import { ParticleVisualizer } from "./components/ParticleVisualizer/ParticleVisualizer";
import Wave from "./components/Wave/Wave";
import Canvas from "./components/Wave/WaveCanvas";
import { VideoOutput } from "./components/ACS/VideoOutput";
import { MicrosoftCallUI } from "./components/ACS/CallAdapterComponent";
import { CollabAI } from "./components/ACS/CollabAI";
import { GraphBuilder } from "./components/ACS/GraphBuilder";
import { MSALPage } from "./components/MSAL/MSALPage";
import { LivePage } from "./components/Live/LivePage";
import { KnowledgeHub, KnowledgeHubRemove } from "./components/KnowledgeHub/KnowledgeHub";
import { FabricJSVideoBuilder } from "./experiences/Video/FabricJSVideoBuilder";
import { AFrameExperience } from "./experiences/AFrame/AFrameExperience";
import { AvatarThing, Playground } from "./experiences/Playground/Playground";
import { Shadcdn } from "./components/Shadcn/Shadcn";
import { SpeechSave } from "./components/Speech/SpeechSave";
import { Jesse } from "./components/Jesse/Jesse";
import { VapiExperience } from "./experiences/VapiExperience/VapiExperience";

async function getUserPhotoAndName() {
  let token = await getTeamsAuthToken();

  let photoBase64 = "";
  try {
    let oboToken = await getOnBehalfOfToken(token);
    photoBase64 = await getMyPhotoBase64(oboToken.access_token);
    var decoded = jwt_decode<{ name: string }>(token);
  } catch (err) {}
  return { photo: photoBase64, name: decoded.name };
}

async function getUserPhoto(token: string) {
  let photoBase64 = await getMyPhotoBase64(token);
  return photoBase64;
}
function decodeTokenForUsername(token: string) {
  let decoded = jwt_decode<{ name: string }>(token);
  return decoded.name;
}

const PlayerPhoto = () => {
  const playerPhoto = useGlobalStore2((state) => state.playerImageBase64);

  return (
    <div>{playerPhoto ? <img src={playerPhoto} /> : <div>no photo</div>}</div>
  );
};

const SidePanelRedirect = () => {
  const history = useHistory();
  const isUserInSidePanel = useGlobalStore2(
    (state) => state.isPlayerSidePanelUser
  );
  if (isUserInSidePanel && !(window.location.pathname === "/sidepanel")) {
    // window.location.href = "/reveal.html";
    history.replace("/sidepanel");
  }
  return null;
};

const TeamsContextAppHandler = () => {
  const history = useHistory();

  const teamsContext = useGlobalStore2((state) => state.teamsContext);
  const setIsHoveredOverAppIcon = useGlobalStore2(
    (state) => state.setIsAppIconHoveredOver
  );

  if (teamsContext) {
    console.log("TEAMS CONTEXT", teamsContext);
    console.log("FRAMECONTEXT", teamsContext.page.frameContext);
    teamsContext.meeting
      ? sessionStorage.setItem("meetingId", teamsContext.meeting.id)
      : () => {};
    teamsContext.meeting
      ? localStorage.setItem("meetingId", teamsContext.meeting.id)
      : () => {};

    switch (teamsContext.page.frameContext) {
      case "content":
        if (window.location.pathname !== "/results") {
          if (
            window.location.search &&
            window.location.search.indexOf("direct") !== -1
          ) {
            console.log("GOING DIRECT");
          } else {
            if (window.location.pathname === "/acs") {
              history.replace("/acscontent");
            } else {
              history.replace("/results");
            }
          }
        }
        break;
      case "meetingStage":
        if (window.location.pathname !== "/") {
          if (window.location.search.indexOf("direct") !== -1) {
            console.log("GOING DIRECT");
          } else {
            if (
              window.location.pathname === "/acs" ||
              window.location.pathname === "/hands" ||
              window.location.pathname === "/liveshare" ||
              window.location.pathname === "/collabai"
            ) {
              console.log("ACS OR HANDS PAGE OR LIVESHARE OR COLLABAI HERE");
            } else {
              history.replace("/");
            }
          }
        }
        break;
      case "sidePanel":
        console.log("PAGE ID IS", teamsContext.page.id);
        console.log("PATHNAME ID IS", window.location.pathname);

        if (window.location.pathname !== "/sidepanel") {
          if (window.location.pathname === "/player") {
            return;
          }
          if (teamsContext.page.id === "soundboard") {
            console.log("SHOULD BE SOUNDBOARD 2", window.location.pathname);
            return null;
          }

          if (window.location.pathname === "/timesheet") {
            if (
              window.location.search &&
              window.location.search.indexOf("direct") !== -1
            ) {
              console.log("GOING DIRECT");
              return null;
            } else {
              return null;
            }
          }

          if (window.location.pathname === "/acs") {
            console.log("THIS IS THE ACS PAGE");
            if (
              window.location.search &&
              window.location.search.indexOf("direct") !== -1
            ) {
              console.log("DIRECT NULL");
              return null;
            } else {
              console.log("ELSE NULL");
              return null;
            }
          }

          if (window.location.pathname === "/hands") {
            console.log("THIS IS THE HANDS PAGE");
            if (
              window.location.search &&
              window.location.search.indexOf("direct") !== -1
            ) {
              console.log("DIRECT NULL");
              return null;
            } else {
              console.log("ELSE NULL");
              return null;
            }
          }

          if (window.location.pathname === "/liveshare") {
            if (
              window.location.search &&
              window.location.search.indexOf("direct") !== -1
            ) {
              return null;
            } else {
              return null;
            }
          }

          if (window.location.pathname === "/collabai") {
            if (
              window.location.search &&
              window.location.search.indexOf("direct") !== -1
            ) {
              return null;
            } else {
              return null;
            }
          }

          if (window.location.pathname === "/live") {
            if (
              window.location.search &&
              window.location.search.indexOf("direct") !== -1
            ) {
              return null;
            } else {
              return null;
            }
          }

          if (window.location.pathname === "/video.html") {
            history.replace("/video");
            return null;
          }

          history.replace("/sidepanel");
        }
        break;
    }
    // if (
    //   teamsContext.page.frameContext === "content" &&
    //   !(window.location.pathname === "/results") &&
    //   !(window.location.pathname === "/sidepanel")
    // ) {
    //   window.location.href = "/results";
    // }

    // if (teamsContext.page.frameContext === "meetingStage" && window.location.pathname === "/results") {
    //   window.location.href = "/";
    // }

    if (
      teamsContext.page.frameContext === "sidePanel" ||
      teamsContext.page.frameContext === "meetingStage" ||
      teamsContext.page.frameContext === "settings" ||
      teamsContext.page.frameContext === "task"
    )
      return null;

    pages.appButton.onHoverEnter(() => {
      console.log("APP HOVER");
      setIsHoveredOverAppIcon(true);
    });
    pages.appButton.onHoverLeave(() => {
      console.log("APP STOP HOVER ");
      setIsHoveredOverAppIcon(false);
    });
    pages.appButton.onClick(() => {
      console.log("APP BUTTON CLICK");
    });
  }
  return null;
};

const AppInner2 = () => {
  return (
    <Switch location={location} key={location.pathname}>
      <Route exact path="/join">
        <Shout />
      </Route>
    </Switch>
  );
};

const AppInner = () => {
  const location = useLocation();
  let history = useHistory();
  const teamsContext = useGlobalStore2((state) => state.teamsContext);
  const isInTeams = useGlobalStore2((state) => state.isInTeamsStatus);
  const playerImage = useGlobalStore2((state) => state.playerImageBase64);
  const playerName = useGlobalStore2((state) => state.playerName);
  const setPlayerIsLeader = useGlobalStore2((state) => state.setIsPlayerLeader);
  const setDebugMenu = useGlobalStore2((state) => state.setDebugMenuOn);
  const debugMenuIsOn = useGlobalStore2((state) => state.debugMenuOn);

  const params = new URLSearchParams(location.search);
  const debugMenuParam = params.get("debug");
  if (debugMenuParam) {
    setDebugMenu(true);
  }
  if (teamsContext) {
    if (teamsContext.page.frameContext === "meetingStage") {
      console.log("WE ARE ON THE MEETING STAGE");
    }
    if (teamsContext.meeting) {
      console.log("WE ARE IN A MEETING", teamsContext.meeting);
    }
  }
  const { client, room } = useColyseusRoom<TomsState>(
    "tom",
    teamsContext ? teamsContext : null
  );

  useEffect(() => {
    if (!room) return;
    room.onMessage("experienceSelected", (message) => {
      if (window.location.pathname === "/sidepanel") return;
      history.push("/" + message.experienceName);
    });

    // room.onMessage("newPlayerImage", (message) => {
    //   setPlayerInCache(message.player.id, message);
    // });

    room.onStateChange.once((state) => {
      if (
        state.selectedExperience &&
        window.location.pathname !== "/sidepanel"
      ) {
        history.push("/" + state.selectedExperience);
      }
      // room creator?
    });

    return () => {
      room.removeAllListeners();
    };
  }, [room]);

  useEffect(() => {
    if (!room) return;

    if (playerImage && playerName) {
      room.send("gotPlayerImage", {
        imageBase64: playerImage,
        playerName: playerName,
      });
    }
    if (playerName && !playerImage) {
      console.log("GOT PLAYER NAME ONLY", playerImage, playerName);
      room.send("gotPlayerImage", {
        imageBase64: playerImage,
        playerName: playerName,
      });
    }
  }, [room, playerImage, playerName]);

  // useEffect(() => {
  //   if (!roomState || !room) return;
  //   let currentPlayer = roomState.players.players.find((p) => p.id === room.sessionId);

  //   if (!currentPlayer) {
  //     console.log("NO CURRENT PLAYER, must be in sidepanel");
  //     return;
  //   }
  //   if (currentPlayer.isLeader) {
  //     setPlayerIsLeader(true);
  //   } else {
  //     setPlayerIsLeader(false);
  //   }
  // }, [roomState, room]);
  // if (!room) return null;

  return (
    <>
      <motion.div
        key={"sound"}
        initial={{
          opacity: 0,
          position: "absolute",
          zIndex: 999,
          right: "5px",
          top: "0px",
        }}
        animate={{ opacity: 1, top: "10px" }}
        exit={{ opacity: 0 }}
      >
        <div className="flex flex-row space-x-2">
          {debugMenuIsOn && <LeaderMenu room={room} client={client} />}
          {/* <SoundButton /> */}
          <VarChanger />
        </div>
      </motion.div>

      <Switch location={location} key={location.pathname}>
        <Route exact path="/">
          <Canvas />
          <ChooseExperience room={room} client={client} />
        </Route>
        <Route exact path="/client">
          <CustomTeamsClient />
        </Route>
        <Route exact path="/blockgame">
          <BlockGame />
        </Route>
        <Route exact path="/speech">
          <SpeechSave />
        </Route>
        <Route exact path="/jesse">
          <Jesse />
        </Route>
        <Route exact path="/jess-e">         
          <VapiExperience />
        </Route>
        {/* <Route exact path="/sidepanel">
          <SidePanel room={room} client={client} />
        </Route> */}
        {/* <Route exact path="/breakroom">
          <Pipes />
        </Route>
        <Route exact path="/blockgame">
          <BlockGame />
        </Route>

        <Route exact path="/shaderdemo">
          <ShaderDemo />
        </Route>
        <Route exact path="/liveshare">
          <LiveShare />
        </Route> */}

        <Route exact path="/compose">
    

          <Compose />
          {/* <MSALPage /> */}
        </Route>
        <Route exact path="/download">
          <DownloadPage />
        </Route>
        <Route exact path="/splash">
          <SplashScreen />
        </Route>
        <Route exact path="/live">
          <LivePage />
        </Route>
        <Route exact path="/knowledgehub">
          <KnowledgeHub />
        </Route>
        <Route exact path="/knowledgehubremove">
          <KnowledgeHubRemove />
        </Route>
        <Route exact path="/hands">
          <HandsPage />
        </Route>
        <Route exact path="/collabai">
          <CollabAI />
        </Route>
        <Route exact path="/graph">
          <GraphBuilder />
        </Route>
        <Route exact path="/fabric">
          <FabricJSVideoBuilder id={"test"} width={300} height={200} />
        </Route>
        <Route exact path="/particles">
          <ParticleVisualizer height={"100px"} width={"100px"} />
        </Route>
        <Route exact path="/msal">
          <MSALPage />
        </Route>
        <Route exact path="/aframe">
         <AFrameExperience />
        </Route>
        <Route exact path="/playground">
          <AvatarThing avatarUrl={"https://cdn.glitch.com/29e07830-2317-4b15-a044-135e73c7f840%2FAshtra.vrm?v=1630342336981"} />
        </Route>
        {/* <Route exact path="/meetingball">
          <MeetingBall />
        </Route> */}
        {/* <Route exact path="/search">
          <Search />
        </Route> */}
        <Route exact path="/breakroom">
          <Pipes showControls={true} canvasId={"none"} soundEnabled={true} />
        </Route>

        <Route exact path="/snap21">
          <Snap21 />
        </Route>
        {/* <Route exact path="/shaders">
          <Shaders />
        </Route> */}
        {/* <Route exact path="/vr">
          <VR />
        </Route> */}
        {/* <Route exact path="/matrix">
          <MatrixRain />
        </Route>
        <Route exact path="/dragon">
          <Dragon />
        </Route> */}
        {/* <Route exact path="/license">
          <License />
        </Route>
        <Route exact path="/accordion">
          <AccordionMenu
            child1={<>This is my child</>}
            child2={<>This is my child</>}
            child3={<>This is my child</>}></AccordionMenu>
        </Route> */}
        {/* <Route exact path="/p5">
          <P5Experience />
        </Route>
        <Route exact path="/playground">
          <Playground room={room} />
        </Route> */}
        {/* <Route exact path="/xylophone">
          <Xylophone room={room} />
        </Route>
        <Route exact path="/packabunchas">
          <PuzzleGame />
        </Route>
        <Route exact path="/drawing">
          <Drawing />
        </Route>
        <Route exact path="/cube">
          <RubiksCube3 />
        </Route> */}
        <Route exact path="/xylophone">
          <Xylophone  />
        </Route>
              <Route exact path="/drawing">
          <Drawing />
        </Route>
           <Route exact path="/cube">
          <RubiksCube3 />
        </Route> 
        <Route exact path="/shout">
          <ACSJoiner />
        </Route>
        <Route exact path="/phone">
          <ACSPhone />
        </Route>
        <Route exact path="/acsphone">
          
        </Route>
        <Route exact path="/inspire">
          <Inspire />
        </Route>
        <Route exact path="/acscontent">
          <WelcomeToShout />
        </Route>
        <Route exact path="/planet">
          <Planet />
        </Route>
        {/* <Route exact path="/thecall">
          <MicrosoftCallUI />
        </Route> */}
        <Route exact path="/meetingrpg">
          <MeetingRPGDynamicWrapper room={room} />
        </Route>
        <Route exact path="/camerafun">
          <CameraFun room={room} />
        </Route>
        <Route exact path="/configure">
          <ConfigurePage />
        </Route>
        <Route exact path="/timesheet">
          <Timesheet />
        </Route>
    
        {/* <Route exact path="/flappy">
          <FlappyGame />
        </Route>
        <Route exact path="/pingpong">
          <PingPong />
        </Route> */}
        {/* <Route exact path="/watchparty">
          <ExperienceWrapper title={"Watch Party"}>
            <WatchParty
              videoUrl={
                "https://sparkprovisioningstor.blob.core.windows.net/videos/out.mp4?sv=2019-12-12&st=2021-08-27T13%3A05%3A49Z&se=2065-08-28T13%3A05%3A00Z&sr=b&sp=r&sig=QY0bkYKo9OPxY9cjK44Kl9XxOpfJvoBwK6ZZyPuw8fo%3D"
              }
            />
          </ExperienceWrapper>
        </Route> */}
        {/* <Route exact path="/question">
          <QuestionPrompt />
        </Route> */}
        {/* <Route exact path="/sizzlereel">
          <ExperienceWrapper title={"Sizzle Reel"}>
            <WatchParty videoUrl={"https://player.vimeo.com/video/502696604"} />
          </ExperienceWrapper>
        </Route> */}
        <Route exact path="/soundboard">
          <SoundBoard2 room={room} client={client} />
        </Route>
        <Route exact path="/soundboardstage">
          <ExperienceWrapper title={"Soundboard"}>
            <SoundboardStage room={room} />
          </ExperienceWrapper>
        </Route>
        <Route exact path="/soundboardpopup">
          <SoundboardPopup />
        </Route>
        <Route exact path="/fallingdown">
          <FallingDown room={room} client={client} />
        </Route>
        <Route exact path="/acs">
          <ACS />
        </Route>
        <Route exact path="/acs2">
          <ACS4 />
        </Route>
        <Route exact path="/acsview">
          <ACSView room={room} />
        </Route>
        <Route exact path="/speech">
          <Speech />
        </Route>
        <Route exact path="/meetingai">
          <MeetingAI />
        </Route>
        <Route exact path="/video">
          <Video />
        </Route>
        {/* <Route exact path="/missioncontrol">
          <MissionControlDynamicWrapper />
        </Route>
        <Route exact path="/player">
          <ReadyPlayerMeWrapper room={room} client={client} />
        </Route>
        <Route exact path="/character">
          <CharacterCreated />
        </Route> */}

        {/* <Route exact path="/gameroom">
          <MatchWrapper />
        </Route> */}
        {/* <Route exact path="/drums">
          <Drums room={room} />
        </Route>
        <Route exact path="/video">
          <Video />
        </Route> */}
        {/* <Route exact path="/gameroom/:roomId">
          <MatchWrapper />
        </Route> */}
        <Route exact path="/choose">
          <ChooseExperience room={room} client={client} />
        </Route>
        {/* <Route exact path="/missionui">
          <MissionUIManager />
          <MissionUIController />
        </Route> */}
        {/* <Route exact path="/missioncontrol2">
          <MissionControl />
        </Route> */}
        {/* <Route exact path="/results">
          <ResultsPage room={room} />
        </Route> */}

        {/* <Route exact path="/cursor">
          <CursorTracker room={room} client={client} />
        </Route> */}
        <Route exact path="/errors">
          <DebugScreen />
        </Route>
        <Route exact path="/debug">
          <DebugScreen />
        </Route>
        {/* <Route path="/*">
          <SoundButton />
        </Route> */}
      </Switch>
    </>
  );
};

const AppInsideTeams = () => {
  return (
    <div>
      <div>
        <AppInner />
      </div>
    </div>
  );
};

const AppNotInsideTeams = () => {
  return (
    <div>
      <AppInner />
    </div>
  );
};

const App2 = () => {
  const location = useLocation();
  let history = useHistory();
  const teamsContext = useGlobalStore2((state) => state.teamsContext);
  const isInTeams = useGlobalStore2((state) => state.isInTeamsStatus);
  const playerImage = useGlobalStore2((state) => state.playerImageBase64);

  switch (isInTeams) {
    case "unknown":
      return <div></div>;
    case "inTeams":
      return <AppInsideTeams />;
    case "notInTeams":
      // return <AppNotInsideTeams />;
      return (
        <div>
          <AppNotInsideTeams />
        </div>
      );
  }
};

function App3() {
  console.log("RENDER APP 3");
  return (
    <Switch location={location} key={location.pathname}>
      <Route exact path="/">
        Hello World
      </Route>
      <Route exact path="/liveshare">
        <LiveShare />
      </Route>
    </Switch>
  );
}

function StartupDelay({ children }) {
  const [delayFinished, setDelayFinished] = useState(false);

  useEffect(() => {
    let meetingId = sessionStorage.getItem("meetingId");
    if (meetingId) {
      console.log("FOUND MEETING ID, SKIPPING DELAY");
      setDelayFinished(true);
    } else {
      console.log("DID NOT FIND MEETING ID, NOT SKIPPING DELAY");
    }
    let timeout = setTimeout(() => {
      setDelayFinished(true);
    }, 5000);
    return () => clearInterval(timeout);
  }, []);
  return delayFinished ? <div>{children}</div> : null;
}

export const StartupWrapper = ({ children }) => {
  const setPlayerTeamsContext = useGlobalStore2(
    (state) => state.setTeamsContext
  );
  const setPlayerInTeamsStatus = useGlobalStore2(
    (state) => state.setInTeamsStatus
  );
  const playerIsInTeamsStatus = useGlobalStore2(
    (state) => state.isInTeamsStatus
  );
  const setPlayerPhoto = useGlobalStore2((state) => state.setPlayerImageBase64);
  const setUserIsInSidePanel = useGlobalStore2(
    (state) => state.setIsPlayerSidePanelUser
  );
  const setPlayerName = useGlobalStore2((state) => state.setPlayerName);
  const setTeamsToken = useGlobalStore2((state) => state.setTeamsToken);
  const setOnBehalfOfToken = useGlobalStore2(
    (state) => state.setOnBehalfOfToken
  );

  const [delayFinished, setDelayFinished] = useState(false);

  const [{ inTeams, context }] = useMicrosoftTeams({});

  useEffect(() => {
    if (inTeams) {
      setPlayerInTeamsStatus("inTeams");
    }
    if (!inTeams && delayFinished) {
      setPlayerInTeamsStatus("notInTeams");
    }
    if (context) {
      console.log("TEAMS CONTEXT IS", context);
      setPlayerTeamsContext(context);
      setUserIsInSidePanel(isUserInSidePanel(context));
      getTeamsAuthToken().then((token) => {
        setTeamsToken(token);
        let name = decodeTokenForUsername(token);
        console.log("PLAYER NAME IS", name);
        setPlayerName(name);

        getOnBehalfOfToken(token).then((oboToken) => {
          setOnBehalfOfToken(oboToken.access_token);
          getUserPhoto(oboToken.access_token).then((photoBase64) => {
            setPlayerPhoto(photoBase64);
          });
        });
      });
    }
  }, [inTeams, delayFinished]);

  useEffect(() => {
    if (inTeams) return;
    if (window.location.href.indexOf("localhost") !== -1)
      setDelayFinished(true);

    let timeout = setTimeout(() => {
      setDelayFinished(true);
    }, 5000);
  }, [inTeams]);

  if (!inTeams && delayFinished) {
    return <div>{children}</div>;
  }

  if (playerIsInTeamsStatus === "inTeams") {
    return <div>{children}</div>;
  } else {
    const params = new URLSearchParams(window.location.search);
    const isTeamsParamThere = params.get("inTeams");
    if (params.get("inTeams")) {
      return <div>{children}</div>;
    } else {
      return (
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="flex items-center justify-center">
              {/* <progress className="progress w-56"></progress> */}
              <Megaphone />

              {/* <div className="spinner-border animate-spin inline-block h-8 border-4 rounded-full" role="status">
              <span className="sr-only">Loading...</span>
            </div> */}
            </div>
          </div>
        </div>
      );
    }
  }
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <PersistGate persistor={persistor}>
                    <App />
                </PersistGate> */}
      {/* <App /> */}

      {/* <StartupDelay> */}
      <StartupWrapper>
        <TeamsContextAppHandler />

        <App2 />
      </StartupWrapper>
      {/* </StartupDelay> */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("app")
);
