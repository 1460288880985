import React, { useEffect } from "react"
import { import_ } from '@brillout/import'

// import { OpenAI } from "langchain/llms";
// import { LLMChain } from "langchain/chains";
// import { PromptTemplate } from "langchain/prompts";

async function LangChainThing(){
//     const { OpenAI } = await import_('langchain');
// const { LLMChain } = await import_('langchain/chains');
// const { PromptTemplate } = await import_('langchain/prompts');

// const llm = new OpenAI({});
//        const template = "What is a good name for a company that makes {product}?";
// const prompt = new PromptTemplate({
//   template: template,
//   inputVariables: ["product"],
// });
//        const chain = new LLMChain({ llm: llm, prompt: prompt });
}


export function LivePage(){

    
    useEffect(() => {
       LangChainThing();
    }, [])
    return <div>Cool</div>
}