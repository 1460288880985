import { ArraySchema, Schema, type } from "@colyseus/schema";

export class CubePlacement extends Schema {

    @type("number")
    x: number;

    @type("number")
    y: number;

    @type("number")
    z: number;

    constructor() {
        super();
        // this.x = x;
        // this.y = y;
    }
}

export class VoxelBoard extends Schema {
    @type(["number"])
    values: number[];

    @type("number")
    rows: number;

    @type("number")
    cols: number;

    @type([CubePlacement])
    cubes: ArraySchema<CubePlacement>;

    constructor(rows: number = 20, cols: number = 20) {
        super();
        this.rows = rows;
        this.cols = cols;
        this.cubes = new ArraySchema<CubePlacement>();
    }

    public addCube(x: number, y: number, z: number) {

        const cube = new CubePlacement().assign({ x: x, y: y, z: z });

        this.cubes.push(cube);
    }
}