import { BATCH_STATES, createBatchItem, isPromise } from "@rpldy/shared";
import { DEFAULT_FILTER } from "./defaults";
import { getIsFileList } from "./utils";
let bCounter = 0;

const processFiles = (batchId, files, isPending, fileFilter) => {
  return Promise.all(Array.prototype //$FlowExpectedError[method-unbinding] flow 0.153 !!!
  .map.call(files, f => {
    const filterResult = (fileFilter || DEFAULT_FILTER)(f);
    return isPromise(filterResult) ? filterResult.then(result => !!result && f) : !!filterResult && f;
  })).then(filtered => filtered.filter(Boolean).map(f => createBatchItem(f, batchId, isPending)));
};

const createBatch = (files, uploaderId, options) => {
  bCounter += 1;
  const id = `batch-${bCounter}`;
  const isFileList = getIsFileList(files);
  files = Array.isArray(files) || isFileList ? files : [files];
  const isPending = !options.autoUpload;
  return processFiles(id, files, isPending, options.fileFilter).then(items => {
    return {
      id,
      uploaderId,
      items,
      state: isPending ? BATCH_STATES.PENDING : BATCH_STATES.ADDED,
      completed: 0,
      loaded: 0,
      orgItemCount: items.length,
      additionalInfo: null
    };
  });
};

export default createBatch;