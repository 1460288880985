import React, { useEffect } from "react";
import { Howl, Howler } from "howler";
import { dialog, meeting } from "@microsoft/teams-js";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { Room } from "colyseus.js";
import { TomsState } from "../../../state/TomsState";
import { DaisyPlayer, PlayerImageWithMenu } from "../../components/PlayersScreen/PlayersScreen";
import { v4 as uuidv4 } from "uuid";
import { startVisualizer } from "./SoundVisualizer";
import { AnimatedVisualizer } from "./AnimatedVisualizer";
import ReactPlayer from "react-player";

export function isVideoFile(url) {
  return url.split("?")[0].endsWith(".mp4");
}
export function SoundboardStage({ room }: { room: Room<TomsState> }) {
  const [soundUrl, setSoundUrl] = React.useState("");
  const [soundName, setSoundName] = React.useState("");

  const [message, setMessage] = React.useState("");
  const [from, setFrom] = React.useState("");
  const [soundsPlayedQueue, setSoundPlayedQueue] = React.useState<Array<any>>([]);

  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  let userId = "NONE";
  if (teamsCtx && teamsCtx.user && teamsCtx.user.id) {
    userId = teamsCtx.user.id;
  }

  const pushToSoundsPlayedQueue = (message) => {
    setSoundPlayedQueue((prev) => [...prev, message]);
  };

  React.useEffect(() => {
    console.log("HELLO WORLD");
    const params = new URLSearchParams(window.location.search); // id=123
    console.log("PARAMS", params);
    console.log(window.location.href);
    let soundUrlParam = params.get("soundUrl"); // 123
    soundUrlParam = atob(soundUrlParam);
    console.log("NEW SOUND URL", soundUrlParam);
    let from = params.get("from");
    let name = params.get("name");
    let soundName = params.get("soundName");
    console.log("SOUNDURL", soundUrlParam);
    if (window.location.href.indexOf("fromNotification") !== -1) {
      let id = uuidv4();
      const url =
        "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Audience%20Impressed.mp3?sv=2020-10-02&se=2022-05-13T18%3A47%3A41Z&sr=c&sp=rade&sig=tBTYfvxJtyBEw%2FQYZ6W%2BUsbOzu7qMT%2FWmyZXDOkyhk4%3D";
      pushToSoundsPlayedQueue({
        url: url,
        from: "Tom",
        name: "Tom Name",
        soundName: "WOW",
        id: id,
        isPlaying: true,
        timestamp: new Date(),
      });
      setSoundUrl(url);
      setFrom("TOM");
      setSoundName("NAME OF SOUND");
      return;
    }
    setSoundUrl(soundUrlParam);

    setFrom(from);
    setSoundName(soundName);

    let id = uuidv4();
    pushToSoundsPlayedQueue({
      url: soundUrl,
      from: from,
      name: name,
      soundName: soundName,
      id: id,
      isPlaying: true,
      timestamp: new Date(),
    });

    // setTimeout(() => {
    //   startVisualizer(Howler.ctx);
    // }, 500);
  }, []);

  useEffect(() => {
    let listener = room.onMessage("playSound", (message) => {
      console.log(message);

      let id = uuidv4();
      message.id = id;
      message.isPlaying = true;
      message.timestamp = new Date();
      pushToSoundsPlayedQueue(message);

      if (isVideoFile(message.url)) return;

      var sound = new Howl({
        src: [message.url],
        html5: true,
        onend: () => {
          setSoundPlayedQueue((prev) => {
            let idx = prev.findIndex((p) => p.id === id);
            prev[idx].isPlaying = false;
            return [...prev];
          });
        },
      });

      sound.play();
    });

    let killSoundListener = room.onMessage("killAllSounds", (message) => {
      Howler.mute();
    });
    return () => {
      listener();
      killSoundListener();
    };
  }, []);

  React.useEffect(() => {
    // if (userId === from) {
    //   console.log("USER IS THE ONE PLAYING THE SOUND");
    //   setSoundPlayedQueue((prev) => {
    //     prev[0].isPlaying = false;
    //     return [...prev];
    //   });
    //   return;
    // }

    if (isVideoFile(soundUrl)) return;

    var sound = new Howl({
      src: [soundUrl],
      html5: true,
      onend: () => {
        setSoundPlayedQueue((prev) => {
          prev[0].isPlaying = false;
          return [...prev];
        });
        // dialog.submit();
      },
    });

    sound.play();
  }, [soundUrl]);
  // debugger;
  return (
    <div>
      <div>
        {soundsPlayedQueue
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((sp) => {
            let player = room.state.players.players.find((p) => sp.from === p.aadId);
            if (!player) {
              player = room.state.players.players[0];
              player.playerName = "Unknown";
            }
            return (
              <div key={sp.id}>
                <div className={"flex"}>
                  <PlayerImageWithVisualizer player={player} room={room} visualizerOn={sp.isPlaying} />
                  {isVideoFile(sp.url) && (
                    <div>
                      <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        url={sp.url}
                        // muted={!soundIsOn}
                        playing={true}
                        controls={true}
                        onSeek={(seconds) => {}}
                        onPlay={() => {
                          // room.send("play");
                          setSoundPlayedQueue((prev) => {
                            let idx = prev.findIndex((p) => p.id === sp.id);
                            prev[idx].isPlaying = true;
                            return [...prev];
                          });
                        }}
                        onPause={() => {
                          // room.send("pause");
                          setSoundPlayedQueue((prev) => {
                            let idx = prev.findIndex((p) => p.id === sp.id);
                            prev[idx].isPlaying = false;
                            return [...prev];
                          });
                        }}
                        onEnded={() => {
                          setSoundPlayedQueue((prev) => {
                            let idx = prev.findIndex((p) => p.id === sp.id);
                            prev[idx].isPlaying = false;
                            return [...prev];
                          });
                        }}
                      />
                    </div>
                  )}
                </div>

                <span className={"text-primary"}>{sp.name}</span>
                <span>{` played `}</span>
                <span className={"text-secondary"}>{sp.soundName}</span>
                <span>{`.`}</span>
              </div>
            );
          })}
      </div>
      {/* <div style={{ position: "absolute", top: 0, zIndex: 0 }} className="wrapper" id="wrapper">
        <label className="file" htmlFor="file">
          choose a mp3 file
          <input id="file" type="file" />
        </label>
      </div> */}
    </div>
  );
}

export function PlayerImageWithVisualizer({ player, room, visualizerOn }) {
  return (
    <div className={""}>
      <PlayerImageWithMenu player={player} key={player.id} room={room} showMenu={false} />
      {visualizerOn && (
        <div style={{ position: "absolute", marginTop: "-20px" }}>
          <AnimatedVisualizer />
        </div>
      )}
    </div>
  );
}
