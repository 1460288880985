import "./splash.scss";

const SplashScreen = () => {
  return (
    <div className={"splash"}>
      <div className="container">
        <span className="title">D</span>
        <span className="title">I</span>
        <span className="title">S</span>
        <span className="title">T</span>
        <span className="title">R</span>
        <span className="title">A</span>
        <span className="title">C</span>
        <span className="title">T</span>
        <span className="title">I</span>
        <span className="title">O</span>
        <span className="title">N</span>
      </div>
      <div id="splash" className="splash flex-container">
        <div id="experienceName"></div>

        <div id="splashPlayers"></div>

        <div id="readyButton">READY</div>

        <img id="userimage" />
        <div id="userimages"></div>
      </div>
    </div>
  );
};

export { SplashScreen };
