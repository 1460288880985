import create from "zustand";

export type FallingDownPlayer = {
  xPosition: number;
  yPosition: number;
  zPosition: number;
};

export type FallingDownState = {
  time: number;
  players: FallingDownPlayer[];
  addPlayer: (player: FallingDownPlayer) => void;

  backwallXRotation: number;
  setBackwallXRotation: (rotation: number) => void;
};

export const useFallingDownState = create<FallingDownState>((set) => ({
  time: 0,
  players: [],
  addPlayer: (player) => set((state) => ({ players: state.players.concat(player) })),

  backwallXRotation: -0.0,
  setBackwallXRotation: (rotation) => set((state) => ({ backwallXRotation: rotation })),
}));
