import create from "zustand";
import { GetState, SetState } from "zustand";

type CardStackState = {
  cards: Array<any>;
  addCard: (card) => void;
};

class CardStack {
  cards: Array<any>;
  constructor(props) {
    this.cards = [];
  }
  addCard(card) {
    this.cards = this.cards.concat(card);
  }

  public get cards2(): Array<any> {
    return this.cards;
  }
}
export const createCardStackState = () =>
  create<CardStackState>((set) => ({
    cards: [],
    addCard: (card) =>
      set((state) => {
        set({ cards: state.cards.concat(card) });
      }),
  }));

type Snap21GameState = {
  deck: {};
  setDeck: (deck) => void;

  stacks: Array<CardStack>;
  addStack: () => void;
  addCardToStack: (stackIndex: number, card) => void;
};

export const useSnap21GameState = create<Snap21GameState>((set) => ({
  deck: {},
  setDeck: (deck) => set({ deck: deck }),

  stacks: [],
  addStack: () =>
    set((state) => {
      let n = new CardStack({});
      set({ stacks: state.stacks.concat(n) });
    }),
  addCardToStack: (stackIndex, card) =>
    set((state) => {
      let theStack = state.stacks[stackIndex];
      theStack.addCard(card);
    }),
}));

export const select = {
  topCards: (state) => {
    if (!state.deck || !state.deck.cards) return [];
    return state.deck.cards.splice(0, 3);
  },
};
