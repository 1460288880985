import { Call, CaptionsInfo, Features } from "@azure/communication-calling";
import create from "zustand";
import { OPENAI_ENDPOINT } from "../../Constants";
import { BuiltInPrompts } from "./Prompts";

export type GraphState = {
    counter: number,
    graph: {
        nodes: Array<any>,
        edges: Array<any>
    }
}
export type GraphBuilderStore = {

    userPrompt: string;
    graphState: GraphState;

    api: {
        setUserPrompt: (text: string) => void;
        clearGraphState: () => void;
        generateNewGraphWithGPT3: () => void;
        setGraphState: (newState: GraphState) => void;
    };
};

export const useGraphBuilderStore = create<GraphBuilderStore>((set, get) => ({
    userPrompt: "",
    graphState: {
        counter: 0,
        "graph": {
            "nodes": [

            ],
            "edges": [

            ]
        }
    },
    api: {
        setUserPrompt: (text: string) => {
            set({ userPrompt: text })
        },
        clearGraphState: () => {
            set({
                graphState: {
                    counter: 0,
                    graph: {
                        nodes: [],
                        edges: []
                    }
                }
            })
        },
        setGraphState: (newState: GraphState) => {
            set({ graphState: newState })
        },
        generateNewGraphWithGPT3: () => {
            console.log("Generating new graph!");
            let basePrompt = BuiltInPrompts.find((p) => p.friendlyName === "Graph Builder 2")?.text;
            let userPrompt = get().userPrompt;
            let newPrompt = basePrompt?.replace("$prompt", userPrompt).replace("$state", JSON.stringify(get().graphState))
            // basePrompt?.replace("$state", JSON.stringify(get().graphState))
            let payload = {
                prompt: `${newPrompt}`,
                model: "text-davinci-003",
                "temperature": 0.3,
                "max_tokens": 800,
                "top_p": 1,
                "frequency_penalty": 0,
                "presence_penalty": 0
            };
            console.log(payload)
            let url = process.env.NODE_ENV === "production" ? OPENAI_ENDPOINT : "http://localhost:2567/openai";
            console.log(`Asking OpenAI: `, JSON.stringify(payload));
            return new Promise((resolve, reject) => {
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                })
                    .then(async (resp) => {
                        return await resp.json();
                    })
                    .then((resp) => {
                        console.log(resp);

                        const text = resp.choices[0].text;
                        console.log(text);
                        const new_graph = JSON.parse(text);
                        console.log(new_graph);
                        get().api.setGraphState(new_graph);

                        resolve(resp);
                    });
            });
        }
    },
}));
