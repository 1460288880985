import { UPLOADER_EVENTS } from "@rpldy/uploader";
import { createRequestUpdateHoc } from "./createRequestUpdateHoc";
const withBatchStartUpdate = createRequestUpdateHoc({
  eventType: UPLOADER_EVENTS.BATCH_START,
  getIsValidEventData: (id, batch) => batch.id === id,
  getRequestData: (batch, batchOptions) => ({
    batch,
    items: batch.items,
    options: batchOptions
  })
});
export default withBatchStartUpdate;