import { FaceMesh, NormalizedLandmarkListList, ResultsListener } from "@mediapipe/face_mesh";
import { InputMap } from "@mediapipe/holistic";
import create from "zustand";

export type FacemeshResultsStore = {
    faceMesh: null | FaceMesh;
    image: any;
  multiFaceLandmarks: NormalizedLandmarkListList;
  api: {
    initFacemesh: () => void;
    send: (inputs: InputMap) => Promise<void>;
    setMultiFaceLandmarks: (landmarks: NormalizedLandmarkListList) => void;
  };
};

export const useFacemeshResultsStore = create<FacemeshResultsStore>(
  (set, get) => ({
    faceMesh: null,
    image: null,
    multiFaceLandmarks: [],
    api: {
      initFacemesh: () => {
        // create media pipe facemesh instance
        const facemesh = new FaceMesh({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
          },
        });

        facemesh.setOptions({
          selfieMode: true,
          maxNumFaces: 1,
          refineLandmarks: true,
          minDetectionConfidence: 0.5,
          minTrackingConfidence: 0.5,
        });

        facemesh.onResults((results) => {
            console.log("facemesh onresults", results);
            set({ image: results.image, multiFaceLandmarks: results.multiFaceLandmarks })
        });
        console.log("SET FACEMSH", facemesh);
        set({ faceMesh: facemesh });
      },
      send: async (inputs) => {
        console.log("FACEMSH", get().faceMesh);
        get().faceMesh?.send(inputs);
      },
      setMultiFaceLandmarks: (landmarks) => {
        set({ multiFaceLandmarks: landmarks });
      }
    },
  })
);
