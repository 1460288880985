import React, { useEffect, useRef, useState } from "react";
import { useColyseusRoom } from "../../hooks/useColyseusRoom";
import { BlockGameState } from "../../../state/BlockGameState";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { CubePlacement } from "../../../state/VoxelBoard";
import { Howl, Howler } from "howler";
import soundUrl from "url:./../../soundscrate-coin-01.mp3";
import bloopSoundUrl from "url:./../../soundscrate-bloop-01.mp3";
import backgroundMusicSound from "url:./../../soundscrate-playground-day.mp3";
import { initVoiceCommands } from "../../Voice";
import { motion } from "framer-motion";
import { useSoundManager } from "../../hooks/useSoundManager";

export function BlockGame() {
  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  const playerName = useGlobalStore2((store) => store.playerName);
  const playerImage = useGlobalStore2((store) => store.playerImageBase64);

  let toMerge = { name: playerName || "defaultName" };
  let joinObj = {
    ...teamsContext,
    ...toMerge,
    ...{ imageBase64: playerImage },
  };

  const [ready, setReady] = useState(false);

  const { client, room, roomState } = useColyseusRoom<BlockGameState>("blockgame", joinObj);
  let soundManager = useSoundManager();

  useEffect(() => {
    if (!room) return;

    let camera, scene, renderer;
    let plane;
    let pointer,
      raycaster,
      isShiftDown = false;

    let rollOverMesh, rollOverMaterial;
    let cubeGeo, cubeMaterial;

    let playersHtml = document.getElementById("players");
    let clock = document.getElementById("clock");
    let gameMessage = document.getElementById("gamemessage");
    let readyButton = document.getElementById("readyButton");
    let splashScreenElement = document.getElementById("splash");

    let splashPlayersElement = document.getElementById("splashPlayers");
    let experienceNameElement = document.getElementById("experienceName");

    let gameElement = document.getElementById("game");

    let runningSound: any = null;
    let objects = [];
    let Jarvis = initVoiceCommands(room);
    init(null);

    function render() {
      renderer.render(scene, camera);
    }

    function init(state: BlockGameState | null) {
      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
      camera.position.set(500, 800, 1300);
      camera.lookAt(0, 0, 0);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xf0f0f0);

      // roll-over helpers

      const rollOverGeo = new THREE.BoxGeometry(50, 50, 50);
      rollOverMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.5, transparent: true });
      rollOverMesh = new THREE.Mesh(rollOverGeo, rollOverMaterial);
      scene.add(rollOverMesh);

      // cubes

      cubeGeo = new THREE.BoxGeometry(50, 50, 50);
      cubeMaterial = new THREE.MeshLambertMaterial({
        color: 0xfeb74c,
        map: new THREE.TextureLoader().load("textures/square-outline-textured.png"),
      });

      // grid

      const gridHelper = new THREE.GridHelper(1000, state?.board?.cols || 20);
      scene.add(gridHelper);

      //

      raycaster = new THREE.Raycaster();
      pointer = new THREE.Vector2();

      const geometry = new THREE.PlaneGeometry(1000, 1000);
      geometry.rotateX(-Math.PI / 2);

      plane = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ visible: false }));
      scene.add(plane);

      objects.push(plane);

      // lights

      const ambientLight = new THREE.AmbientLight(0x606060);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff);
      directionalLight.position.set(1, 0.75, 0.5).normalize();
      scene.add(directionalLight);

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      gameElement.appendChild(renderer.domElement);

      document.addEventListener("pointermove", onPointerMove);
      document.addEventListener("pointerdown", onPointerDown);
      document.addEventListener("keydown", onDocumentKeyDown);
      document.addEventListener("keyup", onDocumentKeyUp);

      //
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0, 0);
      controls.maxPolarAngle = Math.PI / 2;

      controls.update();

      window.addEventListener("resize", onWindowResize);

      let resetButtonElement = document.getElementById("resetbutton");
      resetButtonElement.onclick = () => {
        room.send("resetGame");
      };
      readyButton.onclick = () => {
        room.send("ready");
        // setReady(true);
        // Howler.volume(0.4);

        // var sound = new Howl({
        //   src: [soundUrl],
        //   html5: true,
        // });
        soundManager.startBackgroundMusic(soundUrl);
      };
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function onPointerMove(event) {
      pointer.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);

      raycaster.setFromCamera(pointer, camera);

      const intersects = raycaster.intersectObjects(objects);

      if (intersects.length > 0) {
        const intersect = intersects[0];

        rollOverMesh.position.copy(intersect.point).add(intersect.face.normal);
        rollOverMesh.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);
      }

      render();
    }

    function onPointerDown(event) {
      if (room.state.isGameOver) return;
      pointer.set((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);

      raycaster.setFromCamera(pointer, camera);

      const intersects = raycaster.intersectObjects(objects);

      if (intersects.length > 0) {
        const intersect = intersects[0];

        // delete cube

        if (isShiftDown) {
          if (intersect.object !== plane) {
            console.log("X", intersect.object);
            scene.remove(intersect.object);

            objects.splice(objects.indexOf(intersect.object), 1);
          }

          // create cube
        } else {
          console.log("ROOM STATE", room.state, room.sessionId);
          let player = room.state.players.players.find((p) => p.id === room.sessionId);
          console.log("THE PLAYER", player);
          let colour = new THREE.Color();
          colour.setHex(player.playerColor);
          if (colour < 500) {
            colour.setHex(500);
          }
          const mMaterial = new THREE.MeshBasicMaterial({ color: colour, opacity: 0.5, transparent: true });
          const voxel = new THREE.Mesh(cubeGeo, mMaterial);

          voxel.position.copy(intersect.point).add(intersect.face.normal);
          voxel.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);
          scene.add(voxel);

          objects.push(voxel);
          let placement = new CubePlacement().assign({ x: voxel.position.x, y: voxel.position.y, z: voxel.position.z });
          room.send("cubePlaced", placement);
        }

        render();
      }
    }

    function onDocumentKeyDown(event) {
      switch (event.keyCode) {
        case 16:
          isShiftDown = true;
          break;
      }
    }

    function onDocumentKeyUp(event) {
      switch (event.keyCode) {
        case 16:
          isShiftDown = false;
          break;
      }
    }

    function update(state: BlockGameState) {
      console.log("UPDATE", state);

      if (state.running === 0) {
        generateSplashPlayersHtml(state);
      } else {
        splashScreenElement.style.display = "none";
        gameElement.style.display = "block";
      }

      playersHtml.innerHTML = state.players.players.map((player) => player.playerName).join(", ");
      clock.innerHTML = state.running.toString();
      if (state.isGameOver && state.gameMessage) {
        gameMessage.innerHTML = state.gameMessage;
      }
      if (state.running === 0) {
        objects.forEach((object, idx) => {
          scene.remove(object);
          // objects.splice(idx, 1)
        });
      }

      state.players.players.forEach((player) => {
        player.cubes.forEach((cube) => {
          let cubeExists = objects.find(
            (c) => c.position && c.position.x === cube.x && c.position.y === cube.y && c.position.z === cube.z
          );
          if (!cubeExists) {
            let colour = new THREE.Color();
            colour.setHex(player.playerColor);
            if (colour < 500) {
              colour.setHex(500);
            }
            const mMaterial = new THREE.MeshBasicMaterial({ color: colour, opacity: 1.0, transparent: false });

            const voxel = new THREE.Mesh(cubeGeo, mMaterial);
            voxel.position.x = cube.x;
            voxel.position.y = cube.y;
            voxel.position.z = cube.z;

            // voxel.position.divideScalar(50).floor().multiplyScalar(50).addScalar(25);
            scene.add(voxel);
            objects.push(voxel);
          }
        });
      });

      render();
    }

    function generateSplashPlayersHtml(state: BlockGameState) {
      let readyCount = 0;
      let visiblePlayerCount = 0;
      state.players.players.forEach((player) => {
        if (player.isReady) readyCount++;

        if (!player.isSidePanelUser) visiblePlayerCount++;
      });

      splashPlayersElement.innerHTML = `${readyCount} / ${visiblePlayerCount} players ready - ${state.meetingOrganizerId} is the meeting organizer`;
    }

    room.onStateChange((newState: BlockGameState) => {
      // init(newState)
      update(newState);
      render();
      console.log("PLAYERS", newState.players.toJSON());
      // init(newState);
      // render();
      // renderGame(newState);

      // if (newState.running) {
      //     if (!(typeof document.onkeydown === "function")) {
      //         document.addEventListener('keydown', handleInput);
      //     }
      //     // readyModal.style.display = "none";
      //     // renderGame(newState);
      // } else {
      //     document.removeEventListener('keydown', handleInput);
      // }
    });

    room.state.onChange = (changes: any) => {
      console.log("CHANGES", changes);
      let runningChange = changes.find((c) => c.field === "running");

      if (runningChange) {
        if (runningChange.field && runningChange.field === "running") {
          if (runningChange.previousValue === 0 && runningChange.value === 1) {
            Jarvis.say("highest block wins!");
          }
        }
      }
    };

    room.onMessage("playSound", (message) => {
      if (message.soundName === "coin") {
        var sound = new Howl({
          src: [soundUrl],
          html5: true,
        });
        runningSound = sound;
        sound.play();
      }
      if (message.soundName === "bloop") {
        var sound = new Howl({
          src: [bloopSoundUrl],
          html5: true,
        });
        runningSound = sound;

        sound.play();
      }

      if (message.soundName === "backgroundMusic") {
        var sound = new Howl({
          src: [backgroundMusicSound],
          html5: true,
        });
        runningSound = sound;

        sound.play();
      }

      if (message.soundName === "voice") {
        Jarvis.say(message.message);
      }
    });

    room.onMessage("newPlayerImage", (message) => {
      console.log("NEW PLAYER IMAGE", message);
      var container = document.createElement("div");
      container.className = "user-image-host";

      var img = document.createElement("img");
      img.className = "user-image";

      img.src = message.imageBase64;
      container.appendChild(img);
      document.getElementById("userimages").appendChild(container);
    });

    return () => {
      debugger;
      soundManager.stopBackgroundMusic();
    };
  }, [room]);

  return (
    <div>
      <div id="game">
        <div id="container"></div>

        <img id="userimage" />
        <div id="userimages"></div>
        <div id="splash" className="flex-container"></div>
      </div>
      {!ready && (
        <motion.div
          className={"rounded-md drop-shadow-md bg-indigo-900 select-none"}
          key={"breaktime"}
          initial={{
            opacity: 0,
            position: "absolute",
            left: "0px",
            bottom: "0px",
            color: "white",
            padding: "5px",
          }}
          animate={{ opacity: 1, left: "50px", bottom: "50px" }}
          exit={{ opacity: 0 }}>
          <div id="resetbutton">Reset</div>
          <div id="players">Players</div>
          <div id="clock"></div>
          <div id="gamemessage"></div>
          <div id="readyButton">READY</div>
          <div id="splashPlayers"></div>
        </motion.div>
      )}
    </div>
  );
}
