import { Leva, useControls } from "leva";
import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useGlobalStore2 } from "../../hooks/useGlobalState";

export function VarChanger() {
  // let { backgroundColor } = useControls({ backgroundColor: "#512da8" });
  const enableVarChanger = useGlobalStore2((state) => state.enableVarChanger);
  const setEnableVarChanger = useGlobalStore2((state) => state.setEnableVarChanger);

  useHotkeys("ctrl+k", () => {
    if (enableVarChanger) {
      setEnableVarChanger(false);
    } else {
      setEnableVarChanger(true);
    }
  });

  let { theme } = useControls({
    theme: {
      options: [
        "dracula",
        "synthwave",
        "coffee",
        "night",
        "business",
        "cupcake",
        "bumblebee",
        "garden",
        "CMYK",
        "cyberpunk",
        "retro",
        "luxury",
        "fantasy",
        "wireframe",
        "rightpoint",
        "comcast",
      ],
    },
  });
  useEffect(() => {
    // var r = document.querySelector(":root");
    // r.style.setProperty("--background", backgroundColor);

    const htmlElement = document.getElementsByTagName("html");
    htmlElement[0].setAttribute("data-theme", theme ?? "dracula");
  }, [theme]);
  return <Leva isRoot={true} hidden={!enableVarChanger} />;
}
