import React from "react";

export function Megaphone() {
  return (
    <svg
      style={{ height: "64px", stroke: "white", fill: "white" }}
      xmlns="http://www.w3.org/2000/svg"
      //    style={{enableBa}}
      //    style="enable-background:new 0 0 392.633 392.633;"
      viewBox="0 0 392.633 392.633"
      y="0px"
      x="0px"
      id="Layer_1"
      version="1.1">
      <defs id="defs59" />

      <g id="g6">
        <g id="g4">
          <path
            id="path2"
            d="M 373.883,244.782 147.943,18.907 c -9.762,-7.628 -16.937,0.323 -18.101,4.202 L 44.057,273.55 3.2,314.342 c -4.267,4.267 -4.267,11.119 0,15.451 l 59.798,59.798 c 3.685,3.685 11.766,3.685 15.45,0 l 40.857,-40.857 6.853,-2.392 c 10.99,23.402 36.137,37.43 63.935,37.43 45.834,0 63.16,-24.436 72.533,-40.857 8.404,-14.675 10.99,-30.707 7.628,-45.899 l 99.426,-34.133 c 4.914,-0.84 11.379,-10.602 4.203,-18.101 z M 70.756,366.447 26.409,322.1 53.625,294.884 97.972,339.231 Z m 140.541,-8.21 c -26.117,9.244 -53.463,0.711 -64.259,-19.071 l 102.465,-35.103 c -0.065,33.551 -24.048,49.26 -38.206,54.174 z M 124.219,107.278 145.035,46.64 346.279,247.884 116.267,326.754 66.101,276.588 100.493,176.192 m 7.887,-23.015 7.887,-22.949"
          />
        </g>
      </g>
      <g id="g12">
        <g id="g10">
          <path
            id="path8"
            d="M252.347,0.289c-6.012-0.259-11.055,4.59-11.184,10.602l-1.487,55.402c-0.194,6.012,4.525,10.99,10.537,11.184    c0.065,0,0.259,0,0.323,0c5.883,0,10.731-4.719,10.925-10.602l1.487-55.402C263.079,5.461,258.36,0.418,252.347,0.289z"
          />
        </g>
      </g>
      <g id="g18">
        <g id="g16">
          <path
            id="path14"
            d="M350.352,42.439c-4.267-4.267-11.119-4.267-15.451,0l-39.176,39.176c-4.267,4.267-4.267,11.119,0,15.451    c3.943,3.879,11.895,3.556,15.451,0l39.176-39.176C354.618,53.622,354.618,46.77,350.352,42.439z"
          />
        </g>
      </g>
      <g id="g24">
        <g id="g22">
          <path
            id="path20"
            d="M381.382,129.905l-55.402,1.487c-6.012,0.129-10.731,5.172-10.602,11.184c0.129,5.948,5.042,10.602,10.925,10.602    c0.065,0,0.259,0,0.323,0l55.402-1.487c6.012-0.129,10.731-5.172,10.602-11.184C392.436,134.495,387.459,129.776,381.382,129.905z    "
          />
        </g>
      </g>
      <g id="g26"></g>
      <g id="g28"></g>
      <g id="g30"></g>
      <g id="g32"></g>
      <g id="g34"></g>
      <g id="g36"></g>
      <g id="g38"></g>
      <g id="g40"></g>
      <g id="g42"></g>
      <g id="g44"></g>
      <g id="g46"></g>
      <g id="g48"></g>
      <g id="g50"></g>
      <g id="g52"></g>
      <g id="g54"></g>
    </svg>
  );
}
