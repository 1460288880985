import React, { useEffect, useState } from "react";

import { TeamsFluidClient } from "@microsoft/live-share";
import { SharedMap } from "fluid-framework";
import { app } from "@microsoft/teams-js";
import { LiveShareClient } from "@microsoft/live-share/bin/LiveShareClient";
import { LiveCanvasPage } from "./LiveCanvasPage";
import { useSharedObjects } from "./useSharedObjects";

// export function LiveShare() {
//   useEffect(() => {
//     // Initialize the Teams Client SDK
//     app.initialize().then(() => {
//       app.notifyAppLoaded();
//       app.notifySuccess();
//       console.log("after init 2?");
//       // Setup the Fluid container
//       const client = new LiveShareClient();
//       const schema = {
//         initialObjects: { exampleMap: SharedMap },
//       };
//       client.joinContainer(schema).then(({ container, services, created }) => {
//         console.log("JOINED CONTAINER", container, services, created);
//         const { exampleMap } = container.initialObjects;
//         console.log(exampleMap);
//       });
//     });
//   }, []);
//   return (
//     <div>
//       LiveShare Page!
//       <div
//         onClick={() => {
//           console.log("WTF");
//           app.initialize().then(() => {
//             console.log("here i am");
//             const client = new LiveShareClient();
//             const schema = {
//               initialObjects: { exampleMap: SharedMap },
//             };
//             client.joinContainer(schema).then(({ container, services, created }) => {
//               console.log("JOINED CONTAINER", container, services, created);
//               const { exampleMap } = container.initialObjects;
//               console.log(exampleMap);
//             });
//           });
//         }}>
//         Re init?
//       </div>
//       <div
//         onClick={() => {
//           console.log("test");
//           console.log("test in here");
//           const client = new LiveShareClient();
//           const schema = {
//             initialObjects: { exampleMap: SharedMap },
//           };
//           client.joinContainer(schema).then(({ container, services, created }) => {
//             console.log("JOINED CONTAINER", container, services, created);
//             const { exampleMap } = container.initialObjects;
//             console.log(exampleMap);
//           });
//         }}>
//         Test Again
//       </div>
//     </div>
//   );
// }
export function inTeams() {
  const currentUrl = window.location.href;
  // Check if using HistoryRouter
  const url = currentUrl.includes("/#/")
    ? new URL(`${window.location.href.split("/#/").join("/")}`)
    : new URL(window.location);
  const params = url.searchParams;
  return !!params.get("inTeams");
}

export function LiveShare() {
  // // Fluid objects hook which uses LiveShareClient to create container
  // const {
  //   presence, // LivePresence Fluid object
  //   mediaSession, // LiveMediaSession Fluid object
  //   notificationEvent, // LiveEvent Fluid object
  //   takeControlMap, // SharedMap Fluid object for presenter control
  //   playlistMap, // SharedMap Fluid object for playlist
  //   liveCanvas, // LiveEvent Fluid object
  //   container, // Fluid container
  //   error, // Join container error
  // } = useSharedObjects();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    console.log("Init app");
    app.initialize().then(() => {
      console.log("after init app");
      app.notifyAppLoaded();
      app.notifySuccess();
      setInitialized(true);
    });
  }, []);

  if (!initialized) return <div>initializing...</div>;
  else return <LiveCanvasPage />;
}
