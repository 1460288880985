import { useEffect, useState } from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { app, Context } from "@microsoft/teams-js";

export const checkInTeams = (): boolean => {
  // eslint-disable-next-line dot-notation
  //   const microsoftTeamsLib = window["microsoftTeams"];

  //   if (!microsoftTeamsLib) {
  //     return false; // the Microsoft Teams library is for some reason not loaded
  //   }

  if (
    (window.parent === window.self && (window as any).nativeInterface) ||
    window.navigator.userAgent.includes("Teams/") ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame"
  ) {
    return true;
  }
  return false;
};

export const getQueryVariable = (variable: string): string | undefined => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (const varPairs of vars) {
    const pair = varPairs.split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

/**
 * Microsoft Teams React hook
 * @param options optional options
 * @returns A tuple with properties and methods
 * properties:
 *  - inTeams: boolean = true if inside Microsoft Teams
 *  - fullscreen: boolean = true if in full screen mode
 *  - theme: Fluent UI Theme
 *  - themeString: string - representation of the theme (default, dark or contrast)
 *  - context - the Microsoft Teams JS SDK context
 * methods:
 *  - setTheme - manually set the theme
 */
export function useMicrosoftTeams(options?: { initialTheme?: string; setThemeHandler?: (theme?: string) => void }): [
  {
    inTeams?: boolean;
    fullScreen?: boolean;
    themeString: string;
    context?: app.Context;
  }
] {
  const [inTeams, setInTeams] = useState<boolean | undefined>(undefined);
  const [fullScreen, setFullScreen] = useState<boolean | undefined>(undefined);
  const [themeString, setThemeString] = useState<string>("default");
  const [initialTheme] = useState<string | undefined>(
    options && options.initialTheme ? options.initialTheme : getQueryVariable("theme")
  );
  const [context, setContext] = useState<app.Context>();

  useEffect(() => {
    app
      .initialize()
      .then(() => {
        app.notifyAppLoaded();
        app.notifySuccess();

        app
          .getContext()
          .then((context) => {
            batchedUpdates(() => {
              setInTeams(true);
              setContext(context);
              setFullScreen(context.page.isFullScreen);
            });
            // overrideThemeHandler(context.app.theme);
            // app.registerOnThemeChangeHandler(overrideThemeHandler);
            // pages.registerFullScreenHandler((isFullScreen) => {
            //     setFullScreen(isFullScreen);
            // });
          })
          .catch(() => {
            setInTeams(false);
          });
      })
      .catch(() => {
        setInTeams(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [{ inTeams, fullScreen, context, themeString }];
}
