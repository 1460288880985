import create, { StateCreator } from "zustand";
import { v4 as uuidv4 } from "uuid";

export type PromptBuilderStore = {
  inputItems: Array<string>;
  api: {
    addInputItem: (text: string) => void;
  };
};
const createPromptBuilderStore: StateCreator<PromptBuilderStore, [], [], PromptBuilderStore> = (set, get) => ({
  inputItems: [],
  api: {
    addInputItem: (text: string) => {
      set((state) => {
        let foundExisting = state.inputItems.find((t) => text === t);
        if (foundExisting) {
          return { inputItems: [...state.inputItems] };
        } else {
          let newItems = [...state.inputItems, text];
          // if (newItems.length === 10) {
          //   newItems.shift();
          // }
          return { inputItems: newItems };
        }
      });
    },
  },
});

export const usePromptBuilderStore = create<PromptBuilderStore>()((...a) => ({
  ...createPromptBuilderStore(...a),
}));
