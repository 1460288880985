import React, { useCallback, useEffect, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Clock, DirectionalLight, Euler, PerspectiveCamera, Quaternion, Scene, Vector3, WebGLRenderer } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { VRM,  VRMSchema, VRMUtils } from "@pixiv/three-vrm";
import { useMediapipeStore } from "../Mediapipe/useMediapipeStore";
import { Face, Hand, Pose, Vector } from "kalidokit";
import { clamp } from "kalidokit/dist/utils/helpers";

let oldLookTarget = new Euler();


export function VRMThing(){

    let trackingData = useMediapipeStore(state => state.handPositionData);
    let vrmRef = useRef();
    

    const rigRotation = useCallback((name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {

        console.log("HUMANOID", VRMSchema.HumanoidBoneName[name]);
    
        const Part = vrmRef.current.humanoid.getBoneNode(VRMSchema.HumanoidBoneName[name]);
        console.log("PART", Part);
        if (!Part) {
          return;
        }
  
        let euler = new Euler(rotation.x * dampener, rotation.y * dampener, rotation.z * dampener);
        let quaternion = new Quaternion().setFromEuler(euler);
        Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
        
    }, [vrmRef])

    const rigFace = useCallback((riggedFace) => {
        if (!vrmRef.current) return;
            let currentVrm = vrmRef.current;
            rigRotation("Neck", riggedFace.head, 0.7);
            const { lerp } = Vector;
            // Blendshapes and Preset Name Schema
            const Blendshape = currentVrm.blendShapeProxy;
            const PresetName = VRMSchema.BlendShapePresetName;
            // Simple example without winking. Interpolate based on old blendshape, then stabilize blink with `Kalidokit` helper function.
            // for VRM, 1 is closed, 0 is open.
            riggedFace.eye.l = Vector.lerp(clamp(1 - riggedFace.eye.l, 0, 1), Blendshape.getValue(PresetName.Blink), 0.5);
            riggedFace.eye.r = Vector.lerp(clamp(1 - riggedFace.eye.r, 0, 1), Blendshape.getValue(PresetName.Blink), 0.5);
            riggedFace.eye = Face.stabilizeBlink(riggedFace.eye, riggedFace.head.y);
            Blendshape.setValue(PresetName.Blink, riggedFace.eye.r);
            Blendshape.setValue(PresetName.BlinkL, riggedFace.eye.l);
      
            // Interpolate and set mouth blendshapes
            Blendshape.setValue(PresetName.I, lerp(riggedFace.mouth.shape.I, Blendshape.getValue(PresetName.I), 0.5));
            Blendshape.setValue(PresetName.A, lerp(riggedFace.mouth.shape.A, Blendshape.getValue(PresetName.A), 0.5));
            Blendshape.setValue(PresetName.E, lerp(riggedFace.mouth.shape.E, Blendshape.getValue(PresetName.E), 0.5));
            Blendshape.setValue(PresetName.O, lerp(riggedFace.mouth.shape.O, Blendshape.getValue(PresetName.O), 0.5));
            Blendshape.setValue(PresetName.U, lerp(riggedFace.mouth.shape.U, Blendshape.getValue(PresetName.U), 0.5));
      
            //PUPILS
            //interpolate pupil and keep a copy of the value
            let lookTarget = new Euler(
              Vector.lerp(oldLookTarget.x, riggedFace.pupil.y, 0.4),
              Vector.lerp(oldLookTarget.y, riggedFace.pupil.x, 0.4),
              0,
              "XYZ"
            );
            oldLookTarget.copy(lookTarget);
            currentVrm.lookAt.applyer.lookAt(lookTarget);
          
    }, [vrmRef])

    const rigPosition = useCallback((name, position = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    
         
            const Part = vrmRef.current.humanoid.getBoneNode(VRMSchema.HumanoidBoneName[name]);
            if (!Part) {
              return;
            }
            let vector = new Vector3(position.x * dampener, position.y * dampener, position.z * dampener);
            Part.position.lerp(vector, lerpAmount); // interpolate
      
    }, [vrmRef])

    useEffect(() => {
        let currentVrm;

        // renderer
        const renderer = new WebGLRenderer({ alpha: true });
        renderer.domElement.id = "vrmAvatar";
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        document.body.appendChild(renderer.domElement);
    
        // camera
        const orbitCamera = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
        orbitCamera.position.set(0.0, 1.4, 0.7);
    
        // controls
        const orbitControls = new OrbitControls(orbitCamera, renderer.domElement);
        orbitControls.screenSpacePanning = true;
        orbitControls.target.set(0.0, 1.4, 0.0);
        orbitControls.update();
    
        // scene
        const scene = new Scene();
    
        // light
        const light = new DirectionalLight(0xffffff);
        light.position.set(1.0, 1.0, 1.0).normalize();
        scene.add(light);
    
        // Main Render Loop
        const clock = new Clock();
    
        function animate() {
          requestAnimationFrame(animate);
    
          if (currentVrm) {
            // Update model to render physics
            currentVrm.update(clock.getDelta());
          }
          renderer.render(scene, orbitCamera);
        }
        animate();
    
        /* VRM CHARACTER SETUP */
    
        // Import Character VRM
        const loader = new GLTFLoader();
        // loader.register((parser) => {
        //     return new VRMLoaderPlugin(parser);
        //   });
        loader.crossOrigin = "anonymous";
        // Import model from URL, add your own model here
        const testModel = "/TomVRM.vrm";
        const animeModel = "https://cdn.glitch.com/29e07830-2317-4b15-a044-135e73c7f840%2FAshtra.vrm?v=1630342336981";
        loader.load(
            animeModel,
      
            (gltf) => {
              console.log("GLTF", gltf);
              VRMUtils.removeUnnecessaryJoints(gltf.scene);
              // scene.add(gltf.scene);
              VRM.from(gltf).then((vrm) => {
                // console.log("VRM", vrm);
                // scene.add(vrm.scene);
                // currentVrm = vrm;
                // currentVrm.scene.rotation.y = Math.PI; // Rotate model 180deg to face camera
      
                loader.load(animeModel, (glb) => {
                  console.log("GLB", glb);
                  glb.userData = gltf.userData;
                  glb.parser.json = gltf.parser.json;
                  console.log("GLB WITH USERDATA", glb);
                  // glb.scene.children[1] = gltf.scene.children[1];
                  // glb.scene.children[2] = gltf.scene.children[2];
                  // glb.scene.children[1] = gltf.scene.children[3];
      
                  VRMUtils.removeUnnecessaryJoints(glb.scene);
      
                  VRM.from(glb).then((vr) => {
                    console.log("GLBVRM", vr);
                    scene.add(vr.scene);
                    currentVrm = vr;
                    currentVrm.scene.rotation.y = Math.PI;
                    vrmRef.current = vr;
                  });
                });
              });
          },
    
          (progress) => console.log("Loading model...", 100.0 * (progress.loaded / progress.total), "%"),
    
          (error) => console.error(error)
        );
    }, [])


    useEffect(() => {
        if (!trackingData || !trackingData[0]) return;
    console.log("TRACKING", trackingData);
        const results = trackingData[0].data;
        console.log("RESULTS", trackingData[0].data)
    const faceLandmarks = results.faceLandmarks;
    // Pose 3D Landmarks are with respect to Hip distance in meters
    const pose3DLandmarks = results.za;
    // Pose 2D landmarks are with respect to videoWidth and videoHeight
    const pose2DLandmarks = results.poseLandmarks;
    // Be careful, hand landmarks may be reversed
    const leftHandLandmarks = results.rightHandLandmarks;
    const rightHandLandmarks = results.leftHandLandmarks;

    // Animate Face
    if (faceLandmarks) {

      let solved = Face.solve(faceLandmarks, {
        runtime: "mediapipe",
      
      });
      if (!solved) return;

     console.log("SOLVED FACE", solved)
     rigFace(solved)
    //  rigRotation("Neck", solved.head, 0.7)
    }

    if (pose2DLandmarks && pose3DLandmarks) {
        let riggedPose =  Pose.solve(pose3DLandmarks, pose2DLandmarks, {
          runtime: "mediapipe",
       
        });

        console.log("RIGGED POSE", riggedPose)

        if (riggedPose){
            rigRotation("Hips", riggedPose.Hips.rotation, 0.7);
            rigPosition(
              "Hips",
              {
                x: -riggedPose.Hips.position.x, // Reverse direction
                y: riggedPose.Hips.position.y + 1, // Add a bit of height
                z: -riggedPose.Hips.position.z, // Reverse direction
              },
              1,
              0.07
            );
    
            rigRotation("Chest", riggedPose.Spine, 0.25, 0.3);
            rigRotation("Spine", riggedPose.Spine, 0.45, 0.3);
    
            rigRotation(
              "RightUpperArm",
              {
                x: riggedPose.RightUpperArm.x,
                y: riggedPose.RightUpperArm.y,
                z: riggedPose.RightUpperArm.z,
              },
              1,
              0.3
            );

            if (leftHandLandmarks) {
                let riggedLeftHand = Hand.solve(leftHandLandmarks, "Left");
                if (!riggedLeftHand) return;
                rigRotation("LeftHand", {
                  // Combine pose rotation Z and hand rotation X Y
                  z: riggedPose.LeftHand.z,
                  y: riggedLeftHand.LeftWrist.y,
                  x: riggedLeftHand.LeftWrist.x,
                });
                rigRotation("LeftRingProximal", riggedLeftHand.LeftRingProximal);
                rigRotation("LeftRingIntermediate", riggedLeftHand.LeftRingIntermediate);
                rigRotation("LeftRingDistal", riggedLeftHand.LeftRingDistal);
                rigRotation("LeftIndexProximal", riggedLeftHand.LeftIndexProximal);
                rigRotation("LeftIndexIntermediate", riggedLeftHand.LeftIndexIntermediate);
                rigRotation("LeftIndexDistal", riggedLeftHand.LeftIndexDistal);
                rigRotation("LeftMiddleProximal", riggedLeftHand.LeftMiddleProximal);
                rigRotation("LeftMiddleIntermediate", riggedLeftHand.LeftMiddleIntermediate);
                rigRotation("LeftMiddleDistal", riggedLeftHand.LeftMiddleDistal);
                rigRotation("LeftThumbProximal", riggedLeftHand.LeftThumbProximal);
                rigRotation("LeftThumbIntermediate", riggedLeftHand.LeftThumbIntermediate);
                rigRotation("LeftThumbDistal", riggedLeftHand.LeftThumbDistal);
                rigRotation("LeftLittleProximal", riggedLeftHand.LeftLittleProximal);
                rigRotation("LeftLittleIntermediate", riggedLeftHand.LeftLittleIntermediate);
                rigRotation("LeftLittleDistal", riggedLeftHand.LeftLittleDistal);
              }
              if (rightHandLandmarks) {
                let riggedRightHand = Hand.solve(rightHandLandmarks, "Right");
                if (!riggedRightHand) return; 

                rigRotation("RightHand", {
                  // Combine Z axis from pose hand and X/Y axis from hand wrist rotation
                  z: riggedPose.RightHand.z,
                  y: riggedRightHand.RightWrist.y,
                  x: riggedRightHand.RightWrist.x,
                });
                rigRotation("RightRingProximal", riggedRightHand.RightRingProximal);
                rigRotation("RightRingIntermediate", riggedRightHand.RightRingIntermediate);
                rigRotation("RightRingDistal", riggedRightHand.RightRingDistal);
                rigRotation("RightIndexProximal", riggedRightHand.RightIndexProximal);
                rigRotation("RightIndexIntermediate", riggedRightHand.RightIndexIntermediate);
                rigRotation("RightIndexDistal", riggedRightHand.RightIndexDistal);
                rigRotation("RightMiddleProximal", riggedRightHand.RightMiddleProximal);
                rigRotation("RightMiddleIntermediate", riggedRightHand.RightMiddleIntermediate);
                rigRotation("RightMiddleDistal", riggedRightHand.RightMiddleDistal);
                rigRotation("RightThumbProximal", riggedRightHand.RightThumbProximal);
                rigRotation("RightThumbIntermediate", riggedRightHand.RightThumbIntermediate);
                rigRotation("RightThumbDistal", riggedRightHand.RightThumbDistal);
                rigRotation("RightLittleProximal", riggedRightHand.RightLittleProximal);
                rigRotation("RightLittleIntermediate", riggedRightHand.RightLittleIntermediate);
                rigRotation("RightLittleDistal", riggedRightHand.RightLittleDistal);
              }
            };
        
        

        
    }

    }
    , [trackingData, vrmRef])

    return <div>VRM Thing</div>
  }