import { isFunction } from "@rpldy/shared";

/**
 * mimics FormData.set() when its not available (react-native)
 * in case FormData.delete() isnt available, will append only (unlike set)
 */
const addToFormData = function (fd, name) {
  for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    rest[_key - 2] = arguments[_key];
  }

  //rest = [value, fileName = undefined]
  if ("set" in fd) {
    //$FlowExpectedError[speculation-ambiguous] - ignore flow for not allowing FileLike here
    fd.set(name, ...rest);
  } else {
    if ("delete" in fd) {
      fd.delete(name);
    } //$FlowExpectedError[speculation-ambiguous] - ignore flow for not allowing FileLike here


    fd.append(name, ...rest);
  }
};

const getFormFileField = (fd, items, options) => {
  const single = items.length === 1;
  items.forEach((item, i) => {
    const name = single ? options.paramName : isFunction(options.formatGroupParamName) ? options.formatGroupParamName(i, options.paramName) : `${options.paramName}[${i}]`;

    if (item.file) {
      addToFormData(fd, name, item.file, item.file.name);
    } else if (item.url) {
      addToFormData(fd, name, item.url);
    }
  });
};

const prepareFormData = (items, options) => {
  const fd = new FormData();

  if (options.params) {
    Object.entries(options.params).forEach(_ref => {
      let [key, val] = _ref;

      if (options.formDataAllowUndefined || val !== undefined) {
        addToFormData(fd, key, val);
      }
    });
  }

  getFormFileField(fd, items, options);
  return fd;
};

export default prepareFormData;