import { hasWindow } from "@rpldy/shared";
export const GLOBAL_VERSION_SYM = Symbol.for("_rpldy-version_");

const getVersion = () => "0.17.5" || "";

const getRegisteredVersion = () => {
  /* istanbul ignore next */
  const global = hasWindow() ? window : process; // $FlowIgnore

  return global[GLOBAL_VERSION_SYM];
};

const registerUploadyContextVersion = () => {
  const global = hasWindow() ? window : process; //$FlowIgnore

  global[GLOBAL_VERSION_SYM] = getVersion();
};

const getIsVersionRegisteredAndDifferent = () => {
  const registeredVersion = getRegisteredVersion();
  return !!registeredVersion && registeredVersion !== getVersion();
};

export { getVersion, getRegisteredVersion, registerUploadyContextVersion, getIsVersionRegisteredAndDifferent };