import React, { FC, ReactChildren, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useChatUIStore } from "../useChatUIStore";
import { useSpeechRecognitionStore } from "../useSpeechRecognitionStore";
import { usePromptBuilderStore } from "./usePromptBuilderStore";

type AddToPromptBuilderProps = {
  text: string;
  onSelectFn?: (selectedText: string) => void;
};

export const AddToPromptBuilder: FC<AddToPromptBuilderProps> = (props) => {
  const speechRecognitionStoreApi = useSpeechRecognitionStore((state) => state.api);
  const chatUIApi = useChatUIStore((state) => state.api);
  const [cssClass, setCssClass] = useState("border-primary-focus text-primary-focus");

  const api = usePromptBuilderStore((state) => state.api);
  const items = usePromptBuilderStore((state) => state.inputItems);
  const letters = "asdfghjkl";
  let startingCss = "kbd kbd-xs ";

  let itemArray = [...items].slice(-9);
  console.log(itemArray);

  let letterToUse = "z";
  let currentIndex = itemArray.findIndex((t) => t === props.text);
  if (currentIndex < 9) {
    letterToUse = letters[currentIndex];
  }
  useHotkeys(letterToUse, () => {
    setCssClass("bg-primary");

    // speechRecognitionStoreApi.addRecognitionResult(props.text);
    // chatUIApi.setPromptText(
    //   "You are a chat bot that responds to statements as if you were a job interview candidate during an interview. The input text you receive will be an interviewer asking to explain a concept. You will respond to the interviewer explaining the answer to the question in Markdown format. Include possible related concepts in bold in the response. Your first question is: "
    // );


    props.onSelectFn && props.onSelectFn(props.text);
  });

  useEffect(() => {
    api.addInputItem(props.text);
  }, []);
  return (
    <strong
      onClick={() => {
        speechRecognitionStoreApi.addRecognitionResult(props.text);
        chatUIApi.setPromptText(
          "You are a chat bot that responds to statements as if you were a job interview candidate during an interview. The input text you receive will be an interviewer asking to explain a concept. You will respond to the interviewer explaining the answer to the question in Markdown format. Include possible related concepts in bold in the response. Your first question is: "
        );
        // api.addInputItem(props.text);
      }}>
      {props.text}{" "}
      {letterToUse && (
        <div className={"tooltip tooltip-right z-10"} data-tip={`press ${letterToUse} to explain!`}>
          <kbd className={startingCss + cssClass}>{letterToUse}</kbd>
        </div>
      )}
    </strong>
  );
};
