

/**
 * Provides hints at the properties which may be available on the result object
 */
export interface ISearchResult {
    Rank?: number;
    DocId?: number;
    WorkId?: number;
    Title?: string;
    Author?: string;
    Size?: number;
    Path?: string;
    Description?: string;
    Write?: Date;
    LastModifiedTime?: Date;
    CollapsingStatus?: number;
    HitHighlightedSummary?: string;
    HitHighlightedProperties?: string;
    contentclass?: string;
    PictureThumbnailURL?: string;
    ServerRedirectedURL?: string;
    ServerRedirectedEmbedURL?: string;
    ServerRedirectedPreviewURL?: string;
    FileExtension?: string;
    ContentTypeId?: string;
    ParentLink?: string;
    ViewsLifeTime?: string;
    ViewsRecent?: number;
    SectionNames?: string;
    SectionIndexes?: string;
    SiteLogo?: string;
    SiteDescription?: string;
    importance?: number;
    SiteName?: string;
    IsDocument?: boolean;
    FileType?: string;
    IsContainer?: boolean;
    WebTemplate?: string;
    SPWebUrl?: string;
    UniqueId?: string;
    ProgId?: string;
    OriginalPath?: string;
    RenderTemplateId?: string;
    PartitionId?: string;
    UrlZone?: number;
    Culture?: string;
    GeoLocationSource?: string;
    SiteId?: string;
    WebId?: string;
    ListId?: string;
    IsExternalContent?: boolean;
    DocumentSignature?: string;
    LinkingUrl?: string;
    ResultTypeId?: string;
    ResultTypeIdList?: string;
}

export function formatSearchResults(rawResults: any): ISearchResult[] {

    const results = new Array<ISearchResult>();

    if (typeof (rawResults) === "undefined" || rawResults == null) {
        return [];
    }

    const tempResults = rawResults.results ? rawResults.results : rawResults;

    for (const tempResult of tempResults) {

        const cells: { Key: string; Value: any }[] = tempResult.Cells.results ? tempResult.Cells.results : tempResult.Cells;

        results.push(cells.reduce((res, cell) => {

            res[cell.Key] = cell.Value;

            return res;

        }, {}));
    }

    return results;
}