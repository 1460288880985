import useUploadyContext from "./useUploadyContext";

const useUploadOptions = options => {
  const context = useUploadyContext();

  if (options) {
    context.setOptions(options);
  }

  return context.getOptions();
};

export default useUploadOptions;