import { debugLog } from "../logger";

const parseResponseHeaders = xhr => {
  let resHeaders;

  try {
    resHeaders = xhr.getAllResponseHeaders().trim().split(/[\r\n]+/).reduce((res, line) => {
      const [key, val] = line.split(": ");
      res[key] = val;
      return res;
    }, {});
  } catch (ex) {
    debugLog("uploady.request: failed to read response headers", xhr);
  }

  return resHeaders;
};

export default parseResponseHeaders;