import React, { useEffect, useState } from "react";
import { dialog } from "@microsoft/teams-js";

export function SoundboardPopup() {
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(window.location.href);
  }, []);
  return (
    <div>
      <div
        onClick={() => {
          dialog.submit();
        }}>
        Submit Me
      </div>
      {url}
    </div>
  );
}
