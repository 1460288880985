import { FILE_STATES, logger } from "@rpldy/shared";
import { UPLOADER_EVENTS, ITEM_FINALIZE_STATES } from "../consts";
import { cleanUpFinishedBatches, incrementBatchFinishedCounter } from "./batchHelpers";
import { finalizeItem } from "./itemHelpers";
export const FILE_STATE_TO_EVENT_MAP = {
  [FILE_STATES.PENDING]: null,
  [FILE_STATES.ADDED]: UPLOADER_EVENTS.ITEM_START,
  [FILE_STATES.FINISHED]: UPLOADER_EVENTS.ITEM_FINISH,
  [FILE_STATES.ERROR]: UPLOADER_EVENTS.ITEM_ERROR,
  [FILE_STATES.CANCELLED]: UPLOADER_EVENTS.ITEM_CANCEL,
  [FILE_STATES.ABORTED]: UPLOADER_EVENTS.ITEM_ABORT,
  [FILE_STATES.UPLOADING]: UPLOADER_EVENTS.ITEM_PROGRESS
};

const getIsFinalized = item => !!~ITEM_FINALIZE_STATES.indexOf(item.state);

const processFinishedRequest = (queue, finishedData, next) => {
  finishedData.forEach(itemData => {
    const state = queue.getState();
    const {
      id,
      info
    } = itemData;
    logger.debugLog("uploader.processor.queue: request finished for item - ", {
      id,
      info
    });

    if (state.items[id]) {
      queue.updateState(state => {
        const item = state.items[id];
        item.state = info.state;
        item.uploadResponse = info.response;
        item.uploadStatus = info.status;

        if (getIsFinalized(item)) {
          delete state.aborts[id];
        }
      }); //get most up-to-date item data

      const item = queue.getState().items[id];

      if (info.state === FILE_STATES.FINISHED && item.completed < 100) {
        //ensure we trigger progress event with completed = 100 for all items
        queue.handleItemProgress(item, 100, item.file ? item.file.size : 0);
      }

      if (FILE_STATE_TO_EVENT_MAP[item.state]) {
        //trigger UPLOADER EVENT for item based on its state
        queue.trigger(FILE_STATE_TO_EVENT_MAP[item.state], item);
      }

      if (getIsFinalized(item)) {
        incrementBatchFinishedCounter(queue, item.batchId); //trigger FINALIZE event

        queue.trigger(UPLOADER_EVENTS.ITEM_FINALIZE, item);
      }
    }

    finalizeItem(queue, id);
  }); //ensure finished batches are remove from state

  cleanUpFinishedBatches(queue);
  next(queue);
};

export default processFinishedRequest;