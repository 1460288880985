import { Html, OrbitControls, useFBX, useGLTF, Text, Stars, Cloud, SpotLight, useDepthBuffer, Environment, TransformControls } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Room } from "colyseus.js";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { AnimationMixer, GridHelper, LoopOnce, LoopRepeat, MathUtils, Object3D, SkeletonHelper, Vector3 } from "three";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { TomsState } from "../../../state/TomsState";

import JOYFULJUMPFBX from "../../resources/Joyful Jump.fbx";
import NEWTAUNT from "../../resources/Taunt9.fbx";
import SWIMMING from "../../resources/Swimming.fbx";
import SILLYDANCING from "../../resources/Silly Dancing.fbx";
import RUNNING from "../../resources/Running.fbx";
import JUMP from "../../resources/Jump (1).fbx";
import PINGPONG from "../../resources/pingpongtable.fbx";
import SWORD from "../../../static/guard/Sword And Shield Idle2.fbx";
import WAVING from "../../../static/animations/Waving2.fbx";
import IDLE from "../../resources/Idle.fbx";
import SITTINGTALKING from "../../../static/animations/SittingTalking.fbx";

import { createPlayerApi } from "./PlayerApi";
import { Physics } from "@react-three/cannon";
import { useColyseusRoom2 } from "../../hooks/useColyseusRoom";

function getSkinnedMesh(model) {
  let found = null;
  model.traverse((child) => {
    if (!found && child.type === "SkinnedMesh") {
      found = child;
    }
  });
  return found;
}

function getMappedBoneNames(bonesArray) {
  var tempObject = {};
  bonesArray.map((t) => {
    tempObject[t.name] = t.name;
  });
  return tempObject;
}

let usePlayerOneApi = createPlayerApi();

export function PlaygroundThing() {
  let playerOneIsWalking = usePlayerOneApi((state) => state.isWalking);

  return <Html>Is Walking: {JSON.stringify(playerOneIsWalking)}</Html>;
}

export function AvatarPlayground(props) {
  const { scene } = useThree();
  const [mixer1, setMixer1] = useState();
  const [mixer2, setMixer2] = useState();
  const room = props.room;
  console.log("ROOM", room);
  const model = useRef<Object3D>();
  let playerOneIsWalking = usePlayerOneApi((state) => state.isWalking);
  let setPlayerOneIsWalking = usePlayerOneApi((state) => state.walk);

  const [jump, setJump] = useState();
  const [dance, setDance] = useState();
  const [swim, setSwim] = useState();
  const [run, setRun] = useState();
  const [wave, setWave] = useState();
  const [sittingTalking, setSittingTalking] = useState();
  const [idle, setIdle] = useState();

  const [skHelper1, setSkHelper1] = useState();
  const [skHelper2, setSkHelper2] = useState();
  const [options, setOptions] = useState();

  const [playerOneModel, setPlayerOneModel] = useState();

  const joyfulJumpFbx = useFBX(JOYFULJUMPFBX);
  const jumpModel = SkeletonUtils.clone(joyfulJumpFbx);

  const playerAvatarGltf = useGLTF(props.avatarUrl);
  const playerModel = SkeletonUtils.clone(playerAvatarGltf.scene);

  const player2Fbx = useFBX(NEWTAUNT);
  const player2Model = SkeletonUtils.clone(player2Fbx);

  const emptyMixamoDanceModel = useFBX(SILLYDANCING);
  const emptyMixamoSwimModel = useFBX(SWIMMING);
  const emptyMixamoRunModel = useFBX(RUNNING);
  const emptyMixamoJumpModel = useFBX(JUMP);
  const emptyMixamoWaveModel = useFBX(WAVING);
  const emptyMixamoIdleModel = useFBX(IDLE);
  const emptyMixamoSittingTalkingModel = useFBX(SITTINGTALKING);

  const pingPongTableMOdel = useFBX(PINGPONG);

  useEffect(() => {
    if (!run) return;
    if (playerOneIsWalking) {
      run.setLoop(LoopRepeat);
      run.reset();
      run.play();
    } else {
      run.setLoop(LoopOnce, 1);
      // run.stop();
    }
  }, [playerOneIsWalking]);
  console.log({ emptyMixamoDanceModel });
  useEffect(() => {
    jumpModel.scale.x = 0.01;
    jumpModel.scale.y = 0.01;
    jumpModel.scale.z = 0.01;
    jumpModel.position.x = -1;
    jumpModel.visible = false;

    player2Model.scale.x = 0.01;
    player2Model.scale.y = 0.01;
    player2Model.scale.z = 0.01;
    player2Model.position.x = 1.5;
    player2Model.position.y = 1.5;

    player2Model.visible = false;

    playerModel.scale.x = 2;
    playerModel.scale.y = 2;
    playerModel.scale.z = 2;
    playerModel.position.z = 1.5;
    playerModel.position.y = -1.5;
    // pingPongTableMOdel.scale.x = 0.0075;
    // pingPongTableMOdel.scale.y = 0.0075;
    // pingPongTableMOdel.scale.z = 0.0075;
    // pingPongTableMOdel.position.z = 6;
    // pingPongTableMOdel.rotation.y = MathUtils.degToRad(90);
    // scene.add(pingPongTableMOdel);
    scene.add(jumpModel);
    // scene.add(playerModel);
    model.current.add(playerModel);
    console.log({ player2Model });
    scene.add(player2Model);
    let jumpModelSkinnedMesh = getSkinnedMesh(jumpModel);
    // jumpModelSkinnedMesh.skeleton.bones.shift();
    // jumpModelSkinnedMesh.skeleton.dispose();
    console.log("JUMP MODEL", jumpModel);
    // skeleton helpers
    let jumpModelSkeletonHelper = new SkeletonHelper(jumpModel);
    jumpModelSkeletonHelper.skeleton = jumpModelSkinnedMesh.skeleton;
    jumpModelSkeletonHelper.visible = false;
    // jumpModelSkeletonHelper.skeleton.bones[0].remove();
    // setSkHelper2(jumpModelSkeletonHelper);
    scene.add(jumpModelSkeletonHelper);

    let playerSkeletonHelper = new SkeletonHelper(playerModel);
    let playerSkinnedMesh = getSkinnedMesh(playerModel);
    playerSkeletonHelper.skeleton = playerSkinnedMesh.skeleton;
    playerSkeletonHelper.visible = false;
    scene.add(playerSkeletonHelper);
    // setSkHelper1(playerSkeletonHelper);

    let player2SkeletonHelper = new SkeletonHelper(player2Model);
    let player2SkinnedMesh = getSkinnedMesh(player2Model);
    player2SkeletonHelper.skeleton = player2SkinnedMesh.skeleton;
    player2SkeletonHelper.visible = false;
    scene.add(player2SkeletonHelper);
    setSkHelper1(playerSkeletonHelper);
    setSkHelper2(player2SkeletonHelper);

    console.log("PLAYER HELPER", playerSkeletonHelper);
    console.log("JUMPER HELPER", jumpModelSkeletonHelper);

    // let jumpAnimation = joyfulJumpFbx.animations[0];
    // let mixer3 = new AnimationMixer(jumpModel);
    // let jumpAct = mixer3.clipAction(jumpAnimation);

    // jumpAct.clampWhenFinished = true;
    // jumpAct.setLoop(LoopOnce, 1);

    let tauntAnimation = player2Fbx.animations[0];
    let mixer = new AnimationMixer(playerModel);
    let tauntAct = mixer.clipAction(tauntAnimation);

    let danceAnimation = emptyMixamoDanceModel.animations[0];
    let danceAct = mixer.clipAction(danceAnimation);

    let swimAnimation = emptyMixamoSwimModel.animations[0];
    let swimAct = mixer.clipAction(swimAnimation);

    let runAnimation = emptyMixamoRunModel.animations[0];
    let runAct = mixer.clipAction(runAnimation);

    let jumpAnimation = emptyMixamoJumpModel.animations[0];
    // jumpAnimation.tracks.splice(1, 1)
    // // debugger;
    // console.log("JUMP", jumpAnimation)
    let jumpAct = mixer.clipAction(jumpAnimation);


    let waveAnimation = emptyMixamoWaveModel.animations[0];
    waveAnimation.tracks.shift();
    waveAnimation.tracks.forEach((t) => {
      console.log("remapping names", t.name);
      const newName = t.name.replace("mixamorig1", "")
      t.name = newName;
      console.log(newName)
    })
    let waveAct =  mixer.clipAction(waveAnimation);
    waveAct.setLoop(LoopOnce, 1);


    let sittingTalkingAnimation = emptyMixamoSittingTalkingModel.animations[0];
    sittingTalkingAnimation.tracks.shift();
    sittingTalkingAnimation.tracks.forEach((t) => {
      console.log("remapping names", t.name);
      const newName = t.name.replace("mixamorig1", "")
      t.name = newName;
      console.log(newName)
    })
    let sittingTalkingAct =  mixer.clipAction(sittingTalkingAnimation);
    sittingTalkingAct.setLoop(LoopOnce, 1);


    let idleAnimation = emptyMixamoIdleModel.animations[0];
    idleAnimation.tracks.shift();
    idleAnimation.tracks.forEach((t) => {
      console.log("remapping names", t.name);
      const newName = t.name.replace("mixamorig1", "")
      t.name = newName;
      console.log(newName)
    })
    let idleAct =  mixer.clipAction(idleAnimation);
    idleAct.setLoop(LoopRepeat);
    // const gridHelper = new GridHelper(10, 20);
    // scene.add(gridHelper);
    // let offsets = SkeletonUtils.getSkeletonOffsets(playerSkeletonHelper, player2SkeletonHelper, options);
    // console.log({ offsets });
    // options["offsets"] = offsets;

    // setOptions(options);

    // SkeletonUtils.retarget(playerSkeletonHelper, jumpModelSkeletonHelper, options);

    setMixer1(mixer);
    setJump(jumpAct);
    setDance(danceAct);
    setSwim(swimAct);
    setRun(runAct);
    setWave(waveAct)
    setSittingTalking(sittingTalkingAct)
    setIdle(idleAct)
    // let newClip = SkeletonUtils.retargetClip(playerSkeletonHelper, player2SkeletonHelper, tauntAnimation, options);

    // playerModel.traverse(function (child) {
    //   if (child.type === "SkinnedMesh") {
    //     console.log(child.skeleton);
    //     child.skeleton.pose();
    //   }
    // });

    // let mixer4 = new AnimationMixer(playerSkeletonHelper);
    // let newClip2 = mixer4.clipAction(newClip);

    // setMixer2(mixer4);
    // newClip2.play();
    // console.log({ newClip });
  }, []);

  const jumpAction = () => {
    dance.stop();
    swim.stop();
    run.stop();
    wave.stop();
    jump.reset().play();
  };

  const danceAction = () => {
    jump.stop();
    swim.stop();
    run.stop();
    debugger;
    dance.reset().play();
  };
  const swimAction = () => {
    jump.stop();
    dance.stop();
    run.stop();
    swim.reset().play();
  };

  const runAction = () => {
    jump.stop();
    dance.stop();
    swim.stop();
    run.reset().play();
  };

  const waveAction = () => {
    jump.stop();
    dance.stop();
    swim.stop();
    run.stop();
    idle.stop();
    sittingTalking.stop();
    wave.reset().play();
  };

  const idleAction = () => {
    jump.stop();
    dance.stop();
    swim.stop();
    run.stop();
    wave.stop();
    idle.reset().play();

  };

  useFrame((state, delta) => {
    if (!model.current) return;

    if (mixer1) {
      mixer1.update(delta);

      if (skHelper1 && skHelper2) {
        // SkeletonUtils.retarget(skHelper1, skHelper2, options);
      }
    }

    if (mixer2) {
      mixer2.update(delta);
    }

    if (playerOneIsWalking) {
      let l = new Vector3(state.mouse.x, 0.0, 1);
      // model.current.position.z = model.current.position.z + 0.01;
      model.current.lookAt(l);
      model.current.position.x = state.mouse.x
    }
  });

  useEffect(() => {
    let listener = room.onMessage("handRaiseSeen", (message) => {
      wave.reset().play()
    })
    return () => listener()
  }, [room, wave])

  useEffect(() => {
    let listener = room.onMessage("saySpeechRequest", (message) => {
      sittingTalking.reset().play();
    })
    return () => listener()
  }, [room, sittingTalking])

  return (
    <group>
      {/* <TransformControls mode="translate"> */}
      <group ref={model}></group>
      {/* </TransformControls> */}
      {/* <Physics
        iterations={20}
        tolerance={0.0001}
        defaultContactMaterial={{
          friction: 0.9,
          restitution: 0.7,
          contactEquationStiffness: 1e7,
          contactEquationRelaxation: 1,
          frictionEquationStiffness: 1e7,
          frictionEquationRelaxation: 2,
        }}
        gravity={[1, 1, 1]}
        allowSleep={false}>
        <mesh position={[0, 2, 6.0]}>
          <boxGeometry args={[5.5, 0.1, 10.0]} />

          <meshStandardMaterial color={"green"} />
        </mesh>
      </Physics> */}
      <Html>
        <div className={"flex"}>
          {model.current && JSON.stringify(model.current.rotation)}
          
          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              idleAction();
            }}>
            Idle
          </div>
          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              jumpAction();
            }}>
            Jump
          </div>

          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              waveAction();
            }}>
            Wave
          </div>

          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              danceAction();
            }}>
            Dance
          </div>
          {/* <kbd className="kbd">▲</kbd>
        <kbd className="kbd">▼</kbd>
        <kbd className="kbd">◀︎</kbd>
        <kbd className="kbd">▶︎</kbd>
        <div
          className={"btn btn-xs btn-primary cursor-pointer p-1"}
          onClick={() => {
            jumpAction();
          }}>
          Taunt
        </div>
        <div
          className={"btn btn-xs btn-primary cursor-pointer p-1"}
          onClick={() => {
            runAction();
          }}>
          Run
        </div>
        <div
          className={"btn btn-xs btn-primary cursor-pointer p-1"}
          onClick={() => {
            danceAction();
          }}>
          Dance
        </div> */}
          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              swimAction();
            }}>
            Swim
          </div>
          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              setPlayerOneIsWalking(true);
            }}>
            Walk
          </div>
          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              setPlayerOneIsWalking(false);
            }}>
            Stop Walk
          </div>
          <div
            className={"btn btn-xs btn-primary cursor-pointer p-1"}
            onClick={() => {
              setPlayerOneIsWalking(false);
            }}>
            Walk
          </div>
          is walking {JSON.stringify(playerOneIsWalking)}
        </div>
      </Html>
    </group>
  );
}

function SpotLightControl(){
  const depthBuffer = useDepthBuffer({ size: 256 })

  return  <SpotLight
  penumbra={0.5}
  depthBuffer={depthBuffer}
  position={[3, 2, 0]}
  intensity={0.5}
  angle={0.5}
  color="#ff005b"
  castShadow
/>
}
export function AvatarThing(props) {

  return (
  
    <Canvas id={"avatarCanvas"} color={"#ff555f"} >
         
      <color attach="background" args={['#ff555f']} />
      <OrbitControls />
      <pointLight position={[1, 2, 0]} castShadow={true} />;
      <ambientLight />
      {/* <gridHelper args={{ color: "white" }} /> */}
      <Suspense fallback={null}>
        <AvatarPlayground avatarUrl={props.avatarUrl} room={props.room} />
      </Suspense>
   
    {/* <SpotLightControl />
      <Text color="white" anchorX="center" anchorY="middle" scale={[10,10,10]}>
  hello world!
</Text> */}
{/* <React.Suspense fallback={null}>
      <Cloud position={[-4, -2, 0]} args={[3, 2]} />
      <Cloud position={[-4, 2, 0]} args={[3, 2]} />
      <Cloud args={[3, 2]} />
      <Cloud position={[4, -2, 0]} args={[3, 2]} />
      <Cloud position={[4, 2, 0]} args={[3, 2]} />
    </React.Suspense> */}

      {/* <PlaygroundThing /> */}
    </Canvas>
    
  );
}

export function Playground({ room }: { room: Room<TomsState> }) {
  let [queryComplete, setQueryComplete] = useState(false);
  let [avatarData, setAvatarData] = useState<any>();


  useEffect(() => {
    let listener = room.onMessage("gotAllAvatars", (message) => {
      console.log(message);
      let arr = [];
      if (message.data.length > 0) {
        message.data.forEach((p, index) => {
          if (index === 1) {
            arr.push(p);
          }
        });
        setAvatarData(arr);
      }
      setQueryComplete(true);
    });
    room.send("getAllAvatarsCustom");

    return () => {
      listener();
    };
  }, [room]);

  return (
    <div>
      <a href="/player">Create Character</a>
      <div>
        <div className={"pt-1"}>
          {avatarData &&
            avatarData.map((av) => {
              return (
                <div style={{ width: "75%", height: "55%" }}>
                  {av.avatarUrl && <AvatarThing avatarUrl={av.avatarUrl} />}
                </div>
              );
            })}
          {/* <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            {avatarData && avatarData.avatarUrl && <AvatarThing avatarUrl={avatarData.avatarUrl} />}
          </div> */}
        </div>
      </div>
    </div>
  );
}
