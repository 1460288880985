import React, { useEffect, useState } from "react";
import Sketch from "react-p5";
import p5Types from "p5";

const fragSgader = `
precision mediump float;

// grab texcoords from vert shader
varying vec2 vTexCoord;

// our texture coming from p5
uniform sampler2D tex0;


void main() {
    vec2 uv = vTexCoord;
    
    // the texture is loaded upside down and backwards by default so lets flip it
    uv.y = 1.0 - uv.y;
    
    vec4 tex = texture2D(tex0, uv);
    
    float gray = (tex.r + tex.g + tex.b) / 3.0;
    
    float res = 20.0;
    float scl = res / (10.0);
    
    float threshR = (fract(floor(tex.r*res)/scl)*scl) * gray ;
    float threshG = (fract(floor(tex.g*res)/scl)*scl) * gray ;
    float threshB = (fract(floor(tex.b*res)/scl)*scl) * gray ;
    vec3 thresh = vec3(threshR, threshG, threshB);
    
    // render the output
    gl_FragColor = vec4(thresh, 1.0);
}`;

const vertShader = `
attribute vec3 aPosition;
attribute vec2 aTexCoord;

// lets get texcoords just for fun!
varying vec2 vTexCoord;

void main() {
    // copy the texcoords
    vTexCoord = aTexCoord;
    
    // copy the position data into a vec4, using 1.0 as the w component
    vec4 positionVec4 = vec4(aPosition, 1.0);
    positionVec4.xy = positionVec4.xy * 2.0 - 1.0;
    
    // send the vertex information on to the fragment shader
    gl_Position = positionVec4;
}
`;

var images = [];
var imageIndex = 0;
var position;
var velocity;

export function P5Experience() {
  const setup = (p5: p5Types, canvasParentRef: Element) => {
    const canvas = p5.createCanvas(800, 600).parent(canvasParentRef);
    canvas.id("dvdLogo");
    p5.noStroke();
    position = p5.createVector();
    velocity = p5.createVector(0.707, 0.707);

    // velocity.mult(5);
    //     setPosition(p5.createVector(0, 0))
    // var velocity = p5.createVector(0, 0)fromAngle(45);
    // velocity.mult(5);
    // cam = p5.createCapture(p5.VIDEO);
    // cam.size(710, 400);

    // cam.hide();
    // setCam(cam);
  };

  const draw = (p5: p5Types) => {
    p5.background(250);
    let radius = p5.width * 1.5;

    //drag to move the world.
    p5.orbitControl();

    p5.normalMaterial();
    p5.translate(0, 0, -600);
    for (let i = 0; i <= 12; i++) {
      for (let j = 0; j <= 12; j++) {
        p5.push();
        let a = (j / 12) * p5.PI;
        let b = (i / 12) * p5.PI;
        p5.translate(p5.sin(2 * a) * radius * p5.sin(b), (p5.cos(b) * radius) / 2, p5.cos(2 * a) * radius * p5.sin(b));
        if (j % 2 === 0) {
          p5.cone(30, 30);
        } else {
          p5.box(30, 30, 30);
        }
        p5.pop();
      }
    }
  };

  function checkBoundaryCollision(image, p5) {
    var hasCollision = false;

    // left or right collision
    if (position.x < 0 || position.x + image.width > p5.width) {
      velocity.x *= -1;
      hasCollision = true;
    }
    // top or bottom collision
    if (position.y < 0 || position.y + image.height > p5.height) {
      velocity.y *= -1;
      hasCollision = true;
    }
    return hasCollision;
  }

  const draw2 = (p5: p5Types) => {
    p5.background("#111");
    // p5.background(0, 100);
    // p5.background("rgba(255,255,255, 0)");

    var image = images[imageIndex];
    var hasCollision = checkBoundaryCollision(image, p5);
    if (hasCollision) {
      imageIndex++;
      if (imageIndex + 1 > images.length) {
        imageIndex = 0;
      }
      image = images[imageIndex];
    }

    // p5.stroke(255);
    // p5.point(p5.width * 0.5, p5.height * 0.5);
    // p5.point(p5.width * 0.5, p5.height * 0.25);

    // Coordinates are used for drawing all shapes, not just points.
    // Parameters for different functions are used for different
    // purposes. For example, the first two parameters to line()
    // specify the coordinates of the first endpoint and the second
    // two parameters specify the second endpoint
    // p5.stroke(0, 153, 255);
    // p5.line(0, p5.height * 0.33, p5.width, p5.height * 0.33);

    // By default, the first two parameters to rect() are the
    // coordinates of the upper-left corner and the second pair
    // is the width and height
    // p5.stroke(255, 153, 0);
    // p5.rect(p5.width * 0.25, p5.height * 0.1, p5.width * 0.5, p5.height * 0.8);
    // console.log(image, velocity, position);
    position.add(velocity);
    // console.log("X", position.x);
    // console.log("Y", position.y);
    // console.log("NEW VELO", velocity);
    // position.add(p5Types.Vector.fromAngle(45), 2);
    p5.image(image, position.x, position.y);
  };

  const preload = (p5: p5Types) => {
    for (var i = 1; i < 8; i++) {
      var image = p5.loadImage("dvd/dvd" + i + ".svg");
      images.push(image);
    }
  };

  // useEffect(() => {
  //   console.log("the shader is ", theShader);
  // }, [theShader]);

  return <Sketch setup={setup} draw={draw2} preload={preload} />;
}

export function P5Experience2() {
  let x = 50;
  const y = 50;

  let [theShader, setTheShader] = useState<any>();
  let [cam, setCam] = useState<any>();

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(600, 800, p5.WEBGL).parent(canvasParentRef);
    p5.noStroke();
    p5.rectMode(p5.CENTER);
  };

  const draw = (p5: p5Types) => {
    p5.background(230);
    p5.fill(244, 122, 158);
    p5.rect(p5.mouseX, p5.height / 2, p5.mouseY / 2 + 10, p5.mouseY / 2 + 10);
    p5.fill(237, 34, 93);
    let inverseX = p5.width - p5.mouseX;
    let inverseY = p5.height - p5.mouseY;
    p5.rect(inverseX, p5.height / 2, inverseY / 2 + 10, inverseY / 2 + 10);
  };

  const preload = (p5: p5Types) => {
    // load the shader

    let s = p5.loadShader("/vertShader.vert", "/fragShader.frag");
    setTheShader(s);
  };

  useEffect(() => {
    console.log("the shader is ", theShader);
  }, [theShader]);

  return <Sketch setup={setup} draw={draw} preload={preload} />;
}

class Particle {
  // setting the co-ordinates, radius and the
  // speed of a particle in both the co-ordinates axes.
  p5;

  constructor(p5) {
    this.p5 = p5;
    this.x = p5.random(0, p5.width);
    this.y = p5.random(0, p5.height);
    this.r = p5.random(1, 8);
    this.xSpeed = p5.random(-2, 2);
    this.ySpeed = p5.random(-1, 1.5);
  }

  // creation of a particle.
  createParticle() {
    this.p5.noStroke();
    this.p5.fill("rgba(200,169,169,0.5)");
    this.p5.circle(this.x, this.y, this.r);
  }

  // setting the particle in motion.
  moveParticle() {
    if (this.x < 0 || this.x > this.p5.width) this.xSpeed *= -1;
    if (this.y < 0 || this.y > this.p5.height) this.ySpeed *= -1;
    this.x += this.xSpeed;
    this.y += this.ySpeed;
  }

  // this function creates the connections(lines)
  // between particles which are less than a certain distance apart
  joinParticles(particles) {
    particles.forEach((element) => {
      let dis = this.p5.dist(this.x, this.y, element.x, element.y);
      if (dis < 85) {
        this.p5.stroke("rgba(130,243,255,0.34)");
        this.p5.line(this.x, this.y, element.x, element.y);
      }
    });
  }
}

let particles = [];
export function P5Experience3() {
  let x = 50;
  const y = 50;

  let [theShader, setTheShader] = useState<any>();
  let [cam, setCam] = useState<any>();

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(600, 800, p5.WEBGL).parent(canvasParentRef);
    // p5.noStroke();
    for (let i = 0; i < p5.width / 10; i++) {
      particles.push(new Particle(p5));
    }
  };

  const draw = (p5: p5Types) => {
    p5.background("#0f0f0f");
    for (let i = 0; i < particles.length; i++) {
      particles[i].createParticle();
      particles[i].moveParticle();
      particles[i].joinParticles(particles.slice(i));
    }
  };

  useEffect(() => {
    console.log("the shader is ", theShader);
  }, [theShader]);

  return <Sketch setup={setup} draw={draw} style={{ display: "block" }} />;
}
