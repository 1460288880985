import isPlainObject from "./isPlainObject";
export const isMergeObj = obj => isPlainObject(obj) || Array.isArray(obj);

const getKeys = (obj, options) => {
  const keys = Object.keys(obj);
  return options.withSymbols ? keys.concat(Object.getOwnPropertySymbols(obj)) : keys;
};

const getMerge = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  const merge = function (target) {
    for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    if (target && sources.length) {
      sources.forEach(source => {
        if (source) {
          getKeys(source, options).forEach(key => {
            const prop = source[key];

            if (!options.predicate || options.predicate(key, prop)) {
              if (typeof prop !== "undefined" || options.undefinedOverwrites) {
                //object/array - go deeper
                if (isMergeObj(prop)) {
                  if (typeof target[key] === "undefined" || !isPlainObject(target[key])) {
                    //recreate target prop if doesnt exist or not an object
                    target[key] = Array.isArray(prop) ? [] : {};
                  }

                  merge(target[key], prop);
                } else {
                  target[key] = prop;
                }
              }
            }
          });
        }
      });
    }

    return target;
  };

  return merge;
};
/**
 * Does deep merge of simple objects and arrays (only)
 *
 * The first parameter is the target
 * Will only merge objects passed as arguments to this method
 * Any property in a later object will simply override the one in a previous one
 * Undefined properties from sources will be ignored
 *
 * No recursion protection
 */


export default getMerge();
export { getMerge };