import { LocalAudioStream } from "@azure/communication-calling";


const apiKey = process.env["REACT_APP_ELEVENLABS_API_KEY"] as string;


export const getVoices = async () => {
    return fetch("https://api.elevenlabs.io/v1/voices", { "method": "GET", headers: { 
        "Content-Type": "application/json",
        "xi-api-key": apiKey
    }}).then((resp) => resp.json());
}

 
export const textToSpeechStream = async (textToSpeak : string, voiceId: string) => {
    return fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`, { "method": "POST", headers: { 
        "Content-Type": "application/json",
        "xi-api-key": apiKey
    }, body: JSON.stringify({
        "text": textToSpeak,
        "voice_settings": {
          "stability": 0.75,
          "similarity_boost": 0.75,
          "optimize_streaming_latency": 4
        }
      })}).then((resp) => 
      {
        return resp.arrayBuffer()
      }
      );
}

export const textToSpeechStream2 = async (textToSpeak : string, voiceId: string) => {
    return fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`, { "method": "POST", headers: { 
        "Content-Type": "application/json",
        "xi-api-key": apiKey
    }, body: JSON.stringify({
        "text": textToSpeak,
        "voice_settings": {
          "stability": 0,
          "similarity_boost": 0
        }
      })});
}

export const textToSpeechReadableStream = async (textToSpeak : string, voiceId: string, audioContext: AudioContext, call, mediaDestinationNode) => {
    return fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`, { "method": "POST", headers: { 
        "Content-Type": "application/json",
        "xi-api-key": apiKey
    }, body: JSON.stringify({
        "text": textToSpeak,
        "voice_settings": {
          "stability": 0,
          "similarity_boost": 0
        }
      })}).then(async (resp) => 
      {


        // debugger;
        const reader = resp.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then( async ({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                console.log("inside chunk")
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                // const mediaDestinationNode = audioContext?.createMediaStreamDestination();
                const buffer = await audioContext.decodeAudioData(value.buffer)
                const source = new AudioBufferSourceNode(audioContext, { buffer: buffer });
                source.connect(mediaDestinationNode);
                source.start()
                
                source.onended = () => {
                    pump()
                }
                if (call.isMuted){
                call.unmute();
                }
                const localAudioStream = new LocalAudioStream(mediaDestinationNode.stream);
                await call.startAudio(localAudioStream);
                // return pump();
              });
            }
          }
        })
      }
      );
}

export const streamResponse = async (
    response: Response,
    audioEl: HTMLAudioElement | null = null,
    onContentStart: (() => any) | null = null,
    onChunkReceive: ((chunk: ArrayBuffer) => any) | null = null,
  ) => {
    if (response.body === null) {
      throw Error("Response body can not be null ...");
    }
    const readChunks = (reader: ReadableStreamDefaultReader<Uint8Array>) => {
      return {
        async *[Symbol.asyncIterator]() {
          let readResult = await reader.read();
          while (!readResult.done) {
            yield readResult.value;
            readResult = await reader.read();
          }
        },
      };
    };
  
    const typedArrayToBuffer = (array: Uint8Array) => {
      return array.buffer.slice(
        array.byteOffset,
        array.byteLength + array.byteOffset
      );
    };
  
    const mediaSource = new MediaSource();
    if (audioEl !== null) {
      audioEl.src = URL.createObjectURL(mediaSource);
    }
    await new Promise((resolve) => {
      mediaSource.onsourceopen = resolve;
    });
  
    const sourceBuffer = mediaSource.addSourceBuffer(
      // @ts-expect-error
      response.headers.get("content-type")
    );
    const reader = response.body.getReader();
    let first = true;
    for await (const chunk of readChunks(reader)) {
      if (first && onContentStart !== null) {
        onContentStart();
        first = false;
      }
      await new Promise(async (resolve) => {
        sourceBuffer.onupdateend = resolve;
        const buffer = typedArrayToBuffer(chunk);
        if (onChunkReceive !== null) {

          await onChunkReceive(buffer);
        }

        
        sourceBuffer.appendBuffer(buffer);
      });
    }
    mediaSource.endOfStream();
  };