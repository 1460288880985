import React, { useState } from "react";
import { AIExperienceState } from "../../../state/AIExperienceState";
import { TomsState } from "../../../state/TomsState";
import { useColyseusRoom2 } from "../../hooks/useColyseusRoom";
import { useGlobalStore2 } from "../../hooks/useGlobalState";

function playTone() {  
  var audioContext = new (window.AudioContext || window.webkitAudioContext)();  

  var startTime = audioContext.currentTime;  
  var duration = 0.5;  

  [554.37, 622.25, 523.25].forEach(function(frequency, index) {  
      var oscillator = audioContext.createOscillator();  
      var gainNode = audioContext.createGain();  

      oscillator.frequency.value = frequency;  
      oscillator.type = "triangle";  
      gainNode.gain.setValueAtTime(0, startTime + duration * index);  
      gainNode.gain.linearRampToValueAtTime(1, startTime + duration * index + 0.1);  
      gainNode.gain.linearRampToValueAtTime(0, startTime + duration * (index + 1));  

      oscillator.connect(gainNode);  
      gainNode.connect(audioContext.destination);  

      oscillator.start(startTime + duration * index);  
      oscillator.stop(startTime + duration * (index + 1));  
  });  
}  

playTone();  



export function SpeechSave(){
    const playerName = useGlobalStore2((store) => store.playerName);
    const playerImage = useGlobalStore2((store) => store.playerImageBase64);
    const teamsContext = useGlobalStore2((store) => store.teamsContext);

    let defaultName = "TOM";

    let toMerge = { name: playerName || defaultName, forceNewRoom: false, frameContext: "none" };
    console.log("JOINING COLLABAI ROOM WITH CONTEXT", teamsContext);

    let joinObj = {
        ...teamsContext,
        ...toMerge,
        ...{ imageBase64: playerImage },
      };
      const { client, room } = useColyseusRoom2<AIExperienceState>(
        "collabai",
        joinObj
      );
      
      const [speechToGenerate, setSpeechToGenerate] = useState<string>("");
      const [shouldVectorEmbed, setShouldVectorEmbed] = useState<boolean>(false);

    return <div>
        <div>Speech Save</div>
        <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Vector Embed Speech</span>
          <input
            type="checkbox"
            className="toggle"
            checked={shouldVectorEmbed}
            onChange={async (e) => {
              if (e.target.checked) {
                setShouldVectorEmbed(true);
              } else {
                setShouldVectorEmbed(false);
              }
            }}
          />
        </label>
      </div>
      <input
        type="text"
        placeholder="Enter a search query"
        className="input input-sm input-bordered w-80"
        value={speechToGenerate}
        onChange={(e) => {
          setSpeechToGenerate(e.target.value);
        }}></input>
        <div className={"btn btn-sm"} onClick={() => {
            room?.send("generateSpeech", {
                text: speechToGenerate,
                shouldVectorEmbed: shouldVectorEmbed
            });
        }}>Gen</div>

<div className={"btn btn-sm"} onClick={() => {
            playTone();
        }}>Play Tone</div>
    </div>
}