
export function isVideoFile(url) {
    return url.endsWith(".mp4");
}

export const getSoundName = (theUrl) => {
    return theUrl.split("/").pop().split(".")[0];
};

export type ReactionType = "sound" | "video";
export const getReactionTypeFromSoundUrl = (soundUrl): ReactionType => {
    if (isVideoFile(soundUrl)) {
        return "video";
    }
    return "sound";
};