import React from "react";
import { useJesseState } from "./useJesseStore";

export function Jesse() {
  const queryText = useJesseState((state) => state.queryText);
  const setQueryText = useJesseState((state) => state.setQueryText);
  const isLoading = useJesseState((state) => state.isLoading);

  const results = useJesseState((state) => state.results);
  const namespace = useJesseState((state) => state.namespace);
  const setNamespace = useJesseState((state) => state.setNamespace);

  const model = useJesseState((state) => state.model);
  const setModel = useJesseState((state) => state.setModel);

  const createLLMResponse = useJesseState(state => state.createLLMResponse);
  const setCreateLLMResponse = useJesseState(state => state.setCreateLLMResponse);

  const executeQuery = useJesseState((state) => state.executeQuery);

  return (
    <div className={"p-2"}>
        <div className={"p-2"}>
      <h2 className={"text-3xl pb-6"}>Jess-E Vector Search</h2>
      <input
        type="text"
        placeholder="Type your query here"
        className="input input-sm input-bordered w-full max-w-xs"
        value={queryText}
        onChange={(e) => {
          setQueryText(e.target.value);
        }}
      />

      <div
        className={"btn btn-sm"}
        onClick={() => {
          executeQuery();
        }}
      >
        Search
      </div>
      </div>
      <div>

<div className={"p-2"}>
      <select
          className="select select-sm select-secondary w-full max-w-xs"
          onChange={(e) => {
            setNamespace(e.target.value);
          }}
        >
          <option disabled>Select Namespace</option>
          <option selected value={"ns4"}>
            Jess-E Knowledge Base
          </option>
          <option value={"jokes"}>Jokes</option>
          <option value={"ns10"}>MS Teams Chat</option>
        </select>

        <select
          className="select select-sm select-secondary w-full max-w-xs"
          onChange={(e) => {
            setModel(e.target.value);
          }}
        >
          <option disabled>Select Model</option>
          <option>GPT 3.5 turbo</option>
          <option selected>gpt-4-8k</option>
        </select>

        
      <div className="form-control w-full max-w-xs">
        
        <label className="label cursor-pointer">
          <span className="label-text">Create LLM Response</span>
          <input
            type="checkbox"
            className="toggle"
            checked={createLLMResponse}
            onChange={(e) => {
              if (e.target.checked) {
                setCreateLLMResponse(true)
              } else {
                setCreateLLMResponse(false)
              }
            }}
          />
        </label>
      </div>


      </div>
      </div>
   
      {isLoading ? (
        <div className={"text-sm"}>Loading...</div>
      ) : (
        <div>
          {results.map((r) => {
            let scoreClass = r.score >= 0.8 ? "text-success" : "text-error";
            const truncateDecimals = (number, digits) => {
                const multiplier = Math.pow(10, digits),
                    adjustedNum = number * multiplier,
                    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
            
                return truncatedNum / multiplier;
            };
            return (
              <div className={"p-2"}>
                {r.metadata.title &&
                <div className={"text-xs text-primary "}>
                  Title: {r.metadata.title}
                </div>}
                
                <div className={`text-xs ${scoreClass}`}>Score: {r.score}</div>
                <div className={"text-xs"}>Text: {r.metadata.text || r.id}</div>
                { r.metadata.token_count_estimate && <div className={"text-xs"}>Token Estimate: {r.metadata.token_count_estimate}, GPT4 Cost: <span className={"text-error"}>{truncateDecimals((r.metadata.token_count_estimate / 1000) * 0.06, 3)}</span></div>}

                <div className="divider lg:divider-horizontal"></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
