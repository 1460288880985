import { dialog } from "@microsoft/teams-js";
import React from "react";

export function KnowledgeHub(){
    const urlParams = new URLSearchParams(window.location.search);
    const text = urlParams.get("text") || "";

    return <div className={"p-2"}>
         <div className="flex flex-col h-screen justify-between">
  <header className="h-10"><div>Would you like to send the chat message to Knowledge Hub? </div></header>
  <main className="mb-auto h-10">
    <br />

     <div dangerouslySetInnerHTML={{ __html: text }}></div>  </main>
  <footer className="h-12">  <div className={"btn mb-4 btn-sm btn-primary"} onClick={() => {
             dialog.url.submit({
                text: text,
              });
        }}>OK</div></footer>
</div>
        {/* <div>Would you like to send the chat message to Knowledge Hub? </div>
        <br />
        <br />
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        {/* <div>{text.replace("<p>", "").replace("</p>", "")}</div> */}
        {/* <br />
        <br />
        <br />
        <div className={"btn btn-sm btn-primary"} onClick={() => {
             dialog.url.submit({
                text: text,
              });
        }}>OK</div> */} 
    </div>
}

export function KnowledgeHubRemove(){
    const urlParams = new URLSearchParams(window.location.search);
    const text = urlParams.get("text") || "";

    return <div className={"p-2"}>
       
        <div>Would you like to remove this conversation from the Knowledge Hub? The AI will no longer consider this conversation in it's replies. </div>
        <br />
        <br />
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        {/* <div>{text.replace("<p>", "").replace("</p>", "")}</div> */}
        <br />
        <br />
        <br />
        <div className={"btn btn-sm btn-primary"} onClick={() => {
             dialog.url.submit({
                text: text,
              });
        }}>OK</div>
    </div>
}