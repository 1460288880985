import * as twgl from "twgl.js/dist/4.x/twgl-full.module";
import * as chroma from "chroma-js";
import { video } from "@microsoft/teams-js";

export class VideoFilter {
  constructor(canvas: OffscreenCanvas) {
    this.canvas = canvas;
    this.gl = canvas.getContext("webgl2");
    this.canvasWidth = 430;
    this.canvasHeight = 750;
    this.initalized = false;
    this.program = null;
    this.textureY = null;
    this.textureUV = null;
    this.effectPixelBuffer = null;
    this.time0 = new Date().getTime() / 1000;
   
    
  }

  init(sourceName, renderCanvasId = "textCanvas") {
    if (!this.initalized) {
      //         this._videoElement = document.getElementById("videoElement");
      //        this._bitmapPrerender = document.getElementById("two").getContext("bitmaprenderer");
      //        this._canvas2 = document.getElementById("two")

      //        this._stream = this._canvas2.captureStream(0);
      //        this._videoElement.srcObject = this._stream;
      // this._videoElement.play();

      this._initProgram(sourceName);
      this._initVertexBuffers();
      this._initTexture();

      // this.startTwglCanvas();

      // this._initTexture2();
      this.initalized = true;
        this.renderCanvasId = renderCanvasId;
      window.onpointermove = (e) => {
        this.gl.uniform2f(this.gl.uMouse, e.clientX, e.clientY);
      };
      window.onpointerdown = (e) => {
        this.gl.uniform1f(this.gl.uMouseDown, 1.0);
      };
      window.onpointerup = (e) => {
        this.gl.uniform1f(this.gl.uMouseDown, 0.0);
      };

      // create media pipe facemesh instance
      // const facemesh = new FaceMesh({
      //     locateFile: (file) => {
      //         return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      //     },
      // });
      // // set facemesh config
      // facemesh.setOptions({
      //     maxNumFaces: 1,
      //     refineLandmarks: true,
      //     minDetectionConfidence: 0.5,
      //     minTrackingConfidence: 0.5,
      // });

      // facemesh.onResults((results) => {
      //     console.log("FACEMESH RESULT", results);
      // });

      // this._faceMesh = facemesh;

      this.doTomsCustomThing(this.renderCanvasId);

      // this.doTwiglThing();
      // this._videoElement.onloadeddata = (ev) => {
      //   console.log(ev)
      //        this.camera = new Camera(this._videoElement, {
      //   onFrame: async () => {
      //     await this._facemesh.send({ image: this._videoElement });
      //   },
      //   width: 640,
      //   height: 480
      // });
      // debugger;
      // // this.camera.start();
      // }
    }
  }

  doTwiglThing() {
    console.log("TWGL", twgl);
    this._twglCanvas = document.getElementById("c");
    twgl.setDefaults({ attribPrefix: "a_" });
    const m4 = twgl.m4;

    const gl = document.querySelector("#c").getContext("webgl");
    const programInfo = twgl.createProgramInfo(gl, ["vs", "fs"]);

    const bufferInfo = twgl.primitives.createPlaneBufferInfo(
      gl,
      1,
      1,
      1,
      1,
      m4.rotationX(Math.PI * 0.5)
    );

    function rand(min, max) {
      return min + Math.random() * (max - min);
    }

    // Shared values
    const camera = m4.identity();
    const view = m4.identity();
    const viewProjection = m4.identity();
    const world = m4.identity();

    const ctx = document.createElement("canvas").getContext("2d");

    function makeText(text) {
      ctx.font = "20px monospace";
      const t = ctx.measureText(text);
      ctx.canvas.width = Math.ceil(t.width) + 2;
      ctx.canvas.height = 24;
      ctx.font = "20px monospace";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseAlign = "middle";
      ctx.fillText(
        text,
        (ctx.canvas.width / 2) | 0,
        (ctx.canvas.height / 2) | 0
      );
      return ctx.canvas;
    }

    const msgs = [
      "synergy",
      "alignment",
      "jill",
      "brian",
      "thomas",
      "chrissy",
      "jennifer",
      "alexander",
    ];

    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
    const textTextures = msgs.map(function (msg) {
      const canvas = makeText(msg);
      const scale = 0.01;
      return {
        tex: twgl.createTexture(gl, { src: canvas }),
        scale: [canvas.width * scale, canvas.height * scale, 1],
      };
    });

    const objects = [];
    const drawObjects = [];
    const numObjects = 500;
    const baseHue = rand(0, 360);
    for (let ii = 0; ii < numObjects; ++ii) {
      const texNdx = rand(0, textTextures.length) | 0;
      const text = textTextures[texNdx];
      const uniforms = {
        u_texture: text.tex,
        u_worldViewProjection: m4.identity(),
        u_color: chroma.hsv((baseHue + rand(0, 60)) % 360, 1, 1).gl(),
      };
      drawObjects.push({
        programInfo: programInfo,
        bufferInfo: bufferInfo,
        uniforms: uniforms,
      });
      objects.push({
        translation: [rand(-10, 10), rand(-10, 10), rand(-10, 10)],
        ySpeed: rand(0.1, 0.3),
        zSpeed: rand(0.1, 0.3),
        uniforms: uniforms,
        texInfo: text,
      });
    }
    const clearColor = [0.0, 0.0, 0.0, 0.0]; //chroma.hsv((baseHue + 30) % 360, 0.5, 0.5).gl();

    function render(time) {
      time *= 0.001;
      twgl.resizeCanvasToDisplaySize(gl.canvas);
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

      gl.enable(gl.DEPTH_TEST);
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
      gl.clearColor(clearColor[0], clearColor[1], clearColor[2], clearColor[3]);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      const projection = m4.perspective(
        (10 * Math.PI) / 180,
        gl.canvas.clientWidth / gl.canvas.clientHeight,
        0.5,
        100
      );
      const eye = [1, 4, -20];
      const target = [0, 0, 0];
      const up = [0, 1, 0];

      m4.lookAt(eye, target, up, camera);
      m4.inverse(camera, view);
      m4.multiply(projection, view, viewProjection);

      objects.forEach(function (obj) {
        const uni = obj.uniforms;
        const texInfo = obj.texInfo;
        m4.identity(world);
        m4.rotateY(world, time * obj.ySpeed, world);
        m4.rotateZ(world, time * obj.zSpeed, world);
        m4.translate(world, obj.translation, world);
        m4.rotateX(world, time, world);
        m4.scale(world, texInfo.scale, world);
        m4.multiply(view, world, world);

        m4.multiply(projection, world, uni.u_worldViewProjection);
      });

      twgl.drawObjectList(gl, drawObjects);

      requestAnimationFrame(render);
    }
    requestAnimationFrame(render);
  }

  doTomsCustomThing(renderCanvasId) {
    console.log("RENDERCANVASID", renderCanvasId);
    this._textCanvas = document.getElementById(renderCanvasId).getContext("2d");
    let otherCanvas = document.getElementById("otherCanvas");
    // console.log("OTHERCANV", otherCanvas);
    // let otherCanvasContext = otherCanvas.getContext("2d");
    // console.log("CONTEXT",  window.__tom.getContext("2d"));
    // this._textCanvas = window.__tom.getContext("2d");
    // Puts text in center of canvas.
    const makeTextCanvas = (text, width, height) => {
      // this._textCanvas.canvas.width  = width;
      // this._textCanvas.canvas.height = height;

      this._textCanvas.fillStyle = "white";
      this._textCanvas.fillRect(0, 0, width, height);

      // this._textCanvas.backgroundColor = "black";
      this._textCanvas.font = "20px monospace";

      var textSize = 56;
      this._textCanvas.font = textSize + "px monospace"; // Set the font of the text before measuring the width!
      // this._textCanvas.width = 460; //getPowerOfTwo(this._textCanvas.measureText(text).width);
      // this._textCanvas.height = 380; //getPowerOfTwo(2*textSize);

      this._textCanvas.textAlign = "center";
      this._textCanvas.textBaseline = "middle";
      this._textCanvas.fillStyle = "red";
      // this._textCanvas.clearRect(0, 0, this._textCanvas.canvas.width, this._textCanvas.canvas.height);
      this._textCanvas.fillText(text, 50, 50);
      return this._textCanvas.canvas;
    };

    // makeTextCanvas("HELLO WORLD THIS IS A TEST", 400, 400);
    // this._textCanvas.fillStyle = "white";
    // this._textCanvas.fillRect(0, 0, 300, 400);
    const gl = this.gl;
    this._canvasTexture = this.gl.createTexture();
    this._loadImage();

    // this.handleLoadedTexture(canvasTexture, document.getElementById('textCanvas'));

    // gl.bindTexture(gl.TEXTURE_2D, this._textureText);
    // gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, this._textCanvas.canvas.width, this._textCanvas.canvas.height, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, document.getElementById("textCanvas"));
    // // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_NEAREST);
    // gl.generateMipmap(gl.TEXTURE_2D);

    // gl.bindTexture(gl.TEXTURE_2D, null);

    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    // gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, this._textCanvas.canvas);
  }

  processVideoFrame(videoFrame: video.VideoFrame) {
    // console.log(this._canvasWidth, this._canvasHeight);
    // const bm = this.canvas.transferToImageBitmap();
    // this._bitmapPrerender.transferFromImageBitmap(bm)
    // this._stream.getVideoTracks()[0].requestFrame();
    // this._bitmapPrerender.canvas.drawImage(this._videoElement, 0, 0, this._bitmapPrerender.canvas.width, this._bitmapPrerender.canvas.height);
    const gl = this.gl;
    const width = videoFrame.width;
    const height = videoFrame.height;
    // console.log("WIDTH", width);
    // console.log("HEIGHT", height);
    this._setSize(width, height);
    gl.viewport(0, 0, width, height);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.enable(gl.DEPTH_TEST);
    gl.enable(gl.BLEND);
    // gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, 0, 1);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    gl.uniform1f(gl.uTime, new Date().getTime() / 1000 - this.time0);
    gl.uniform2f(gl.uResolution, width, height);

    const uOffset = width * height;

    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // gl.generateMipmap(gl.TEXTURE_2D);

    // gl.bindTexture(gl.TEXTURE_2D, null);
    gl.bindTexture(gl.TEXTURE_2D, this.textureY);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width,
      height,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      videoFrame.data.subarray(0, uOffset)
    );

    gl.bindTexture(gl.TEXTURE_2D, this.textureUV);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE_ALPHA,
      width >> 1,
      height >> 1,
      0,
      gl.LUMINANCE_ALPHA,
      gl.UNSIGNED_BYTE,
      videoFrame.data.subarray(uOffset, videoFrame.data.length)
    );

    gl.bindTexture(gl.TEXTURE_2D, this._textureText);
    // gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, this._textCanvas.canvas.width, this._textCanvas.canvas.height, 0, gl.RGBA, gl.UNSIGNED_BYTE, document.getElementById("c"));
    // gl.generateMipmap(gl.TEXTURE_2D);
    // // tom
    // // gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

    // // gl.bindTexture(gl.TEXTURE_2D, this.textureUV2);
    try {
        // console.log("TEXTCANVAS", this._textCanvas)
        // console.log("TEXTCANVAS 2", this._textCanvas.canvas)

      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        width,
        height,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        this._textCanvas.canvas
      );
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    } catch (err) {
      console.error(err);
    }

    // gl.bindTexture(gl.TEXTURE_2D, null);
    // tom

    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
    gl.readPixels(
      0,
      0,
      width,
      height,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this.effectPixelBuffer
    );

    // Get the YUV data from the effectPixelBuffer
    for (let i = 0; i < uOffset; i += 1) {
      videoFrame.data[i] = this.effectPixelBuffer[4 * i];
    }

    let widthIndex = 0;
    let curIndex = 0;
    for (let i = uOffset; i < videoFrame.data.length; i += 2) {
      videoFrame.data[i] = this.effectPixelBuffer[4 * curIndex + 1];
      videoFrame.data[i + 1] = this.effectPixelBuffer[4 * curIndex + 2];
      widthIndex += 2;
      curIndex += 2;
      if (widthIndex > videoFrame.width) {
        curIndex += videoFrame.width;
        widthIndex = widthIndex % videoFrame.width;
      }
    }
    
  }

  startTwglCanvas() {
    twgl.setDefaults({ attribPrefix: "a_" });
    const m4 = twgl.m4;

    const gl = this.gl;
    const programInfo = twgl.createProgramInfo(gl, ["vs", "fs"]);

    const bufferInfo = twgl.primitives.createPlaneBufferInfo(
      gl,
      1,
      1,
      1,
      1,
      m4.rotationX(Math.PI * 0.5)
    );

    function rand(min, max) {
      return min + Math.random() * (max - min);
    }

    this.camera = m4.identity();
    this.view = m4.identity();
    this.viewProjection = m4.identity();
    this.world = m4.identity();

    const ctx = document.createElement("canvas").getContext("2d");

    function makeText(text) {
      ctx.font = "20px monospace";
      const t = ctx.measureText(text);
      ctx.canvas.width = Math.ceil(t.width) + 2;
      ctx.canvas.height = 24;
      ctx.font = "20px monospace";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseAlign = "middle";
      ctx.fillText(
        text,
        (ctx.canvas.width / 2) | 0,
        (ctx.canvas.height / 2) | 0
      );
      return ctx.canvas;
    }

    const msgs = [
      "synergy",
      "alignment",
      "jill",
      "brian",
      "thomas",
      "chrissy",
      "jennifer",
      "alexander",
    ];

    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
    const textTextures = msgs.map(function (msg) {
      const canvas = makeText(msg);
      const scale = 0.1;
      return {
        tex: twgl.createTexture(gl, { src: canvas }),
        scale: [canvas.width * scale, canvas.height * scale, 1],
      };
    });

    this.objects = [];
    this.drawObjects = [];
    const numObjects = 500;
    const baseHue = rand(0, 360);
    for (let ii = 0; ii < numObjects; ++ii) {
      const texNdx = rand(0, textTextures.length) | 0;
      const text = textTextures[texNdx];
      const uniforms = {
        u_texture: text.tex,
        u_worldViewProjection: m4.identity(),
        u_color: chroma.hsv((baseHue + rand(0, 60)) % 360, 1, 1).gl(),
      };
      this.drawObjects.push({
        programInfo: programInfo,
        bufferInfo: bufferInfo,
        uniforms: uniforms,
      });
      this.objects.push({
        translation: [rand(-10, 10), rand(-10, 10), rand(-10, 10)],
        ySpeed: rand(0.1, 0.3),
        zSpeed: rand(0.1, 0.3),
        uniforms: uniforms,
        texInfo: text,
      });
    }
    const clearColor = [0.0, 0.0, 0.0, 0.0]; //chroma.hsv((baseHue + 30) % 360, 0.5, 0.5).gl();
  }
  processVideoFrame2(videoFrame) {
    const width = videoFrame.width;
    const height = videoFrame.height;
    this._setSize(width, height);
    const uOffset = width * height;

    const clearColor = [0.0, 0.0, 0.0, 0.0]; //chroma.hsv((baseHue + 30) % 360, 0.5, 0.5).gl();
    const { world, camera, view, viewProjection } = this;

    const gl = this.gl;
    const m4 = twgl.m4;
    const time = new Date().getTime() / 1000 - this.time0;

    twgl.resizeCanvasToDisplaySize(gl.canvas);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    gl.enable(gl.DEPTH_TEST);
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    gl.clearColor(clearColor[0], clearColor[1], clearColor[2], clearColor[3]);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    const projection = m4.perspective(
      (10 * Math.PI) / 180,
      gl.canvas.clientWidth / gl.canvas.clientHeight,
      0.5,
      100
    );
    const eye = [1, 4, -20];
    const target = [0, 0, 0];
    const up = [0, 1, 0];

    m4.lookAt(eye, target, up, this.camera);
    m4.inverse(this.camera, this.view);
    m4.multiply(projection, this.view, this.viewProjection);

    this.objects.forEach(function (obj) {
      const uni = obj.uniforms;
      const texInfo = obj.texInfo;
      m4.identity(world);
      m4.rotateY(world, time * obj.ySpeed, world);
      m4.rotateZ(world, time * obj.zSpeed, world);
      m4.translate(world, obj.translation, world);
      m4.rotateX(world, time, world);
      m4.scale(world, texInfo.scale, world);
      m4.multiply(view, world, world);

      m4.multiply(projection, world, uni.u_worldViewProjection);
    });

    twgl.drawObjectList(gl, this.drawObjects);
    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);

    gl.readPixels(
      0,
      0,
      width,
      height,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this.effectPixelBuffer
    );

    // Get the YUV data from the effectPixelBuffer
    for (let i = 0; i < uOffset; i += 1) {
      videoFrame.data[i] = this.effectPixelBuffer[4 * i];
    }

    let widthIndex = 0;
    let curIndex = 0;
    for (let i = uOffset; i < videoFrame.data.length; i += 2) {
      videoFrame.data[i] = this.effectPixelBuffer[4 * curIndex + 1];
      videoFrame.data[i + 1] = this.effectPixelBuffer[4 * curIndex + 2];
      widthIndex += 2;
      curIndex += 2;
      if (widthIndex > videoFrame.width) {
        curIndex += videoFrame.width;
        widthIndex = widthIndex % videoFrame.width;
      }
    }
  }

  _setSize(width, height) {
    if (this.canvasWidth !== width || this.canvasHeight !== height) {
      this.canvas.width = width;
      this.canvas.height = height;
      this.canvasWidth = width;
      this.canvasHeight = height;
      this.effectPixelBuffer = new Uint8Array(width * height * 4);
    }
  }

  _loadImage() {
    return new Promise((resolve, reject) => {
      var image = new Image();
      image.src =
        "https://collabtris.sparkworkspace.com/rightpoint_logo_1080_white.svg"; // MUST BE SAME DOMAIN!!!
      image.onload = () => {
        // console.log("IMAGE LOADED", image);
        // this._textCanvas.drawImage(image, 0, 0, 1920, 1080);
        // this._renderImage(image);
        resolve(image);
      };
    });
  }

  _renderImage(image) {
    const gl = this.gl;
    // look up where the texture coordinates need to go.
    var texCoordLocation = gl.getAttribLocation(
      this.program,
      "a_texturePosition"
    );

    // provide texture coordinates for the rectangle.
    var texCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([
        0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
      ]),
      gl.STATIC_DRAW
    );
    gl.enableVertexAttribArray(texCoordLocation);
    gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

    // Create a texture.
    var texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Set the parameters so we can render any size image.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    // Upload the image into the texture.
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
  }

  _initProgram(sourceName) {
    const gl = this.gl;
    const vertexShader = this._compileShader(
      getVertexShader(),
      gl.VERTEX_SHADER
    );
    const fragmentShader = this._compileShader(
      getFragShader(sourceName),
      gl.FRAGMENT_SHADER
    );

    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.useProgram(program);
    const success = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (!success) {
      console.error("program fail to link" + gl.getShaderInfoLog(program));
      return;
    }
    this.program = program;
  }

  _initVertexBuffers() {
    const vertices = new Float32Array([
      -1, -1, 0, 0.0, 0.0, 1, -1, 0, 1.0, 0.0, -1, 1, 0, 0.0, 1.0, 1, 1, 0, 1.0,
      1.0,
    ]);

    const gl = this.gl;
    const program = this.program;
    const verticeBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, verticeBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
    const positionLocation = gl.getAttribLocation(program, "a_vertexPosition");
    gl.vertexAttribPointer(positionLocation, 3, gl.FLOAT, false, 5 * 4, 0);
    gl.enableVertexAttribArray(positionLocation);

    const indices = new Int16Array([0, 1, 2, 2, 1, 3]);
    const indicesBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indicesBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, indices, gl.STATIC_DRAW);
    const texcoordLocation = gl.getAttribLocation(program, "a_texturePosition");
    gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 5 * 4, 3 * 4);
    gl.enableVertexAttribArray(texcoordLocation);
    gl.uTime = gl.getUniformLocation(program, "uTime");
    gl.uResolution = gl.getUniformLocation(program, "uResolution");
    gl.uMouse = gl.getUniformLocation(program, "uMouse");
    gl.uMouseDown = gl.getUniformLocation(program, "uMouseDown");
  }

  _createTexture() {
    const gl = this.gl;
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    return texture;
  }

  _createTexture2() {
    const gl = this.gl;
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    return texture;
  }

  _initTexture() {
    const gl = this.gl;
    const program = this.program;
    this.textureY = this._createTexture();
    gl.uniform1i(gl.getUniformLocation(program, "u_samplerY"), 0);

    gl.activeTexture(gl.TEXTURE1);
    this.textureUV = this._createTexture();
    gl.uniform1i(gl.getUniformLocation(program, "u_samplerUV"), 1);

    gl.activeTexture(gl.TEXTURE2);

    this._textureText = this._createTexture2();

    gl.bindTexture(gl.TEXTURE_2D, this._textureText);

    // Set the parameters so we can render any size image.
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    gl.uniform1i(gl.getUniformLocation(program, "u_samplerText"), 2);
  }

  _compileShader(shaderSource, shaderType) {
    const gl = this.gl;
    const shader = gl.createShader(shaderType);
    gl.shaderSource(shader, shaderSource);
    gl.compileShader(shader);
    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (!success) {
      const err = gl.getShaderInfoLog(shader);
      gl.deleteShader(shader);
      console.error("could not compile shader", err);
      return;
    }
    return shader;
  }
}

function getPowerOfTwo(value, pow) {
  var pow = pow || 1;
  while (pow < value) {
    pow *= 2;
  }
  return pow;
}

const vertexShaderSource1 = `
              uniform mat4 u_worldViewProjection;
              attribute vec4 a_vertexPosition;
              attribute vec2 a_texturePosition;
              varying vec2 v_texCoord;
  
              void main() {
                  // gl_Position = a_vertexPosition;
                  v_texCoord = a_texturePosition;
                  gl_Position = a_vertexPosition;
  
              }
          `;

const fragmentShaderSource1 = `
          #define SMOOTH(r,R) (1.0-smoothstep(R-1.0,R+1.0, r))
      #define RANGE(a,b,x) ( step(a,x)*(1.0-step(b,x)) )
      #define RS(a,b,x) ( smoothstep(a-1.0,a+1.0,x)*(1.0-smoothstep(b-1.0,b+1.0,x)) )
      #define M_PI 3.1415926535897932384626433832795
      
      #define blue1 vec3(0.74,0.95,1.00)
      #define blue2 vec3(0.87,0.98,1.00)
      #define blue3 vec3(0.35,0.76,0.83)
      #define blue4 vec3(0.953,0.969,0.89)
      #define red   vec3(1.00,0.38,0.227)
      
                  precision mediump float; 
                  varying vec2      v_texCoord; 
                  uniform sampler2D u_samplerY; 
                  uniform sampler2D u_samplerUV;
                  uniform sampler2D u_samplerText;
                  uniform vec4 u_color;
  
                  uniform float uTime;
                  uniform vec2 uResolution;
                  uniform vec2 uMouse;
                  uniform float uMouseDown;
      
                  vec3 yuv2r = vec3(1.164, 0.0, 1.596);
                  vec3 yuv2g = vec3(1.164, -0.391, -0.813);
                  vec3 yuv2b = vec3(1.164, 2.018, 0.0);
      
                  vec3 nv12_to_rgb(vec2 texCoord) {
                      vec3 yuv; 
                      yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
                      yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
                      yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
                      vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
                      return rgb; 
                  }
      
                  vec4 rgba_to_nv12(vec3 rgb) {
                      float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
                      float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
                      float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
                      return vec4(y, u, v, 1.0);
                  }
      
                  bool isgreen(in vec3 color) {
                    vec3 delta = abs(color.rgb - vec3(0.401, 0.560, 0.353).rgb);
                    return delta.r < 0.45 && delta.g < 0.1 && delta.b < 0.15;
                    // return 0.9 * color.g > color.r && 0.6 * color.g > color.b;
                }
      
                  float movingLine(vec2 uv, vec2 center, float radius)
      {
          //angle of the line
          float theta0 = 90.0 * uTime;
          vec2 d = uv - center;
          float r = sqrt( dot( d, d ) );
          if(r<radius)
          {
              //compute the distance to the line theta=theta0
              vec2 p = radius*vec2(cos(theta0*M_PI/180.0),
                                  -sin(theta0*M_PI/180.0));
              float l = length( d - p*clamp( dot(d,p)/dot(p,p), 0.0, 1.0) );
            d = normalize(d);
              //compute gradient based on angle difference to theta0
              float theta = mod(180.0*atan(d.y,d.x)/M_PI+theta0,360.0);
              float gradient = clamp(1.0-theta/90.0,0.0,1.0);
              return SMOOTH(l,1.0)+0.5*gradient;
          }
          else return 0.0;
      }
      
      float circle(vec2 uv, vec2 center, float radius, float width)
      {
          float r = length(uv - center);
          return SMOOTH(r-width/2.0,radius)-SMOOTH(r+width/2.0,radius);
      }
      
      float circle2(vec2 uv, vec2 center, float radius, float width, float opening)
      {
          vec2 d = uv - center;
          float r = sqrt( dot( d, d ) );
          d = normalize(d);
          if( abs(d.y) > opening )
            return SMOOTH(r-width/2.0,radius)-SMOOTH(r+width/2.0,radius);
          else
              return 0.0;
      }
      float circle3(vec2 uv, vec2 center, float radius, float width)
      {
          vec2 d = uv - center;
          float r = sqrt( dot( d, d ) );
          d = normalize(d);
          float theta = 180.0*(atan(d.y,d.x)/M_PI);
          return smoothstep(2.0, 2.1, abs(mod(theta+2.0,45.0)-2.0)) *
              mix( 0.5, 1.0, step(45.0, abs(mod(theta, 180.0)-90.0)) ) *
              (SMOOTH(r-width/2.0,radius)-SMOOTH(r+width/2.0,radius));
      }
      float triangles(vec2 uv, vec2 center, float radius)
      {
          vec2 d = uv - center;
          return RS(-8.0, 0.0, d.x-radius) * (1.0-smoothstep( 7.0+d.x-radius,9.0+d.x-radius, abs(d.y)))
               + RS( 0.0, 8.0, d.x+radius) * (1.0-smoothstep( 7.0-d.x-radius,9.0-d.x-radius, abs(d.y)))
               + RS(-8.0, 0.0, d.y-radius) * (1.0-smoothstep( 7.0+d.y-radius,9.0+d.y-radius, abs(d.x)))
               + RS( 0.0, 8.0, d.y+radius) * (1.0-smoothstep( 7.0-d.y-radius,9.0-d.y-radius, abs(d.x)));
      }
      
      float _cross(vec2 uv, vec2 center, float radius)
      {
          vec2 d = uv - center;
          int x = int(d.x);
          int y = int(d.y);
          float r = sqrt( dot( d, d ) );
          if( (r<radius) && ( (x==y) || (x==-y) ) )
              return 1.0;
          else return 0.0;
      }
      float dots(vec2 uv, vec2 center, float radius)
      {
          vec2 d = uv - center;
          float r = sqrt( dot( d, d ) );
          if( r <= 2.5 )
              return 1.0;
          if( ( r<= radius) && ( (abs(d.y+0.5)<=1.0) && ( mod(d.x+1.0, 50.0) < 2.0 ) ) )
              return 1.0;
          else if ( (abs(d.y+0.5)<=1.0) && ( r >= 50.0 ) && ( r < 115.0 ) )
              return 0.5;
          else
            return 0.0;
      }
      float bip1(vec2 uv, vec2 center)
      {
          return SMOOTH(length(uv - center),3.0);
      }
      float bip2(vec2 uv, vec2 center)
      {
          float r = length(uv - center);
          float R = 8.0+mod(87.0*uTime, 80.0);
          return (0.5-0.5*cos(30.0*uTime)) * SMOOTH(r,5.0)
              + SMOOTH(6.0,r)-SMOOTH(8.0,r)
              + smoothstep(max(8.0,R-20.0),R,r)-SMOOTH(R,r);
      }
      
      vec4 drawHUD(vec4 sColor){
        vec3 finalColor;
      
        vec2 uv = v_texCoord.xy;
          //center of the image
          vec2 c = uResolution.xy/2.0;
          finalColor = vec3( 0.3*_cross(uv, c, 240.0) );
      
        return vec4(finalColor, 1.0);
      }
      
                  vec4 star_plane(vec2 uv) {
                      uv = uv.xy + uv.yx*vec2(1,-1);
                      vec2 res = vec2(1,1);
                      vec2 pos = uv*res;
                      vec2 cell_center = floor(pos)+.5;
                      vec4 q = vec4(0.49, cell_center/res, 0.0);
                      q*=q;
                      float blue = q.x;
                      float f = 0.25;
                      return vec4(1.0-blue*f,1.0-blue*f,1.0-f*(1.0-blue),1)*clamp(q.t *8.0 * (0.25*q.z-distance(pos - cell_center, (q.xy-.5)*.5/q.z)),0.0,1.0);
                  }
      
                  mat2 scale(vec2 _scale){
                    return mat2(_scale.x,0.0,
                                0.0,_scale.y);
                }
      
                  float box(in vec2 _st, in vec2 _size){
                    _size = vec2(0.5) - _size*0.5;
                    vec2 uv = smoothstep(_size,
                                        _size+vec2(0.001),
                                        _st);
                    uv *= smoothstep(_size,
                                    _size+vec2(0.001),
                                    vec2(1.0)-_st);
                    return uv.x*uv.y;
                }
      
                float cross(in vec2 _st, float _size){
                  return  box(_st, vec2(_size,_size/4.)) +
                          box(_st, vec2(_size/4.,_size));
              }
              
      
                  vec4 stars(vec3 eye) {
                      eye /= max(abs(eye.x),max(abs(eye.y),abs(eye.z)));
                      vec3 eye_m = abs(eye);
                      vec2 pq;
                      if(eye_m.x > eye_m.y && eye_m.x > eye_m.z) {
                          pq = eye.yz;
                      } else if(eye_m.z > eye_m.x && eye_m.z > eye_m.y) {
                          pq = eye.xy;
                      } else if(eye_m.y > eye_m.x && eye_m.y > eye_m.z) {
                          pq = eye.zx;
                      }
                      vec2 st = fract(pq*16.0);
                      vec4 fragColor = star_plane(pq*0.2);
                      fragColor += star_plane(pq*0.1);
                      return fragColor;    
                  }
      
                  vec2 rotate(vec2 uv, float th) {
                      return mat2(cos(th), sin(th), -sin(th), cos(th)) * uv;
                    }
      
                  vec3 sdfSquare(vec2 uv, float size, vec2 offset) {
                      float x = uv.x - offset.x;
                      float y = uv.y - offset.y;
                      vec2 rotated = rotate(vec2(x,y), uTime * uMouseDown);
                      float d = max(abs(rotated.x), abs(rotated.y)) - size;
                      
                      return d > 0. ? vec3(1.) : vec3(1., 0., 0.);
                    }
      
                  void main() {
                      gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
                      // gray effect
                    //   float luminance = 0.299 * gl_FragColor.r + 0.587 * gl_FragColor.g + 0.114 * gl_FragColor.b;
                      // float y = smoothstep(0.1,luminance * uTime,luminance);
      
                      // gl_FragColor = vec4(luminance, luminance, luminance, 5);
      
                      // vec2 position = vec2(v_texCoord.x, v_texCoord.y);
                      // vec4 rect = vec4(0.2, 0.4, 0.4, 0.5);
                      // vec2 hv = step(rect.xy, position) * step(position, rect.zw);
                      // float onOff = hv.x * hv.y;
      
                      // gl_FragColor = mix(gl_FragColor, vec4(1,0,0,0), onOff);
                      // vec2 uv = v_texCoord/uResolution.xy; 
                      // uv -= 0.5; // <-0.5,0.5>
                      // uv.x *= uResolution.x/uResolution.y; // fix aspect ratio
                      vec2 offset = vec2(uMouse.x / uResolution.x, uMouse.y / uResolution.y);
      
                    //   vec3 col = sdfSquare(v_texCoord, 0.2, offset);
                    //   gl_FragColor = mix(gl_FragColor, vec4(col, 1), 0.2);
                      // vec2 st = v_texCoord;
                      // float pct = 0.0;
                  
                      // // a. The DISTANCE from the pixel to the center
                      // pct = distance(st,vec2(0.5));
      
                      // vec3 color = vec3(pct);
      
                      // gl_FragColor = mix(gl_FragColor, vec4( color, 1.0 ), 0.5);
      
                      // rgba to nv12
                      // gl_FragColor = rgba_to_nv12(gl_FragColor.rgb);
                      // float h = min(v_texCoord.y-0.1,0.)+0.8;
      
                      // float r = max(length(v_texCoord),min(.51,(1.0+sin(max(h*h*64.0,0.)))*.5));
      
                      // float x = v_texCoord.x;
                      // float y = v_texCoord.y;
                      // // float z = v_texCoord.z;
                      // float xTime = uTime * x/sin(uTime);
                      // float yTime = uTime * y/sin(uTime);
                      // // float zTime = uTime * z/sin(uTime);
                      // vec3 color = vec3(sin(uTime), 0.0, 0.0);
                      // gl_FragColor = vec4(color, 1.0);
                      // // vec4 sky = vec4(xTime,0.2,0.4,clamp(0.5-(r-.5)*1.0,0.0,1.0));
                      // // gl_FragColor = mix(gl_FragColor, vec4(0.0,0.0,0.0,1.0), 0.2);
                      
                      // // gl_FragColor = stars(gl_FragColor.rgb);
                      // // gl_FragColor = vec4(mix(gl_fragColor.rgb, gl_FragColor.rgb, 0.5), 1);
                      // vec3 color = nv12_to_rgb(v_texCoord);
                      // // Send final color to output, and add opacity
                      // gl_FragColor = vec4(color, 1.0);
      
                      // vec2 st = v_texCoord.xy;
                      // vec3 color = vec3(0.0);
                  
                      // To move the cross we move the space
                      // vec2 translate = vec2(cos(uTime),sin(uTime));
                      // st += translate*0.35;
                  
                      // st -= vec2(0.5);
                      // st = scale( vec2(sin(uTime * uMouseDown)+1.0) ) * st;
                      // st += vec2(0.5);
      
                      // Show the coordinates of the space on the background
                      // color = vec3(st.x,st.y,0.0);
                  
                      // Add the shape on the foreground
                      // color += vec3(cross(st,0.25));
                  
                      // gl_FragColor = mix(gl_FragColor, vec4(color,1.0), 0.5);
                      // vec2 c = uResolution/2.0;
                      // vec3 finalColor = movingLine(st, vec2(0.5,0.5), 240.0) * blue3;
                      // gl_FragColor = mix(gl_FragColor, vec4( finalColor, 1.0 ), 0.5);
      
                      // gl_FragColor = mix(gl_FragColor, drawHUD(gl_FragColor), 0.5);
                      // gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
                      //  gl_FragColor = vec4(luminance, luminance, luminance, 5);
                    //   vec2 flipped = vec2(1.0 - v_texCoord.s, v_texCoord.t);
                      vec2 flipped = vec2(v_texCoord.s, v_texCoord.t);

                      vec2 scaled = flipped * 1.0;
                       vec4 color1 = texture2D(u_samplerText, scaled);
                       
                       float distance = length(2.0 * gl_PointCoord - 1.0);
      
                    //    color1.a = color1.a * (1.0 - smoothstep(
                    //     vSize - 2.0,
                    //     vSize,
                    //     distance * vSize
                    // ));
                      //  gl_FragColor = mix(rgba_to_nv12(gl_FragColor.rgb), rgba_to_nv12(color1.rgb), 0.5);
                      //  gl_FragColor = rgba_to_nv12(gl_FragColor.rgb);
      
      
                       vec2 uv2 = v_texCoord/uResolution.xy; 
                       vec3 foreground = nv12_to_rgb(v_texCoord).rgb;
                       vec3 background = color1.rgb;
                      //  vec3 foreground = texture2d(rgba_to_nv12(gl_FragColor.rgb), uv2);
                      //  vec3 background = texture2d(rgba_to_nv12(color1.rgb), uv2).rgb;
                       vec3 color = background;
                    //    if (!isgreen(foreground)) {
                    //        color = foreground;
                    //    }
                        color = foreground;
                        vec2 pos = vec2(1.0 - v_texCoord.s, v_texCoord.t) * 1.5;
                        vec2 offset2 = vec2(uMouse.x / uResolution.x, uMouse.y / uResolution.y);
                        vec2 newPos = pos + offset2;
                        vec4 color2 = texture2D(u_samplerText, newPos);
                        // if (color2.a < 0.1) {
                        //   discard;
                        // }
                        gl_FragColor = rgba_to_nv12(color2.rgb);
      
  
  
                    //   // gl_FragColor =  rgba_to_nv12(gl_FragColor.rgb);

                    //   gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
                    //   float luminance = 0.299 * gl_FragColor.r + 0.587 * gl_FragColor.g + 0.114 * gl_FragColor.b;
                    //   gl_FragColor = vec4(luminance, luminance, luminance, 5);
                    //   gl_FragColor = rgba_to_nv12(gl_FragColor.rgb);

                      // gray effect
                    //   float luminance = 0.299 * gl_FragColor.r + 0.587 * gl_FragColor.g + 0.114 * gl_FragColor.b;
                    //   // float y = smoothstep(0.1,luminance * uTime,luminance);
      
                    //   gl_FragColor = vec4(luminance, luminance, luminance, 5);

                     vec2 st = v_texCoord.xy;
                    // color = vec3(0.0);
                  
                      // To move the cross we move the space
                      vec2 translate = vec2(cos(uTime),sin(uTime));
                      st += translate*0.35;
                  
                      st -= vec2(0.5);
                      st = scale( vec2(sin(uTime * uMouseDown)+1.0) ) * st;
                      st += vec2(0.5);
      
                      // Show the coordinates of the space on the background
                      // color = vec3(st.x,st.y,0.0);
                  
                      // Add the shape on the foreground
                      color += vec3(cross(st,0.25));
                  
                      gl_FragColor = mix(gl_FragColor, vec4(color,1.0), 0.5);
                      // vec2 c = uResolution/2.0;
                      // vec3 finalColor = movingLine(st, vec2(0.5,0.5), 240.0) * blue3;
                      // gl_FragColor = mix(gl_FragColor, vec4( finalColor, 1.0 ), 0.5);
                  }
              `;

const vertexShaderSource2 = `
              uniform mat4 u_worldViewProjection;
      
              attribute vec4 a_vertexPosition;
              attribute vec2 a_texturePosition;
              varying vec2 v_texCoord;
  
              void main() {
                  gl_Position = a_vertexPosition;
                  v_texCoord = a_texturePosition;
              }
              `;

const fragmentShaderSourceNone = `
              precision mediump float; 
              varying vec2      v_texCoord; 
              uniform sampler2D u_samplerY; 
              uniform sampler2D u_samplerUV; 
              vec3 yuv2r = vec3(1.164, 0.0, 1.596);
              vec3 yuv2g = vec3(1.164, -0.391, -0.813);
              vec3 yuv2b = vec3(1.164, 2.018, 0.0);
              vec3 nv12_to_rgb(vec2 texCoord) {
                  vec3 yuv; 
                  yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
                  yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
                  yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
                  vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
                  return rgb; 
              }
              vec4 rgba_to_nv12(vec3 rgb) {
                  float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
                  float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
                  float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
                  return vec4(y, u, v, 1.0);
              }
              void main() {
                  gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
           
                  // rgba to nv12
                  gl_FragColor = rgba_to_nv12(gl_FragColor.rgb);
              }
          `;

const fragmentShaderSourceSepia = `
              precision mediump float; 
              varying vec2      v_texCoord; 
              uniform sampler2D u_samplerY; 
              uniform sampler2D u_samplerUV; 

              uniform float uTime;
              uniform vec2 uResolution;
              uniform vec2 uMouse;
              uniform float uMouseDown;

              vec3 yuv2r = vec3(1.164, 0.0, 1.596);
              vec3 yuv2g = vec3(1.164, -0.391, -0.813);
              vec3 yuv2b = vec3(1.164, 2.018, 0.0);
              vec3 nv12_to_rgb(vec2 texCoord) {
                  vec3 yuv; 
                  yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
                  yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
                  yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
                  vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
                  return rgb; 
              }
              vec4 rgba_to_nv12(vec3 rgb) {
                  float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
                  float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
                  float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
                  return vec4(y, u, v, 1.0);
              }
              void main() {
                  gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
           
                  vec3 sepia = vec3(1.2, 1.0, 0.8);
                  vec2 xy = v_texCoord.xy / uResolution.xy;

                  xy.y = 1.0 - xy.y; // flip image y axis to match canvas
                  vec4 texColour = gl_FragColor; // get pixel from input img

                  float grey = dot(texColour.rgb, vec3(0.299, 0.587, 0.114));
    
                  vec3 sepiaColour = vec3(grey) * sepia;
                  texColour.rgb = mix(texColour.rgb, vec3(sepiaColour), 0.75);
                  gl_FragColor = texColour;
                  // rgba to nv12
                  gl_FragColor = rgba_to_nv12(gl_FragColor.rgb);
              }
          `;

const fragmentShaderSourceBlur = `
              precision mediump float; 
              varying vec2      v_texCoord; 
              uniform sampler2D u_samplerY; 
              uniform sampler2D u_samplerUV; 

              uniform float uTime;
              uniform vec2 uResolution;
              uniform vec2 uMouse;
              uniform float uMouseDown;

              vec3 yuv2r = vec3(1.164, 0.0, 1.596);
              vec3 yuv2g = vec3(1.164, -0.391, -0.813);
              vec3 yuv2b = vec3(1.164, 2.018, 0.0);
              vec3 nv12_to_rgb(vec2 texCoord) {
                  vec3 yuv; 
                  yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
                  yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
                  yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
                  vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
                  return rgb; 
              }
              vec4 rgba_to_nv12(vec3 rgb) {
                  float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
                  float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
                  float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
                  return vec4(y, u, v, 1.0);
              }
              void main() {
                  gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
           
                  float Pi = 6.28318530718; // Pi*2
    
                  // GAUSSIAN BLUR SETTINGS {{{
                  float Directions = 16.0; // BLUR DIRECTIONS (Default 16.0 - More is better but slower)
                  float Quality = 3.0; // BLUR QUALITY (Default 4.0 - More is better but slower)
                  float Size = 8.0; // BLUR SIZE (Radius)

                  vec2 Radius = Size/uResolution.xy;

                  // Normalized pixel coordinates (from 0 to 1)
                  vec2 uv = v_texCoord/uResolution.xy;
                  // Pixel colour
                  vec4 Color = gl_FragColor;

                  // Blur calculations
                //   for( float d=0.0; d<Pi; d+=Pi/Directions)
                //   {
                //       for(float i=1.0/Quality; i<=1.0; i+=1.0/Quality)
                //       {
                //           Color += texture2D( u_samplerUV, uv+vec2(cos(d),sin(d))*Radius*i);		
                //       }
                //   }
    
                  Color /= Quality * Directions - 15.0;
                  gl_FragColor = Color;
                //   // rgba to nv12
                gl_FragColor = rgba_to_nv12(gl_FragColor.rgb);
              }
          `;

const fragmentShaderSourceTom = `
              precision mediump float; 
              varying vec2      v_texCoord; 
              uniform sampler2D u_samplerY; 
              uniform sampler2D u_samplerUV; 
              uniform sampler2D u_samplerText;


              uniform float uTime;
              uniform vec2 uResolution;
              uniform vec2 uMouse;
              uniform float uMouseDown;

              vec3 yuv2r = vec3(1.164, 0.0, 1.596);
              vec3 yuv2g = vec3(1.164, -0.391, -0.813);
              vec3 yuv2b = vec3(1.164, 2.018, 0.0);
              vec3 nv12_to_rgb(vec2 texCoord) {
                  vec3 yuv; 
                  yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
                  yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
                  yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
                  vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
                  return rgb; 
              }
              vec4 rgba_to_nv12(vec3 rgb) {
                  float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
                  float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
                  float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
                  return vec4(y, u, v, 1.0);
              }

              mat2 scale(vec2 _scale){
                return mat2(_scale.x,0.0,
                            0.0,_scale.y);
            }

              float box(in vec2 _st, in vec2 _size){
                _size = vec2(0.5) - _size*0.5;
                vec2 uv = smoothstep(_size,
                                    _size+vec2(0.001),
                                    _st);
                uv *= smoothstep(_size,
                                _size+vec2(0.001),
                                vec2(1.0)-_st);
                return uv.x*uv.y;
            }
  
            float cross(in vec2 _st, float _size){
              return  box(_st, vec2(_size,_size/4.)) +
                      box(_st, vec2(_size/4.,_size));
          }
          bool isgreen(in vec3 color) {
            vec3 delta = abs(color.rgb - vec3(0.401, 0.560, 0.353).rgb);
            return delta.r < 0.45 && delta.g < 0.1 && delta.b < 0.15;
            // return 0.9 * color.g > color.r && 0.6 * color.g > color.b;
        }



              void main() {
                  gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
           
                  vec2 st = v_texCoord.xy;
                  vec3 color = vec3(0.0);
                
                    // To move the cross we move the space
                    vec2 translate = vec2(cos(uTime),sin(uTime));
                    st += translate*0.35;
                
                    st -= vec2(0.5);
                    st = scale( vec2(sin(uTime * uMouseDown)+1.0) ) * st;
                    st += vec2(0.5);
    
                    // Show the coordinates of the space on the background
                    // color = vec3(st.x,st.y,0.0);
                
                    // Add the shape on the foreground
                    // color += vec3(cross(st,0.25));
                    vec3 background = nv12_to_rgb(v_texCoord).rgb;
                    vec3 foreground = color.rgb;

                    // if (!isgreen(background)) {
                    //            color = foreground;
                    // }
                    // color = background;
                    // gl_FragColor = mix(rgba_to_nv12(gl_FragColor.rgb), vec4(color,1.0), 0.5);

                    color += background;
                    
                    vec2 pos = vec2(1.0 - v_texCoord.s, v_texCoord.t);
                        vec2 offset2 = vec2(uMouse.x / uResolution.x, uMouse.y / uResolution.y);
                        vec2 offset3 = vec2(0,0);
                        vec2 newPos = pos + offset3;
                        vec4 color2 = texture2D(u_samplerText, newPos);
                    if (color2.a > 0.01){
                    color = color2.rgb;
                    }
                    
                    
                    gl_FragColor = rgba_to_nv12(color.rgb);
                    // gl_FragColor += color2;
              }
          `;

const fragmentShaderSourceRightpoint = `
          precision mediump float; 
          varying vec2      v_texCoord; 
          uniform sampler2D u_samplerY; 
          uniform sampler2D u_samplerUV; 
          uniform sampler2D u_samplerText;


          uniform float uTime;
          uniform vec2 uResolution;
          uniform vec2 uMouse;
          uniform float uMouseDown;

          
          vec3 yuv2r = vec3(1.164, 0.0, 1.596);
          vec3 yuv2g = vec3(1.164, -0.391, -0.813);
          vec3 yuv2b = vec3(1.164, 2.018, 0.0);
          vec3 nv12_to_rgb(vec2 texCoord) {
              vec3 yuv; 
              yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
              yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
              yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
              vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
              return rgb; 
          }
          vec4 rgba_to_nv12(vec3 rgb) {
              float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
              float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
              float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
              return vec4(y, u, v, 1.0);
          }

          mat2 scale(vec2 _scale){
            return mat2(_scale.x,0.0,
                        0.0,_scale.y);
        }

          float box(in vec2 _st, in vec2 _size){
            _size = vec2(0.5) - _size*0.5;
            vec2 uv = smoothstep(_size,
                                _size+vec2(0.001),
                                _st);
            uv *= smoothstep(_size,
                            _size+vec2(0.001),
                            vec2(1.0)-_st);
            return uv.x*uv.y;
        }

        float cross(in vec2 _st, float _size){
          return  box(_st, vec2(_size,_size/4.)) +
                  box(_st, vec2(_size/4.,_size));
      }
      bool isgreen(in vec3 color) {
        vec3 delta = abs(color.rgb - vec3(0.401, 0.560, 0.353).rgb);
        return delta.r < 0.45 && delta.g < 0.1 && delta.b < 0.15;
        // return 0.9 * color.g > color.r && 0.6 * color.g > color.b;
    }



          void main() {
            
              gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
       
              vec2 st = v_texCoord.xy;
              vec3 color = vec3(0.0);
            
                // To move the cross we move the space
                vec2 translate = vec2(cos(uTime),sin(uTime));
                st += translate*0.35;
            
                st -= vec2(0.5);
                st = scale( vec2(sin(uTime * uMouseDown)+1.0) ) * st;
                st += vec2(0.5);

                // Show the coordinates of the space on the background
                // color = vec3(st.x,st.y,0.0);
            
                // Add the shape on the foreground
                // color += vec3(cross(st,0.25));
                vec3 background = nv12_to_rgb(v_texCoord).rgb;
                vec3 foreground = color.rgb;

                // if (!isgreen(background)) {
                //            color = foreground;
                // }
                // color = background;
                // gl_FragColor = mix(rgba_to_nv12(gl_FragColor.rgb), vec4(color,1.0), 0.5);

                color += background;
                
                vec2 pos = vec2(v_texCoord.s, v_texCoord.t);
                    vec2 offset2 = vec2(uMouse.x / uResolution.x, uMouse.y / uResolution.y);
                    vec2 offset3 = vec2(0,0);
                    vec2 newPos = pos + offset3;
                    vec4 color2 = texture2D(u_samplerText, newPos);
                if (color2.a > 0.5){
                color = color2.rgb;
                }
                
                
                gl_FragColor = rgba_to_nv12(color.rgb);
                // gl_FragColor += color2;
          }
      `;

      const fragmentShaderSourceCanvas = `
          precision mediump float; 
          varying vec2      v_texCoord; 
          uniform sampler2D u_samplerY; 
          uniform sampler2D u_samplerUV; 
          uniform sampler2D u_samplerText;


          uniform float uTime;
          uniform vec2 uResolution;
          uniform vec2 uMouse;
          uniform float uMouseDown;

          
          vec3 yuv2r = vec3(1.164, 0.0, 1.596);
          vec3 yuv2g = vec3(1.164, -0.391, -0.813);
          vec3 yuv2b = vec3(1.164, 2.018, 0.0);
          vec3 nv12_to_rgb(vec2 texCoord) {
              vec3 yuv; 
              yuv.x = texture2D(u_samplerY, texCoord).r - 0.0625;
              yuv.y = texture2D(u_samplerUV, texCoord).r - 0.5;
              yuv.z = texture2D(u_samplerUV, texCoord).a - 0.5;
              vec3 rgb = vec3(dot(yuv, yuv2r), dot(yuv, yuv2g), dot(yuv, yuv2b));
              return rgb; 
          }
          vec4 rgba_to_nv12(vec3 rgb) {
              float y = (0.257 * rgb.r) + (0.504 * rgb.g) + (0.098 * rgb.b) + 0.0625;
              float u = -(0.148 * rgb.r) - (0.291 * rgb.g) + (0.439 * rgb.b) + 0.5;
              float v = (0.439 * rgb.r) - (0.368 * rgb.g) - (0.071 * rgb.b) + 0.5;
              return vec4(y, u, v, 1.0);
          }

          mat2 scale(vec2 _scale){
            return mat2(_scale.x,0.0,
                        0.0,_scale.y);
        }

          float box(in vec2 _st, in vec2 _size){
            _size = vec2(0.5) - _size*0.5;
            vec2 uv = smoothstep(_size,
                                _size+vec2(0.001),
                                _st);
            uv *= smoothstep(_size,
                            _size+vec2(0.001),
                            vec2(1.0)-_st);
            return uv.x*uv.y;
        }

        float cross(in vec2 _st, float _size){
          return  box(_st, vec2(_size,_size/4.)) +
                  box(_st, vec2(_size/4.,_size));
      }
      bool isgreen(in vec3 color) {
        vec3 delta = abs(color.rgb - vec3(0.401, 0.560, 0.353).rgb);
        return delta.r < 0.45 && delta.g < 0.1 && delta.b < 0.15;
        // return 0.9 * color.g > color.r && 0.6 * color.g > color.b;
    }



          void main() {
            
              gl_FragColor = vec4(nv12_to_rgb(v_texCoord), 1); 
       
              vec2 st = v_texCoord.xy;
              vec3 color = vec3(0.0);
            
                // To move the cross we move the space
                vec2 translate = vec2(cos(uTime),sin(uTime));
                st += translate*0.35;
            
                st -= vec2(0.5);
                st = scale( vec2(sin(uTime * uMouseDown)+1.0) ) * st;
                st += vec2(0.5);

                // Show the coordinates of the space on the background
                // color = vec3(st.x,st.y,0.0);
            
                // Add the shape on the foreground
                // color += vec3(cross(st,0.25));
                vec3 background = nv12_to_rgb(v_texCoord).rgb;
                vec3 foreground = color.rgb;

                // if (!isgreen(background)) {
                //            color = foreground;
                // }
                // color = background;
                // gl_FragColor = mix(rgba_to_nv12(gl_FragColor.rgb), vec4(color,1.0), 0.5);

                color += background;
                
                vec2 pos = vec2(v_texCoord.s, v_texCoord.t);
                    vec2 offset2 = vec2(uMouse.x / uResolution.x, uMouse.y / uResolution.y);
                    vec2 offset3 = vec2(0,0);
                    vec2 newPos = pos + offset3;
                    vec4 color2 = texture2D(u_samplerText, newPos);
                if (color2.a > 0.5){
                color = color2.rgb;
                }
                
                
                gl_FragColor = rgba_to_nv12(color2.rgb);
                // gl_FragColor += color2;
          }
      `;
function getVertexShader() {
  return vertexShaderSource1;
}

function getFragShader(sourceName) {
  console.log("GETTING FRAG SHADER FOR", sourceName);
  if (sourceName === "none") {
    console.log("frag shader is none");
    return fragmentShaderSourceNone;
  }
  if (sourceName === "grayscale") {
    console.log("frag shader is grayscale");

    return fragmentShaderSource1;
  }

  if (sourceName === "sepia") {
    console.log("frag shader is sepia");
    return fragmentShaderSourceSepia;
  }

  if (sourceName === "blur") {
    console.log("frag shader is blur");
    return fragmentShaderSourceBlur;
  }

  if (sourceName === "tom") {
    console.log("frag shader is tom");
    return fragmentShaderSourceTom;
  }

  if (sourceName === "rightpoint") {
    console.log("frag shader is rightpoint");
    return fragmentShaderSourceRightpoint;
  }
  
  if (sourceName === "canvas") {
    console.log("frag shader is canvas");
    return fragmentShaderSourceCanvas;
  }
  else return fragmentShaderSourceSepia;
}
