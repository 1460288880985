import { LocalAudioStream } from "@azure/communication-calling";
import { SpeechConfig, SpeechSynthesisOutputFormat, SpeechSynthesizer } from "microsoft-cognitiveservices-speech-sdk";

export const speakTheText = async (inputText, cognitiveContext, audioContext: AudioContext, call) => {
    return new Promise<{ source: AudioBufferSourceNode }>((resolve, reject) => {
      try {
        const speechConfig = SpeechConfig.fromAuthorizationToken(cognitiveContext.token, cognitiveContext.region);
  
        speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
        speechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat.Webm24Khz16Bit24KbpsMonoOpus; //SpeechSynthesisOutputFormat.Webm16Khz16BitMonoOpus;
  
        var synthesizer = new SpeechSynthesizer(speechConfig, null);
  
        synthesizer.speakSsmlAsync(
          inputText,
          async (result) => {
            const audioData = result.audioData;
            console.log(`Audio data byte size: ${audioData.byteLength}.`);
  
            const decodedData = await audioContext?.decodeAudioData(audioData);
            const destNode = audioContext?.createMediaStreamDestination();
            const source = new AudioBufferSourceNode(audioContext, { buffer: decodedData });
  
            // if (visualizerRef && visualizerRef.current) {
            //   const visualizer = visualizerRef.current;
            //   visualizer.connectSound(source);
            // }
  
            source.connect(destNode);
            console.log("MEDIASTREAM", destNode.stream instanceof MediaStream);
            let track = destNode.stream.getAudioTracks()[0];
            const localAudioStream = new LocalAudioStream(destNode.stream);
            await call?.startAudio(localAudioStream);
            console.log("after start audio");
            synthesizer.close();
            resolve({ source: source });
          },
          (error) => {
            console.log(error);
            synthesizer.close();
            reject(error);
          }
        );
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };