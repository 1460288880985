import React from "react";
import { LiveShareClient } from "@microsoft/live-share";
import { LiveCanvas, InkingTool } from "@microsoft/live-share-canvas";
import { InsecureTokenProvider } from "@fluidframework/test-client-utils";
import { useEffect, useState, useRef, useCallback } from "react";
import { useLiveCanvas } from "./useLiveCanvas";
import "./livecanvas.css";

const containerSchema = {
  initialObjects: {
    liveCanvas: LiveCanvas,
  },
};

let connection;
const clientOptions = {
  connection,
  //   connection: {
  //     type: "local",
  //     tokenProvider: new InsecureTokenProvider("", { id: "123" }),
  //     endpoint: "http://localhost:7070",
  //   },
};

export const LiveCanvasPage = () => {
  const [liveCanvas, setliveCanvas] = useState(undefined);
  const divRef = useRef();
  const { inkingManager } = useLiveCanvas(liveCanvas, divRef.current);

  const setToPen = useCallback(() => {
    if (inkingManager) {
      inkingManager.tool = InkingTool.pen;
    }
  }, [inkingManager]);

  const setToLaserPointer = useCallback(() => {
    if (inkingManager) {
      inkingManager.tool = InkingTool.laserPointer;
    }
  }, [inkingManager]);

  const setToHighlighter = useCallback(() => {
    if (inkingManager) {
      inkingManager.tool = InkingTool.highlighter;
    }
  }, [inkingManager]);

  const setToEraser = useCallback(() => {
    if (inkingManager) {
      inkingManager.tool = InkingTool.pointEraser;
    }
  }, [inkingManager]);

  const setToBlackBrush = useCallback(() => {
    if (inkingManager) {
      inkingManager.penBrush.color = { r: 0, g: 0, b: 0 };
    }
  }, [inkingManager]);

  const setToBlueBrush = useCallback(() => {
    if (inkingManager) {
      inkingManager.penBrush.color = { r: 0, g: 0, b: 255, a: 1 };
    }
  }, [inkingManager]);

  const setToRedBrush = useCallback(() => {
    if (inkingManager) {
      inkingManager.penBrush.color = { r: 255, g: 0, b: 0 };
    }
  }, [inkingManager]);

  const clearCanvas = useCallback(() => {
    if (inkingManager) {
      inkingManager.clear();
    }
  }, [inkingManager]);

  // Define container callback (optional).
  // * This is only called once when the container is first created.
  const onFirstInitialize = (container) => {
    console.log("useSharedObjects: onFirstInitialize called");
    // Setup any initial state here
    console.log("FIRST");
  };

  const initialize = async () => {
    console.log("INIT LIVESHARE CLIENT WITH", clientOptions);
    const client = new LiveShareClient(clientOptions);
    console.log("AFTER NEW UP");
    const { container } = await client.joinContainer(containerSchema, onFirstInitialize);
    console.log("AFTER JOINCONTAINER CALL");
    setliveCanvas(container.initialObjects.liveCanvas);
    console.log("AFTER SET LIVECANV");
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <div id="inkingRoot">
        <div id="inkingHost" ref={divRef}></div>
      </div>
      <div>
        <button onClick={clearCanvas}>Clear</button>
        <button onClick={setToEraser}>Eraser</button>
        <button onClick={setToPen}>Pen</button>
        <button onClick={setToHighlighter}>Highlighter</button>
        <button onClick={setToLaserPointer}>Laser Pointer</button>
        <button onClick={setToBlueBrush}>Blue brush</button>
        <button onClick={setToBlackBrush}>Black brush</button>
        <button onClick={setToRedBrush}>Red brush</button>
      </div>
    </>
  );
};
