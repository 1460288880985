import { dialog, tasks } from "@microsoft/teams-js";
import React, { useState } from "react";


export function Compose() {
  let [text, setText] = useState("");

  return (
    <div>
      This is the Compose Page.
      <input
        type={"text"}
        onChange={(e) => {
          setText(e.target.value);
        }}
        value={text}
      />
      <div
        onClick={() => {
          let appId = "f0c4b31a-97b1-47a8-9f89-eee63db9ffc2"; // process.env["REACT_APP_CLIENT_ID"] as string;
          dialog.url.submit({
            text: text,
          });
        }}
        className={"btn btn-xs btn-primary"}
      >
        Submit Me!
      </div>
    </div>
  );
}
