import create, { StateCreator } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { string } from "@colyseus/schema/lib/encoding/encode";

export type ChatUIStore = {
  responses: Array<{ inputText: string; id: string; suggestions: Array<{ text: string }>, displayName: string }>;
  speakerBotEnabled: boolean;
  showTypingIndicator: boolean;
  promptText: string;
  autoQuery: boolean;
  usePromptChaining: boolean;
  latestSuggestion: string;

  api: {
    addResponse: (inputText: string, id?: string, displayName?: string) => string;
    addSuggestionsToResponse: (suggestions: Array<{ text: string; id: string }>, responseId: string) => void;
    toggleSpeakerBot: () => void;
    setShowTypingIndicator: (shouldShowIndicator: boolean) => void;
    setPromptText: (text: string) => void;
    setAutoQuery: (shouldAutoQuery: boolean) => void;
    setUsePromptChaining: (shouldUsePromptChaining: boolean) => void;
  };
};
const createChatUIStore: StateCreator<ChatUIStore, [], [], ChatUIStore> = (set, get) => ({
  responses: [],
  speakerBotEnabled: false,
  showTypingIndicator: false,
  promptText: "",
  autoQuery: true,
  usePromptChaining: false,
  latestSuggestion: "",

  api: {
    addResponse: (inputText: string, id?: string, displayName?: string) => {
      id = id ? id : uuidv4();
      set((state) => {
        return { responses: [...state.responses, { inputText: inputText, id: id as string, suggestions: [], displayName: displayName }] };
      });

      return id as string;
    },
    addSuggestionsToResponse: (suggestions, responseId) => {
      const topLevelResponseIndex = get().responses.findIndex((r) => r.id === responseId);
      set((state) => {
        let newResponses = [...state.responses];
        newResponses[topLevelResponseIndex].suggestions = suggestions;
        return { responses: newResponses };
      });

      set({ latestSuggestion: suggestions[0].text })
    },
    toggleSpeakerBot: () => {
      set((state) => {
        return { speakerBotEnabled: !state.speakerBotEnabled };
      });
    },
    setShowTypingIndicator: (shouldShowIndicator: boolean) => {
      set({ showTypingIndicator: shouldShowIndicator });
    },
    setPromptText: (text: string) => {
      set({ promptText: text });
    },
    setAutoQuery: (shouldAutoQuery) => {
      set({ autoQuery: shouldAutoQuery });
    },
    setUsePromptChaining: (shouldUsePromptChaining) => {
      set({ usePromptChaining: shouldUsePromptChaining });
    },
  },
});

export const useChatUIStore = create<ChatUIStore>()((...a) => ({
  ...createChatUIStore(...a),
}));
