import { meeting, pages } from "@microsoft/teams-js";
import { Client, Room } from "colyseus.js";
import React from "react";
import { TomsState } from "../../../state/TomsState";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { ExperienceDisplay } from "../ExperienceDisplay/ExperienceDisplay";
import { getAllExperiences } from "../experienceHelpers";
import { Megaphone } from "../Megaphone/Megaphone";

function ExperienceDisplay({ onSelectExperience, title, description }) {
  return (
    <div className={""}>
      <div className={"text-secondary"}>{title}</div>
      <div>{description}</div>
      <div>
        <button
          onClick={() => {
            onSelectExperience("packabunchas");
          }}
          className="btn btn-primary btn-xs">
          Select Experience
        </button>{" "}
      </div>
      <div className="divider"></div>
    </div>
  );
}
export function ChooseExperience({ room, client }: { room: Room; client: Client }) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  let experiences = getAllExperiences();
  function onSelectExperience(expName) {
    client
      .create(expName, { teamsContext: teamsCtx, fromSelectExperience: true })
      .then((newRoom) => {
        room.send("selectExperience", { experienceName: expName });
        setTimeout(() => {
          newRoom.leave();
        }, 3000);
      })
      .catch((err) => {
        room.send("selectExperience", { experienceName: expName });
      });
  }

  // if (teamsCtx?.page.subPageId) {
  //   // window.location.href = "https://rpo365.sharepoint.com/sites/home#/home";
  //   pages.navigateCrossDomain(
  //     "https://rpo365.sharepoint.com/_layouts/15/teamslogon.aspx?spfx=true&dest=https%3A%2F%2Frpo365.sharepoint.com%2Fsites%2Fhome"
  //   );
  // }

  return (
    <div className={"pl-4 pt-4"}>
      <div className={"text-3xl"}>Choose an Experience</div>

      <div className={"pt-2"}>
        {experiences.map((exp) => {
          return (
            <ExperienceDisplay
              onSelectExperience={() => onSelectExperience(exp.experienceName)}
              description={exp.experienceDescription}
              title={exp.friendlyName}
            />
          );
        })}
      </div>
    </div>
  );
}

export function ChooseExperience2({ room, client }: { room: Room; client: Client }) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  let experiences = getAllExperiences();
  // function onSelectExperience(expName) {
  //   client
  //     .create(expName, { teamsContext: teamsCtx, fromSelectExperience: true })
  //     .then((newRoom) => {
  //       room.send("selectExperience", { experienceName: expName });
  //       setTimeout(() => {
  //         newRoom.leave();
  //       }, 3000);
  //     })
  //     .catch((err) => {
  //       room.send("selectExperience", { experienceName: expName });
  //     });
  // }

  function onSelectExperience(exp) {
    if (exp.createRoomBefore) {
      client.create(exp.experienceName, { teamsContext: teamsCtx, ...exp.defaultOptions }).then((r) => {
        setTimeout(() => {
          r.leave().then(() => {
            meeting.shareAppContentToStage((err, result) => {
              if (err) {
                alert(JSON.stringify(err));
              }
            }, `${"https://collabtris.sparkworkspace.com" + "/"}${exp.experienceName}?direct=true`);
          });
        }, 3000);
      });
    } else {
      meeting.shareAppContentToStage((err, result) => {
        if (err) {
          alert(JSON.stringify(err));
        }
      }, `${"https://collabtris.sparkworkspace.com" + "/"}${exp.experienceName}?direct=true`);
    }
  }

  return (
    <div className={"pl-2 pt-2"}>
      <div className={"text-xl"}>Choose an Experience</div>

      <div className={"pt-2"}>
        {experiences.map((exp) => {
          return (
            <ExperienceDisplay
              onSelectExperience={() => onSelectExperience(exp)}
              description={exp.experienceDescription}
              title={exp.friendlyName}
            />
          );
        })}
      </div>
    </div>
  );
}
