import * as THREE from 'three';
import { Controls } from './Controls';
import { Cube } from './Cube';
import { Draggable } from './Draggable';
import { Easing } from './Easing';
import { RoundedBoxGeometry } from './RoundedBoxGeometry';
import { RoundedPlaneGeometry } from './RoundedPlaneGeometry';
import { Scores } from './Scores';
import { Scrambler } from './Scrambler';
import { States } from './States';
import { Timer } from './Timer';
import { Transition } from './Transition';
import { Storage } from './Storage';
import { World } from './World';

const animationEngine = (() => {

    let uniqueID = 0;

    class AnimationEngine {

        constructor() {

            this.ids = [];
            this.animations = {};
            this.update = this.update.bind(this);
            this.raf = 0;
            this.time = 0;

        }

        update() {

            const now = performance.now();
            const delta = now - this.time;
            this.time = now;

            let i = this.ids.length;

            this.raf = i ? requestAnimationFrame(this.update) : 0;

            while (i--)
                this.animations[this.ids[i]] && this.animations[this.ids[i]].update(delta);

        }

        add(animation) {

            animation.id = uniqueID++;

            this.ids.push(animation.id);
            this.animations[animation.id] = animation;

            if (this.raf !== 0) return;

            this.time = performance.now();
            this.raf = requestAnimationFrame(this.update);

        }

        remove(animation) {

            const index = this.ids.indexOf(animation.id);

            if (index < 0) return;

            this.ids.splice(index, 1);
            delete this.animations[animation.id];
            animation = null;

        }

    }

    return new AnimationEngine();

})();

class Animation {

    constructor(start) {

        if (start === true) this.start();

    }

    start() {

        animationEngine.add(this);

    }

    stop() {

        animationEngine.remove(this);

    }

    update(delta) { }

}











class Tween extends Animation {

    constructor(options) {

        super(false);

        this.duration = options.duration || 500;
        this.easing = options.easing || (t => t);
        this.onUpdate = options.onUpdate || (() => { });
        this.onComplete = options.onComplete || (() => { });

        this.delay = options.delay || false;
        this.yoyo = options.yoyo ? false : null;

        this.progress = 0;
        this.value = 0;
        this.delta = 0;

        this.getFromTo(options);

        if (this.delay) setTimeout(() => super.start(), this.delay);
        else super.start();

        this.onUpdate(this);

    }

    update(delta) {

        const old = this.value * 1;
        const direction = (this.yoyo === true) ? - 1 : 1;

        this.progress += (delta / this.duration) * direction;

        this.value = this.easing(this.progress);
        this.delta = this.value - old;

        if (this.values !== null) this.updateFromTo();

        if (this.yoyo !== null) this.updateYoyo();
        else if (this.progress <= 1) this.onUpdate(this);
        else {

            this.progress = 1;
            this.value = 1;
            this.onUpdate(this);
            this.onComplete(this);
            super.stop();

        }

    }

    updateYoyo() {

        if (this.progress > 1 || this.progress < 0) {

            this.value = this.progress = (this.progress > 1) ? 1 : 0;
            this.yoyo = !this.yoyo;

        }

        this.onUpdate(this);

    }

    updateFromTo() {

        this.values.forEach(key => {

            this.target[key] = this.from[key] + (this.to[key] - this.from[key]) * this.value;

        });

    }

    getFromTo(options) {

        if (!options.target || !options.to) {

            this.values = null;
            return;

        }

        this.target = options.target || null;
        this.from = options.from || {};
        this.to = options.to || null;
        this.values = [];

        if (Object.keys(this.from).length < 1)
            Object.keys(this.to).forEach(key => { this.from[key] = this.target[key]; });

        Object.keys(this.to).forEach(key => { this.values.push(key); });

    }

}

window.addEventListener('touchmove', () => { });
document.addEventListener('touchmove', event => { event.preventDefault(); }, { passive: false });










export function replaceRanges() {
    const RangeHTML = [

        '<div class="range">',
        '<div class="range__label"></div>',
        '<div class="range__track">',
        '<div class="range__track-line"></div>',
        '<div class="range__handle"><div></div></div>',
        '</div>',
        '<div class="range__list"></div>',
        '</div>',

    ].join('\n');
    document.querySelectorAll('.customRange').forEach(el => {
        const temp = document.createElement('div');
        temp.innerHTML = RangeHTML;

        const range = temp.querySelector('.range');
        const rangeLabel = range.querySelector('.range__label');
        const rangeList = range.querySelector('.range__list');

        range.setAttribute('name', el.getAttribute('name'));
        rangeLabel.innerHTML = el.getAttribute('title');

        if (el.hasAttribute('color')) {

            range.classList.add('range--type-color');
            range.classList.add('range--color-' + el.getAttribute('name'));

        }

        if (el.hasAttribute('list')) {

            el.getAttribute('list').split(',').forEach(listItemText => {

                const listItem = document.createElement('div');
                listItem.innerHTML = listItemText;
                rangeList.appendChild(listItem);

            });

        }

        el.parentNode.replaceChild(range, el);

    });
}

class Range {

    constructor(name, options) {

        options = Object.assign({
            range: [0, 1],
            value: 0,
            step: 0,
            onUpdate: () => { },
            onComplete: () => { },
        }, options || {});

        this.element = document.querySelector('.range[name="' + name + '"]');
        this.track = this.element.querySelector('.range__track');
        this.handle = this.element.querySelector('.range__handle');
        this.list = [].slice.call(this.element.querySelectorAll('.range__list div'));

        this.value = options.value;
        this.min = options.range[0];
        this.max = options.range[1];
        this.step = options.step;

        this.onUpdate = options.onUpdate;
        this.onComplete = options.onComplete;

        this.setValue(this.value);

        this.initDraggable();

    }

    setValue(value) {

        this.value = this.round(this.limitValue(value));
        this.setHandlePosition();

    }

    initDraggable() {

        let current;

        this.draggable = new Draggable(this.handle, { calcDelta: true });

        this.draggable.onDragStart = position => {

            current = this.positionFromValue(this.value);
            this.handle.style.left = current + 'px';

        };

        this.draggable.onDragMove = position => {

            current = this.limitPosition(current + position.delta.x);
            this.value = this.round(this.valueFromPosition(current));
            this.setHandlePosition();

            this.onUpdate(this.value);

        };

        this.draggable.onDragEnd = position => {

            this.onComplete(this.value);

        };

    }

    round(value) {

        if (this.step < 1) return value;

        return Math.round((value - this.min) / this.step) * this.step + this.min;

    }

    limitValue(value) {

        const max = Math.max(this.max, this.min);
        const min = Math.min(this.max, this.min);

        return Math.min(Math.max(value, min), max);

    }

    limitPosition(position) {

        return Math.min(Math.max(position, 0), this.track.offsetWidth);

    }

    percentsFromValue(value) {

        return (value - this.min) / (this.max - this.min);

    }

    valueFromPosition(position) {

        return this.min + (this.max - this.min) * (position / this.track.offsetWidth);

    }

    positionFromValue(value) {

        return this.percentsFromValue(value) * this.track.offsetWidth;

    }

    setHandlePosition() {

        this.handle.style.left = this.percentsFromValue(this.value) * 100 + '%';

    }

}

class Preferences {

    constructor(game) {

        this.game = game;

    }

    init() {

        this.ranges = {

            size: new Range('size', {
                value: this.game.cube.size,
                range: [2, 5],
                step: 1,
                onUpdate: value => {

                    this.game.cube.size = value;

                    this.game.preferences.ranges.scramble.list.forEach((item, i) => {

                        item.innerHTML = this.game.scrambler.scrambleLength[this.game.cube.size][i];

                    });

                },
                onComplete: () => this.game.storage.savePreferences(),
            }),

            flip: new Range('flip', {
                value: this.game.controls.flipConfig,
                range: [0, 2],
                step: 1,
                onUpdate: value => {

                    this.game.controls.flipConfig = value;

                },
                onComplete: () => this.game.storage.savePreferences(),
            }),

            scramble: new Range('scramble', {
                value: this.game.scrambler.dificulty,
                range: [0, 2],
                step: 1,
                onUpdate: value => {

                    this.game.scrambler.dificulty = value;

                },
                onComplete: () => this.game.storage.savePreferences()
            }),

            fov: new Range('fov', {
                value: this.game.world.fov,
                range: [2, 45],
                onUpdate: value => {

                    this.game.world.fov = value;
                    this.game.world.resize();

                },
                onComplete: () => this.game.storage.savePreferences()
            }),

            theme: new Range('theme', {
                value: { cube: 0, erno: 1, dust: 2, camo: 3, rain: 4 }[this.game.themes.theme],
                range: [0, 4],
                step: 1,
                onUpdate: value => {

                    const theme = ['cube', 'erno', 'dust', 'camo', 'rain'][value];
                    this.game.themes.setTheme(theme);

                },
                onComplete: () => this.game.storage.savePreferences()
            }),

            hue: new Range('hue', {
                value: 0,
                range: [0, 360],
                onUpdate: value => this.game.themeEditor.updateHSL(),
                onComplete: () => this.game.storage.savePreferences(),
            }),

            saturation: new Range('saturation', {
                value: 100,
                range: [0, 100],
                onUpdate: value => this.game.themeEditor.updateHSL(),
                onComplete: () => this.game.storage.savePreferences(),
            }),

            lightness: new Range('lightness', {
                value: 50,
                range: [0, 100],
                onUpdate: value => this.game.themeEditor.updateHSL(),
                onComplete: () => this.game.storage.savePreferences(),
            }),

        };

        this.ranges.scramble.list.forEach((item, i) => {

            item.innerHTML = this.game.scrambler.scrambleLength[this.game.cube.size][i];

        });

    }

}

class Confetti {

    constructor(game) {

        this.game = game;
        this.started = 0;

        this.options = {
            speed: { min: 0.0011, max: 0.0022 },
            revolution: { min: 0.01, max: 0.05 },
            size: { min: 0.1, max: 0.15 },
            colors: [0x41aac8, 0x82ca38, 0xffef48, 0xef3923, 0xff8c0a],
        };

        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = new THREE.MeshLambertMaterial({ side: THREE.DoubleSide });

        this.holders = [
            new ConfettiStage(this.game, this, 1, 20),
            new ConfettiStage(this.game, this, -1, 30),
        ];

    }

    start() {

        if (this.started > 0) return;

        this.holders.forEach(holder => {

            this.game.world.scene.add(holder.holder);
            holder.start();
            this.started++;

        });

    }

    stop() {

        if (this.started == 0) return;

        this.holders.forEach(holder => {

            holder.stop(() => {

                this.game.world.scene.remove(holder.holder);
                this.started--;

            });

        });

    }

    updateColors(colors) {

        this.holders.forEach(holder => {

            holder.options.colors.forEach((color, index) => {

                holder.options.colors[index] = colors[['D', 'F', 'R', 'B', 'L'][index]];

            });

        });

    }

}

class ConfettiStage extends Animation {

    constructor(game, parent, distance, count) {

        super(false);

        this.game = game;
        this.parent = parent;

        this.distanceFromCube = distance;

        this.count = count;
        this.particles = [];

        this.holder = new THREE.Object3D();
        this.holder.rotation.copy(this.game.world.camera.rotation);

        this.object = new THREE.Object3D();
        this.holder.add(this.object);

        this.resizeViewport = this.resizeViewport.bind(this);
        this.game.world.onResize.push(this.resizeViewport);
        this.resizeViewport();

        this.geometry = this.parent.geometry;
        this.material = this.parent.material;

        this.options = this.parent.options;

        let i = this.count;
        while (i--) this.particles.push(new Particle(this));

    }

    start() {

        this.time = performance.now();
        this.playing = true;

        let i = this.count;
        while (i--) this.particles[i].reset();

        super.start();

    }

    stop(callback) {

        this.playing = false;
        this.completed = 0;
        this.callback = callback;

    }

    reset() {

        super.stop();

        this.callback();

    }

    update() {

        const now = performance.now();
        const delta = now - this.time;
        this.time = now;

        let i = this.count;

        while (i--)
            if (!this.particles[i].completed) this.particles[i].update(delta);

        if (!this.playing && this.completed == this.count) this.reset();

    }

    resizeViewport() {

        const fovRad = this.game.world.camera.fov * THREE.Math.DEG2RAD;

        this.height = 2 * Math.tan(fovRad / 2) * (this.game.world.camera.position.length() - this.distanceFromCube);
        this.width = this.height * this.game.world.camera.aspect;

        const scale = 1 / this.game.transition.data.cameraZoom;

        this.width *= scale;
        this.height *= scale;

        this.object.position.z = this.distanceFromCube;
        this.object.position.y = this.height / 2;

    }

}

class Particle {

    constructor(confetti) {

        this.confetti = confetti;
        this.options = this.confetti.options;

        this.velocity = new THREE.Vector3();
        this.force = new THREE.Vector3();

        this.mesh = new THREE.Mesh(this.confetti.geometry, this.confetti.material.clone());
        this.confetti.object.add(this.mesh);

        this.size = THREE.Math.randFloat(this.options.size.min, this.options.size.max);
        this.mesh.scale.set(this.size, this.size, this.size);

        return this;

    }

    reset(randomHeight = true) {

        this.completed = false;

        this.color = new THREE.Color(this.options.colors[Math.floor(Math.random() * this.options.colors.length)]);
        this.mesh.material.color.set(this.color);

        this.speed = THREE.Math.randFloat(this.options.speed.min, this.options.speed.max) * - 1;
        this.mesh.position.x = THREE.Math.randFloat(- this.confetti.width / 2, this.confetti.width / 2);
        this.mesh.position.y = (randomHeight)
            ? THREE.Math.randFloat(this.size, this.confetti.height + this.size)
            : this.size;

        this.revolutionSpeed = THREE.Math.randFloat(this.options.revolution.min, this.options.revolution.max);
        this.revolutionAxis = ['x', 'y', 'z'][Math.floor(Math.random() * 3)];
        this.mesh.rotation.set(Math.random() * Math.PI / 3, Math.random() * Math.PI / 3, Math.random() * Math.PI / 3);

    }

    stop() {

        this.completed = true;
        this.confetti.completed++;

    }

    update(delta) {

        this.mesh.position.y += this.speed * delta;
        this.mesh.rotation[this.revolutionAxis] += this.revolutionSpeed;

        if (this.mesh.position.y < - this.confetti.height - this.size)
            (this.confetti.playing) ? this.reset(false) : this.stop();

    }

}


class Themes {

    constructor(game) {

        this.game = game;
        this.theme = null;

        this.defaults = {
            cube: {
                U: 0xfff7ff, // white
                D: 0xffef48, // yellow
                F: 0xef3923, // red
                R: 0x41aac8, // blue
                B: 0xff8c0a, // orange
                L: 0x82ca38, // green
                P: 0x08101a, // piece
                G: 0xd1d5db, // background
            },
            erno: {
                U: 0xffffff,
                D: 0xffd500,
                F: 0xc41e3a,
                R: 0x0051ba,
                B: 0xff5800,
                L: 0x009e60,
                P: 0x08101a,
                G: 0x8abdff,
            },
            dust: {
                U: 0xfff6eb,
                D: 0xe7c48d,
                F: 0x8f253e,
                R: 0x607e69,
                B: 0xbe6f62,
                L: 0x849f5d,
                P: 0x08101a,
                G: 0xE7C48D,
            },
            camo: {
                U: 0xfff6eb,
                D: 0xbfb672,
                F: 0x37241c,
                R: 0x718456,
                B: 0x805831,
                L: 0x37431d,
                P: 0x08101a,
                G: 0xBFB672,
            },
            rain: {
                U: 0xfafaff,
                D: 0xedb92d,
                F: 0xce2135,
                R: 0x449a89,
                B: 0xec582f,
                L: 0xa3a947,
                P: 0x08101a,
                G: 0x87b9ac,
            },
        };

        this.colors = JSON.parse(JSON.stringify(this.defaults));

    }

    getColors() {

        return this.colors[this.theme];

    }

    setTheme(theme = false, force = false) {

        if (theme === this.theme && force === false) return;
        if (theme !== false) this.theme = theme;

        const colors = this.getColors();

        this.game.dom.prefs.querySelectorAll('.range__handle div').forEach(range => {

            range.style.background = '#' + colors.R.toString(16).padStart(6, '0');

        });

        this.game.cube.updateColors(colors);

        this.game.confetti.updateColors(colors);

        this.game.dom.back.style.background = '#' + colors.G.toString(16).padStart(6, '0');

    }

}

class ThemeEditor {

    constructor(game) {

        this.game = game;

        this.editColor = 'R';

        this.getPieceColor = this.getPieceColor.bind(this);

    }

    colorFromHSL(h, s, l) {

        h = Math.round(h);
        s = Math.round(s);
        l = Math.round(l);

        return new THREE.Color(`hsl(${h}, ${s}%, ${l}%)`);

    }

    setHSL(color = null, animate = false) {

        this.editColor = (color === null) ? 'R' : color;

        const hsl = new THREE.Color(this.game.themes.getColors()[this.editColor]);

        const { h, s, l } = hsl.getHSL(hsl);
        const { hue, saturation, lightness } = this.game.preferences.ranges;

        if (animate) {

            const ho = hue.value / 360;
            const so = saturation.value / 100;
            const lo = lightness.value / 100;

            const colorOld = this.colorFromHSL(hue.value, saturation.value, lightness.value);

            if (this.tweenHSL) this.tweenHSL.stop();

            this.tweenHSL = new Tween({
                duration: 200,
                easing: Easing.Sine.Out(),
                onUpdate: tween => {

                    hue.setValue((ho + (h - ho) * tween.value) * 360);
                    saturation.setValue((so + (s - so) * tween.value) * 100);
                    lightness.setValue((lo + (l - lo) * tween.value) * 100);

                    const colorTween = colorOld.clone().lerp(hsl, tween.value);

                    const colorTweenStyle = colorTween.getStyle();
                    const colorTweenHex = colorTween.getHSL(colorTween);

                    hue.handle.style.color = colorTweenStyle;
                    saturation.handle.style.color = colorTweenStyle;
                    lightness.handle.style.color = colorTweenStyle;

                    saturation.track.style.color =
                        this.colorFromHSL(colorTweenHex.h * 360, 100, 50).getStyle();
                    lightness.track.style.color =
                        this.colorFromHSL(colorTweenHex.h * 360, colorTweenHex.s * 100, 50).getStyle();

                    this.game.dom.theme.style.display = 'none';
                    this.game.dom.theme.offsetHeight;
                    this.game.dom.theme.style.display = '';

                },
                onComplete: () => {

                    this.updateHSL();
                    this.game.storage.savePreferences();

                },
            });

        } else {

            hue.setValue(h * 360);
            saturation.setValue(s * 100);
            lightness.setValue(l * 100);

            this.updateHSL();
            this.game.storage.savePreferences();

        }

    }

    updateHSL() {

        const { hue, saturation, lightness } = this.game.preferences.ranges;

        const h = hue.value;
        const s = saturation.value;
        const l = lightness.value;

        const color = this.colorFromHSL(h, s, l).getStyle();

        hue.handle.style.color = color;
        saturation.handle.style.color = color;
        lightness.handle.style.color = color;

        saturation.track.style.color = this.colorFromHSL(h, 100, 50).getStyle();
        lightness.track.style.color = this.colorFromHSL(h, s, 50).getStyle();

        this.game.dom.theme.style.display = 'none';
        this.game.dom.theme.offsetHeight;
        this.game.dom.theme.style.display = '';

        const theme = this.game.themes.theme;

        this.game.themes.colors[theme][this.editColor] = this.colorFromHSL(h, s, l).getHex();
        this.game.themes.setTheme();

    }

    colorPicker(enable) {

        if (enable) {

            this.game.dom.game.addEventListener('click', this.getPieceColor, false);

        } else {

            this.game.dom.game.removeEventListener('click', this.getPieceColor, false);

        }

    }

    getPieceColor(event) {

        const clickEvent = event.touches
            ? (event.touches[0] || event.changedTouches[0])
            : event;

        const clickPosition = new THREE.Vector2(clickEvent.pageX, clickEvent.pageY);

        let edgeIntersect = this.game.controls.getIntersect(clickPosition, this.game.cube.edges, true);
        let pieceIntersect = this.game.controls.getIntersect(clickPosition, this.game.cube.cubes, true);

        if (edgeIntersect !== false) {

            const edge = edgeIntersect.object;

            const position = edge.parent
                .localToWorld(edge.position.clone())
                .sub(this.game.cube.object.position)
                .sub(this.game.cube.animator.position);

            const mainAxis = this.game.controls.getMainAxis(position);
            if (position.multiplyScalar(2).round()[mainAxis] < 1) edgeIntersect = false;

        }

        const name = edgeIntersect ? edgeIntersect.object.name : pieceIntersect ? 'P' : 'G';

        this.setHSL(name, true);

    }

    resetTheme() {

        this.game.themes.colors[this.game.themes.theme] =
            JSON.parse(JSON.stringify(this.game.themes.defaults[this.game.themes.theme]));

        this.game.themes.setTheme();

        this.setHSL(this.editColor, true);

    }

}



class IconsConverter {

    constructor(options) {

        options = Object.assign({
            tagName: 'icon',
            className: 'icon',
            styles: false,
            icons: {},
            observe: false,
            convert: false,
        }, options || {});

        this.tagName = options.tagName;
        this.className = options.className;
        this.icons = options.icons;

        this.svgTag = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this.svgTag.setAttribute('class', this.className);

        if (options.styles) this.addStyles();
        if (options.convert) this.convertAllIcons();

        if (options.observe) {

            const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
            this.observer = new MutationObserver(mutations => { this.convertAllIcons(); });
            this.observer.observe(document.documentElement, { childList: true, subtree: true });

        }

        return this;

    }

    convertAllIcons() {

        document.querySelectorAll(this.tagName).forEach(icon => { this.convertIcon(icon); });

    }

    convertIcon(icon) {

        const svgData = this.icons[icon.attributes[0].localName];

        if (typeof svgData === 'undefined') return;

        const svg = this.svgTag.cloneNode(true);
        const viewBox = svgData.viewbox.split(' ');

        svg.setAttributeNS(null, 'viewBox', svgData.viewbox);
        svg.style.width = viewBox[2] / viewBox[3] + 'em';
        svg.style.height = '1em';
        svg.innerHTML = svgData.content;

        icon.parentNode.replaceChild(svg, icon);

    }

    addStyles() {

        const style = document.createElement('style');
        style.innerHTML = `.${this.className} { display: inline-block; font-size: inherit; overflow: visible; vertical-align: -0.125em; preserveAspectRatio: none; }`;
        document.head.appendChild(style);

    }

}

const Icons = new IconsConverter({

    icons: {
        settings: {
            viewbox: '0 0 512 512',
            content: '<path fill="currentColor" d="M444.788 291.1l42.616 24.599c4.867 2.809 7.126 8.618 5.459 13.985-11.07 35.642-29.97 67.842-54.689 94.586a12.016 12.016 0 0 1-14.832 2.254l-42.584-24.595a191.577 191.577 0 0 1-60.759 35.13v49.182a12.01 12.01 0 0 1-9.377 11.718c-34.956 7.85-72.499 8.256-109.219.007-5.49-1.233-9.403-6.096-9.403-11.723v-49.184a191.555 191.555 0 0 1-60.759-35.13l-42.584 24.595a12.016 12.016 0 0 1-14.832-2.254c-24.718-26.744-43.619-58.944-54.689-94.586-1.667-5.366.592-11.175 5.459-13.985L67.212 291.1a193.48 193.48 0 0 1 0-70.199l-42.616-24.599c-4.867-2.809-7.126-8.618-5.459-13.985 11.07-35.642 29.97-67.842 54.689-94.586a12.016 12.016 0 0 1 14.832-2.254l42.584 24.595a191.577 191.577 0 0 1 60.759-35.13V25.759a12.01 12.01 0 0 1 9.377-11.718c34.956-7.85 72.499-8.256 109.219-.007 5.49 1.233 9.403 6.096 9.403 11.723v49.184a191.555 191.555 0 0 1 60.759 35.13l42.584-24.595a12.016 12.016 0 0 1 14.832 2.254c24.718 26.744 43.619 58.944 54.689 94.586 1.667 5.366-.592 11.175-5.459 13.985L444.788 220.9a193.485 193.485 0 0 1 0 70.2zM336 256c0-44.112-35.888-80-80-80s-80 35.888-80 80 35.888 80 80 80 80-35.888 80-80z" />',
        },
        back: {
            viewbox: '0 0 512 512',
            content: '<path transform="translate(512, 0) scale(-1,1)" fill="currentColor" d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z" />',
        },
        trophy: {
            viewbox: '0 0 576 512',
            content: '<path fill="currentColor" d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 66.5 77.9 131.7 171.9 142.4C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6C498.4 275.6 576 210.3 576 144V88c0-13.3-10.7-24-24-24zM64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-47.5-16.4-77-49.9-77-70.2zm448 0c0 20.2-29.4 53.8-77 70.2 7-25 11.8-53.6 12.8-86.2H512v16zm-127.3 4.7l-39.6 38.6 9.4 54.6c1.7 9.8-8.7 17.2-17.4 12.6l-49-25.8-49 25.8c-8.8 4.6-19.1-2.9-17.4-12.6l9.4-54.6-39.6-38.6c-7.1-6.9-3.2-19 6.7-20.5l54.8-8 24.5-49.6c4.4-8.9 17.1-8.9 21.5 0l24.5 49.6 54.8 8c9.6 1.5 13.5 13.6 6.4 20.5z" />',
        },
        cancel: {
            viewbox: '0 0 352 512',
            content: '<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />',
        },
        theme: {
            viewbox: '0 0 512 512',
            content: '<path fill="currentColor" d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/>',
        },
        reset: {
            viewbox: '0 0 512 512',
            content: '<path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z" />',
        },
        trash: {
            viewbox: '0 0 448 512',
            content: '<path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />',
        },
    },

    convert: true,

});

const STATE = {
    Menu: 0,
    Playing: 1,
    Complete: 2,
    Stats: 3,
    Prefs: 4,
    Theme: 5,
};

const BUTTONS = {
    Menu: ['stats', 'prefs'],
    Playing: ['back'],
    Complete: [],
    Stats: [],
    Prefs: ['back', 'theme'],
    Theme: ['back', 'reset'],
    None: [],
};

const SHOW = true;
const HIDE = false;

export class Game {

    scrambler: Scrambler;

    constructor() {

        this.dom = {
            ui: document.querySelector('.ui'),
            game: document.querySelector('.ui__game'),
            back: document.querySelector('.ui__background'),
            prefs: document.querySelector('.ui__prefs'),
            theme: document.querySelector('.ui__theme'),
            stats: document.querySelector('.ui__stats'),
            texts: {
                title: document.querySelector('.text--title'),
                note: document.querySelector('.text--note'),
                timer: document.querySelector('.text--timer'),
                complete: document.querySelector('.text--complete'),
                best: document.querySelector('.text--best-time'),
                theme: document.querySelector('.text--theme'),
            },
            buttons: {
                prefs: document.querySelector('.btn--prefs'),
                back: document.querySelector('.btn--back'),
                stats: document.querySelector('.btn--stats'),
                reset: document.querySelector('.btn--reset'),
                theme: document.querySelector('.btn--theme'),
            },
        };

        this.world = new World(this);
        this.cube = new Cube(this);
        this.controls = new Controls(this);
        this.scrambler = new Scrambler(this);
        this.transition = new Transition(this);
        this.timer = new Timer(this);
        this.preferences = new Preferences(this);
        this.scores = new Scores(this);
        this.storage = new Storage(this);
        this.confetti = new Confetti(this);
        this.themes = new Themes(this);
        this.themeEditor = new ThemeEditor(this);

        this.initActions();

        this.state = STATE.Menu;
        this.newGame = false;
        this.saved = false;

        this.storage.init();
        this.preferences.init();
        this.cube.init();
        this.transition.init();

        this.storage.loadGame();
        this.scores.calcStats();

        setTimeout(() => {

            this.transition.float();
            this.transition.cube(SHOW);

            setTimeout(() => this.transition.title(SHOW), 700);
            setTimeout(() => this.transition.buttons(BUTTONS.Menu, BUTTONS.None), 1000);

        }, 500);

    }

    initActions() {

        let tappedTwice = false;

        this.dom.game.addEventListener('click', event => {

            if (this.transition.activeTransitions > 0) return;
            if (this.state === STATE.Playing) return;

            if (this.state === STATE.Menu) {

                if (!tappedTwice) {

                    tappedTwice = true;
                    setTimeout(() => tappedTwice = false, 300);
                    return false;

                }

                this.game(SHOW);

            } else if (this.state === STATE.Complete) {

                this.complete(HIDE);

            } else if (this.state === STATE.Stats) {

                this.stats(HIDE);

            }

        }, false);

        this.controls.onMove = () => {

            if (this.newGame) {

                this.timer.start(true);
                this.newGame = false;

            }

        };

        this.dom.buttons.back.onclick = event => {

            if (this.transition.activeTransitions > 0) return;

            if (this.state === STATE.Playing) {

                this.game(HIDE);

            } else if (this.state === STATE.Prefs) {

                this.prefs(HIDE);

            } else if (this.state === STATE.Theme) {

                this.theme(HIDE);

            }

        };

        this.dom.buttons.reset.onclick = event => {

            if (this.state === STATE.Theme) {

                this.themeEditor.resetTheme();

            }

        };

        this.dom.buttons.prefs.onclick = event => this.prefs(SHOW);

        this.dom.buttons.theme.onclick = event => this.theme(SHOW);

        this.dom.buttons.stats.onclick = event => this.stats(SHOW);

        this.controls.onSolved = () => this.complete(SHOW);

    }

    game(show) {

        if (show) {

            if (!this.saved) {

                this.scrambler.scramble();
                this.controls.scrambleCube();
                this.newGame = true;

            }

            const duration = this.saved ? 0 :
                this.scrambler.converted.length * (this.controls.flipSpeeds[0] + 10);

            this.state = STATE.Playing;
            this.saved = true;

            this.transition.buttons(BUTTONS.None, BUTTONS.Menu);

            this.transition.zoom(STATE.Playing, duration);
            this.transition.title(HIDE);

            setTimeout(() => {

                this.transition.timer(SHOW);
                this.transition.buttons(BUTTONS.Playing, BUTTONS.None);

            }, this.transition.durations.zoom - 1000);

            setTimeout(() => {

                this.controls.enable();
                if (!this.newGame) this.timer.start(true);

            }, this.transition.durations.zoom);

        } else {

            this.state = STATE.Menu;

            this.transition.buttons(BUTTONS.Menu, BUTTONS.Playing);

            this.transition.zoom(STATE.Menu, 0);

            this.controls.disable();
            if (!this.newGame) this.timer.stop();
            this.transition.timer(HIDE);

            setTimeout(() => this.transition.title(SHOW), this.transition.durations.zoom - 1000);

            this.playing = false;
            this.controls.disable();

        }

    }

    prefs(show) {

        if (show) {

            if (this.transition.activeTransitions > 0) return;

            this.state = STATE.Prefs;

            this.transition.buttons(BUTTONS.Prefs, BUTTONS.Menu);

            this.transition.title(HIDE);
            this.transition.cube(HIDE);

            setTimeout(() => this.transition.preferences(SHOW), 1000);

        } else {

            this.cube.resize();

            this.state = STATE.Menu;

            this.transition.buttons(BUTTONS.Menu, BUTTONS.Prefs);

            this.transition.preferences(HIDE);

            setTimeout(() => this.transition.cube(SHOW), 500);
            setTimeout(() => this.transition.title(SHOW), 1200);

        }

    }

    theme(show) {

        this.themeEditor.colorPicker(show);

        if (show) {

            if (this.transition.activeTransitions > 0) return;

            this.cube.loadFromData(States['3']['checkerboard']);

            this.themeEditor.setHSL(null, false);

            this.state = STATE.Theme;

            this.transition.buttons(BUTTONS.Theme, BUTTONS.Prefs);

            this.transition.preferences(HIDE);

            setTimeout(() => this.transition.cube(SHOW, true), 500);
            setTimeout(() => this.transition.theming(SHOW), 1000);

        } else {

            this.state = STATE.Prefs;

            this.transition.buttons(BUTTONS.Prefs, BUTTONS.Theme);

            this.transition.cube(HIDE, true);
            this.transition.theming(HIDE);

            setTimeout(() => this.transition.preferences(SHOW), 1000);
            setTimeout(() => {

                const gameCubeData = JSON.parse(localStorage.getItem('theCube_savedState'));

                if (!gameCubeData) {

                    this.cube.resize(true);
                    return;

                }

                this.cube.loadFromData(gameCubeData);

            }, 1500);

        }

    }

    stats(show) {

        if (show) {

            if (this.transition.activeTransitions > 0) return;

            this.state = STATE.Stats;

            this.transition.buttons(BUTTONS.Stats, BUTTONS.Menu);

            this.transition.title(HIDE);
            this.transition.cube(HIDE);

            setTimeout(() => this.transition.stats(SHOW), 1000);

        } else {

            this.state = STATE.Menu;

            this.transition.buttons(BUTTONS.Menu, BUTTONS.None);

            this.transition.stats(HIDE);

            setTimeout(() => this.transition.cube(SHOW), 500);
            setTimeout(() => this.transition.title(SHOW), 1200);

        }

    }

    complete(show) {

        if (show) {

            this.transition.buttons(BUTTONS.Complete, BUTTONS.Playing);

            this.state = STATE.Complete;
            this.saved = false;

            this.controls.disable();
            this.timer.stop();
            this.storage.clearGame();

            this.bestTime = this.scores.addScore(this.timer.deltaTime);

            this.transition.zoom(STATE.Menu, 0);
            this.transition.elevate(SHOW);

            setTimeout(() => {

                this.transition.complete(SHOW, this.bestTime);
                this.confetti.start();

            }, 1000);

        } else {

            this.state = STATE.Stats;
            this.saved = false;

            this.transition.timer(HIDE);
            this.transition.complete(HIDE, this.bestTime);
            this.transition.cube(HIDE);
            this.timer.reset();

            setTimeout(() => {

                this.cube.reset();
                this.confetti.stop();

                this.transition.stats(SHOW);
                this.transition.elevate(0);

            }, 1000);

            return false;

        }

    }

}

