const composeEnhancers = function () {
  for (var _len = arguments.length, enhancers = new Array(_len), _key = 0; _key < _len; _key++) {
    enhancers[_key] = arguments[_key];
  }

  return function (uploader) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    return enhancers.reduce((enhanced, e) => e(enhanced, ...args) || enhanced, uploader);
  };
};

export default composeEnhancers;