import React, { useCallback, useEffect, useMemo, useState } from "react";
import Uploady, {
  useItemFinishListener,
  useItemProgressListener,
  UPLOADER_EVENTS,
  useUploady,
  useBatchAddListener,
  useBatchFinishListener,
} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";
import { UPLOAD_URL } from "../../Constants";
import { meeting } from "@microsoft/teams-js";
import { Howl, Howler } from "howler";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { Client, Room } from "colyseus.js";
import { TomsState } from "../../../state/TomsState";
import UploadPreview, { getUploadPreviewForBatchItemsMethod } from "@rpldy/upload-preview";
import { MatrixRain } from "../../components/MatrixRain/MatrixRain";
import ReactPlayer from "react-player";
import { SoundOnlyIcon, VideoIcon } from "./SoundboardIcons";
import { isVideoFile, getSoundName, getReactionTypeFromSoundUrl } from "./SoundboardHelpers";
import { AccordionMenu } from "./MenuAccordion";
import { ChooseExperience, ChooseExperience2 } from "../../components/ChooseExperience/ChooseExperience";
import { useSoundboardStore } from "../../components/ACS/useSoundboardStore";

let serverUrl = process.env.NODE_ENV === "production" ? UPLOAD_URL : "http://localhost:2567/upload";
let soundUrl =
  "https://sparkprovisioningstor.blob.core.windows.net/soundboard/tomhasenteredmeeting.mp4?sv=2019-12-12&st=2022-03-07T14:58:31Z&se=2049-08-08T13:58:00Z&sr=b&sp=r&sig=j6pf7iwij9gQ2rtuG2f7ZQiorIvtfNcsD7Mv6Ef5mgg=";

const playInNotificationWindowFunction = (soundData, room, playerName, teamsCtx) => {
  meeting.getMeetingDetails((err, meetingDetails) => {
    if (err) {
      console.error(err);
      return;
    }
    console.log("MEETING DETAILS", meetingDetails);

    const id = meetingDetails.conversation.id;

    const popupWidth = 800;
    const popupHeight = 600;
    const autoClose = true ? `&autoClose=true` : "";
    const urlPart =
      "https://" +
      window.location.hostname +
      "/soundboardstage.html" +
      "?soundName=" +
      soundData.name.split("/").pop().split(".")[0] +
      "&soundUrl=" +
      btoa(soundData.url) +
      "&from=" +
      teamsCtx.user.id +
      "&name=" +
      playerName +
      autoClose +
      "&inTeams=true" +
      "&ms=" +
      Date.now();

    const externalResourceUrl = `https://teams.microsoft.com/l/bubble/5b81432a-7abc-41de-8cdc-86b9be1ab866?url=${encodeURIComponent(
      urlPart
    )}&width=${popupWidth}&height=${popupHeight}`;

    room.send("notifyInMeeting", {
      url: soundData.url,
      from: teamsCtx.user.id,
      name: playerName,
      soundName: soundData.name.split("/").pop().split(".")[0],
      conversationId: id,
      text: `${playerName} played the ${
        soundData.name.split("/").pop().split(".")[0]
      } reaction! <b>Click here</b> to replay it!`,
      externalResourceUrl: externalResourceUrl,
      urlPart: urlPart,
    });
  });
};

const playInMeetingStageFunction = (soundData, room, playerName, teamsCtx) => {
  console.log("SHARING");
  if (!teamsCtx) {
    room.send("playSoundRequest", {
      url: soundData.url,
      from: "Unknown",
      name: playerName,
      soundName: getSoundName(soundData.name),
    });
    return;
  }
  meeting.getAppContentStageSharingState((err, appSharingState) => {
    console.log("APP SHARING STATE", appSharingState);
    if (appSharingState.isAppSharing) {
      room.send("playSoundRequest", {
        url: soundData.url,
        from: teamsCtx.user.id,
        name: playerName,
        soundName: getSoundName(soundData.name),
      });
    } else {
      meeting.shareAppContentToStage((err, res) => {
        setTimeout(() => {
          if (isVideoFile(soundData.url)) return;

          var sound = new Howl({
            src: [soundData.url],
            html5: true,
            onend: () => {
              console.log("HOWLER ON END");
            },
          });

          sound.play();
        }, 500);
      }, `${"https://collabtris.sparkworkspace.com" + "/"}${"soundboardstage"}?direct=true&soundUrl=${encodeURIComponent(btoa(soundData.url))}&from=${teamsCtx.user.id}&name=${playerName}&soundName=${getSoundName(soundData.name)}"&inTeams=true"`);
    }
  });
};

export const VideoOrSoundOnlyIconDisplay = ({ soundData }) => {
  return (
    <div className={"flex"}>
      <div className={"text-secondary pr-1"}>{soundData.metadata?.reactionName || getSoundName(soundData.url)}</div>
      <div className={"text-xs"}>{soundData.metadata?.duration} </div>
      <div className={"pt-2"}>
        {" "}
        {getReactionTypeFromSoundUrl(soundData.url) === "video" ? <VideoIcon /> : <SoundOnlyIcon />}
      </div>
    </div>
  );
};

export function SVGPreviewButton() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16px"
      height="16px"
      stroke="black"
      fill="hsl(var(--pf))"
      viewBox="0 0 32 32">
      <g>
        <path
          d="M32,5.074v21.854c0,0.189-0.107,0.365-0.279,0.449c-0.068,0.033-0.146,0.051-0.221,0.051c-0.107,0-0.216-0.033-0.307-0.104
l-7.939-6.121v3.129c0,0.277-0.225,0.5-0.5,0.5H0.5c-0.275,0-0.5-0.223-0.5-0.5V7.667c0-0.275,0.225-0.5,0.5-0.5h22.254
c0.275,0,0.5,0.225,0.5,0.5v3.899l7.918-6.872c0.146-0.128,0.354-0.158,0.537-0.078C31.885,4.7,32,4.877,32,5.074z"
        />
      </g>
    </svg>
  );
}

export function SVGStopButton() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 65.518 65.518"
      width="16px"
      height="16px"
      fill="hsl(var(--pf))">
      <g>
        <path
          d="M32.759,0C14.696,0,0,14.695,0,32.759s14.695,32.759,32.759,32.759s32.759-14.695,32.759-32.759S50.822,0,32.759,0z
      M6,32.759C6,18.004,18.004,6,32.759,6c6.648,0,12.734,2.443,17.419,6.472L12.472,50.178C8.443,45.493,6,39.407,6,32.759z
      M32.759,59.518c-5.948,0-11.447-1.953-15.895-5.248l37.405-37.405c3.295,4.448,5.248,9.947,5.248,15.895
     C59.518,47.514,47.514,59.518,32.759,59.518z"
        />
      </g>
    </svg>
  );
}

export function SVGPlayButton() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 485 485"
      width="16px"
      height="16px"
      fill="hsl(var(--pf))">
      <g>
        <path
          d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
   s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
   C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
   S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
        />
        <polygon points="181.062,336.575 343.938,242.5 181.062,148.425 	" />
      </g>
    </svg>
  );
}

export function SVGStar() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 526.673 526.673"
      width="16px"
      height="16px"
      fill="hsl(var(--pf))">
      <g>
        <g>
          <path
            d="M526.673,204.221l-195.529-7.76L263.337,12.885l-67.798,183.577L0,204.221l153.635,121.202l-53.048,188.365
			l162.75-108.664l162.75,108.664l-53.048-188.365L526.673,204.221z M392.683,467.808l-129.346-86.356L133.99,467.808
			l42.163-149.692L54.058,221.779l155.404-6.163l53.875-145.885l53.885,145.885l155.394,6.163l-122.096,96.337L392.683,467.808z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export function SVGStar2() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 511.987 511.987"
      width="16px"
      height="16px"
      fill="hsl(var(--pf))">
      <g>
        <g>
          <path
            d="M510.991,185.097c-2.475-7.893-9.301-13.632-17.515-14.72l-148.843-19.84L275.087,11.316
			c-7.211-14.464-30.976-14.464-38.187,0l-69.547,139.221l-148.843,19.84c-8.213,1.088-15.04,6.827-17.515,14.72
			c-2.496,7.872-0.213,16.512,5.867,22.101l107.392,98.923L85.604,486.857c-1.365,8.469,2.517,16.96,9.835,21.483
			c7.339,4.501,16.661,4.203,23.616-0.811l136.939-97.792l136.939,97.792c3.691,2.667,8.043,3.989,12.395,3.989
			c3.883,0,7.765-1.067,11.221-3.179c7.317-4.523,11.2-13.013,9.835-21.483l-28.651-180.736l107.392-98.923
			C511.204,201.609,513.487,192.969,510.991,185.097z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export function PlayInMeetingButton({ soundData, room, playerName, teamsCtx }) {
  return (
    <div
      onClick={() => {
        playInNotificationWindowFunction(soundData, room, playerName, teamsCtx);
      }}>
      <div className={"cursor-pointer tooltip"} data-tip={"Play in Meeting!"}>
        <SVGPlayButton />
      </div>
    </div>
  );
}

export function StopAllMeetingReactionsButton({ soundData, room, playerName, teamsCtx }) {
  return (
    <div
      onClick={() => {
        console.log("Sending closePopup request");
        room.send("closePopupRequest", {});
      }}
      className={"cursor-pointer tooltip"}
      data-tip={"Stop"}>
      <SVGStopButton />
    </div>
  );
}
export function SoundboardPreviewButton({ soundUrl }: { soundUrl: string }) {
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const [theSound, setSound] = useState<any>();
  const volumeLevel = useSoundboardStore((state) => state.volumeLevel);

  return (
    <div
      onClick={() => {
        var sound = new Howl({
          src: [soundUrl],
          html5: true,
          onend: () => {
            console.log("HOWLER ON END");
            setIsPreviewPlaying(false);
          },
        });

        setSound(sound);

        if (isPreviewPlaying) {
          theSound.stop();
          setIsPreviewPlaying(false);
        } else {
          setIsPreviewPlaying(true);
          Howler.volume(volumeLevel);
          sound.play();
        }
      }}
      className="tooltip cursor-pointer"
      data-tip={isPreviewPlaying ? "Stop Preview" : "Preview"}>
      {/* <SVGStopButton /> */}
      {isPreviewPlaying ? <SVGStopButton /> : <SVGPreviewButton />}
    </div>
  );
}

export function ItemWatcher({
  room,
  notificationMethod,
}: {
  room: Room<TomsState>;
  notificationMethod: NotificationMethod;
}) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  const playerName = useGlobalStore2((state) => state.playerName);

  let [queryComplete, setQueryComplete] = useState(false);
  let [soundboardData, setSoundboardData] = useState([]);
  let [noCustomItemsYet, setNoCustomItemsYet] = useState(false);

  let userId = "NONE";
  if (teamsCtx && teamsCtx.user && teamsCtx.user.id) {
    userId = teamsCtx.user.id;
  }

  const getSoundboardData = () => {
    room.send("getSoundboardForUser", { id: userId });
  };

  useEffect(() => {
    let listener = room.onMessage("gotSoundBoard", (message) => {
      console.log(message);
      if (message.data.length > 0) {
        setSoundboardData(message.data);
      } else {
        setNoCustomItemsYet(true);
      }
      setQueryComplete(true);
    });
    room.send("getSoundboardForUser", { id: userId });

    return () => {
      listener();
    };
  }, []);

  return (
    <div>
      {queryComplete && noCustomItemsYet && <div>Use this section to upload your own custom reactions.</div>}
      {queryComplete &&
        !noCustomItemsYet &&
        soundboardData.length > 0 &&
        soundboardData.map((soundData) => {
          return (
            <div className={"pb-2"}>
              <VideoOrSoundOnlyIconDisplay soundData={soundData} />

              <div className={"flex items-end justify-end"}>
                <div className={"px-1"}>
                  <PlayInMeetingButton soundData={soundData} room={room} playerName={playerName} teamsCtx={teamsCtx} />
                </div>
                <div className={"px-1"}>
                  <StopAllMeetingReactionsButton
                    soundData={soundData}
                    room={room}
                    playerName={playerName}
                    teamsCtx={teamsCtx}
                  />
                </div>
                <div className={"px-1"}>
                  <SoundboardPreviewButton soundUrl={soundData.url} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function SharedSoundBoardItems({
  room,
  notificationMethod,
}: {
  room: Room<TomsState>;
  notificationMethod: NotificationMethod;
}) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  const playerName = useGlobalStore2((state) => state.playerName);

  let [queryComplete, setQueryComplete] = useState(false);
  let [soundboardData, setSoundboardData] = useState([]);

  useEffect(() => {
    let listener = room.onMessage("gotSharedSoundBoard", (message) => {
      console.log(message);
      if (message.data.length > 0) {
        setSoundboardData(message.data);
      }
      setQueryComplete(true);
    });
    room.send("getSharedSoundboardBlobs", {});
    return () => {
      listener();
    };
  }, []);

  return (
    <div>
      {/* <div
        className={"btn btn-secondary"}
        onClick={() => {
          room.send("killAllSoundsRequest");
        }}>
        Stop All
      </div> */}
      {queryComplete &&
        soundboardData.length > 0 &&
        soundboardData.map((soundData) => {
          return (
            <div className={"pb-2"}>
              <VideoOrSoundOnlyIconDisplay soundData={soundData} />
              <div className={"flex items-end justify-end"}>
                <div className={"px-1"}>
                  <PlayInMeetingButton soundData={soundData} room={room} playerName={playerName} teamsCtx={teamsCtx} />
                </div>
                <div className={"px-1"}>
                  <StopAllMeetingReactionsButton
                    soundData={soundData}
                    room={room}
                    playerName={playerName}
                    teamsCtx={teamsCtx}
                  />
                </div>
                <div className={"px-1"}>
                  <SoundboardPreviewButton soundUrl={soundData.url} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function MyReactionsSoundboardItems({
  room,
  notificationMethod,
}: {
  room: Room<TomsState>;
  notificationMethod: NotificationMethod;
}) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  const playerName = useGlobalStore2((state) => state.playerName);

  let [queryComplete, setQueryComplete] = useState(false);
  let [soundboardData, setSoundboardData] = useState([]);
  const [noReactionsYet, setNoReactionsYet] = useState(false);

  let userId = "NONE";
  if (teamsCtx && teamsCtx.user && teamsCtx.user.id) {
    userId = teamsCtx.user.id;
  }

  useEffect(() => {
    let listener = room.onMessage("gotMyReactions", (message) => {
      console.log(message);
      if (message.data.length > 0) {
        let mappedData = message.data.map((d) => {
          let soundName = getSoundName(d.url);
          return {
            name: soundName,
            url: d.url,
          };
        });
        setSoundboardData(mappedData);
      } else {
        setNoReactionsYet(true);
      }
      setQueryComplete(true);
    });
    room.send("getMyReactionsRequest", { id: userId });

    return () => {
      listener();
    };
  }, []);

  return (
    <div>
      {/* <div
        className={"btn btn-secondary"}
        onClick={() => {
          room.send("killAllSoundsRequest");
        }}>
        Stop All
      </div> */}
      {noReactionsYet && (
        <div>You don't have any Reactions yet, you can get them by adding them when you see one in a meeting.</div>
      )}
      {queryComplete &&
        !noReactionsYet &&
        soundboardData.length > 0 &&
        soundboardData.map((soundData) => {
          return (
            <div className={"pb-2"}>
              <VideoOrSoundOnlyIconDisplay soundData={soundData} />
              <div
                className="btn btn-primary btn-xs pr-1"
                onClick={() => {
                  if (notificationMethod === "meetingStage") {
                    playInMeetingStageFunction(soundData, room, playerName, teamsCtx);
                    return;
                  }

                  if (notificationMethod === "meetingNotification") {
                    playInNotificationWindowFunction(soundData, room, playerName, teamsCtx);
                    return;
                  }
                }}>
                Play in Meeting
              </div>

              <div
                className="btn btn-primary btn-xs"
                onClick={() => {
                  Howler.volume(1.0);

                  var sound = new Howl({
                    src: [soundData.url],
                    html5: true,
                    onend: () => {
                      console.log("HOWLER ON END");
                    },
                  });

                  sound.play();
                }}>
                Preview
              </div>
              <div>
                {isVideoFile(soundData.url) && (
                  <ReactPlayer
                    width={"240px"}
                    height={"130px"}
                    url={soundData.url}
                    // muted={!soundIsOn}
                    playing={false}
                    controls={true}
                    onSeek={(seconds) => {}}
                    onPlay={() => {}}
                    onPause={() => {}}
                    onEnded={() => {}}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function TabbedSection({
  room,
  notificationMethod,
}: {
  room: Room<TomsState>;
  notificationMethod: NotificationMethod;
}) {
  let [activeTabName, setActiveTabName] = useState("reactions");

  const getTabClass = (tabName) => {
    if (tabName === activeTabName) return "tab tab-xs tab-active";
    else return "tab tab-xs";
  };
  return (
    <div className={"pt-4"}>
      <div className="tabs tabs-boxed">
        <a
          onClick={() => {
            setActiveTabName("reactions");
          }}
          className={getTabClass("reactions")}>
          Reactions
        </a>
        <a
          onClick={() => {
            setActiveTabName("myReactions");
          }}
          className={getTabClass("myReactions")}>
          My Reactions
        </a>
        <a
          onClick={() => {
            setActiveTabName("custom");
          }}
          className={getTabClass("custom")}>
          Custom
        </a>
      </div>
      {activeTabName === "reactions" && (
        <div>
          <SharedSoundBoardItems room={room} notificationMethod={notificationMethod} />
        </div>
      )}
      {activeTabName === "myReactions" && (
        <div>
          <MyReactionsSoundboardItems room={room} notificationMethod={notificationMethod} />
        </div>
      )}
      {activeTabName === "custom" && (
        <div>
          <UploadyWrapper>
            <UploadReactionForm />
          </UploadyWrapper>

          <ItemWatcher room={room} notificationMethod={notificationMethod} />
        </div>
      )}
    </div>
  );
}

const MyUploadPreview = getUploadPreviewForBatchItemsMethod(useBatchAddListener);

export function UploadyWrapper({ children }) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  const [uploadError, setUploadError] = useState("");

  let userId = "NONE";
  if (teamsCtx && teamsCtx.user && teamsCtx.user.id) {
    userId = teamsCtx.user.id;
  }
  const filterBySize = useCallback((file) => {
    //filter out files larger than 5MB
    if (file.name.endsWith(".mp3") || file.name.endsWith(".mp4")) {
    } else {
      setUploadError("Only mp3 and mp4 files are allowed");
      return false;
    }
    if (file.size > 5242880) {
      setUploadError("Files must be less than 5MB");
      return false;
    }
    return true;
  }, []);

  return (
    <Uploady
      destination={{ url: serverUrl, headers: { "X-UPLOAD-USER": userId } }}
      fileFilter={filterBySize}
      autoUpload={false}>
      <div>
        {uploadError ? (
          <div className={"text-danger"}>
            <div>{uploadError}</div>
            <div
              className={"btn btn-primary btn-xs"}
              onClick={() => {
                setUploadError("");
              }}>
              Try Again
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </Uploady>
  );
}
export function UploadReactionForm() {
  const [uploadAvailable, setUploadAvailable] = useState(false);
  const uploady = useUploady();

  const [reactionName, setReactionName] = useState("");
  const [reactionDescription, setReactionDescription] = useState("");
  const [duration, setDuration] = useState("");

  useBatchAddListener((batch) => {
    // debugger;
    setUploadAvailable(true);
    // setItems((items) => items.concat(batch.items));
  });

  useItemFinishListener((batch) => {
    console.log("BATCH FINISHED", batch);
    window.location.reload();
  });
  const showUploadButton = uploadAvailable && !!reactionName && !!reactionDescription;

  return (
    <div>
      <div>
        <div> Upload Your Own Reactions</div>
        <UploadButton text={"Select file to upload"} className={"btn btn-secondary btn-xs"} />
        <div className={"p-2"} style={{ width: "240px" }}>
          <MyUploadPreview
            fallbackUrl="/svg/movie-videos.svg"
            onPreviewsChanged={(previews) => {
              if (previews.length > 0) {
                let video = document.createElement("video");
                video.preload = "metadata";

                video.onloadedmetadata = function () {
                  console.log("LOADED METADATA", video.duration);
                  setDuration(video.duration as any);
                };

                video.onerror = function () {
                  throw new Error("Unable to load video");
                };

                video.src = previews[0].url;
              }
            }}
          />
        </div>
        {uploadAvailable && (
          <div className="form-control w-full max-w-xs pb-2">
            <label className="label">
              <span className="label-text">Enter the name for your reaction</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              onChange={(ev) => {
                setReactionName(ev.target.value);
              }}
              type="text"
              placeholder="Type here"
              className="input input-xs input-bordered w-full max-w-xs"
              value={reactionName}
            />

            <label className="label">
              <span className="label-text">Enter the description for your reaction</span>
              {/* <span className="label-text-alt">Alt label</span> */}
            </label>
            <input
              onChange={(ev) => {
                setReactionDescription(ev.target.value);
              }}
              type="text"
              placeholder="Type here"
              className="input input-xs input-bordered w-full max-w-xs"
              value={reactionDescription}
            />
            {/* <label className="label">
              <span className="label-text-alt">Alt label</span>
              <span className="label-text-alt">Alt label</span>
            </label> */}
          </div>
        )}
        {showUploadButton && (
          <div
            className={"btn btn-secondary btn-xs"}
            onClick={() => {
              uploady.processPending({
                params: { reactionName: reactionName, reactionDescription: reactionDescription, duration: duration },
              });
            }}>
            Upload File
          </div>
        )}
      </div>
    </div>
  );
}

export type NotificationMethod = "meetingStage" | "meetingNotification";

export function SoundBoard({ room }: { room: Room<TomsState> }) {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  const [notificationMethod, setNotificationMethod] = useState<NotificationMethod>("meetingNotification");

  let userId = "NONE";
  if (teamsCtx && teamsCtx.user && teamsCtx.user.id) {
    userId = teamsCtx.user.id;
  }

  let isUserApresenter = false;
  const params = new URLSearchParams(window.location.search); // id=123

  let commaSeperatedPresenters = params.get("presenters"); // 123
  if (commaSeperatedPresenters) {
    let presentersArray = commaSeperatedPresenters.split(",");
    isUserApresenter = !!presentersArray.find((p) => teamsCtx.user.id === p);
  } else {
    isUserApresenter = true;
  }
  if (commaSeperatedPresenters === "all") {
    isUserApresenter = true;
  }

  return (
    <div className={""}>
      {isUserApresenter ? (
        <div>
          <div className={"text-primary text-xl"}>Soundboard</div>
          <div>Choose one of the built in soundboard reactions, or upload your own.</div>

          <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box">
            <div className="collapse-title text-xs font-medium">Options</div>
            <div className="collapse-content">
              <div>
                <select
                  className="select select-bordered select-xs w-full max-w-xs"
                  onChange={(e) => {
                    console.log(e);
                    setNotificationMethod(e.target.value as any);
                  }}>
                  <option
                    selected
                    value={"meetingNotification"}
                    onChange={(e) => {
                      console.log(e);
                    }}>
                    Notification Bubble
                  </option>
                  <option
                    value={"meetingStage"}
                    onChange={(e) => {
                      console.log(e);
                    }}>
                    Meeting Stage
                  </option>
                  <option
                    value={"sidePanelOnly"}
                    onChange={(e) => {
                      console.log(e);
                    }}>
                    Sidepanel Only
                  </option>
                </select>
              </div>
            </div>
          </div>

          {/* <Uploady
            destination={{ url: serverUrl, headers: { "X-UPLOAD-USER": userId } }}
            fileFilter={filterBySize}
            autoUpload={false}> */}
          <TabbedSection room={room} notificationMethod={notificationMethod} />
          {/* </Uploady> */}
        </div>
      ) : (
        <div>
          <MatrixRain />
        </div>
      )}
    </div>
  );
}

export function SoundBoard2({ room, client }: { room: Room<TomsState>; client: Client }) {
  let notificationMethod = "meetingNotification";

  return (
    <AccordionMenu
      child1={<SharedSoundBoardItems room={room} notificationMethod={notificationMethod} />}
      child2={<ChooseExperience2 room={room} client={client} />}
      child3={
        <>
          {" "}
          <UploadyWrapper>
            <UploadReactionForm />
          </UploadyWrapper>
          <ItemWatcher room={room} notificationMethod={notificationMethod} />
        </>
      }></AccordionMenu>
  );
}
