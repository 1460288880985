import { Room, Client } from "colyseus.js";
import * as microsoftTeams from "@microsoft/teams-js"
import jwt_decode from "jwt-decode";
import { TOKEN_ENDPOINT } from "./Constants";
import { authentication } from "@microsoft/teams-js";

export async function getMicrosoftTeamsContextOrNull(): Promise<microsoftTeams.Context> {
    return new Promise((resolve, reject) => {
        let teamsContext = null;
        if (window["microsoftTeams"]) {
            microsoftTeams.initialize(async () => {
                microsoftTeams.getContext((context) => {
                    resolve(context);
                })
            })
            setTimeout(() => {
                resolve(null);
            }, 50)
        }
        else {
            resolve(null)
        }
    })
}


export function getMicrosoftTeamsContextOrNull2(teamsClient, timeout = 1000) {
    return new Promise((resolve, reject) => {
        let shouldReject = true;
        // teamsClient.getChatMembers(x => console.log(x));
        // teamsClient.getUserJoinedTeams(x => console.log(x));
        teamsClient.getContext((teamsContext) => {
            shouldReject = false;
            resolve({
                ...teamsContext,
                meetingId: teamsContext.meetingId,
                conversationId: teamsContext.chatId,
            });
        });
        setTimeout(() => {
            if (shouldReject) {
                console.error("Error getting context: Timeout. Make sure you are running the app within teams context and have initialized the sdk");
                resolve(null)
                // reject("Error getting context: Timeout");
            }
        }, timeout);
    });

}


export function getMicrosoftTeamsContext(teamsClient, timeout = 1000): Promise<microsoftTeams.Context> {
    return new Promise((resolve, reject) => {
        let shouldReject = true;
        // teamsClient.getChatMembers(x => console.log(x));
        // teamsClient.getUserJoinedTeams(x => console.log(x));
        teamsClient.getContext((teamsContext) => {
            shouldReject = false;
            resolve({
                ...teamsContext,
                meetingId: teamsContext.meetingId,
                conversationId: teamsContext.chatId,
            } as microsoftTeams.Context);
        });
        setTimeout(() => {
            if (shouldReject) {
                console.error("Error getting context: Timeout. Make sure you are running the app within teams context and have initialized the sdk");
                reject("Error getting context: Timeout");
            }
        }, timeout);
    });

}

export async function getTeamsAuthToken(): Promise<string> {
    return new Promise((resolve, reject) => {

        authentication.getAuthToken({
            successCallback: function (token) {
                resolve(token)
            },
            failureCallback: function (error) {
                console.error("Failed to get auth: ", error)
                reject(error);
            },
        })
    });
}
export async function joinOrCreateRoomByTeamsContext<T>(roomName: string, teamsContext, client: Client, options?: any) {
    let existingRooms = await client.getAvailableRooms(roomName);

    let room: Room<T>;

    if (teamsContext && teamsContext.meetingId) {
        let existingMeetingRoom = existingRooms.find((r) => {
            if (!r.metadata) return false;

            return r.metadata.meetingId === teamsContext.meeting.id
        })
        if (existingMeetingRoom) {
            room = await client.joinById(existingMeetingRoom.roomId, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
        }
        else {
            room = await client.create<T>(roomName, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
        }
    }
    else {
        room = await client.joinOrCreate<T>(roomName, { isInTeams: false, teamsContext: teamsContext, ...options });
    }

    return room;
}

export async function joinOrCreateRoomByTeamsContext2<T>(roomName: string, teamsContext: microsoftTeams.app.Context, client: Client, options?: any) {
    let existingRooms = await client.getAvailableRooms(roomName);
    console.log("GOT EXISTING ROOMS", existingRooms)
    console.log("COLYSEUS TEAMS CONTEXT", teamsContext)

    let room: Room<T>;

    if (teamsContext && teamsContext.meeting && teamsContext.meeting.id) {
        let existingMeetingRoom = existingRooms.find((r) => {
            if (!r.metadata) return false;

            return r.metadata.meetingId === teamsContext.meeting.id
        })
        if (existingMeetingRoom) {
            room = await client.joinById(existingMeetingRoom.roomId, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
        }
        else {
            room = await client.create<T>(roomName, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
        }
    }
    else {
        if (teamsContext && teamsContext.page && teamsContext.page.id) {
            let existingOpenRoom = existingRooms.find((r) => {
                if (!r.metadata) return false;

                return r.metadata.pageId === teamsContext.page.id
            })

            if (existingOpenRoom) {
                room = await client.joinById(existingOpenRoom.roomId, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
            }
            else {
                room = await client.create<T>(roomName, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
            }
        }
        else {
            let existingOpenRooms = existingRooms.find((r) => {
                if (r.metadata && r.metadata.meetingId) return false;

                return true;
            })

            if (existingOpenRooms && !options.forceNewRoom) {
                room = await client.joinById(existingOpenRooms.roomId, { isInTeams: false, teamsContext: teamsContext, ...options });
                debugger;
            }
            else {
                room = await client.create<T>(roomName, { isInTeams: typeof teamsContext === "object" && teamsContext !== null, teamsContext: teamsContext, ...options });
            }
        }
    }

    return room;
}

export function isUserInSidePanel(teamsContext) {
    if (!teamsContext) return false;
    if (!teamsContext.page) return false;

    if (!teamsContext.page.frameContext) return false;

    return teamsContext.page.frameContext === "sidePanel"
}

export function setupExperienceSelectedHandler(room, teamsContext) {
    room.onMessage("experienceSelected", (message) => {
        console.log("experienceSelected", message)
        if (isUserInSidePanel(teamsContext)) return;

        switch (message.experienceName) {
            case "flappy":
                window.location.href = "/flappy.html"
                break;

            case "Tomflix":
                window.location.href = "https://tomflix.sparkworkspace.com/splash"
                break;
            case "missioncontrol":
                window.location.href = "/root.html?app=missioncontrol"
                break;
            case "pingpong":
                window.location.href = "/root.html?app=pingpong"
                break;
            default:

                window.location.href = "/"
                break;
        }
    })
}

export async function augmentTeamsContextWithUserInfo(teamsContext) {
    if (teamsContext) {
        let token = await getTeamsAuthToken();
        var decoded = jwt_decode<{ name: string }>(token);
        teamsContext.name = decoded.name;
    }
    return teamsContext;
}

export function blobToBase64(blob): Promise<string> {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result as any;
            var base64 = dataUrl.split(",")[1];
            resolve(base64);
        };
        reader.readAsDataURL(blob);
    });
};

export async function getOnBehalfOfToken(teamsAuthToken: string) {
    let resp = await fetch(TOKEN_ENDPOINT, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${teamsAuthToken}`
        }
    })

    let oboToken = await resp.json();

    if (oboToken.suberror && oboToken.suberror === "consent_required") {
        console.log("OBOTOKEN ERROR");
        throw new Error("App has not been consented to yet.")
    }
    return oboToken;
}

export async function getOnBehalfOfTokenSP(teamsAuthToken: string) {
    let resp = await fetch("https://collabtris.azurewebsites.net/sptoken", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${teamsAuthToken}`
        }
    })

    let oboToken = await resp.json();

    if (oboToken.suberror && oboToken.suberror === "consent_required") {
        console.log("OBOTOKEN ERROR");
        throw new Error("App has not been consented to yet.")
    }
    return oboToken;
}

export async function getMyPhotoBase64(accessToken: string) {
    const photo = await fetch(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
        },
    });
    if (photo.status !== 200) {
        throw new Error("Unable to get user photo");
    }
    const photoResult = await photo.blob();
    let photoBase64 = "data:image/jpeg;base64," + await blobToBase64(photoResult);
    return photoBase64;
}

export function generateMeetingId(organizerOid: string, chatId: string) {
    let id = btoa(`1*${organizerOid}*0**${chatId}`)
    return id;
}

export async function getMeetingInfo(authToken: string, organizerOid: string, chatId: string) {
    let id = generateMeetingId(organizerOid, chatId);
    let url = `https://graph.microsoft.com/beta/me/onlineMeetings/${id}`
    let response = await fetch(url, {
        headers: {
            "Authorization": `Bearer ${authToken}`
        }
    })

    let json = await response.json();
    return json;
}

export function getThreadIdFromJoinUrl(joinUrl: string = "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjU0ZWVhOGYtOGFiYS00MzI1LTlmNTItMzIwYWYwZDVjNWU5%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%220786a28b-fd76-429c-8a34-df0ae9d35165%22%7d"): string {
    const firstPart = joinUrl.split("/meetup-join/")[1];
    console.log(firstPart);
    const secondPart = firstPart.split("/")[0];
    console.log(secondPart);
    const lastPart = secondPart.replace("%3a", ":").replace("%40", "@");
    console.log("LAST", lastPart)
    return lastPart;
}


function createTeamsVivaPassThruDeepLink(vivaAppId, rootSharePointUrl, sharePointPageUrl, pageName) {
    // first encode the full url to the SharePoint page, it will end up getting double encoded when we encode the full context object
    const encodedUrl = encodeURIComponent(sharePointPageUrl);

    // teamslogon.aspx is a special built in SP page that makes sure you are logged in correctly and then passes you through to the dest=
    const contextObject = {
        contentUrl: `${rootSharePointUrl}/_layouts/15/teamslogon.aspx?spfx=true&dest=${encodedUrl}`,
        websiteUrl: `${rootSharePointUrl}/_layouts/15/teamslogon.aspx?spfx=true&dest=${encodedUrl}`,
        name: pageName, // pageName shows as the title of the popup
    };

    // create a link to the stage view of the Viva Connections app, we use the Viva app because its already got SP in its allowedDomains in the Teams App manifest
    const finalUrl = `https://teams.microsoft.com/l/stage/${vivaAppId}/0?context=${encodeURIComponent(
        JSON.stringify(contextObject)
    )}`;
    return finalUrl;
}