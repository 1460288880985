import { motion } from "framer-motion";
import React from "react";

export function ExperienceWrapper({ children, title }: { children: React.ReactChild; title: string }) {
  return (
    <div className={"experience-wrapper select-none"}>
      <div className={"flex flex-col"}>
        <h1 className={"text-primary text-2xl px-8 py-4"}>{title}</h1>
      </div>
      {/* <motion.div animate={{ x: 100 }} transition={{ ease: "easeOut", duration: 1 }}>
        <h1 className="experience-title">{title}</h1>
      </motion.div>
      <h1 className="experience-title">{title}</h1> */}

      {/* {children} */}
      <motion.div
        initial={{ position: "absolute", left: 2000 }}
        animate={{ left: 50 }}
        transition={{ ease: "easeOut", duration: 1 }}>
        {children}
      </motion.div>
    </div>
  );
}
