
import React from "react";
import  create   from "zustand";
import { AzureLogger, setLogLevel, AzureLogLevel } from "@azure/logger";

type AzureLoggerStore = {
    setLogLevel: (level: AzureLogLevel) => void;
    setLogFunction: (logFn: (...args: any[]) => void) => void;
}
export const useAzureLogger = create<AzureLoggerStore>((set) => ({
    setLogLevel: (logLevel) => {
        setLogLevel(logLevel)
    },
    setLogFunction: (logFn) => {
        AzureLogger.log = logFn;
    }
 }))