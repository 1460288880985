import { Room, Client } from "colyseus.js";
import React, { forwardRef, Suspense, useEffect, useMemo, useRef } from "react";
import { TomsState } from "../../../state/TomsState";
import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";

import * as CANNON from "cannon";
import { TOM_IMAGE_BASE64 } from "../../Constants";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { Camera, Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Box, PerspectiveCamera, OrbitControls } from "@react-three/drei";
import { Renderer } from "three";
import CannonDebugger from "cannon-es-debugger";
import { Physics, usePlane, useBox, useCylinder, Debug, useSphere } from "@react-three/cannon";
import { Leva, useControls } from "leva";
import { useFallingDownState } from "./FallingDownState";

const radians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

export function FallingDownInner({
  room,
  client,
  scene,
  gl,
  camera,
}: {
  room: Room<TomsState>;
  client: Client;
  scene: any;
  gl: any;
  camera: any;
}) {
  const canvasRef = useRef();
  useEffect(() => {
    const hexToRgb = (hex) => {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      return result
        ? {
            r: parseInt(result[1], 16) / 255,
            g: parseInt(result[2], 16) / 255,
            b: parseInt(result[3], 16) / 255,
          }
        : null;
    };

    const rgbToHex = (s) => s.match(/[0-9]+/g).reduce((a, b) => a + (b | 256).toString(16).slice(1), "#");

    const rInterval = function (callback, delay) {
      const dateNow = Date.now;
      const requestAnimation = window.requestAnimationFrame;
      let start = dateNow();
      let stop;

      const intervalFunc = function () {
        dateNow() - start < delay || ((start += delay), callback());
        stop || requestAnimation(intervalFunc);
      };

      requestAnimation(intervalFunc);

      return {
        clear: function () {
          stop = 1;
        },
      };
    };

    class App {
      camera: THREE.PerspectiveCamera;
      clock;
      speed: 2;
      delta: 0;

      init() {
        this.setup();
        this.addClock();
        // this.addStatsMonitor();
        this.createScene();
        this.createCamera();
        this.addCameraControls();
        this.addAmbientLight();
        this.addDirectionalLight();
        this.addPhysicsWorld();
        this.addFloor();
        this.addFloorGrid();
        this.addBackWall();
        this.addObstacles();
        this.addSpheres();
        this.addAxisHelper();
        // this.addGuiControls();
        this.animate();
        this.addWindowListeners();
        this.addFallingBalls();

        room.state.players.players.onAdd = (player: any, key: number) => {
          console.log(`${player.id} joined the game`);

          player.onChange = (changes) => {
            console.log("PLAYER CHANGES", changes);

            for (const change of changes) {
              if (change.field === "imageBase64") {
                if (!change.previousValue && change.value) {
                  this.addBlockWithImage(change.value);
                }
                // console.log("ADDING IMAGE BLOCK", change.value);
                // this.addBlockWithImage(change.value);
              }
            }
          };
        };

        room.onStateChange.once((state) => {
          console.log("this is the first room state!", state);
        });

        room.onStateChange((state) => {
          console.log("the room state has been updated:", state);
        });

        setInterval(() => {
          room.state.players.players.forEach((p) => {
            if (p.imageBase64) {
              this.addPlinkoWithImage(p.imageBase64, 9);
            } else {
              this.addSpheres();
            }
          });
        }, 6000);
      }

      addClock() {
        this.clock = new THREE.Clock();
      }
      addFallingBalls() {
        if (this.animation.auto) {
          //   this.animation.loop = rInterval(this.addSpheres.bind(this), 300);
        } else {
          //   this.animation.loop.clear();
        }
      }

      tweenColors(material, rgb) {
        gsap.to(material.color, 0.3, {
          ease: "power2.out",
          r: rgb.r,
          g: rgb.g,
          b: rgb.b,
        });
      }

      setup() {
        this.debug = false;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.animation = {
          auto: true,
          interval: null,
        };

        this.colors = {
          background: rgbToHex(window.getComputedStyle(document.body).backgroundColor),
          wall: "#ff003e",
          floor: "#ffffff",
          ball: "#5661ff",
          cylinder: "#ffff00",
          grid: "#aca9a9",
        };

        let texture = new THREE.TextureLoader().load(TOM_IMAGE_BASE64);

        this.meshes = {
          container: new THREE.Object3D(),
          spheres: [],
          obstacles: [],
          sphereMaterial: new THREE.MeshStandardMaterial({
            color: this.colors.ball,
            metalness: 0.11,
            emissive: 0x0,
            roughness: 0.1,
          }),
          faceMaterial: new THREE.MeshLambertMaterial({
            map: texture,
          }),
          cylinderMaterial: new THREE.MeshStandardMaterial({
            color: this.colors.cylinder,
            emissive: 0x0,
            roughness: 1,
            metalness: 0,
          }),
        };
      }

      createScene() {
        this.scene = scene;
        // this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(this.colors.background);
        this.renderer = gl; // new THREE.WebGLRenderer({ antialias: true, canvas: canvasRef.current });
        this.scene.add(this.meshes.container);
        this.renderer.setSize(this.width, this.height);

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        // document.body.appendChild(this.renderer.domElement);
      }

      addAxisHelper() {
        const axesHelper = new THREE.AxesHelper(5);

        this.debug && this.scene.add(axesHelper);
      }

      createCamera() {
        this.camera = camera;
        // this.camera.position.set(0, 10, 50);
        // this.camera.far = 1000;
        // this.camera.fov = 20;

        // this.camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 1, 1000);
        // // this.camera.position.set(0, 10, 50);
        // this.scene.add(this.camera);
      }

      addCameraControls() {
        this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
        this.orbitControl.minPolarAngle = radians(30);
        this.orbitControl.maxPolarAngle = radians(90);
        this.orbitControl.minAzimuthAngle = radians(-40);
        this.orbitControl.maxAzimuthAngle = radians(40);
        this.orbitControl.enableDamping = true;
        this.orbitControl.dampingFactor = 0.02;

        document.body.style.cursor = "-moz-grabg";
        document.body.style.cursor = "-webkit-grab";

        this.orbitControl.addEventListener("start", () => {
          requestAnimationFrame(() => {
            document.body.style.cursor = "-moz-grabbing";
            document.body.style.cursor = "-webkit-grabbing";
          });
        });

        this.orbitControl.addEventListener("end", () => {
          requestAnimationFrame(() => {
            document.body.style.cursor = "-moz-grab";
            document.body.style.cursor = "-webkit-grab";
          });
        });
      }

      addAmbientLight() {
        const light = new THREE.AmbientLight({ color: "#ffffff" }, 0.5);

        this.scene.add(light);
      }

      addDirectionalLight() {
        const target = new THREE.Object3D();
        target.position.set(0, 0, -40);

        this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        this.directionalLight.castShadow = true;
        this.directionalLight.shadow.camera.needsUpdate = true;
        this.directionalLight.shadow.mapSize.width = 2048;
        this.directionalLight.shadow.mapSize.height = 2048;
        this.directionalLight.position.set(0, 13, 23);
        this.directionalLight.target = target;

        this.directionalLight.shadow.camera.far = 1000;
        this.directionalLight.shadow.camera.near = -100;

        this.directionalLight.shadow.camera.left = -20;
        this.directionalLight.shadow.camera.right = 20;
        this.directionalLight.shadow.camera.top = 15;
        this.directionalLight.shadow.camera.bottom = -15;
        this.directionalLight.shadow.camera.zoom = 1;
        this.directionalLight.shadow.camera.needsUpdate = true;

        this.scene.add(this.directionalLight);
      }

      addFloorGrid() {
        const size = 100;
        const divisions = 100;
        this.grid = new THREE.GridHelper(size, divisions, this.colors.grid, this.colors.grid);

        this.grid.position.set(0, -5, 0);
        this.grid.material.opacity = 0;
        this.grid.material.transparent = false;

        this.scene.add(this.grid);
      }

      addBackWall() {
        const materialParams = { color: this.colors.wall, side: THREE.DoubleSide };
        const geometry = new THREE.PlaneBufferGeometry(100, 80);
        const material = new THREE.MeshStandardMaterial(materialParams);

        this.backwall = new THREE.Mesh(geometry, material);
        this.backwall.position.z = -0.5;
        this.backwall.receiveShadow = true;

        this.scene.add(this.backwall);

        // physics backwall
        this.backwall.body = new CANNON.Body({
          mass: 0,
          position: new CANNON.Vec3(0, 3, -0.4),
          material: new CANNON.Material(),
          shape: new CANNON.Plane(2, 2, 2),
        });

        this.backwall.body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), radians(0));
        this.world.addBody(this.backwall.body);
      }

      addFloor() {
        const geometry = new THREE.PlaneBufferGeometry(100, 80);
        const material = new THREE.MeshStandardMaterial({
          color: this.colors.floor,
          side: THREE.DoubleSide,
        });

        this.floor = new THREE.Mesh(geometry, material);
        this.floor.position.y = -5;
        this.floor.position.z = 5;
        this.floor.rotateX(Math.PI / 2);
        this.floor.receiveShadow = true;

        this.scene.add(this.floor);

        // physics floor
        this.floor.body = new CANNON.Body({
          mass: 0,
          position: new CANNON.Vec3(0, -5, 5),
          material: new CANNON.Material(),
          shape: new CANNON.Plane(2, 2, 2),
        });

        this.floor.body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), radians(-90));
        this.world.addBody(this.floor.body);
      }

      addFloorHelper() {
        this.controls = new TransformControls(this.camera, this.renderer.domElement);
        this.controls.enabled = false;
        this.controls.attach(this.floor);
        this.scene.add(this.controls);
      }

      getSphereMesh({ x, y, z }) {
        const radius = 0.3,
          width = 32,
          height = 32;

        const geometry = new THREE.SphereBufferGeometry(radius, width, height);

        let sphereMaterial = this.meshes.sphereMaterial;

        const mesh = new THREE.Mesh(geometry, sphereMaterial);

        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);

        // physics sphere
        mesh.body = new CANNON.Body({
          mass: 1,
          material: new CANNON.Material(),
          shape: new CANNON.Sphere(radius),
          position: new CANNON.Vec3(x, y, z),
        });

        mesh.body.linearDamping = this.damping;
        mesh.body.fixedRotation = true;

        return mesh;
      }

      getBlockMeshWithImage({ x, y, z, imageUrl }) {
        const radius = 0.3,
          width = 32,
          height = 32;
        const geometry = new THREE.BoxBufferGeometry(0.75, 0.75, 0.75);

        // const geometry = new THREE.SphereBufferGeometry(radius, width, height);
        // const geometry = new THREE.SphereBufferGeometry(radius, width, height);

        let textureLoader = new THREE.TextureLoader();
        let textureEquirec = textureLoader.load(imageUrl);
        textureEquirec.mapping = THREE.EquirectangularRefractionMapping;
        textureEquirec.encoding = THREE.sRGBEncoding;
        let sphereMaterial = new THREE.MeshStandardMaterial({
          map: textureEquirec,
        });

        const mesh = new THREE.Mesh(geometry, sphereMaterial);

        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);

        // physics sphere
        mesh.body = new CANNON.Body({
          mass: 1,
          material: new CANNON.Material(),
          shape: new CANNON.Sphere(radius),
          position: new CANNON.Vec3(x, y, z),
        });

        mesh.body.linearDamping = this.damping;
        mesh.body.fixedRotation = true;

        return mesh;
      }

      getSphereMesh2({ x, y, z }) {
        const radius = 0.3,
          width = 32,
          height = 32;
        const geometry = new THREE.BoxBufferGeometry(0.75, 0.75, 0.75);

        // const geometry = new THREE.SphereBufferGeometry(radius, width, height);
        // const geometry = new THREE.SphereBufferGeometry(radius, width, height);

        let textureLoader = new THREE.TextureLoader();
        let textureEquirec = textureLoader.load(TOM_IMAGE_BASE64);
        textureEquirec.mapping = THREE.EquirectangularRefractionMapping;
        textureEquirec.encoding = THREE.sRGBEncoding;
        let sphereMaterial = new THREE.MeshStandardMaterial({
          map: textureEquirec,
        });

        const mesh = new THREE.Mesh(geometry, sphereMaterial);

        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.position.set(x, y, z);

        // physics sphere
        mesh.body = new CANNON.Body({
          mass: 1,
          material: new CANNON.Material(),
          shape: new CANNON.Sphere(radius),
          position: new CANNON.Vec3(x, y, z),
        });

        mesh.body.linearDamping = this.damping;
        mesh.body.fixedRotation = true;

        return mesh;
      }

      addObstacle(posX, posY, posZ, rotation) {
        const width = 4;
        const geometry = new THREE.BoxBufferGeometry(width, 1, 0.1);
        const matParams = {
          emissive: 0x0,
          roughness: 1,
          metalness: 0,
        };

        const material = new THREE.MeshStandardMaterial(matParams);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        mesh.position.set(posX, posY, posZ);
        mesh.rotation.x = -radians(90);
        mesh.rotation.y = radians(rotation);

        this.meshes.container.add(mesh);
        this.meshes.obstacles.push(mesh);

        // physics obstacle
        mesh.body = new CANNON.Body({
          mass: 0,
          material: new CANNON.Material(),
          shape: new CANNON.Box(new CANNON.Vec3(width * 0.5, 0.05, 0.5)),
          position: new CANNON.Vec3(posX, posY, posZ),
        });

        mesh.body.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 0, -1), radians(rotation));
        this.world.addBody(mesh.body);
      }

      addObstacles() {
        this.addObstacle(-3, 2, 0, 30);
        this.addObstacle(3, 2, 0, -40);
        this.addObstacle(0, -2, 0, 0);

        this.addCylinder(8, 0);
        this.addCylinder(7, 0.5);
        this.addCylinder(6, 1);
        this.addCylinder(5, -0.5);
        this.addCylinder(4, 1);
        this.addCylinder(8, -2);
        this.addCylinder(7, -2.5);
        this.addCylinder(6, -2);
        this.addCylinder(5, -2.5);
        this.addCylinder(4, -1);

        // this.addPlinko(9);
        // this.addPlinko(2);

        //
        // this.addCylinder(3, 0);
        // this.addCylinder(2, -1);
      }

      addCylinder(posY, posX) {
        const size = 0.1;
        const geometry = new THREE.CylinderGeometry(size, size, 1, 32);

        for (let index = 0; index < 3; index++) {
          const mesh = new THREE.Mesh(geometry, this.meshes.cylinderMaterial);

          mesh.position.set(index + posX, posY, 0);
          mesh.rotateX(Math.PI / 2);
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          this.meshes.container.add(mesh);

          // physics cylinder
          mesh.body = new CANNON.Body({
            mass: 0,
            material: new CANNON.Material(),
            shape: new CANNON.Cylinder(size, size, 1, 32),
            position: new CANNON.Vec3(index + posX, posY, 0),
          });

          this.world.addBody(mesh.body);
        }
      }

      addPlinko(posY) {
        const pos = [-2, -1, 0, 0.2, 0.8, 1.1];
        const posX = pos[Math.floor(Math.random() * pos.length)];

        let textureLoader = new THREE.TextureLoader();
        let textureEquirec = textureLoader.load(TOM_IMAGE_BASE64);

        const size = 0.36;
        const geometry = new THREE.CylinderGeometry(size, size, 0.1, 32);
        geometry.rotateX(Math.PI / 2);

        const mesh = new THREE.Mesh(
          geometry,
          new THREE.MeshStandardMaterial({
            // color: this.colors.cylinder,
            emissive: 0x0,
            roughness: 1,
            metalness: 0,
            map: textureEquirec,
          })
        );

        // mesh.position.set(posX, posY, 0);
        // mesh.rotateX(Math.PI / 2);
        // mesh.rotateX(Math.PI / 2);

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        this.meshes.spheres.push(mesh);
        this.meshes.container.add(mesh);

        // physics cylinder
        // mesh.body = new CANNON.Body({
        //   mass: 0,
        //   material: new CANNON.Material(),
        //   shape: new CANNON.Cylinder(size, size, 1, 32),
        //   position: new CANNON.Vec3(index + posX, posY, 0),
        // });
        let cylinder = new CANNON.Cylinder(size, size, 0.3, 32);

        mesh.body = new CANNON.Body({
          mass: 1,
          material: new CANNON.Material(),
          shape: cylinder,
          position: new CANNON.Vec3(posX, posY, 0),
        });

        // var cylinderBody = mesh.body;
        // var quat = new CANNON.Quaternion();
        // quat.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
        // var translation = new CANNON.Vec3(0, 0, 0);
        // cylinderBody.shape.transformAllPoints(translation, quat);

        this.world.addBody(mesh.body);

        // let shape2 = new CANNON.Cylinder(size, size, 0.4, 32);
        // let body2 = new CANNON.Body({ mass: 1, shape2 });

        // this.world.addBody(body2);
      }

      addPlinkoWithImage(imageUrl, posY) {
        const pos = [-2, -1, 0, 0.2, 0.8, 1.1];
        const posX = pos[Math.floor(Math.random() * pos.length)];

        let textureLoader = new THREE.TextureLoader();
        let textureEquirec = textureLoader.load(imageUrl);

        const size = 0.36;
        const geometry = new THREE.CylinderGeometry(size, size, 0.1, 32);
        geometry.rotateX(Math.PI / 2);

        const mesh = new THREE.Mesh(
          geometry,
          new THREE.MeshStandardMaterial({
            // color: this.colors.cylinder,
            emissive: 0x0,
            roughness: 1,
            metalness: 0,
            map: textureEquirec,
          })
        );

        // mesh.position.set(posX, posY, 0);
        // mesh.rotateX(Math.PI / 2);
        // mesh.rotateX(Math.PI / 2);

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        this.meshes.spheres.push(mesh);
        this.meshes.container.add(mesh);

        // physics cylinder
        // mesh.body = new CANNON.Body({
        //   mass: 0,
        //   material: new CANNON.Material(),
        //   shape: new CANNON.Cylinder(size, size, 1, 32),
        //   position: new CANNON.Vec3(index + posX, posY, 0),
        // });
        let cylinder = new CANNON.Cylinder(size, size, 0.3, 32);

        mesh.body = new CANNON.Body({
          mass: 1,
          material: new CANNON.Material(),
          shape: cylinder,
          position: new CANNON.Vec3(posX, posY, 0),
        });

        // var cylinderBody = mesh.body;
        // var quat = new CANNON.Quaternion();
        // quat.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
        // var translation = new CANNON.Vec3(0, 0, 0);
        // cylinderBody.shape.transformAllPoints(translation, quat);

        this.world.addBody(mesh.body);

        // let shape2 = new CANNON.Cylinder(size, size, 0.4, 32);
        // let body2 = new CANNON.Body({ mass: 1, shape2 });

        // this.world.addBody(body2);
      }
      addSpheres() {
        const pos = [-2, -1, 0, 0.2, 0.8, 1.1];
        const posX = pos[Math.floor(Math.random() * pos.length)];
        const mesh = this.getSphereMesh({ x: posX, y: 12, z: 0 });

        this.meshes.spheres.push(mesh);
        this.meshes.container.add(mesh);

        // add contact config in relation to floor
        this.world.addBody(mesh.body);
        const mat = new CANNON.ContactMaterial(this.floor.body.material, mesh.body.material, {
          friction: 0.3,
          restitution: 0.5,
        });
        this.world.addContactMaterial(mat);

        this.meshes.spheres.forEach((mesh) => {
          this.meshes.obstacles.forEach((o) => {
            // add contact config in relation to each obstacle, only shapes (no cylinders)
            const mat = new CANNON.ContactMaterial(o.body.material, mesh.body.material, {
              friction: 0.3,
              restitution: 0.5,
            });

            this.world.addContactMaterial(mat);
          });
        });
      }

      addBlockWithImage(imageUrl: string) {
        const pos = [-2, -1, 0, 0.2, 0.8, 1.1];
        const posX = pos[Math.floor(Math.random() * pos.length)];
        const mesh = this.getBlockMeshWithImage({ x: posX, y: 12, z: 0, imageUrl: imageUrl });

        this.meshes.spheres.push(mesh);
        this.meshes.container.add(mesh);

        // add contact config in relation to floor
        this.world.addBody(mesh.body);
        const mat = new CANNON.ContactMaterial(this.floor.body.material, mesh.body.material, {
          friction: 0.3,
          restitution: 0.5,
        });
        this.world.addContactMaterial(mat);

        this.meshes.spheres.forEach((mesh) => {
          this.meshes.obstacles.forEach((o) => {
            // add contact config in relation to each obstacle, only shapes (no cylinders)
            const mat = new CANNON.ContactMaterial(o.body.material, mesh.body.material, {
              friction: 0.3,
              restitution: 0.5,
            });

            this.world.addContactMaterial(mat);
          });
        });
      }

      addPhysicsWorld() {
        this.fixedTimeStep = 1 / 60; // seconds
        this.maxSubSteps = 3;
        this.damping = 0.09;
        this.time = 0.01;
        this.lastTime = this.time;

        this.world = new CANNON.World();
        this.world.gravity.set(0, -20, 0.01);
        this.world.broadphase = new CANNON.NaiveBroadphase();
        this.world.solver.iterations = 10;
        this.world.defaultContactMaterial.contactEquationStiffness = 1e6;
        this.world.defaultContactMaterial.contactEquationRelaxation = 3;

        this.cannonDebugRenderer = this.debug && new CannonDebugRenderer(this.scene, this.world);
      }

      addGuiControls() {
        this.pane = new Tweakpane();

        // control animation
        this.guiAnimation = this.pane.addFolder({
          title: "Animation",
          expanded: false,
        });

        this.guiAnimation.addInput(this.animation, "auto").on("change", (value) => {
          this.animation.auto = value;
          this.addFallingBalls();
        });

        // add ball
        const btn = this.guiAnimation.addButton({
          title: "Add Ball",
        });

        btn.on("click", () => {
          this.addSpheres();
        });

        // control colors
        this.guiColors = this.pane.addFolder({
          title: "Colors",
          expanded: false,
        });

        this.guiColors.addInput(this.colors, "wall").on("change", (value) => {
          this.tweenColors(this.backwall.material, hexToRgb(value));
        });

        this.guiColors.addInput(this.colors, "floor").on("change", (value) => {
          this.tweenColors(this.floor.material, hexToRgb(value));
        });

        this.guiColors.addInput(this.colors, "ball").on("change", (value) => {
          this.tweenColors(this.meshes.sphereMaterial, hexToRgb(value));
        });

        this.guiColors.addInput(this.colors, "cylinder").on("change", (value) => {
          this.tweenColors(this.meshes.cylinderMaterial, hexToRgb(value));
        });

        this.guiColors.addInput(this.colors, "grid").on("change", (value) => {
          this.tweenColors(this.grid.material, hexToRgb(value));
        });

        // control lights
        this.guiLights = this.pane.addFolder({
          title: "Lights",
          expanded: false,
        });

        this.guiLights.addInput(this.directionalLight.position, "x", { min: -100, max: 100 }).on("change", (value) => {
          this.directionalLight.position.x = value;
        });

        this.guiLights.addInput(this.directionalLight.position, "y", { min: -100, max: 100 }).on("change", (value) => {
          this.directionalLight.position.y = value;
        });

        this.guiLights.addInput(this.directionalLight.position, "z", { min: -100, max: 100 }).on("change", (value) => {
          this.directionalLight.position.z = value;
        });
      }

      addWindowListeners() {
        window.addEventListener("resize", this.onResize.bind(this), {
          passive: true,
        });

        window.addEventListener(
          "visibilitychange",
          (evt) => {
            if (evt.target.hidden) {
              this.animation.auto = false;
              this.addFallingBalls();
              this.pane && this.pane.refresh();
            }
          },
          false
        );
      }

      addStatsMonitor() {
        this.stats = new Stats();
        this.stats.showPanel(0);
        document.body.querySelector(".stats").appendChild(this.stats.domElement);
      }

      onResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
      }

      animate() {
        this.delta = this.clock.getDelta();

        this.stats && this.stats.begin();
        this.orbitControl.update();
        this.renderer.render(this.scene, this.camera);

        // physics loop
        if (this.lastTime !== undefined) {
          this.debug && this.cannonDebugRenderer.update();
          var dt = (this.time - this.lastTime) / 1000;
          this.world.step(this.fixedTimeStep, dt, this.maxSubSteps);

          // map physics position to threejs mesh position
          this.meshes.spheres.forEach((s) => {
            s.position.copy(s.body.position);
            s.quaternion.copy(s.body.quaternion);
          });
          this.backwall.position.set(this.delta, 0, this.delta);
          this.backwall.position.copy(this.backwall.body.position);
          this.backwall.quaternion.copy(this.backwall.body.quaternion);
        }

        this.stats && this.stats.end();
        this.lastTime = this.time;

        requestAnimationFrame(this.animate.bind(this));
      }
    }

    new App().init();
  }, [gl, camera]);

  return null;
  //   return (
  //     <div>
  //       <canvas ref={canvasRef}></canvas>
  //       <main>
  //         <div className="stats"></div>
  //         <div className="frame"></div>
  //       </main>
  //     </div>
  //   );
}

// export const FallingDown = FallingDownInner;

export function FallingDownInner2({ room, client }: { room: Room<TomsState>; client: Client }) {
  const { scene, gl, camera } = useThree();

  return <FallingDownInner room={room} client={client} scene={scene} gl={gl} camera={camera} />;
}

// function Floor() {
//   const { scene } = useThree();
//   useThree(({ camera }) => {
//     camera.position.set(0, 10, 50);
//   });
//   const geometry = new THREE.PlaneBufferGeometry(100, 80);
//   const material = new THREE.MeshStandardMaterial({
//     color: "#5661ff",
//     side: THREE.DoubleSide,
//   });

//   // const floorMesh = new THREE.Mesh(geometry, material);
//   // this.floor.position.y = -5;
//   // this.floor.position.z = 5;
//   // this.floor.rotateX(Math.PI / 2);
//   // this.floor.receiveShadow = true;

//   // this.scene.add(this.floor);

//   // // physics floor
//   // this.floor.body = new CANNON.Body({
//   //   mass: 0,
//   //   position: new CANNON.Vec3(0, -5, 5),
//   //   material: new CANNON.Material(),
//   //   shape: new CANNON.Plane(2, 2, 2),
//   // });

//   // this.floor.body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), radians(-90));
//   // this.world.addBody(this.floor.body);
//   return (
//     <group>
//       <mesh geometry={geometry} material={material} position={[0, -5, 5]} rotateX={Math.PI / 2} receiveShadow={true} />
//     </group>
//   );
// }

function ABox(props) {
  return (
    <mesh {...props}>
      <boxGeometry />
      <meshStandardMaterial />
    </mesh>
  );
}

function Mouse() {
  const { viewport } = useThree();
  const [, api] = useSphere(() => ({ type: "Kinematic", args: [4] }));
  useFrame((state) => api.position.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0));
  return null;
}

function BackWall(props) {
  const { name, aNumber } = useControls({ name: "World", aNumber: -0.92 });
  // const materialParams = { color: this.colors.wall, side: THREE.oubleSide };
  // const geometry = new THREE.PlaneBufferGeometry(100, 80);
  // const material = new THREE.MeshStandardMaterial(materialParams);

  // this.backwall = new THREE.Mesh(geometry, material);
  // this.backwall.position.z = -0.5;
  // this.backwall.receiveShadow = true;

  // this.scene.add(this.backwall);

  // // physics backwall
  // this.backwall.body = new CANNON.Body({
  //   mass: 0,
  //   position: new CANNON.Vec3(0, 3, -0.4),
  //   material: new CANNON.Material(),
  //   shape: new CANNON.Plane(2, 2, 2),
  // });

  // this.backwall.body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), radians(0));
  // this.world.addBody(this.backwall.body);
  console.log("ROTATION", aNumber);
  const { viewport } = useThree();

  const [ref, api] = usePlane(() => ({
    // args: [10, 80],
    mass: 0,
    rotation: [aNumber, 0.0, 0],
    // rotation: [Math.PI / 2, 0, 0],
    // shape: new CANNON.Cylinder(size, size, 0.3, 32),
    ...props,
  }));

  // useEffect(() => {
  //   api.rotation.set(aNumber, 0, 0);
  //   // api.applyLocalImpulse(new CANNON.Vec3(aNumber, 0, -0.0), new CANNON.Vec3(0, 0, 0));
  // }, [aNumber]);

  let values = useRef([0, 0, 0]);
  let plinkoRef = useRef();
  const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
  const baubleMaterial = new THREE.MeshStandardMaterial({
    color: "red",
    roughness: 0,
    envMapIntensity: 0.2,
    emissive: "#370037",
  });
  const mat = new THREE.Matrix4();
  const vec = new THREE.Vector3();

  const pegRef1 = useRef();
  // const [peg1, apiPeg1] = useCylinder((index) => ({
  //   args: [size, size, 1, 32],
  //   mass: 0,
  //   rotation: [Math.PI / 2, 0, 0],
  //   type: "Kinematic",
  //   position: [index, 0, 0],
  //   // shape: new CANNON.Cylinder(size, size, 0.3, 32),
  //   ...props,
  // }));

  useFrame(({ clock }) => {
    // values.current[0] = 2;
    // api.rotation.set(Math.sin(clock.getElapsedTime()) * 2, 0, 0);
    let pos = Math.sin(clock.getElapsedTime());
    api.position.set(pos * 2, 0, 0);

    api.velocity.set(pos * 2, 0, 0);
    // pegRef1?.current.position.set(pos * 2, 0, 0);
    // // let lastPosition = apiPeg1.position;
    // apiPeg1.position.set(pos * 2, 0, 0);
    // peg1?.current.position.set(pos * 2, 0, 0);
    // boxApi.position.set(pos * 2, 0, 0);
    // values[0] = pos * 2;
    // values[1] = 0;
    // values[2] = 0;
    // plinkoRef?.current?.position.set(pos * 2, 0, 0);
  });

  const size = 0.1;

  // useFrame((state) => {
  //   for (let i = 0; i < 1; i++) {
  //     // Get current whereabouts of the instanced sphere
  //     ref2.current.getMatrixAt(i, mat);
  //     // Normalize the position and multiply by a negative force.
  //     // This is enough to drive it towards the center-point.
  //     api2.at(i).applyForce(vec.setFromMatrixPosition(mat).normalize().multiplyScalar(-50).toArray(), [0, 0, 0]);
  //   }
  // });
  return (
    <mesh ref={ref}>
      <mesh castShadow={true} receiveShadow={true}>
        <planeBufferGeometry args={[100, 80]} />
        <meshStandardMaterial color={props.color} side={THREE.DoubleSide} />
      </mesh>
      <ForwardRefPeg ref={pegRef1} />

      <PlinkoChip position={[0.1, 10, 0]} />
      <PlinkoChip position={[0.3, 8, 0]} />

      {/* <mesh ref={peg1} castShadow receiveShadow>
        <cylinderGeometry attach={"geometry"} args={[size, size, 2, 32]} />
        <meshStandardMaterial color={"material"} emissive={0x0} roughness={1} metalness={0} />
      </mesh> */}
      {/* <mesh ref={boxRef}>
        <boxBufferGeometry position={[1, 2, 0]} />
      </mesh> */}
      {/* <PlinkoPeg ref={plinkoRef} /> */}
      {/* 
      <instancedMesh
        ref={ref2}
        castShadow
        receiveShadow
        args={[null, null, 40]}
        geometry={<cylinderGeometry />}
        material={baubleMaterial}
      /> */}

      {/* <PlinkoPeg position={[2, 5, 0]} />

      <PlinkoPeg position={[0.5, 6, 0]} />
      <PlinkoPeg position={[1.5, 6, 0]} />
      <PlinkoPeg position={[2.5, 6, 0]} />

      <PlinkoPeg position={[-0.5, 7, 0]} />
      <PlinkoPeg position={[-1.5, 7, 0]} />
      <PlinkoPeg position={[-2.5, 7, 0]} />

      <PlinkoPeg position={[0.5, 7, 0]} />
      <PlinkoPeg position={[1.5, 7, 0]} />
      <PlinkoPeg position={[2.5, 7, 0]} />

      <PlinkoPeg position={[-0.0, 8, 0]} />
      <PlinkoPeg position={[-1.0, 8, 0]} />
      <PlinkoPeg position={[-2.0, 8, 0]} />

      <PlinkoPeg position={[0.0, 8, 0]} />
      <PlinkoPeg position={[1.0, 8, 0]} />
      <PlinkoPeg position={[2.0, 8, 0]} /> */}
    </mesh>
  );
}

const ForwardRefPeg = forwardRef((props, ref) => {
  const size = 0.1;

  // const [ref, api] = useCylinder(() => ({
  //   args: [size, size, 1, 32],
  //   mass: 0,
  //   rotation: [Math.PI / 2, 0, 0],
  //   type: "Kinematic",

  //   // shape: new CANNON.Cylinder(size, size, 0.3, 32),
  //   ...props,
  // }));

  useCylinder(
    () => ({
      mass: 1,
      type: "Kinematic",
      material: "wheel",
      collisionFilterGroup: 0,
      args: [size, size, 1, 32],
      rotation: [Math.PI / 2, 0, 0],
      ...props,
    }),
    ref
  );

  return (
    <mesh ref={ref} castShadow={true} receiveShadow={true}>
      <cylinderGeometry args={[size, size, 2, 32]} />
      <meshStandardMaterial color={"yellow"} emissive={0x0} roughness={1} metalness={0} />
    </mesh>
  );
});
function PlinkoPeg(props) {
  const size = 0.1;

  const [ref, api] = useCylinder(() => ({
    args: [size, size, 1, 32],
    mass: 0,
    rotation: [Math.PI / 2, 0, 0],
    type: "Kinematic",

    // shape: new CANNON.Cylinder(size, size, 0.3, 32),
    ...props,
  }));
  const cylinderRef = useRef();

  useFrame(({ clock }) => {
    // this.meshes.spheres.forEach((s) => {
    //   s.position.copy(s.body.position);
    //   s.quaternion.copy(s.body.quaternion);
    // });
    // ref.current.position.copy(api.position);
    // ref.current.quaternion.copy(api.quaternion);
    // api.position.set(ref.current.position.x, ref.current.position.y, 0);
    // api.position.set(Math.sin(clock.getElapsedTime()) * 2, 0, 0);
    // api.quaternion.copy(ref.current.quaternion);
    // console.log(ref);
    // console.log(api);
  });
  // useEffect(() => {
  //   cylinderRef?.current?.rotateX(Math.PI / 2);
  // }, [cylinderRef]);

  return (
    <mesh ref={ref} castShadow={true} receiveShadow={true}>
      <cylinderGeometry ref={cylinderRef} args={[size, size, 2, 32]} />
      <meshStandardMaterial color={"yellow"} emissive={0x0} roughness={1} metalness={0} />
    </mesh>
  );
}

function PlinkoChip(props) {
  const size = 0.4;

  const image = useLoader(THREE.TextureLoader, TOM_IMAGE_BASE64);
  // const geometry = new THREE.CylinderGeometry(size, size, 0.1, 32);
  // geometry.rotateX(Math.PI / 2);

  // const mesh = new THREE.Mesh(
  //   geometry,
  //   new THREE.MeshStandardMaterial({
  //     // color: this.colors.cylinder,
  //     emissive: 0x0,
  //     roughness: 1,
  //     metalness: 0,
  //     map: textureEquirec,
  //   })
  // );
  const [ref] = useCylinder(() => ({
    args: [size, size, 0.1, 32],
    rotation: [Math.PI / 2, 0, 0],
    mass: 1,
    position: [0, 3, -0.5],
    ...props,
  }));
  const cylinderRef = useRef();

  // useEffect(() => {
  //   cylinderRef?.current?.rotateX(Math.PI / 2);
  // }, [cylinderRef]);
  // geometry.rotateX(Math.PI / 2);
  return (
    <mesh ref={ref}>
      <cylinderGeometry ref={cylinderRef} args={[size, size, 0.2, 32]} />
      <meshStandardMaterial color={"red"} emissive={0x0} roughness={1} metalness={0} map={image} />
    </mesh>
  );
}
function Cube(props) {
  const [ref] = useBox(() => ({ mass: 1, ...props }));
  return (
    <mesh ref={ref}>
      <boxGeometry />
      <meshStandardMaterial color="red" />
    </mesh>
  );
}

function Sphere(props) {
  const [ref] = useSphere(() => ({ mass: 1, ...props }));
  return (
    <mesh ref={ref}>
      <sphereGeometry />
      <meshStandardMaterial color="blue" />
    </mesh>
  );
}

function Plane(props) {
  const [ref] = usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0], ...props }));
  return (
    <mesh ref={ref}>
      <planeGeometry args={[100, 100]} />
    </mesh>
  );
}

function Peg(props) {
  const backwallXRotation = useFallingDownState((state) => state.backwallXRotation);
  const setBackwallXRotation = useFallingDownState((state) => state.setBackwallXRotation);

  const size = 0.1;

  const [ref, api] = useCylinder(() => ({
    args: [size, size, 1, 32],
    // mass: 0,
    // rotation: [Math.PI / 2, 0, 0],
    type: "Static",

    // shape: new CANNON.Cylinder(size, size, 0.3, 32),
    ...props,
  }));

  const cylinderRef = useRef();

  // useEffect(() => {
  //   api.rotation.set(backwallXRotation + Math.PI / 2, 0, 0);
  //   // ref?.current?.rotation.set(backwallXRotation, 0, 0);
  // }, [backwallXRotation]);

  // useEffect(() => {
  //   cylinderRef?.current?.rotateX(Math.PI / 2);
  // }, [cylinderRef]);

  useFrame((clock) => {
    // api.position.set(ref.current.position.x, ref.current.position.y, ref.current.position.z);
    // api.quaternion.set(ref.current.quaternion.clone());
    // ref.current.position.copy(api.position);
    // ref.current.quaternion.copy(api.quaternion);
  });
  return (
    <group>
      <mesh ref={ref} castShadow={true} receiveShadow={true}>
        {/* <cylinderGeometry ref={cylinderRef} args={[size, size, 1, 32]} /> */}
        <meshStandardMaterial color={"yellow"} emissive={0x0} roughness={1} metalness={0} />
      </mesh>
      <mesh castShadow={true} receiveShadow={true} position={props.position}>
        <cylinderGeometry ref={cylinderRef} args={[size, size, 1, 32]} />
        <meshStandardMaterial color={"red"} emissive={0x0} roughness={1} metalness={0} />
      </mesh>
    </group>
  );
}

function BoxPeg(props) {
  const [ref] = useBox(() => ({ mass: 0, type: "Static", ...props }));
  const geoRef = useRef();

  useEffect(() => {
    geoRef?.current?.rotateZ(Math.PI / 2);
  }, [geoRef]);
  return (
    <mesh ref={ref}>
      <boxGeometry {...props} ref={geoRef} />
      <meshStandardMaterial color="yellow" />
    </mesh>
  );
}

function Backwall2(props) {
  const backwallXRotation = useFallingDownState((state) => state.backwallXRotation);
  const setBackwallXRotation = useFallingDownState((state) => state.setBackwallXRotation);
  const { backwallRotation } = useControls({ backwallRotation: backwallXRotation });

  const [ref, api] = usePlane(() => ({
    args: [10, 80],
    mass: 0,
    // rotation: [0, 0.0, 0],
    // rotation: [Math.PI / 2, 0, 0],
    // shape: new CANNON.Cylinder(size, size, 0.3, 32),
    ...props,
  }));

  useEffect(() => {
    setBackwallXRotation(backwallRotation);
    api.rotation.set(backwallXRotation, 0, 0);
  }, [backwallRotation]);

  return (
    <group ref={ref}>
      <mesh castShadow={true} receiveShadow={true}>
        <planeBufferGeometry args={[100, 80]} />
        <meshStandardMaterial color={props.color} side={THREE.DoubleSide} />
      </mesh>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <BoxPeg position={[0, 0.5, 0]} args={[0.1, 0.1, 0.1]} />
        <BoxPeg position={[2, 0.5, 0]} />
        <BoxPeg position={[4, 0.5, 0]} />
        <BoxPeg position={[6, 0.5, 0]} />
        <Peg position={[-2, 0, 0]} />
      </group>

      {/* <Peg position={[-3, 0, 0]} />
      <Peg position={[-2, 0, 0]} />
      <Peg position={[-1, 0, 0]} />
      <Peg position={[2, 0, 0]} />
      <Peg position={[3, 0, 0]} />
      <Peg position={[4, 0, 0]} />

      <Peg position={[-3, 1, 0]} />
      <Peg position={[-2, 1, 0]} />
      <Peg position={[-1, 1, 0]} />
      <Peg position={[2, 1, 0]} />
      <Peg position={[3, 1, 0]} />
      <Peg position={[4, 1, 0]} />

      <Peg position={[-3, 2, 0]} />
      <Peg position={[-2, 2, 0]} />
      <Peg position={[-1, 2, 0]} />
      <Peg position={[2, 2, 0]} />
      <Peg position={[3, 2, 0]} />
      <Peg position={[4, 2, 0]} /> */}
    </group>
  );
}

function Plane2(props) {
  const [ref] = usePlane(() => ({ type: "Static", ...props }));
  return (
    <mesh receiveShadow ref={ref}>
      <planeGeometry args={[8, 8]} />
      <meshStandardMaterial color="#ffb385" />
    </mesh>
  );
}

function Ground(props) {
  const [ref] = usePlane(() => ({ type: "Static", ...props }));
  return (
    <mesh receiveShadow ref={ref}>
      <planeGeometry args={[8, 8]} />
      <meshStandardMaterial color="#ffb385" />
    </mesh>
  );
}

function FallingDownInner3() {
  const players = useFallingDownState((state) => state.players);
  const addPlayer = useFallingDownState((state) => state.addPlayer);

  const backwallXRotation = useFallingDownState((state) => state.backwallXRotation);

  console.log("BACKWALLX", backwallXRotation);
  useEffect(() => {
    addPlayer({ xPosition: 0, yPosition: 1, zPosition: -1 });
    addPlayer({ xPosition: 2, yPosition: 2, zPosition: 0 });
    addPlayer({ xPosition: 4, yPosition: 3, zPosition: 0 });
    addPlayer({ xPosition: 6, yPosition: 4, zPosition: 0 });
  }, []);

  return (
    <group>
      {players.map((player, index) => {
        return <Sphere key={index} position={[player.xPosition, player.yPosition, player.zPosition]} />;
      })}
      <Backwall2 color={"green"} position={[0, 0, -1]} />
    </group>
  );
}

export function FallingDown({ room, client }: { room: Room<TomsState>; client: Client }) {
  return (
    <div>
      <Leva isRoot={true} />
      <Canvas
        shadows={true}
        // legacy={false}
        gl={(canvas) => new THREE.WebGLRenderer({ canvas, antialias: true })}
        //   camera={(camera) => {
        //     camera.position.set(0, 10, 50);
        //     return new THREE.PerspectiveCamera(200, window.innerWidth / window.innerHeight, 1, 1000);
        //   }      }
        // camera={{ position: [0, 0, 50], fov: 20, far: 1000 }}>
        camera={{ position: [-2, 1, 7], fov: 50 }}>
        <OrbitControls
          minPolarAngle={radians(30)}
          maxPolarAngle={radians(90)}
          minAzimuthAngle={radians(-40)}
          maxAzimuthAngle={radians(40)}
          enableDamping={true}
          dampingFactor={0.02}
        />
        <axesHelper args={[2, 2, 2]} position={[0, 1, 0]} />
        <ambientLight args={[{ color: "#ffffff" }, 0.5]} />
        <Suspense fallback={null}>
          <Physics>
            <Debug color="black" scale={1.1}>
              <Ground rotation={[-Math.PI / 2, 0, 0]} />
              <FallingDownInner3 />
              {/* <Cube position={[0, 10, 0]} /> */}
              {/* <BackWall position={[0, 0, -0.4]} color={"green"} /> */}

              {/* <PlinkoPeg /> */}

              {/* <Plane /> */}
              {/* <Mouse /> */}
              {/* <FallingDownInner2 client={client} room={room} /> */}
            </Debug>
          </Physics>
        </Suspense>
        {/* <ABox onClick={() => {}} /> */}
      </Canvas>
    </div>
  );
}
