import React, { useEffect, useRef, useState } from "react";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { useColyseusRoom, useColyseusRoom2 } from "../../hooks/useColyseusRoom";
import { AIExperienceState } from "../../../state/AIExperienceState";
import { Room } from "colyseus.js";
import { useCallingStore } from "./useCallingStore";
import { useSpeechRecognitionStore } from "./useSpeechRecognitionStore";
import { useChatUIStore } from "./useChatUIStore";
import { meeting, teamsCore } from "@microsoft/teams-js";
import { generateUuid } from "@azure/core-http";
import { TypingIndicator } from "./TypingIndicator";
import { Lightbulb } from "./Lightbulb";
import ReactMarkdown from "react-markdown";
import emoji from "remark-emoji";
import { AddToPromptBuilder } from "./PromptBuilder/AddToPromptBuilder";
import { BuiltInChatGPTPrompts, BuiltInPrompts } from "./Prompts";
import { Alerts, useAlertsStore } from "../Alerts/Alerts";
import { useTeamsCaptionsStore } from "./useTeamsCaptionsStore";
import { getOnBehalfOfTokenSP } from "../../Helpers";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { VRMThing } from "./VRMThing";
import {
  formatSearchResults,
  ISearchResult,
} from "../../utility/SPSearchHelper";

import shallow from "zustand/shallow";
import {
  getVoices,
  streamResponse,
  textToSpeechReadableStream,
  textToSpeechStream,
  textToSpeechStream2,
} from "./ElevenSpeech";
import { LocalAudioStream, LocalVideoStream } from "@azure/communication-calling";
import {
  PushAudioInputStream,
  PushAudioOutputStream,
} from "microsoft-cognitiveservices-speech-sdk";
import { useHotkeys } from "react-hotkeys-hook";
import { FabricJSDisplay } from "./FabricJSDisplay";
import { useFabricJSDisplayStore } from "./useFabricJSDisplayStore";
import { speakTheText } from "./AzureCognitiveSpeech";
import { FabricJSVideoBuilder } from "../../experiences/Video/FabricJSVideoBuilder";
import { useMediapipeStore } from "../Mediapipe/useMediapipeStore";
import { VRMThing } from "./VRMThing";
import { MeetingRPGDynamicWrapper } from "../../experiences/MeetingRPG/src/MeetingRPGDynamicWrapper";
import { AvatarThing } from "../../experiences/Playground/Playground";

export function ACSConnectionHolder({
  room,
}: {
  room: Room<AIExperienceState>;
}) {
  const [joinUrl, setJoinUrl] = useState("");
  const [showVideoControls, setShowVideoControls] = useState(false);
  const [enableRemoteVideHandTracking, setEnableRemoteVideHandTracking] = useState(false);
  const [useVRMAvatar, setUseVRMAvatar] = useState(false);
  const [enableAudioReactions, setEnableAudioReactions] = useState(false);
  const [showSpeechControls, setShowSpeechControls] = useState(false);
  const [useThreeJSAvatar, setUseThreeJSAvatar] = useState(false);
  const [isPhoneCall, setIsPhoneCall] = useState(false);
  const [numberOfConversationTurnsToUse, setNumberOfConversationTurnsToUse] = useState(20);

  const [shouldLiveQueryAI, setShouldLiveQueryAI] = useState(false);
  const [shouldSendKeyConceptsRequests, setShouldSendKeyConceptsRequests] = useState(false);

  const name = "Jess-E";
  const callingApi = useCallingStore((state) => state.api);
  const call = useCallingStore((state) => state.call);
  const callIsConnected = useCallingStore((state) => state.callIsConnected);
  const availableMicrophones = useCallingStore((state) => state.availableMicrophones);
  const selectedMicrophone = useCallingStore((state) => state.selectedMicrophone);

const localVideoRef = useRef<LocalVideoStream>();
  const audioContext = useCallingStore((state) => state.audioContext);
  const mediaDestinationNode = useCallingStore(
    (state) => state.mediaDestinationNode
  );

  const speechRecognitionApi = useSpeechRecognitionStore((state) => state.api);
  const lastSpeechResult = useSpeechRecognitionStore(
    (state) => state.lastResult
  );
  const allSpeechResults = useSpeechRecognitionStore((state) => state.results);

  const continuousRecognitionInProgress = useSpeechRecognitionStore(
    (state) => state.continuousRecognitionInProgress
  );
  const cognitiveContext = useSpeechRecognitionStore(
    (state) => state.cognitiveContext
  );

  const chatUIApi = useChatUIStore((state) => state.api);
  const promptText = useChatUIStore((state) => state.promptText);
  const usePromptChaining = useChatUIStore((state) => state.usePromptChaining);
  const latestSuggestion = useChatUIStore((state) => state.latestSuggestion);

  const [textInput, setTextInput] = useState("");
  const [autoRespond, setAutoRespond] = useState(false);
  const [recognizeIncomingSpeech, setRecognizeIncomingSpeech] = useState(false);
  const [voiceName, setVoiceName] = useState("en-US-JennyNeural");

  const fabricJSStoreApi = useFabricJSDisplayStore((state) => state.api);
    const mediaPipeStoreApi = useMediapipeStore(state => state.api);

    const listRef = useRef(null);
    const teamsCaptionsApi = useTeamsCaptionsStore((state) => state.api);
    const results = useTeamsCaptionsStore((state) => state.results);

  const startAudioFromArrayBuffer = async (arrayBuffer, call) => {
    if (!audioContext) return;
    let audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const mediaDestinationNode = audioContext?.createMediaStreamDestination();
    const source = new AudioBufferSourceNode(audioContext, {
      buffer: audioBuffer,
    });
    source.connect(mediaDestinationNode);
    source.start();
    const localAudioStream = new LocalAudioStream(mediaDestinationNode.stream);
    await call.unmute();
    call.startAudio(localAudioStream);
  };

const preInit = async () => {
  await callingApi.initCallClient();
  await callingApi.initCallAgent(name);
  await callingApi.loadAvailableCamerasAndMicrophones();

}
  const init = async (teamsJoinUrl) => {
    await callingApi.initCallClient();
    await callingApi.initCallAgent(name);
    await callingApi.joinCall(teamsJoinUrl);
  };

  const initWithPhoneNumber = async (phoneNumber, selectedMicrophone) => {
    // await callingApi.initCallClient();
    // await callingApi.initCallAgent(name);
    debugger;
    await callingApi.startCallWithPhoneNumber(phoneNumber, selectedMicrophone);
  };

  const muteUnmuteAllAsync = async () => {
    await callingApi.unmuteIncomingAudio();
    await callingApi.unmuteOutgoingAudio();
    await callingApi.muteIncomingAudio();
    await callingApi.muteOutgoingAudio();
  };
  useEffect(() => {
    if (callIsConnected && call) {
      callingApi.muteOutgoingAudio();
      callingApi.muteIncomingAudio();
    }
  }, [callIsConnected, call]);




  // useEffect(() => {
  //   if (!lastSpeechResult) return;
  //   if (!shouldLiveQueryAI) return;

  //   if (!usePromptChaining) {
  //     room.send("speech", {
  //       text: lastSpeechResult,
  //       lastSpeechResult: lastSpeechResult,
  //       id: generateUuid(),
  //       prompt: promptText,
  //       max_tokens: 500,
  //     });
  //   } else {
  //     room.send("speech", {
  //       text: allSpeechResults.join(" \n"),
  //       lastSpeechResult: lastSpeechResult,
  //       id: generateUuid(),
  //       prompt: promptText,
  //       max_tokens: 500,
  //     });
  //   }
  // }, [lastSpeechResult, shouldLiveQueryAI]);

  useEffect(() => {
    if (!call) return;
    if (!callIsConnected) return;
    if (isPhoneCall) return;

    teamsCaptionsApi.startTeamsCaptionsFeature(call);
  }, [call, callIsConnected, isPhoneCall]);

  useEffect(() => {
    if (!call) return;
    if (!callIsConnected) return;
    if (!isPhoneCall) return;
    
    call.unmuteIncomingAudio().then(() => {
      console.log("unmuted incoming audio")

    })
    call.unmute().then(() => {
      console.log("unmute outgoing")
    })
  }, [call, callIsConnected, isPhoneCall]);

  useEffect(() => {
    if (!results || results.length === 0) return;
    console.log(results[results.length - 1])
    const latestResult = results[results.length -1];
    const payload = { text: latestResult.text, displayName: latestResult.speaker.displayName, id: generateUuid(), shouldLiveQueryAI: shouldLiveQueryAI }
    room.send("transcriptionResult", payload);
  }, [results])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inTeams = params.get("inTeams");

    const phoneNumber = params.get("phone");

    if (!phoneNumber){
    let jesseOneOnOne = `https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDZkM2NiMjMtM2EzNi00ZjFiLWE0NjgtMzVhYTMxZDc2N2Ni%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%223cd1dc6c-9da4-4a2e-92e1-ea2dfcba14b7%22%7d`
    let tomJoin = "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YWY5MGY3ZjctOTY4ZC00NmI1LTkxOTEtNzljMmMyY2Q1MzJi%40thread.v2/0?context=%7b%22Tid%22%3a%225fbbce2a-c3e6-4b5e-a51f-222674fdb44d%22%2c%22Oid%22%3a%220786a28b-fd76-429c-8a34-df0ae9d35165%22%7d";
    if (!inTeams) {
      setJoinUrl(tomJoin);
      init(tomJoin);
    } else {
      meeting.getMeetingDetails((err, details) => {
        console.log("DETAILS", details, err);
        if (!err) {
          setJoinUrl(details?.details.joinUrl || "");
          init(details?.details.joinUrl);
          //   setThreadId(details?.conversation.id || "");
          meeting.appShareButton.setOptions({ isVisible: false });
        }
      });
    }
  }
  else {
    if (!selectedMicrophone) return;
    
    console.log(`Calling with phone number: ${phoneNumber}`);
    setIsPhoneCall(true);
    navigator.mediaDevices.enumerateDevices()
.then(function(devices) {
  devices.forEach(function(device) {
    console.log(device.kind + ": " + device.label +
            " id = " + device.deviceId);

  });
})

    initWithPhoneNumber(phoneNumber, selectedMicrophone);
  }
  }, [selectedMicrophone]);

  // useEffect(() => {
  //   preInit();
  // }, [])
  useEffect(() => {
    console.log("SAYSPEECHREQUEST", audioContext);
    if (!room || !audioContext || !call || !cognitiveContext) return;

    let listener = room.onMessage("saySpeechRequest", async (message) => {
      if (!audioContext) return;
      // let voiceName = "en-US-JennyNeural";
      let prosodyRate = "0";
      if (voiceName !== "Tom" && voiceName !== "Jesse") {
        let modifiedSSMLContent = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="${voiceName}"><mstts:express-as style="hopeful" ><prosody rate="${prosodyRate}%" pitch="0%">${message.text}</prosody></mstts:express-as></voice></speak>`;

        callingApi.unmuteOutgoingAudio();
        speakTheText(
          modifiedSSMLContent,
          cognitiveContext,
          audioContext,
          call
        ).then((bufferNode) => {
          bufferNode.source.start();
        });
      } else {
        let voiceId =  "QyeUySDFWWm34mYadxAo"
        if (voiceName === "Jesse") voiceId = "TzRCJkJTIMeahYO4qInh";
        let ab = await textToSpeechStream(message.text, voiceId);
        await startAudioFromArrayBuffer(ab, call);
      }
    });
    return () => listener();
  }, [room, audioContext, call, cognitiveContext, voiceName]);

  useEffect(() => {
    if (!room || !call) return;

    let listener = room.onMessage("askBotSingleRequest", async (message) => {
      await speechRecognitionApi.startRemoteSpeechRecognition(call);
      fabricJSStoreApi.setText("listening...");
    });
    return () => listener();
  }, [room, call]);

  useEffect(() => {
    if (!room || !call) return;

    let listener = room.onMessage("handRaisedSeen", async (message) => {
      
    });
    return () => listener();
  }, [room, call]);

  useEffect(() => {
    if (!room || !call) return;

    let listener = room.onMessage(
      "stopSpeechRecognitionRequest",
      async (message) => {
        await speechRecognitionApi.stopRemoteSpeechRecognition();
        fabricJSStoreApi.setText("waiting...");
      }
    );
    return () => listener();
  }, [room, call]);

  useEffect(() => {
    if (!room || !call || !callIsConnected) return;
    if (!isPhoneCall) return;
    setTimeout(() => {
      muteUnmuteAllAsync();
    }, 1000);
  }, [room, call, callIsConnected, isPhoneCall]);

  useEffect(() => {
    if (!latestSuggestion) return;
    if (autoRespond) {
      room.send("saySpeech", { text: latestSuggestion });
    }
  }, [latestSuggestion, autoRespond]);

  useEffect(() => {
    if (!latestSuggestion) return;
    fabricJSStoreApi.setText(latestSuggestion);
    fabricJSStoreApi.setQuestionText(lastSpeechResult);
  }, [latestSuggestion, lastSpeechResult]);

  useEffect(() => {
    if (!call) return;
    if (!callIsConnected) return;

    if (!enableRemoteVideHandTracking) return;

     mediaPipeStoreApi.setCall(call);
     mediaPipeStoreApi.setupHandTracking();
    
  }, [call, callIsConnected, enableRemoteVideHandTracking])

  

  useEffect(() => {
    if (!useVRMAvatar) return;

    let canvasElement = document.getElementById("vrmAvatar") as HTMLCanvasElement;

    const stream = canvasElement?.captureStream(30);
    const localVideoStream = new LocalVideoStream(stream);
    call?.startVideo(localVideoStream);
    localVideoRef.current = localVideoStream;
  
  }, [useVRMAvatar])

  useEffect(() => {
    if (!room || !call || !enableAudioReactions) return;

    let listener = room.onMessage("soundEffect", async (message) => {
      let url = "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Ask%20You%20a%20Bunch%20of%20Questions.mp3?sv=2019-12-12&st=2022-09-22T13%3A57%3A11Z&se=2103-03-23T13%3A57%3A00Z&sr=b&sp=r&sig=5povtvuXxADS4R4UJc106%2B%2FUswS7Z4lPbqjFJdZBtQ4%3D"
      switch (message.name){
        case "Ask You a Bunch of Questions":
          url = "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Ask%20You%20a%20Bunch%20of%20Questions.mp3?sv=2019-12-12&st=2022-09-22T13%3A57%3A11Z&se=2103-03-23T13%3A57%3A00Z&sr=b&sp=r&sig=5povtvuXxADS4R4UJc106%2B%2FUswS7Z4lPbqjFJdZBtQ4%3D";
          break;
        case "Thank You":
          url = "https://sparkprovisioningstor.blob.core.windows.net/soundboard/SHARED/Thank%20You.mp3?sv=2019-12-12&st=2022-09-22T19%3A01%3A39Z&se=2092-09-23T19%3A01%3A00Z&sr=b&sp=r&sig=dtF8PnWG0TYNo9tNjV62XUXrYBjqL%2F%2BaDCBnLji5U3g%3D"
          break;
        case "Hello":
          url = "https://sparkprovisioningstor.blob.core.windows.net/soundboard/0786a28b-fd76-429c-8a34-df0ae9d35165/Hello.mov?sv=2021-10-04&st=2023-05-01T17%3A26%3A26Z&se=2100-05-02T17%3A26%3A00Z&sr=b&sp=r&sig=3SqyCZq4Ai9Q5Gl5vxmReGY9%2BDQ%2FQsQTZlFuUrMGgCo%3D";
          break;
        default:
          break;
      }
      console.log(url)
      const data = await callingApi.getAudioTrack(url)
      const localAudioStream = new LocalAudioStream(data.stream);
      if (call.isMuted){
    await call.unmute();
      }
    call.startAudio(localAudioStream);
    data.source.start();
    });
    return () => listener();
  }, [room, call, enableAudioReactions]);

  // useEffect(() => {
  //   if (!room || !call) return;

  //   let interval = setInterval(() => {
  //     console.log("SENDING MATCHING CONVERSATION REQUEST");
  //     room.send("lookForMatchingConversations", {});
  //   }, 30000)
    
  //   let listener = room.onMessage(
  //     "matchingConversationResult",
  //     async (message) => {
  //       console.log("RECEIVED MATCHING CONVERSATION RESULT", message)
  //     }
  //   );
  //   return () =>  {   
  //     clearInterval(interval);
  //     listener();
  //   };
  // }, [room, call]);

  useEffect(() => {
    if (!room || !call || !shouldSendKeyConceptsRequests) return;

    let interval = setInterval(() => {
   
      room.send("lookForMeetingKeyConcepts", {});
    }, 30000)
    
    let listener = room.onMessage(
      "meetingKeyConceptsResults",
      async (message) => {
        console.log("RECEIVED meetingKeyConceptsResults", message)
        let guid = generateUuid();
        chatUIApi.addResponse(
          "Identified Key Concepts",
          guid,
          "Key Concepts"
        );
        chatUIApi.addSuggestionsToResponse([{ text: message.text, id: "1"}], guid)
      }
    );
    return () =>  {   
      clearInterval(interval);
      listener();
    };
  }, [room, call, shouldSendKeyConceptsRequests]);

  useEffect(() => {
    if (useThreeJSAvatar){
      setTimeout(() => {
        let canvasElement = document.getElementById("avatarCanvas").children[0] as HTMLCanvasElement;

        const stream = canvasElement?.captureStream(30);
        const localVideoStream = new LocalVideoStream(stream);
        call?.startVideo(localVideoStream)
        localVideoRef.current = localVideoStream;
      }, 500)
   
    }
    return () => {
      call?.stopVideo(localVideoRef.current)
    }
  }, [useThreeJSAvatar])

  useEffect(() => {
    if (call && callIsConnected) {
      const silentAudioUrl = process.env.REACT_APP_SILENT_AUDIO_URL;

      if (!silentAudioUrl) throw new Error("Missing setting for REACT_APP_SILENT_AUDIO_URL");
      callingApi.sendAudioTrack2(silentAudioUrl);
    }
  }, [call, callIsConnected]);
  // useEffect(() => {
  //   if (!call || !callIsConnected) return;
  //   let canvasElement = document.getElementById("textCanvas") as HTMLCanvasElement;

  //   const stream = canvasElement?.captureStream(30);
  //   const localVideoStream = new LocalVideoStream(stream);  
  //   call.startVideo(localVideoStream);
  //   localVideoRef.current = localVideoStream;
  // }, [call, callIsConnected])
  // useEffect(() => {
  //   getVoices().then((voices) => {
  //     console.log("VOICES", voices);
  //     let voice = voices.voices[0].voice_id;
  //     textToSpeechStream("Tom was here", voice).then((speechStream) => {
  //       console.log("SPEECH STREAM", speechStream)
  //     })
  //   })
  // }, [])

  return (
    <div className={"p-2"}>
      
      <input
        className={""}
        onChange={(e) => {
          setTextInput(e.target.value);
        }}
      ></input>
      <div
        className={"btn btn-xs btn-primary"}
        onClick={async () => {
          if (!audioContext) return;

          let prosodyRate = "0";
          if (voiceName !== "Tom" && voiceName !== "Jesse") {
            let modifiedSSMLContent = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US"><voice name="${voiceName}"><mstts:express-as style="hopeful" ><prosody rate="${prosodyRate}%" pitch="0%">${textInput}</prosody></mstts:express-as></voice></speak>`;
    
            callingApi.unmuteOutgoingAudio();
            speakTheText(
              modifiedSSMLContent,
              cognitiveContext,
              audioContext,
              call
            ).then((bufferNode) => {
              bufferNode.source.start();
            });
          } else {
            let voiceId =  "QyeUySDFWWm34mYadxAo"
            if (voiceName === "Jesse") voiceId = "TzRCJkJTIMeahYO4qInh";
            let ab = await textToSpeechStream(textInput, voiceId);

            await startAudioFromArrayBuffer(ab, call);
            // const mediaDestinationNode = audioContext?.createMediaStreamDestination();
            // let better = await textToSpeechReadableStream(textInput, voiceId, audioContext, call, mediaDestinationNode)

          }

    
          //   .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
          // .then(async (audioBuffer) => {
          //   const mediaDestinationNode = audioContext?.createMediaStreamDestination();
          //   const source = new AudioBufferSourceNode(audioContext, { buffer: audioBuffer });
          //   source.connect(mediaDestinationNode);
          //   source.start();
          //   let track = mediaDestinationNode.stream.getAudioTracks()[0];
          //   const localAudioStream = new LocalAudioStream(mediaDestinationNode.stream);
          //   await call?.unmute();
          //   call?.startAudio(localAudioStream)
          // })
        }}
      >
        Speech
      </div>
      {showVideoControls && <>
      <div
        className={"btn btn-xs btn-primary"}
        onClick={async () => {
          let canvasElement = document.getElementById("textCanvas") as HTMLCanvasElement;

          const stream = canvasElement?.captureStream(30);
          const localVideoStream = new LocalVideoStream(stream);
          await call?.startVideo(localVideoStream);
          localVideoRef.current = localVideoStream;
        }}
      >
        Start Video
      </div>
      <div
        className={"btn btn-xs btn-primary"}
        onClick={async () => {
         
          await call?.stopVideo(localVideoRef.current);
        }}
      >
        Stop Video
      </div>
     

     
      {/* <FabricJSDisplay /> */}

      <FabricJSVideoBuilder id={"test"} width={300} height={200} userId={"111"} />
      </>
}

<div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Show Speech Controls</span>
          <input
            type="checkbox"
            className="toggle"
            checked={showSpeechControls}
            onChange={async (e) => {
              if (e.target.checked) {
                setShowSpeechControls(true);
              } else {
                setShowSpeechControls(false);
              }
            }}
          />
        </label>
      </div>

      {showSpeechControls && <div>
        <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">Select Voice</span>
        </label>
        <select
          className="select select-bordered"
          onChange={(e) => {
            setVoiceName(e.target.value);
          }}
        >
          <option value="en-US-JennyNeural">Jenny (Neural)</option>
          <option value="en-US-JennyMultilingualNeural">
            Jenny Multilingual (Neural)
          </option>
          <option value="en-US-GuyNeural">Guy (Neural)</option>
          <option value="en-US-AmberNeural">Amber (Neural)</option>
          <option value="en-US-AnaNeural">Ana (Neural)</option>
          <option value="en-US-AriaNeural">Aria (Neural)</option>
          <option value="en-US-AshleyNeural">Ashley (Neural)</option>
          <option value="en-US-BrandonNeural">Brandon (Neural)</option>
          <option value="en-US-ChristopherNeural">Christopher (Neural)</option>
          <option value="en-US-CoraNeural">Cora (Neural)</option>
          <option value="en-US-DavisNeural">Davis (Neural)</option>
          <option value="en-US-ElizabethNeural">Elizabeth (Neural)</option>
          <option value="en-US-EricNeural">Eric (Neural)</option>
          <option value="en-US-JacobNeural">Jacob (Neural)</option>
          <option value="en-US-JaneNeural">Jane (Neural)</option>
          <option value="en-US-JasonNeural">Jason (Neural)</option>
          <option value="en-US-MichelleNeural">Michelle (Neural)</option>
          <option value="en-US-MonicaNeural">Monica (Neural)</option>
          <option value="en-US-NancyNeural">Nancy (Neural)</option>
          <option value="en-US-SaraNeural">Sara (Neural)</option>
          <option value="en-US-TonyNeural">Tony (Neural)</option>
          <option value="en-US-AIGenerate1Neural">
            AIGenerate1 (Neural) - Preview
          </option>
          <option value="en-US-AIGenerate2Neural">
            AIGenerate2 (Neural) - Preview
          </option>
          <option value="en-US-RogerNeural">Roger (Neural) - Preview</option>
          <option value="en-US-SteffanNeural">
            Steffan (Neural) - Preview
          </option>
          <option value="Tom">Tom</option>
          <option value="Jesse">Jesse</option>
        </select>
       
      </div>
      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Autorespond</span>
          <input
            type="checkbox"
            className="toggle"
            checked={autoRespond}
            onChange={(e) => {
              if (e.target.checked) {
                setAutoRespond(true);
              } else {
                setAutoRespond(false);
              }
            }}
          />
        </label>
      </div>

        </div>}
<div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Use VRM Avatar</span>
          <input
            type="checkbox"
            className="toggle"
            checked={useVRMAvatar}
            onChange={async (e) => {
              if (e.target.checked) {
                setUseVRMAvatar(true);
              } else {
                setUseVRMAvatar(false);
              }
            }}
          />
        </label>
      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Use Three JS Avatar</span>
          <input
            type="checkbox"
            className="toggle"
            checked={useThreeJSAvatar}
            onChange={async (e) => {
              if (e.target.checked) {
                setUseThreeJSAvatar(true);
              } else {
                setUseThreeJSAvatar(false);
              }
            }}
          />
        </label>
      </div>

{useThreeJSAvatar &&       <AvatarThing avatarUrl={"https://d1a370nemizbjq.cloudfront.net/0d925d4e-e32f-4572-b531-327589f052e7.glb"} room={room} />
}

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Enable AI Live Query</span>
          <input
            type="checkbox"
            className="toggle"
            checked={shouldLiveQueryAI}
            onChange={async (e) => {
              if (e.target.checked) {
                setShouldLiveQueryAI(true);
              } else {
                setShouldLiveQueryAI(false);
              }
            }}
          />
        </label>

        <input
        onChange={(e) => {
          // soundboardApi.setVolumeLevel(e.target.valueAsNumber);
          room.send("setNumberOfConversationTurnsToUse", { numberOfConversationTurnsToUse: e.target.valueAsNumber });
          setNumberOfConversationTurnsToUse(e.target.valueAsNumber)
        }}
        type="range"
        min="1"
        max="50"
        value={numberOfConversationTurnsToUse}
        className="range range-primary range-xs"
        step={1}
      />

      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Enable Meeting Key Concepts AI</span>
          <input
            type="checkbox"
            className="toggle"
            checked={shouldSendKeyConceptsRequests}
            onChange={async (e) => {
              if (e.target.checked) {
                setShouldSendKeyConceptsRequests(true);
              } else {
                setShouldSendKeyConceptsRequests(false);
              }
            }}
          />
        </label>
      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Enable Hand Tracking</span>
          <input
            type="checkbox"
            className="toggle"
            checked={enableRemoteVideHandTracking}
            onChange={async (e) => {
              if (e.target.checked) {
                setEnableRemoteVideHandTracking(true);
              } else {
                setEnableRemoteVideHandTracking(false);
              }
            }}
          />
        </label>
      </div>

      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"handRaised"} gestureName={"Open_Palm"} room={room} />}
      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"thumbUp"} gestureName={"Thumb_Up"} room={room} />}
      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"thumbDown"} gestureName={"Thumb_Down"} room={room} />}
      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"pointingUp"} gestureName={"Pointing_Up"} room={room} />}
      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"victory"} gestureName={"Victory"} room={room} />}
      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"closedFist"} gestureName={"Closed_Fist"} room={room} />}
      {enableRemoteVideHandTracking && <GestureRecognizer eventName={"iLoveYou"} gestureName={"ILoveYou"} room={room} />}

      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Enable Audio Reactions</span>
          <input
            type="checkbox"
            className="toggle"
            checked={enableAudioReactions}
            onChange={async (e) => {
              if (e.target.checked) {
                setEnableAudioReactions(true);
              } else {
                setEnableAudioReactions(false);
              }
            }}
          />
        </label>
      </div>

 
      {/* <div className={"btn btn-xs btn-primary"} onClick={async () => {
        if (!audioContext || !mediaDestinationNode ||!call) return;
        let ab = await textToSpeechStream2(textInput, "QyeUySDFWWm34mYadxAo");
        let audioElement: HTMLVideoElement = document.getElementById("audio1") as HTMLVideoElement;
        // audioElement.muted = true;
        // const source = new AudioBufferSourceNode(audioContext);
        // source.connect(mediaDestinationNode);
        // source.start();
      
      //   audioElement.onplay = function() {
      //     // Set the source of one <video> element to be a stream from another.
      //     var stream = audioElement.captureStream();
      //     rightVideo.srcObject = stream;
      // };
      let stream = audioElement.captureStream();
      let las = new LocalAudioStream(stream);
      if (call.isMuted){
        await call?.unmute();
          }
          await call?.startAudio(las)

        await streamResponse(ab, audioElement,  async () => {
          console.log("on content start");

   
        }, (async (chunk) => {
          let audioData = await audioContext.decodeAudioData(chunk);
          let newDest = await audioContext.createMediaStreamDestination();
          const source = new AudioBufferSourceNode(audioContext, { buffer: audioData });
          // audioContext.createBufferSource()
          source.connect(mediaDestinationNode);
          source.start();
          source.onended = () => {
            return Promise.resolve();
          }
          
        }) )

        // let stream = audioElement.captureStream();
        // let origAudioTrack = stream.getAudioTracks()[0];
        // console.log(origAudioTrack)
        // let ms = new MediaStream([origAudioTrack]);
        // console.log(ms);
        // let las = new LocalAudioStream(ms);
        // await call?.unmute();
        // await call?.startAudio(las)
        // let ab = await textToSpeechReadableStream(textInput, "QyeUySDFWWm34mYadxAo", audioContext, call, mediaDestinationNode );
        // if (call?.isMuted){
        // await call?.unmute()
        // let las = new LocalAudioStream(mediaDestinationNode?.stream);
        // await call?.startAudio(las);
        // }
      }}>Speech Readable Stream</div> */}
      {/* {!continuousRecognitionInProgress && (
        <div
          className={"btn btn-xs btn-primary"}
          onClick={async () => {
            if (!call) return;
            callingApi.unmuteIncomingAudio();
            callingApi.unmuteOutgoingAudio();
            await speechRecognitionApi.startRemoteSpeechRecognition(call);
            callingApi.muteIncomingAudio();
            callingApi.muteOutgoingAudio();
          }}
        >
          Start
        </div>
      )}
      {continuousRecognitionInProgress && (
        <div
          className={"btn btn-xs btn-primary"}
          onClick={() => {
            if (!call) return;

            speechRecognitionApi.stopRemoteSpeechRecognition();
          }}
        >
          Stop
        </div>
      )} */}
      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Recognize Incoming Speech</span>
          <input
            type="checkbox"
            className="toggle"
            checked={recognizeIncomingSpeech}
            onChange={async (e) => {
              if (e.target.checked) {
                setRecognizeIncomingSpeech(true);

                if (!call) return;
                callingApi.unmuteIncomingAudio();
                callingApi.unmuteOutgoingAudio();
                await speechRecognitionApi.startRemoteSpeechRecognition(call);
                callingApi.muteIncomingAudio();
                callingApi.muteOutgoingAudio();
              } else {
                setRecognizeIncomingSpeech(false);
              }
            }}
          />
        </label>
      </div>
      <button onClick={() => {
        call?.localAudioStreams.forEach((val) => {
          console.log(val)
        })
      }}>LAS</button>
          <AvailableMicrophonesDropdown />
          <div
            className={"btn btn-primary btn-xs"}
            onClick={async () => {
              if (!call) return;
              let las = new LocalAudioStream(selectedMicrophone);
              call?.startAudio(las);
            }}>
            Use Microphone Output Audio
          </div>
      <label className="label">
          <span className="label-text-alt">Prompt:</span>
        </label>
      <AskBotSingleButton room={room} />

    
      <div className="form-control w-full max-w-xs">
        <label className="label cursor-pointer">
          <span className="label-text">Show Video Controls</span>
          <input
            type="checkbox"
            className="toggle"
            checked={showVideoControls}
            onChange={(e) => {
              if (e.target.checked) {
                setShowVideoControls(true);
              } else {
                setShowVideoControls(false);
              }
            }}
          />
        </label>
      </div>

      <div className="form-control w-full max-w-xs">
        <video hidden={true} id={"audio1"} autoPlay={true}></video>
        <label className="label cursor-pointer">
          <span className="label-text">Use Prompt Chaining</span>
          <input
            type="checkbox"
            className="toggle"
            checked={usePromptChaining}
            onChange={(e) => {
              if (e.target.checked) {
                chatUIApi.setUsePromptChaining(true);
              } else {
                chatUIApi.setUsePromptChaining(false);
              }
            }}
          />
        </label>
      </div>
      {/* <div
        className={"btn btn-xs btn-primary"}
        onClick={async () => {
          if (!call) return;

          callingApi.muteIncomingAudio();
          callingApi.muteOutgoingAudio();
        }}>
        Mute All
      </div> */}
        <div className={"remoteVideos"}></div>
      <div className={"handTrackingVideos"}></div>
      {useVRMAvatar &&  <VRMThing /> }
     
    </div>
  );
}

const AskBotSingleButton = ({ room }: { room: Room }) => {
  const [cssClass, setCssClass] = useState(
    "border-primary-focus text-primary-focus"
  );

  const [rOnce, setROnce] = useState(false);

  useHotkeys(
    "b",
    (event) => {
      // console.log(event);
      // console.log(event.repeat);
      if (event.repeat) return;
      if (!rOnce) {
        room.send("askBotSingle", {});
        setROnce(true);
      }
      if (rOnce) {
        console.log("I SHOULD STOP NOW");
        room.send("stopSpeechRecognition", {});

        setROnce(false);
      }
    },
    { keyup: true, keydown: true },
    [rOnce]
  );

  return (
    <div className={"tooltip tooltip-right z-10"} data-tip={`ask the bot!`}>
      <kbd className={cssClass}>b</kbd>
    </div>
  );
};
export function ChatGPTUI({ room }: { room: Room<AIExperienceState> }) {
  const [userText, setUserText] = useState("");
  const [chatResponse, setChatResponse] = useState("");
  const listRef = useRef();

  useEffect(() => {
    return () => room.onMessage("chatGPTResponse", (message) => {
      setChatResponse(message.text);
    });
  }, [room]);


  useEffect(() => {     
         
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [results]);

  return (
    <div>
      Ask ChatGPT
      <div>
        <input
          type={"text"}
          value={userText}
          onChange={(e) => {
            setUserText(e.target.value);
          }}
        ></input>
      </div>
      <AskBotSingleButton room={room} />
      <div
        className="btn btn-xs btn-primary"
        onClick={() => {
          room.send("askChatGPT", { text: userText });
        }}
      ></div>
      <div className="chat chat-end">
        <div className="chat-image avatar">
          <div style={{ transform: "scale(1.7)", marginBottom: "5px" }}>
            <Lightbulb />
          </div>
        </div>
        <div className="chat-bubble text-sm" style={{ padding: "1rem" }}>
          {" "}
          <ReactMarkdown
            components={{
              strong: ({ node, ...props }) => (
                <AddToPromptBuilder
                  text={props.children ? props.children[0] : "Error"}
                  onSelectFn={(selectedText: string) => {
                    // room.send("speech", {
                    //   text: selectedText,
                    //   id: generateUuid(),
                    //   max_token: 500,
                    //   name: playerName,
                    // });
                    room.send("expandKeyConcept", { text: selectedText, id: generateUuid() });
                  }}
                />
              ),
            }}
            remarkPlugins={[emoji]}
          >
            {chatResponse}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export function GestureRecognizer({ room, gestureName, eventName }: { room: Room, gestureName: string, eventName:string }){
  const data = useMediapipeStore(state => state.handPositionData);
let [lastGesture, setLastGesture] = useState<Array<string>>([]);
let [lastDataFrames, setLastDataFrames] = useState<Array<{ userId: string, gestureData: Array<string> }>>([])


  useEffect(() => {
    if (!data || data.length === 0) return;
    for (var i = 0; i < data.length; i++){
      let user = data[i].userId;
      if (data[i].data.gestures[0] && data[i].data.gestures[0][0]){
      let userGesture = data[i].data.gestures[0][0]

      let userIndex = lastDataFrames.findIndex((d) => d.userId === user);
 

      if (userGesture){
        console.log(userGesture.categoryName)
        if (userGesture.categoryName === gestureName){
          if (userIndex === -1){
            setLastDataFrames([...lastDataFrames, { userId: user, gestureData: [userGesture.categoryName] }])
          }
          else {
            console.log("CONCAT DATA")
            console.log(lastDataFrames[userIndex].gestureData.concat( userGesture.categoryName ))
            lastDataFrames[userIndex].gestureData = lastDataFrames[userIndex].gestureData.concat( userGesture.categoryName )
            setLastDataFrames([...lastDataFrames])
          }
  
        }
        else {
          if (userIndex === -1){
            setLastDataFrames([...lastDataFrames, { userId: user, gestureData: [] }])
          }
          else {
            lastDataFrames[userIndex].gestureData = [];
            console.log("DATAFRAMES, las", lastDataFrames[userIndex].gestureData)
            setLastDataFrames([...lastDataFrames])
          }
        }
      }
    }
  }


    // if (data[0].data.gestures[0]){
    //   console.log(data[0].data.gestures[0])
    //   // console.log("WE HAVE A GESTURE")
    //   let gesture = data[0].data.gestures[0][0].categoryName;
    //   console.log(gesture)
    //   if (gesture === "Open_Palm"){

    //     console.log("OPEN PALME")
    //     setLastGesture([...lastGesture, "Open Palm"])

    //   }
    //   else {
    //     setLastGesture([])
    //   }
    // }
  }, [data])

  useEffect(() => {
    let foundMatch = false;
    lastDataFrames.forEach((df) => {
      if (df.gestureData.length === 4){
        foundMatch = true;
      }
    })

    if (foundMatch){
      console.log("TIME TO DO IT")
      room?.send(eventName, {});
    }
   }, [lastDataFrames])

  // useEffect(() => {
  //   if (!data || data.length === 0) return;
  //   let mappedData = data.map((userData) => {
  //     console.log(userData);
  //     let userId = userData.userId;
  //     let userGesture = "";
  //     if (userData.data.gestures && userData.data.gestures[0] && userData.data.gestures[0][0]){
  //      userGesture = userData.data.gestures[0][0].categoryName as string;
  //     }
  //     return { userId: userId, gesture: userGesture }
  //   })

  //   setLastDataFrames([...lastDataFrames, ...mappedData])
  // }, [data])

  // useEffect(() => {
  //   if (lastGesture.length === 4){
  //     console.log("LETS GO")
  //     room?.send("handRaised", {});
  //   }
  // }, [lastGesture, room])
  return <div>
    <div>Gesture Data</div>
    {JSON.stringify(lastDataFrames)}
  </div>
}


export function MeetingTranscriptionUI({ room }: { room: Room<AIExperienceState> }) {

  const listRef = useRef(null);
  const teamsCaptionsApi = useTeamsCaptionsStore((state) => state.api);
  const results = useTeamsCaptionsStore((state) => state.results);

  const call = useCallingStore((state) => state.call);
  const callIsConnected = useCallingStore((state) => state.callIsConnected);

  const playerImage = useGlobalStore2((state) => state.playerImageBase64);
  const addAlert = useAlertsStore((state) => state.addAlert);
const chatUIApi = useChatUIStore(state => state.api)

  useEffect(() => {
    if (!call) return;
    if (!callIsConnected) return;
    teamsCaptionsApi.startTeamsCaptionsFeature(call);
  }, [call, callIsConnected]);

  useEffect(() => {
    if (!results || results.length === 0) return;
    console.log(results[results.length - 1])
    const latestResult = results[results.length -1];
    const payload = { text: latestResult.text, displayName: latestResult.speaker.displayName, id: generateUuid() }
    room.send("transcriptionResult", payload);
  }, [results])

  useEffect(() => {
    if (!room) return;
    let listener = room.onMessage("aiResult", (message) => {
      addAlert({ text: message.text })
    })
    return () => listener()
  }, [room])

  useEffect(() => {     
         
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [results]);

  return (
    <div>
      Meeting Transcription
      <div onClick={() => {
          if (!call) return;
            teamsCaptionsApi.startTeamsCaptionsFeature(call);

      }}>Start</div>
      <ul ref={listRef}>
        {results.map((r) => {

          return (
            <div className="chat chat-start">
              {/* <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img src={playerImage} />
                </div>
              </div> */}
     
                <div className="chat-header">
                {r.speaker.displayName}
    <time className="text-xs opacity-50">{r.timestamp.toString()}</time>
  </div>
              <div
                className="chat-bubble chat-bubble-primary"
                style={{ padding: "1rem" }}
              >
                <div className={"text-xs"}>{r.text} </div>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
}

export function CallHangerUpper() {
  const teamsCtx = useGlobalStore2((state) => state.teamsContext);
  const [message, setMessage] = useState("");

  if (teamsCtx) {
    teamsCore.registerOnLoadHandler((loadCtx) => {
      console.log("registerOnLoadHandler", loadCtx);
      setMessage(JSON.stringify(loadCtx));
    });
    // const beforeUnloadHandler: (readyToUnload: () => void) = () => {

    // }
    // teamsCore.registerBeforeUnloadHandler(beforeUnloadHandler)
  }
  return <div>Call Hanger Upper - {message}</div>;
}

function TeamsTokenDisplay() {
  const teamsToken = useGlobalStore2((state) => state.teamsToken);
  // const onBehalfOfToken = useGlobalStore2((state) => state.onBehalfOfToken);
  const [searchText, setSearchText] = useState("");
  const [tempSearchResult, setTempSearchResult] = useState("");
  const [onBehalfOfToken, setOnBehalfOfToken] = useState("");
  const responses = useChatUIStore((state) => state.responses, shallow);
  const chatUIApi = useChatUIStore((state) => state.api);

  const runSearchQuery = async (queryText) => {
    const requestBody = {
      request: {
        __metadata: {
          type: "Microsoft.Office.Server.Search.REST.SearchRequest",
        },
        Querytext: queryText,
        QueryTemplate: "{searchterms}",
      },
    };

    let r = await fetch("https://rpo365.sharepoint.com/_api/search/postquery", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${onBehalfOfToken}`,
        "Content-Type": "application/json;odata=verbose",
        Accept: "application/json;odata=verbose",
      },
      body: JSON.stringify(requestBody),
    });

    let result = await r.json();
    console.log("SEARCH RESULTS", result);
    let formattedResults = formatSearchResults(
      result.d.postquery?.PrimaryQueryResult?.RelevantResults?.Table?.Rows ||
        null
    );

    return formattedResults;
  };

  useEffect(() => {
    if (!teamsToken) return;

    getOnBehalfOfTokenSP(teamsToken).then((token) => {
      setOnBehalfOfToken(token.access_token);
    });
  }, [teamsToken]);

  useEffect(() => {
    console.log("RESPONSES Changed", responses);
    const latestSuggestion = responses[responses.length - 1];
    console.log("latestSuggestion", latestSuggestion);
    if (!latestSuggestion) return;
    const latest = latestSuggestion.suggestions[0];
    if (latest && latest.text) {
      const splitQuery = latest.text.split(",");
      const joinedQuery = splitQuery.join(" OR ");
      console.log("query is", joinedQuery);
      runSearchQuery(joinedQuery).then((formattedResults) => {
        chatUIApi.addSuggestionsToResponse(
          [
            { text: JSON.stringify(formattedResults), id: "1" },
            // { text: JSON.stringify({ Title: joinedQuery || "WTF" }), id: "1" },
          ],
          latestSuggestion.id
        );
      });
    }
  }, [responses]);

  return (
    <div>
      {/* <div>Teams Token:</div>
      {teamsToken}
      <div>OBO Token:</div>
      {onBehalfOfToken} */}
      {/* <input
        type="text"
        placeholder="Enter a search query"
        className="input input-sm input-bordered w-80"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}></input>
      <div
        className={"btn btn-primary btn-xs"}
        onClick={async () => {
          await runSearchQuery();
        }}>
        Search
      </div>
      <div>{tempSearchResult}</div> */}
    </div>
  );
}

function AvailableMicrophonesDropdown() {
  const call = useCallingStore((state) => state.call);
  const callingApi = useCallingStore((state) => state.api);
  const availableMicrophones = useCallingStore((state) => state.availableMicrophones);
const selectedMicrophone = useCallingStore((state) => state.selectedMicrophone);

useEffect(() => {
  if (!call) return;
  if (!selectedMicrophone) return;
  let las = new LocalAudioStream(selectedMicrophone);
  call?.startAudio(las);
  console.log("staed mi audio?")
}, [selectedMicrophone, call])
  return (
    <div className="dropdown">
      <label tabIndex={1} className="btn btn-xs m-1">
        Select Microphone
      </label>
      <ul tabIndex={1} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
        {availableMicrophones?.map((ac) => {
          return (
            <li
              key={ac.id}
              onClick={() => {
                callingApi.setSelectedMicrophone(ac);
                document.activeElement?.blur();
              }}>
              <a>{ac.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function SearchCard({ text }: { text: string }) {
  if (!text) {
    return <div>Text was empty</div>;
  }
  try {
    let parsed = JSON.parse(text) as Array<ISearchResult>;

    function createMarkup(text) {
      return { __html: text };
    }

    return (
      <div>
        {parsed.map((sr) => {
          return (
            <div>
              <div>
                {" "}
                <a href={sr.Path} target={"_blank"}>
                  {sr.Title}
                </a>
              </div>
              {/* <div>
                <div dangerouslySetInnerHTML={createMarkup(sr.HitHighlightedSummary || "")}></div>
              </div> */}
            </div>
          );
        })}
      </div>
    );
  } catch (err) {
    return <div>{JSON.stringify(err)}</div>;
  }
}
export function CollabAI() {
  const canvasRef = useRef(null);
  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  const playerName = useGlobalStore2((store) => store.playerName);
  const playerImage = useGlobalStore2((store) => store.playerImageBase64);
  const chatUIApi = useChatUIStore((state) => state.api);
  const responses = useChatUIStore((state) => state.responses);
  const listRef = useRef<HTMLElement>();
  const showTypingIndicator = useChatUIStore(
    (state) => state.showTypingIndicator
  );
  const addAlert = useAlertsStore((state) => state.addAlert);

  const [shouldBeACSConnectionHolder, setShouldBeACSConnectionHolder] =
    useState(false);
  const [promptText, setPromptText] = useState(BuiltInPrompts[0].text);

  let defaultName = "TOM";

  let toMerge = { name: playerName || defaultName, forceNewRoom: false };
  console.log("JOINING COLLABAI ROOM WITH CONTEXT", teamsContext);
  let joinObj = {
    ...teamsContext,
    ...toMerge,
    ...{ imageBase64: playerImage },
  };
  const { client, room } = useColyseusRoom2<AIExperienceState>(
    "collabai",
    joinObj
  );

  useEffect(() => {
    if (!room) return;
    room?.onStateChange.once((state) => {
      console.log("this is the first room state!", state);
      if (state.userWithACSConnection === room.sessionId) {
        console.log("I SHOULD BE THE ONE WITH THE ACS CONNECTION", state);
        setShouldBeACSConnectionHolder(true);
      }

      state.promptsAndResponses.forEach((pr) => {
        chatUIApi.addResponse(pr.text, pr.id);
        if (pr.responses[0]) {
          chatUIApi.addSuggestionsToResponse(
            [{ text: pr.responses[0].text, id: 1 }],
            pr.id
          );
        }
      });
    });
    room?.state.listen("userWithACSConnection", (value, prevValue) => {
      console.log("USER WITH ACS CONNECTION", value);
      if (value === room.sessionId) {
        if (room.state.acsConnectionStatus === "notstarted") {
          setShouldBeACSConnectionHolder(true);
        }
      }
    });

    room?.onMessage("newSpeech", (message) => {
      chatUIApi.addResponse(
        message.lastSpeechResult ? message.lastSpeechResult : message.text,
        message.id,
        message.displayName
      );
      if (message.shouldLiveQueryAI){
      chatUIApi.setShowTypingIndicator(true);
      }
    });

    room?.onMessage("suggestion", (message) => {
      chatUIApi.addSuggestionsToResponse(
        [{ text: message.suggestion, id: 1 }],
        message.id
      );
      chatUIApi.setShowTypingIndicator(false);
    });



    room?.onMessage("promptChanged", (message) => {
      let text = "";
      if (message.playerName) {
        text = `${message.playerName} changed the prompt to: ${message.friendlyName}`;
      } else {
        text = `AI prompt changed to: ${message.friendlyName}`;
      }
      addAlert({
        text: text,
        imageBase64: message.playerImage,
      });

      setPromptText(message.prompt);
    });



    () => room.removeAllListeners();
    // room?.onStateChange((newState: AIExperienceState) => {
    //   console.log("NEW STATE", newState);
    // });

    // room.state.promptsAndResponses.onAdd = (pr, sessionId) => {
    //   console.log(pr);
    //   chatUIApi.addResponse(pr.text, pr.id);
    // };

    // room.state.promptsAndResponses.onChange = (pr, sessionId) => {
    //   chatUIApi.addSuggestionsToResponse([{ text: pr.responses[0].text, id: 1 }], pr.id);
    // };
  }, [room]);

  useEffect(() => {
    if (!room) return;

    let listener = room.onMessage("keyConceptExpanded", (message) => {
        chatUIApi.addSuggestionsToResponse([{ text: message.text, id: "1"}], message.id)
    })
    return () => listener();
  }, [room])

  useEffect(() => {
    document.addEventListener("mouseup", (event) => {
      if (window.getSelection().toString().length) {
        let exactText = window.getSelection().toString();
        console.log(exactText);
      }
    });
  }, []);

  // useEffect(() => {     
         
  //   listRef.current?.lastElementChild?.scrollIntoView({  behavior: "smooth"});
  // }, [responses]);

  if (!room) return null;



  return (
    <div>
      {shouldBeACSConnectionHolder && <ACSConnectionHolder room={room} />}
      <div className={"px-2"}>
        <div className="text-lg">Meeting AI Experience</div>

        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">Select Prompt:</span>
          </label>
          <select
            value={promptText}
            className="select select-bordered select-xs"
            onChange={(e) => {
              room.send("changePrompt", {
                prompt: e.target.value,
                friendlyName: BuiltInChatGPTPrompts.find(
                  (p) => p.systemText === e.target.value
                )?.friendlyName,
                playerName: playerName,
                playerImage: playerImage,
              });
            }}
          >
            {BuiltInChatGPTPrompts.map((prompt) => {
              return (
                <option value={prompt.systemText}>{prompt.friendlyName}</option>
              );
            })}
          </select>
        </div>

        <div className={"btn btn-xs btn-primary"} onClick={() => {
          room?.send("clearHistory", {})
        }}>Clear History</div>
      </div>
      {/* <TeamsTokenDisplay /> */}
      <div ref={listRef} id={"chatui"} className={""}>
        {[...responses].reverse().map((response) => {
          return (
            <div key={response.id}>
              <div className="chat chat-start">
              <div className="chat-header">
    {response.displayName}
  </div>
                <div
                  className="chat-bubble chat-bubble-primary"
                  style={{ padding: "1rem" }}
                >
                  <div className={"text-xs"}>{response.inputText} </div>
                </div>
              </div>
              {showTypingIndicator && response.suggestions.length === 0 && (
                <div className="chat chat-end">
                  <div className="chat-bubble" style={{ padding: "1rem" }}>
                    <TypingIndicator />
                  </div>
                </div>
              )}
              {response.suggestions.map((r) => {
                return (
                  <div className="chat chat-end">
                    <div className="chat-image avatar">
                      <div
                        style={{ transform: "scale(1.7)", marginBottom: "5px" }}
                      >
                        <Lightbulb />
                      </div>
                    </div>
                    <div
                      className="chat-bubble text-sm"
                      style={{ padding: "1rem" }}
                    >
                      {BuiltInPrompts.find((p) => p.text === promptText)
                        ?.displayType === "SPSearch" ? (
                        <div>
                          <SearchCard text={r.text} />
                        </div>
                      ) : (
                        <ReactMarkdown
                          components={{
                            strong: ({ node, ...props }) => (
                              <AddToPromptBuilder
                                text={
                                  props.children ? props.children[0] : "Error"
                                }
                                onSelectFn={(selectedText: string) => {
                                  room.send("speech", {
                                    text: selectedText,
                                    lastSpeechResult: selectedText,
                                    id: generateUuid(),
                                    max_token: 500,
                                    name: playerName,
                                  });
                                }}
                              />
                            ),
                          }}
                          remarkPlugins={[emoji]}
                        >
                          {r.text}
                        </ReactMarkdown>
                      )}
                    </div>
                    <div
                      className={"btn btn-xs btn-primary"}
                      onClick={() => {
                        room.send("saySpeech", { text: r.text });
                      }}
                    >
                      Say
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* <MeetingTranscriptionUI room={room} /> */}
      {/* <div className={"max-w-xs"}>
        <MeetingTranscriptionUI />
      </div>
      <div className={"max-w-xs"}>
        <ChatGPTUI room={room}></ChatGPTUI>
      </div> */}
      <div>{/* <CallHangerUpper /> */}</div>
      <Alerts />
      {/* <MeetingRPGDynamicWrapper  /> */}
    </div>
  );
}


