"use strict";
exports.__esModule = true;
var EventEmitter = /** @class */ (function () {
    function EventEmitter() {
        this.handlers = {};
    }
    EventEmitter.prototype.on = function (event, handler) {
        var eventHandlers = this.handlers[event];
        if (!eventHandlers) {
            eventHandlers = [];
            this.handlers[event] = eventHandlers;
        }
        this.handlers[event].push(handler);
        return handler;
    };
    EventEmitter.prototype.off = function (event, handler) {
        var eventHandlers = this.handlers[event];
        if (!handler) {
            return;
        }
        var index = eventHandlers.indexOf(handler);
        if (index === -1) {
            return;
        }
        this.handlers[event].splice(index, 1);
    };
    EventEmitter.prototype.offAll = function () {
        this.handlers = {};
    };
    EventEmitter.prototype.emit = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var eventHandlers = this.handlers[event];
        if (!eventHandlers) {
            return;
        }
        eventHandlers.forEach(function (handler) { return handler.apply(void 0, args); });
    };
    return EventEmitter;
}());
exports.EventEmitter = EventEmitter;
