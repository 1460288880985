import React, { useEffect, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { VRM, VRMSchema, VRMUtils } from "@pixiv/three-vrm";
import { Camera } from "@mediapipe/camera_utils";
import { FACEMESH_TESSELATION, HAND_CONNECTIONS, Holistic, POSE_CONNECTIONS } from "@mediapipe/holistic";
import * as Kalidokit from "kalidokit";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import "./cameraFun.scss";
import { Clock, DirectionalLight, Euler, PerspectiveCamera, Quaternion, Scene, Vector3, WebGLRenderer } from "three";

export function CameraFunInner({ avatarUrl }: { avatarUrl: string }) {
  useEffect(() => {
    //Import Helper Functions from Kalidokit
    const remap = Kalidokit.Utils.remap;
    const clamp = Kalidokit.Utils.clamp;
    window["clamp"] = clamp;
    const lerp = Kalidokit.Vector.lerp;
    /* THREEJS WORLD SETUP */
    let currentVrm;

    // renderer
    const renderer = new WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    document.body.appendChild(renderer.domElement);

    // camera
    const orbitCamera = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
    orbitCamera.position.set(0.0, 1.4, 0.7);

    // controls
    const orbitControls = new OrbitControls(orbitCamera, renderer.domElement);
    orbitControls.screenSpacePanning = true;
    orbitControls.target.set(0.0, 1.4, 0.0);
    orbitControls.update();

    // scene
    const scene = new Scene();

    // light
    const light = new DirectionalLight(0xffffff);
    light.position.set(1.0, 1.0, 1.0).normalize();
    scene.add(light);

    // Main Render Loop
    const clock = new Clock();

    function animate() {
      requestAnimationFrame(animate);

      if (currentVrm) {
        // Update model to render physics
        currentVrm.update(clock.getDelta());
      }
      renderer.render(scene, orbitCamera);
    }
    animate();

    /* VRM CHARACTER SETUP */

    // Import Character VRM
    const loader = new GLTFLoader();
    loader.crossOrigin = "anonymous";
    // Import model from URL, add your own model here
    const testModel = "/TomVRM.vrm";
    const animeModel = "https://cdn.glitch.com/29e07830-2317-4b15-a044-135e73c7f840%2FAshtra.vrm?v=1630342336981";
    loader.load(
      testModel,

      (gltf) => {
        console.log("GLTF", gltf);
        VRMUtils.removeUnnecessaryJoints(gltf.scene);
        // scene.add(gltf.scene);
        VRM.from(gltf).then((vrm) => {
          // console.log("VRM", vrm);
          // scene.add(vrm.scene);
          // currentVrm = vrm;
          // currentVrm.scene.rotation.y = Math.PI; // Rotate model 180deg to face camera

          loader.load(testModel, (glb) => {
            console.log("GLB", glb);
            glb.userData = gltf.userData;
            glb.parser.json = gltf.parser.json;
            console.log("GLB WITH USERDATA", glb);
            // glb.scene.children[1] = gltf.scene.children[1];
            // glb.scene.children[2] = gltf.scene.children[2];
            // glb.scene.children[1] = gltf.scene.children[3];

            VRMUtils.removeUnnecessaryJoints(glb.scene);

            VRM.from(glb).then((vr) => {
              console.log("GLBVRM", vr);
              scene.add(vr.scene);
              currentVrm = vr;
              // currentVrm.scene.rotation.y = Math.PI;
            });
          });
        });
      },

      (progress) => console.log("Loading model...", 100.0 * (progress.loaded / progress.total), "%"),

      (error) => console.error(error)
    );

    // Animate Rotation Helper function
    const rigRotation = (name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
      if (!currentVrm) {
        return;
      }
      console.log("HUMANOID", VRMSchema.HumanoidBoneName[name]);
      const Part = currentVrm.humanoid.getBoneNode(VRMSchema.HumanoidBoneName[name]);
      console.log("PART", Part);
      if (!Part) {
        return;
      }

      let euler = new Euler(rotation.x * dampener, rotation.y * dampener, rotation.z * dampener);
      let quaternion = new Quaternion().setFromEuler(euler);
      Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
    };

    // Animate Position Helper Function
    const rigPosition = (name, position = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
      if (!currentVrm) {
        return;
      }
      const Part = currentVrm.humanoid.getBoneNode(VRMSchema.HumanoidBoneName[name]);
      if (!Part) {
        return;
      }
      let vector = new Vector3(position.x * dampener, position.y * dampener, position.z * dampener);
      Part.position.lerp(vector, lerpAmount); // interpolate
    };

    let oldLookTarget = new Euler();
    const rigFace = (riggedFace) => {
      if (!currentVrm) {
        return;
      }
      rigRotation("Neck", riggedFace.head, 0.7);

      // Blendshapes and Preset Name Schema
      const Blendshape = currentVrm.blendShapeProxy;
      const PresetName = VRMSchema.BlendShapePresetName;
      // Simple example without winking. Interpolate based on old blendshape, then stabilize blink with `Kalidokit` helper function.
      // for VRM, 1 is closed, 0 is open.
      riggedFace.eye.l = lerp(clamp(1 - riggedFace.eye.l, 0, 1), Blendshape.getValue(PresetName.Blink), 0.5);
      riggedFace.eye.r = lerp(clamp(1 - riggedFace.eye.r, 0, 1), Blendshape.getValue(PresetName.Blink), 0.5);
      riggedFace.eye = Kalidokit.Face.stabilizeBlink(riggedFace.eye, riggedFace.head.y);
      Blendshape.setValue(PresetName.Blink, riggedFace.eye.r);
      // Blendshape.setValue(PresetName.BlinkL, riggedFace.eye.l);

      // Interpolate and set mouth blendshapes
      // Blendshape.setValue(PresetName.I, lerp(riggedFace.mouth.shape.I, Blendshape.getValue(PresetName.I), 0.5));
      // Blendshape.setValue(PresetName.A, lerp(riggedFace.mouth.shape.A, Blendshape.getValue(PresetName.A), 0.5));
      // Blendshape.setValue(PresetName.E, lerp(riggedFace.mouth.shape.E, Blendshape.getValue(PresetName.E), 0.5));
      // Blendshape.setValue(PresetName.O, lerp(riggedFace.mouth.shape.O, Blendshape.getValue(PresetName.O), 0.5));
      // Blendshape.setValue(PresetName.U, lerp(riggedFace.mouth.shape.U, Blendshape.getValue(PresetName.U), 0.5));

      //PUPILS
      //interpolate pupil and keep a copy of the value
      let lookTarget = new Euler(
        lerp(oldLookTarget.x, riggedFace.pupil.y, 0.4),
        lerp(oldLookTarget.y, riggedFace.pupil.x, 0.4),
        0,
        "XYZ"
      );
      oldLookTarget.copy(lookTarget);
      currentVrm.lookAt.applyer.lookAt(lookTarget);
    };

    /* VRM Character Animator */
    const animateVRM = (vrm, results) => {
      if (!vrm) {
        return;
      }
      // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
      let riggedPose, riggedLeftHand, riggedRightHand, riggedFace;

      const faceLandmarks = results.faceLandmarks;
      // Pose 3D Landmarks are with respect to Hip distance in meters
      const pose3DLandmarks = results.ea;
      // Pose 2D landmarks are with respect to videoWidth and videoHeight
      const pose2DLandmarks = results.poseLandmarks;
      // Be careful, hand landmarks may be reversed
      const leftHandLandmarks = results.rightHandLandmarks;
      const rightHandLandmarks = results.leftHandLandmarks;

      // Animate Face
      if (faceLandmarks) {
        riggedFace = Kalidokit.Face.solve(faceLandmarks, {
          runtime: "mediapipe",
          video: videoElement,
        });
        rigFace(riggedFace);
      }

      // Animate Pose
      if (pose2DLandmarks && pose3DLandmarks) {
        riggedPose = Kalidokit.Pose.solve(pose3DLandmarks, pose2DLandmarks, {
          runtime: "mediapipe",
          video: videoElement,
        });
        rigRotation("Hips", riggedPose.Hips.rotation, 0.7);
        rigPosition(
          "Hips",
          {
            x: -riggedPose.Hips.position.x, // Reverse direction
            y: riggedPose.Hips.position.y + 1, // Add a bit of height
            z: -riggedPose.Hips.position.z, // Reverse direction
          },
          1,
          0.07
        );

        rigRotation("Chest", riggedPose.Spine, 0.25, 0.3);
        rigRotation("Spine", riggedPose.Spine, 0.45, 0.3);

        rigRotation(
          "RightUpperArm",
          {
            x: -riggedPose.RightUpperArm.x,
            y: riggedPose.RightUpperArm.y,
            z: -riggedPose.RightUpperArm.z,
          },
          1,
          0.3
        );

        // rigRotation("RightUpperArm", riggedPose.RightUpperArm, 1, 0.3);
        console.log("RIGHTUPPERARM", riggedPose.RightUpperArm);
        rigRotation("RightLowerArm", riggedPose.RightLowerArm, 1, 0.3);
        rigRotation("LeftUpperArm", riggedPose.LeftUpperArm, 1, 0.3);
        rigRotation("LeftLowerArm", riggedPose.LeftLowerArm, 1, 0.3);

        rigRotation("LeftUpperLeg", riggedPose.LeftUpperLeg, 1, 0.3);
        rigRotation("LeftLowerLeg", riggedPose.LeftLowerLeg, 1, 0.3);
        rigRotation("RightUpperLeg", riggedPose.RightUpperLeg, 1, 0.3);
        rigRotation("RightLowerLeg", riggedPose.RightLowerLeg, 1, 0.3);
      }

      // Animate Hands
      if (leftHandLandmarks) {
        riggedLeftHand = Kalidokit.Hand.solve(leftHandLandmarks, "Left");
        rigRotation("LeftHand", {
          // Combine pose rotation Z and hand rotation X Y
          z: riggedPose.LeftHand.z,
          y: riggedLeftHand.LeftWrist.y,
          x: riggedLeftHand.LeftWrist.x,
        });
        rigRotation("LeftRingProximal", riggedLeftHand.LeftRingProximal);
        rigRotation("LeftRingIntermediate", riggedLeftHand.LeftRingIntermediate);
        rigRotation("LeftRingDistal", riggedLeftHand.LeftRingDistal);
        rigRotation("LeftIndexProximal", riggedLeftHand.LeftIndexProximal);
        rigRotation("LeftIndexIntermediate", riggedLeftHand.LeftIndexIntermediate);
        rigRotation("LeftIndexDistal", riggedLeftHand.LeftIndexDistal);
        rigRotation("LeftMiddleProximal", riggedLeftHand.LeftMiddleProximal);
        rigRotation("LeftMiddleIntermediate", riggedLeftHand.LeftMiddleIntermediate);
        rigRotation("LeftMiddleDistal", riggedLeftHand.LeftMiddleDistal);
        rigRotation("LeftThumbProximal", riggedLeftHand.LeftThumbProximal);
        rigRotation("LeftThumbIntermediate", riggedLeftHand.LeftThumbIntermediate);
        rigRotation("LeftThumbDistal", riggedLeftHand.LeftThumbDistal);
        rigRotation("LeftLittleProximal", riggedLeftHand.LeftLittleProximal);
        rigRotation("LeftLittleIntermediate", riggedLeftHand.LeftLittleIntermediate);
        rigRotation("LeftLittleDistal", riggedLeftHand.LeftLittleDistal);
      }
      if (rightHandLandmarks) {
        riggedRightHand = Kalidokit.Hand.solve(rightHandLandmarks, "Right");
        rigRotation("RightHand", {
          // Combine Z axis from pose hand and X/Y axis from hand wrist rotation
          z: riggedPose.RightHand.z,
          y: riggedRightHand.RightWrist.y,
          x: riggedRightHand.RightWrist.x,
        });
        rigRotation("RightRingProximal", riggedRightHand.RightRingProximal);
        rigRotation("RightRingIntermediate", riggedRightHand.RightRingIntermediate);
        rigRotation("RightRingDistal", riggedRightHand.RightRingDistal);
        rigRotation("RightIndexProximal", riggedRightHand.RightIndexProximal);
        rigRotation("RightIndexIntermediate", riggedRightHand.RightIndexIntermediate);
        rigRotation("RightIndexDistal", riggedRightHand.RightIndexDistal);
        rigRotation("RightMiddleProximal", riggedRightHand.RightMiddleProximal);
        rigRotation("RightMiddleIntermediate", riggedRightHand.RightMiddleIntermediate);
        rigRotation("RightMiddleDistal", riggedRightHand.RightMiddleDistal);
        rigRotation("RightThumbProximal", riggedRightHand.RightThumbProximal);
        rigRotation("RightThumbIntermediate", riggedRightHand.RightThumbIntermediate);
        rigRotation("RightThumbDistal", riggedRightHand.RightThumbDistal);
        rigRotation("RightLittleProximal", riggedRightHand.RightLittleProximal);
        rigRotation("RightLittleIntermediate", riggedRightHand.RightLittleIntermediate);
        rigRotation("RightLittleDistal", riggedRightHand.RightLittleDistal);
      }
    };

    let videoElement = document.querySelector(".input_video"),
      guideCanvas = document.querySelector("canvas.guides");

    const onResults = (results) => {
      // Draw landmark guides
      drawResults(results);
      // Animate model
      animateVRM(currentVrm, results);
    };

    const holistic = new Holistic({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@0.5.1635989137/${file}`;
      },
    });

    holistic.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      minDetectionConfidence: 0.7,
      minTrackingConfidence: 0.7,
      refineFaceLandmarks: true,
    });
    // Pass holistic a callback function
    holistic.onResults(onResults);

    const drawResults = (results) => {
      guideCanvas.width = videoElement.videoWidth;
      guideCanvas.height = videoElement.videoHeight;
      let canvasCtx = guideCanvas.getContext("2d");
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, guideCanvas.width, guideCanvas.height);
      // Use `Mediapipe` drawing functions
      drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS, {
        color: "#00cff7",
        lineWidth: 4,
      });
      drawLandmarks(canvasCtx, results.poseLandmarks, {
        color: "#ff0364",
        lineWidth: 2,
      });
      drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION, {
        color: "#C0C0C070",
        lineWidth: 1,
      });
      if (results.faceLandmarks && results.faceLandmarks.length === 478) {
        //draw pupils
        drawLandmarks(canvasCtx, [results.faceLandmarks[468], results.faceLandmarks[468 + 5]], {
          color: "#ffe603",
          lineWidth: 2,
        });
      }
      drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS, {
        color: "#eb1064",
        lineWidth: 5,
      });
      drawLandmarks(canvasCtx, results.leftHandLandmarks, {
        color: "#00cff7",
        lineWidth: 2,
      });
      drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS, {
        color: "#22c3e3",
        lineWidth: 5,
      });
      drawLandmarks(canvasCtx, results.rightHandLandmarks, {
        color: "#ff0364",
        lineWidth: 2,
      });
    };

    var audioSelect = document.querySelector("select#audioSource");
    var videoSelect = document.querySelector("select#videoSource");

    audioSelect.onchange = getStream;
    videoSelect.onchange = getStream;

    getStream().then(getDevices).then(gotDevices);

    function getDevices() {
      // AFAICT in Safari this only gets default devices until gUM is called :/
      return navigator.mediaDevices.enumerateDevices();
    }

    function gotDevices(deviceInfos) {
      window.deviceInfos = deviceInfos; // make available to console
      console.log("Available input and output devices:", deviceInfos);
      for (const deviceInfo of deviceInfos) {
        const option = document.createElement("option");
        option.value = deviceInfo.deviceId;
        if (deviceInfo.kind === "audioinput") {
          option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
          audioSelect.appendChild(option);
        } else if (deviceInfo.kind === "videoinput") {
          option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
          videoSelect.appendChild(option);
        }
      }
    }

    function getStream() {
      console.log("GETTING STREAM");
      const audioSource = audioSelect.value;
      const videoSource = videoSelect.value;
      const constraints = {
        audio: false, //{ deviceId: audioSource ? { exact: audioSource } : undefined },
        video: { deviceId: videoSource ? { exact: videoSource } : undefined },
      };

      console.log("VIDEO SOURCE", videoSource);
      console.log("CONSTRAINTS", constraints);

      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      return navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(handleError);
    }

    function gotStream(stream) {
      console.log("GOT THE STREAM");
      window.stream = stream; // make stream available to console
      // audioSelect.selectedIndex = [...audioSelect.options].findIndex(
      //   (option) => option.text === stream.getAudioTracks()[0].label
      // );
      videoSelect.selectedIndex = [...videoSelect.options].findIndex(
        (option) => option.text === stream.getVideoTracks()[0].label
      );
      videoElement.srcObject = stream;
    }

    function handleError(error) {
      console.error("Error: ", error);
    }

    const camera = new Camera(videoElement, {
      onFrame: async () => {
        await holistic.send({ image: videoElement });
      },
      width: 640,
      height: 480,
    });
    camera.start();
  }, []);
  return (
    <div className={"cameraFun"}>
      <div>
        <progress className="progress w-56"></progress>

        <select id="videoSource" className="select select-sm select-secondary w-full max-w-xs">
          <option disabled selected>
            Select Video Source
          </option>
        </select>
      </div>
      <div>
        <select id="audioSource" className="select select-sm select-secondary w-full max-w-xs">
          <option disabled selected>
            Select Audio Source
          </option>
        </select>
      </div>
      {/* <div onClick={() => {}}></div>{" "}
      <div className="select">
        <label>Audio source: </label>
        <select id="audioSource"></select>
      </div> */}
      {/* <div className="select">
        <label>Video source: </label>
        <select id="videoSource"></select>
      </div> */}
      <div className="preview">
        <video className="input_video" width="1280px" height="720px"></video>
        <canvas className="guides"></canvas>
      </div>
    </div>
  );
}

export function CameraFun({ room }) {
  let [queryComplete, setQueryComplete] = useState(false);
  let [avatarUrl, setAvatarUrl] = useState<any>();
  useEffect(() => {
    let listener = room.onMessage("gotAllAvatars", (message) => {
      console.log(message);

      const randomElement = message.data[Math.floor(Math.random() * message.data.length)];
      setAvatarUrl(randomElement.avatarUrl);
      setQueryComplete(true);
    });
    room.send("getAllAvatarsCustom");

    return () => {
      listener();
    };
  }, []);

  if (!avatarUrl || !queryComplete) return null;
  return <CameraFunInner avatarUrl={avatarUrl} />;
}
