import { isFunction } from "@rpldy/shared";

const validateFunction = (f, name) => {
  if (!isFunction(f)) {
    throw new Error(`'${name}' is not a valid function`);
  }
};

const isUndefined = val => typeof val === "undefined";

export { validateFunction, isUndefined };