import { TDUser, TDUserStatus, Tldraw, TldrawApp } from "@tldraw/tldraw";
import React, { useEffect } from "react";
import { useColyseusRoom, useColyseusRoom2 } from "../../hooks/useColyseusRoom";
import { DrawingState } from "../../../state/DrawingState";
import { useGlobalStore2 } from "../../hooks/useGlobalState";

export function Drawing() {
  const [app, setApp] = React.useState<TldrawApp>();
  const teamsContext = useGlobalStore2((store) => store.teamsContext);
  let joinObj = {
    ...teamsContext,
  };
  const { client, room, roomState } = useColyseusRoom<DrawingState>("drawing", joinObj);

  // Put the state into the window, for debugging.
  const handleMount = React.useCallback((app: TldrawApp) => {
    window["app"] = app;
    setApp(app);
  }, []);

  const handleChange = React.useCallback((app: TldrawApp, reason: string) => {
    // Do something with the change
    console.log(app, reason);
  }, []);

  const handlePatch = React.useCallback(
    (app: TldrawApp, reason: string) => {
      // Do something with the change
      console.log(app, reason);
      if (room) {
        room.send("patch", app);
      }
    },
    [room]
  );

  useEffect(() => {
    if (!room) return;
    if (!app) return;

    room.onMessage("onPatch", (message) => {
      app.mergeDocument(message);
    });

    // room.onMessage("loadDocument", (message) => {
    //   app.loadDocument(message);
    // });

    room.onMessage("userChanged", (message) => {
      console.log(app.room.users);
    });

    room.onMessage("userLeave", (message) => {
      app.removeUser(message.userId);
    });

    app.loadRoom(room.id);
  }, [room, app]);

  const handlePersist = React.useCallback(
    (app: TldrawApp) => {
      room?.send("patch", app.document);
      console.log("ROOM", app.state.room);
    },
    [room]
  );

  const handleUserChange = React.useCallback(
    (app: TldrawApp, user: TDUser) => {
      room?.send("userChange", user);
    },
    [client, room]
  );

  React.useEffect(() => {
    if (!roomState) return;
    if (!roomState.players) return;
    let users = roomState.players
      .filter((p) => p.editorId !== app.room.userId && p.editorId)
      .map((p) => {
        return {
          id: p.editorId,
          color: p.color ? p.color : "red",
          point: p.position ? [...p.position] : [150, 150],
          selectedIds: p.selectedIds ? [...p.selectedIds] : [],
          activeShapes: p.activeShapes ? [...p.activeShapes] : [],
          status: TDUserStatus.Connected,
        };
      });
    app.updateUsers(users);
    return () => {};
  }, [roomState, app, room]);

  const onCommand = React.useCallback((state, reason) => {
    console.log(state, reason);
  }, []);
  return (
    <div>
      <div style={{ width: "50%" }}>
        <Tldraw
          showMenu={false}
          onMount={handleMount}
          onPersist={handlePersist}
          // onChangePresence={(state, user) => {
          //   console.log("ON CHANGE PRESENCE", state, user);
          // }}
          onChangePresence={handleUserChange}
          onUserChange={handleUserChange}
          showTools={true}
          showSponsorLink={false}
          onSaveProject={(app) => {
            console.log("SAVE PORJECT", app);
            let svg = app.copySvg();
            console.log("SVG", svg);
          }}
          onCommand={onCommand}
          // showUI={false}
          // readOnly={true}
        />
      </div>
    </div>
  );
}
