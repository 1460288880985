import React from "react";

export function DownloadPage() {
  return (
    <div className={"flex items-center justify-center h-screen"}>
      <div className={"flex flex-col"}>
        <div className={"text-primary pb-4"}>Welcome to Shout!</div>
        <div className={"pb-4"}>
          Shout is a soundboard app for meetings. With Shout, you can easily inject more fun into your meetings.
        </div>
        <div className={"flex flex-row pb-2"}>
          <div>
            {" "}
            <a className={"text-secondary pr-1"} href={"/Shout.zip"}>
              Click here
            </a>{" "}
          </div>
          <div>to download the Shout app for Microsoft Teams!</div>
        </div>
        <div className={"flex flex-row"}>
          <div className={"pr-1"}>Then, use</div>
          <a
            className={"pr-1 text-secondary"}
            target={"_blank"}
            href={
              "https://support.microsoft.com/en-us/office/use-apps-in-teams-meetings-62bca572-ba7e-4e21-9190-a47c61319739"
            }>
            these instructions
          </a>
          to add the app to your meeting.
        </div>
      </div>
    </div>
  );
}
