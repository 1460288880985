import React from "react";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTimeout } from "../../hooks/useTimeout";

export const KeyBoardKey = ({
  letter,
  onPressedFn,
  onCntrlPressedFn,
}: {
  letter: string;
  onPressedFn: () => void;
  onCntrlPressedFn: () => void;
}) => {
  const [cssClass, setCssClass] = useState("border-primary-focus text-primary-focus");

  const { reset } = useTimeout(
    () => {
      setCssClass("border-primary-focus text-primary-focus");
    },
    1000,
    false
  );

  let startingCss = "kbd kbd-xs ";

  useHotkeys(letter, () => {
    reset();
    onPressedFn();
    setCssClass("bg-primary");
  });

  useHotkeys("shift+" + letter, () => {
    onCntrlPressedFn();
  });

  return (
    <div className={"tooltip tooltip-right z-10"} data-tip={`press ${letter} to play!`}>
      <kbd className={startingCss + cssClass}>{letter}</kbd>
    </div>
  );
};
