import React, { useEffect, useState } from "react";
import { useGlobalStore2 } from "../../hooks/useGlobalState";
import { pages, app, people } from "@microsoft/teams-js";
import { v4 as uuidv4 } from "uuid";
import { Room } from "colyseus.js";

export type ExperienceSelectionType =
  | "full"
  | "soundboard"
  | "timesheet"
  | "shout"
  | "hands"
  | "liveshare"
  | "collabai"
  | "live"

export function ConfigurePage() {
  const [selectedExperience, setSelectedExperience] = useState<ExperienceSelectionType>(null);
  const teamsContext = useGlobalStore2((state) => state.teamsContext);
  const [message, setMessage] = useState("");
  const [selectedPresenters, setSelectedPresenters] = useState([]);
  const [enableDebugMode, setEnableDebugMode] = useState(false);
  const [anyoneCanUseSoundboard, setAnyoneCanUseSoundboard] = useState(false);

  useEffect(() => {
    switch (selectedExperience) {
      case "full":
        console.log("full");
        const uuid = uuidv4();
        console.log("Use uuidv4", uuid);

        pages.config.registerOnSaveHandler((saveEvent) => {
          // room.send("trackPageId", { pageId: uuid });

          let configUrl = `https://${window.location.host}?inTeams=true`;
          if (enableDebugMode) configUrl = configUrl + "&debug=true";

          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: uuid,
            suggestedDisplayName: "Experiences",
          });
          saveEvent.notifySuccess();
        });
        break;

      case "timesheet":
        console.log("timesheet");

        pages.config.registerOnSaveHandler((saveEvent) => {
          let configUrl = `https://${window.location.host}/timesheet?inTeams=true&direct=true`;
          if (enableDebugMode) configUrl = configUrl + "&debug=true";

          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: "Timesheet",
            suggestedDisplayName: "Timesheet",
          });
          saveEvent.notifySuccess();
        });
        break;

      case "shout":
        console.log("shout");

        pages.config.registerOnSaveHandler((saveEvent) => {
          let configUrl = `https://${window.location.host}/acs?inTeams=true`;
          if (enableDebugMode) configUrl = configUrl + "&debug=true";

          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: "ACS",
            suggestedDisplayName: "Shout",
          });
          saveEvent.notifySuccess();
        });
        break;

      case "hands":
        console.log("hands");

        pages.config.registerOnSaveHandler((saveEvent) => {
          let configUrl = `https://${window.location.host}/hands?inTeams=true`;
          if (enableDebugMode) configUrl = configUrl + "&debug=true";

          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: "Hands",
            suggestedDisplayName: "Hands",
          });
          saveEvent.notifySuccess();
        });
        break;

      case "meetingai":
        console.log("meetingai");

        pages.config.registerOnSaveHandler((saveEvent) => {
          let configUrl = `https://${window.location.host}/meetingai?inTeams=true`;
          if (enableDebugMode) configUrl = configUrl + "&debug=true";

          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: "MeetingAI",
            suggestedDisplayName: "Meeting AI",
          });
          saveEvent.notifySuccess();
        });
        break;

      case "liveshare":
        console.log("liveshare");

        pages.config.registerOnSaveHandler((saveEvent) => {
          let configUrl = `https://${window.location.host}/liveshare?inTeams=true`;
          if (enableDebugMode) configUrl = configUrl + "&debug=true";

          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: "LiveShare",
            suggestedDisplayName: "LiveShare",
          });
          saveEvent.notifySuccess();
        });
        break;

      case "live":
          console.log("live");
  
          pages.config.registerOnSaveHandler((saveEvent) => {
            let configUrl = `https://${window.location.host}/live?inTeams=true`;
            if (enableDebugMode) configUrl = configUrl + "&debug=true";
  
            pages.config.setConfig({
              contentUrl: configUrl,
              websiteUrl: configUrl,
              entityId: "Live",
              suggestedDisplayName: "Live",
            });
            saveEvent.notifySuccess();
          });
          break;

      default:
        pages.config.registerOnSaveHandler((saveEvent) => {
          let presenters = anyoneCanUseSoundboard ? "all" : selectedPresenters.map((p) => p.objectId).join(",");
          let configUrl = `https://${window.location.host}/${selectedExperience}?presenters=${presenters}&inTeams=true`;

          if (enableDebugMode) configUrl = configUrl + "&debug=true";
          console.log("SOUNDBOARD CONFIG URL IS", configUrl);
          pages.config.setConfig({
            contentUrl: configUrl,
            websiteUrl: configUrl,
            entityId: selectedExperience ? selectedExperience : "Moments",
            suggestedDisplayName: "Moments",
          });
          saveEvent.notifySuccess();
        });
        break;
    }

    if (!anyoneCanUseSoundboard && selectedExperience === "soundboard" && selectedPresenters.length === 0) {
      // if we haven't picked presenters
    } else {
      pages.config.setValidityState(true);
    }
  }, [selectedExperience, selectedPresenters, anyoneCanUseSoundboard, enableDebugMode]);
  return (
    <div>
      <div className="flex h-screen">
        <div className="m-auto">
          <h1 className={"text-xl"}>
            <span>Get Started with </span>
            <span className={"text-secondary"}>Spark Moments</span>
          </h1>
          <div>Select an option below, then click Save.</div>
          <div className="p-6 card bordered">
            <div className="form-control">
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Full Experience</span>
                <input
                  onClick={() => {
                    setSelectedExperience("full");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "full"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Soundboard</span>
                <input
                  onClick={() => {
                    setSelectedExperience("soundboard");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "soundboard"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Timesheet</span>
                <input
                  onClick={() => {
                    setSelectedExperience("timesheet");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "timesheet"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Shout</span>
                <input
                  onClick={() => {
                    setSelectedExperience("shout");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "shout"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Hands</span>
                <input
                  onClick={() => {
                    setSelectedExperience("hands");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "hands"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">LiveShare</span>
                <input
                  onClick={() => {
                    setSelectedExperience("liveshare");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "liveshare"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Meeting AI</span>
                <input
                  onClick={() => {
                    setSelectedExperience("collabai");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "collabai"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text text-secondary">Live AI</span>
                <input
                  onClick={() => {
                    setSelectedExperience("live");
                  }}
                  type="radio"
                  name="opt"
                  checked={selectedExperience === "live"}
                  className="radio"
                  value=""
                />
              </label>
            </div>
            {selectedExperience === "soundboard" && (
              <div>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">Anyone Can Use</span>
                    <input
                      type="checkbox"
                      checked={anyoneCanUseSoundboard}
                      className="checkbox checkbox-sm"
                      onClick={() => {
                        setAnyoneCanUseSoundboard((prev) => {
                          return !prev;
                        });
                      }}
                    />
                  </label>
                </div>
                <div
                  className={"btn btn-primary btn-xs"}
                  onClick={() => {
                    people
                      .selectPeople({
                        title: "Select Presenters",
                        openOrgWideSearchInChatOrChannel: false,
                        setSelected: [teamsContext.user.id],
                      })
                      .then((people) => {
                        setSelectedPresenters(people);
                      })
                      .catch((err) => setMessage("Error - " + JSON.stringify(err)));
                  }}>
                  Select Presenters
                </div>
                {selectedPresenters.map((p) => {
                  return <div>{p.displayName}</div>;
                })}
              </div>
            )}
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Enable Debug Mode</span>
                <input
                  type="checkbox"
                  checked={enableDebugMode}
                  className="checkbox checkbox-sm"
                  onClick={() => {
                    setEnableDebugMode((prev) => {
                      return !prev;
                    });
                  }}
                />
              </label>
            </div>
            {/* <div className="form-control">
              <label className="cursor-pointer label">
                <span className="label-text">Primary</span>
                <input type="radio" name="opt" checked={false} className="radio radio-primary" value="" />
              </label>
            </div>
            <div className="form-control">
              <label className="cursor-pointer label">
                <span className="label-text">Secondary</span>
                <input type="radio" name="opt" checked={false} className="radio radio-secondary" value="" />
              </label>
            </div>
            <div className="form-control">
              <label className="cursor-pointer label">
                <span className="label-text">Accent</span>
                <input type="radio" name="opt" checked={false} className="radio radio-accent" value="" />
              </label>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Disabled</span>
                <input type="radio" name="opt" checked={false} value="" disabled={true} className="radio" />
              </label>
            </div> */}
          </div>
          {/* <div>{selectedExperience}</div>
          <input
            type="radio"
            id="html"
            name="fav_language"
            value="packabunchas"
            onChange={() => {
              setSelectedExperience("packabunchas");
            }}
          />
          <label htmlFor="html">Packabunchas</label>
          <br />
          <input
            type="radio"
            id="css"
            name="fav_language"
            value="flappymeeting"
            onChange={() => {
              setSelectedExperience("flappymeeting");
            }}
          />
          <label htmlFor="css">Flappy Meeting</label>
          <br />
          <input
            type="radio"
            id="javascript"
            name="fav_language"
            value="camerafun"
            onChange={() => {
              setSelectedExperience("camerafun");
            }}
          />
          <label htmlFor="javascript">Camera Fun</label>
          <br />
          <input
            type="radio"
            id="javascript"
            name="fav_language"
            value="drawing"
            onChange={() => {
              setSelectedExperience("drawing");
            }}
          />
          <label htmlFor="javascript">Drawing</label>
          <button>Save</button>

          <button
            onClick={() => {
              console.log("TRYING TO GET CONTEXT");
              microsoftTeams.app
                .getContext()
                .then((context) => {
                  console.log("GOT CONTEXT", context);
                })
                .catch((err) => {
                  console.log("ERROR GETTING CONTEXT", err);
                });
            }}>
            Get Context
          </button>

          <label htmlFor="javascript">Debug</label>
          <br />
          <input
            type="radio"
            id="javascript"
            name="debug"
            value="debug"
            onChange={() => {
              setSelectedExperience("debug");
            }}
          />

          <button
            onClick={() => {
              setSelectedExperience("");
            }}
            className="inline-flex items-center h-10 px-5 text-white transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline hover:bg-green-800">
            <span>Main Experience</span>

            <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
              <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
              <path
                className="play-btn__svg"
                d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
              />
            </svg>
          </button>

          <button
            onClick={() => {
              setSelectedExperience("results");
            }}
            className="inline-flex items-center h-10 px-5 text-white transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline hover:bg-green-800">
            <span>Results Page</span>

            <svg className="w-4 h-4 ml-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
              <polygon className="play-btn__svg" points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69" />
              <path
                className="play-btn__svg"
                d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
              />
            </svg>
          </button> */}
        </div>
      </div>
    </div>
  );
}
