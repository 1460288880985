import React, { useCallback, useEffect, useRef, useState } from "react";
import create from "zustand";
import { TOM_IMAGE_BASE64 } from "../../Constants";
import { useTimeout } from "../../hooks/useTimeout";
import "./alerts.scss";

export type Alert = {
  text: string;
  imageBase64?: string;
  userDisplayName?: string;
};
export type AlertsStore = {
  shouldShow: boolean;
  setShouldShow: (shouldShow: boolean) => void;

  alerts: Alert[];
  addAlert: (alert: Alert) => void;
};
export const useAlertsStore = create<AlertsStore>((set) => ({
  shouldShow: false,
  setShouldShow: (shouldShow) => set({ shouldShow }),

  alerts: [],
  addAlert: (alert) => {
    set((state) => ({
      alerts: state.alerts.concat(alert),
    }));
  },
}));

function AlertDisplay({ alert }: { alert: Alert }) {
  return (
    <div className="flex flex-row">
      <div className={""}>
        {alert.imageBase64 && (
          <img className="relative z-0 object-cover rounded-full h-6" src={alert.imageBase64} alt="Profile image" />
        )}
      </div>
      <div>
        {alert.userDisplayName && <span className={"px-2"}>{alert.userDisplayName} :</span>}

        <span>{alert.text}</span>
      </div>
    </div>
  );
}

export function Alerts() {
  const { shouldShow, setShouldShow, addAlert } = useAlertsStore();
  const alerts = useAlertsStore((state) => state.alerts);

  const alertToShow = alerts[alerts.length - 1];

  const { reset } = useTimeout(
    () => {
      setShouldShow(false);
    },
    3000,
    true
  );

  useEffect(() => {
    if (alerts.length > 0) {
      setShouldShow(true);
      reset();
    }
  }, [alerts]);

  useEffect(() => {
    // addAlert({ text: "Window Break", userDisplayName: "Tom White", imageBase64: TOM_IMAGE_BASE64 });
  }, []);

  if (!alertToShow) return null;

  return (
    <>
      {/* <div
        style={{ height: "200px" }}
        onClick={() => {
          addAlert({ text: "Hi2" });
        }}>
        CLick
      </div> */}
      {shouldShow && (
        <div
          className={
            "transition-opacity transform fixed z-100 bottom-0 inset-x-0 pb-2 sm:pb-5 opacity-100 scale-100 translate-y-0 ease-out duration-500 z-10"
          }>
          <div className={"max-w-screen-xl mx-auto px-2 sm:px-4"}>
            <div className={"p-2 rounded-lg bg-gray-900 shadow-lg sm:p-3"}>
              <AlertDisplay alert={alertToShow} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
