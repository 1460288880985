import create from "zustand";

export type FabricJSDisplayStore = {    

    text: string;
    questionText: string;
    api: {
        setText: (text: string) => void;
        setQuestionText: (text: string) => void;
    };
};

export const useFabricJSDisplayStore = create<FabricJSDisplayStore>((set, get) => ({
    text: "",
    questionText: "",
    api: {
        setText: (text: string) => {
            set({ text: text}) ;
        },
        setQuestionText: (text: string) => {
            set({ questionText: text })
        }
    }
 }));