import { Client, Room } from "colyseus.js";
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SERVER_URL } from "../Constants";
import { joinOrCreateRoomByTeamsContext, joinOrCreateRoomByTeamsContext2 } from "../Helpers";

let serverUrl = process.env.NODE_ENV === "production" ? SERVER_URL : "ws://localhost:2567";
const client = new Client(serverUrl);

export function useColyseusRoom<T>(
  roomName: string,
  options: any
): { client: Client; room: Room<T> | null; roomState: T } {
  const [room, setRoom] = useState<Room<T>>(null);
  const [roomState, setRoomState] = useState<T>();
  let roomRef = useRef(room);

  useEffect(() => {
    console.log("RUNNING COLYSEUS ROOM USE EFFECT");
    console.log("TEAMSCONTEXT", JSON.stringify(options));
    joinOrCreateRoomByTeamsContext2<T>(roomName, options, client)
      .then((r) => {
        console.log("ROOM NAME IS " + roomName);
        setRoom(r);
        roomRef.current = r;
      })
      .catch((err) => {
        console.error(err);

        debugger;
      });
    // client.joinOrCreate<T>(roomName, options).then((r) => {
    //   setRoom(r);
    // });
    return function leave() {
      console.log("TRYING TO LEAVE ROOM", roomName);

      roomRef.current.leave();
    };
  }, []);

  useEffect(() => {
    function handleStatusChange(remoteState) {
      // console.log("SENDING CHANGES FROM ROOMNAME " + roomName);
      // console.log(remoteState);
    }
    if (!room) return;
    let emitter = room.onStateChange(handleStatusChange);
    emitter.register((data) => {
      setRoomState({ ...(data as any) });
    });
    return () => {
      emitter.clear();
    };
  }, [room]);

  //   useEffect(() => {
  //     console.log("R CHANGES");
  //   }, [roomState]);

  return { client: client, room: room, roomState };
}

export function useColyseusRoom2<T>(roomName: string, options: any): { client: Client; room: Room<T> | null } {
  const [room, setRoom] = useState<Room<T>>(null);
  let roomRef = useRef(room);

  useEffect(() => {
    joinOrCreateRoomByTeamsContext2<T>(roomName, options, client, options)
      .then((r) => {
        console.log("ROOM NAME IS " + roomName);
        setRoom(r);
        roomRef.current = r;
      })
      .catch((err) => {
        console.error(err);
        debugger;
      });

    return function leave() {
      console.log("TRYING TO LEAVE ROOM", roomName);

      roomRef.current.leave();
    };
  }, []);

  return { client: client, room: room };
}
