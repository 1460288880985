import { logger, hasWindow, isFunction } from "@rpldy/shared";
import createState from "@rpldy/simple-state";
import { SENDER_EVENTS, UPLOADER_EVENTS } from "../consts";
import processQueueNext from "./processQueueNext";
import * as abortMethods from "./abort";
import { detachRecycledFromPreviousBatch, getBatchFromState, preparePendingForUpload, removePendingBatches } from "./batchHelpers";

const createUploaderQueue = (options, trigger, cancellable, sender, uploaderId) => {
  const {
    state,
    update
  } = createState({
    itemQueue: [],
    currentBatch: null,
    batches: {},
    items: {},
    activeIds: [],
    aborts: {}
  });

  const getState = () => state;

  const updateState = updater => {
    update(updater);
  };

  const add = item => {
    if (state.items[item.id] && !item.recycled) {
      throw new Error(`Uploader queue conflict - item ${item.id} already exists`);
    }

    if (item.recycled) {
      detachRecycledFromPreviousBatch(queueState, item);
    }

    updateState(state => {
      state.items[item.id] = item;
      state.itemQueue.push(item.id);
    });
  };

  const handleItemProgress = (item, completed, loaded) => {
    if (state.items[item.id]) {
      updateState(state => {
        const stateItem = state.items[item.id];
        stateItem.loaded = loaded;
        stateItem.completed = completed;
      }); //trigger item progress event for the outside

      trigger(UPLOADER_EVENTS.ITEM_PROGRESS, getState().items[item.id]);
    }
  };

  sender.on(SENDER_EVENTS.ITEM_PROGRESS, handleItemProgress);
  sender.on(SENDER_EVENTS.BATCH_PROGRESS, batch => {
    var _state$batches$batch$;

    const batchItems = (_state$batches$batch$ = state.batches[batch.id]) === null || _state$batches$batch$ === void 0 ? void 0 : _state$batches$batch$.batch.items;

    if (batchItems) {
      const [completed, loaded] = batchItems.reduce((res, _ref) => {
        let {
          id
        } = _ref;
        //getting data from state.items since in dev the wrapped state.batch.items and state.items aren't the same objects
        const {
          completed,
          loaded
        } = state.items[id];
        res[0] += completed;
        res[1] += loaded;
        return res;
      }, [0, 0]);
      updateState(state => {
        const stateBatch = state.batches[batch.id].batch; //average of completed percentage for batch items

        stateBatch.completed = completed / batchItems.length; //sum of loaded bytes for batch items

        stateBatch.loaded = loaded;
      });
      trigger(UPLOADER_EVENTS.BATCH_PROGRESS, state.batches[batch.id].batch);
    }
  });
  const queueState = {
    uploaderId,
    getOptions: () => options,
    getCurrentActiveCount: () => state.activeIds.length,
    getState,
    updateState,
    trigger,
    runCancellable: function (name) {
      if (!isFunction(cancellable)) {
        //for flow :(
        throw new Error("Uploader queue - cancellable is of wrong type");
      }

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return cancellable(name, ...args);
    },
    sender,
    handleItemProgress
  };

  if (hasWindow() && logger.isDebugOn()) {
    window[`__rpldy_${uploaderId}_queue_state`] = queueState;
  }

  return {
    updateState,
    getState: queueState.getState,
    runCancellable: queueState.runCancellable,
    uploadBatch: (batch, batchOptions) => {
      if (batchOptions) {
        updateState(state => {
          state.batches[batch.id].batchOptions = batchOptions;
        });
      }

      processQueueNext(queueState);
    },
    addBatch: (batch, batchOptions) => {
      updateState(state => {
        state.batches[batch.id] = {
          batch,
          batchOptions,
          finishedCounter: 0
        };
      });
      batch.items.forEach(add);
      return getBatchFromState(state, batch.id);
    },
    abortItem: id => {
      return abortMethods.abortItem(queueState, id, processQueueNext);
    },
    abortBatch: id => {
      abortMethods.abortBatch(queueState, id, processQueueNext);
    },
    abortAll: () => {
      abortMethods.abortAll(queueState, processQueueNext);
    },
    clearPendingBatches: () => {
      removePendingBatches(queueState);
    },
    uploadPendingBatches: uploadOptions => {
      preparePendingForUpload(queueState, uploadOptions);
      processQueueNext(queueState);
    }
  };
};

export default createUploaderQueue;